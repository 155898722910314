<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Route Plan</span></p>

<div class="container-fluid ">

  <div class="row p-0" *ngIf="RoutePlanTable">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">Route Plan List</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;"
                (click)="onCreateRoutePlanBtn()">
                <span class="material-icons" style="font-size: 2.1vh;"> add</span> Create Route Plan
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 81vh;">
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>

          <select class="form-select device" [(ngModel)]="selectvehicle" name="selectvehicle">
            <option value="" class="select_options" disabled>Select Vehicle</option>
            <option value="D1278YHGF9" class="select_options">D1278YHGF9</option>
            <option value="D1278YHGF9" class="select_options">D1278YHGF9</option>
            <option value="D1278YHGF9" class="select_options">D1278YHGF9</option>
          </select>

          <select class="form-select device" [(ngModel)]="selectroute" name="selectroute">
            <option value="" class="select_options" disabled>Select Route</option>
            <option value="Route1" class="select_options">Route1</option>
            <option value="Route1" class="select_options">Route1</option>
            <option value="Route1" class="select_options">Route1</option>
          </select>

          <select class="form-select device" [(ngModel)]="selectpoi" name="selectpoi">
            <option value="" class="select_options" disabled>Select POI</option>
            <option value="office" class="select_options">office</option>
            <option value="home" class="select_options">home</option>
            <option value="venue" class="select_options">venue</option>
          </select>

        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>

      <!-- POI LIST TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0" style="border-bottom: 1px solid #E3E7EC;">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center" style="width: 11%;">Vehicle Name</th>
              <th class="table_head_text text-center">Route Name</th>
              <th class="table_head_text text-center">Start Point</th>
              <th class="table_head_text text-center">End Point</th>
              <th class="table_head_text text-center">No. of Haults</th>
              <th class="table_head_text text-center">End time</th>
              <th class="table_head_text text-center">Status</th>
              <th class="table_head_text text-center">Action</th>
            </tr>
          </thead>

          <tbody *ngIf="routePlanList.length">

            <tr *ngFor="let plan of routePlanList;let i = index">
              <td class="align-items-center table_data d-flex" style="padding-left: 3vh;">{{plan.vehicle?.Device_Name}}</td>
              <td class="table_data text-center">{{plan.name}}</td>
              <td class="table_data text-center">{{plan.startPoint}}</td>
              <td class="table_data text-center">{{plan.endPoint?.poi?.address}}</td>
              <td class="table_data text-center">{{plan.hault.length}}</td>
              <td class="table_data text-center">{{plan.endtime}}</td>
              <td class="table_data text-center">{{plan.status}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                    <li>
                      <a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <span class="editDeletebtns">Show Haults</span>
                        <mat-slide-toggle style="padding-left: 2.5vh;"></mat-slide-toggle>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <span class="editDeletebtns">View</span>
                        <mat-slide-toggle style="padding-left: 10vh;"></mat-slide-toggle>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

          </tbody>

          <tbody *ngIf="!routePlanList.length">
            <tr>
              <td colspan="8">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
        <div *ngIf="routePlanList.length" class="flex_row_center_spacebetween" style="padding: 1vh 2vh;">
          <div>{{'Showing'|translate}} 1 to 9 of 9 entries</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

    </div>
  </div>

  <div class="row p-0" *ngIf="!RoutePlanTable">

    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 first_col" id="routePlanForm">
      <div class="card addmain_card p-0">
        <div class="addgeohead" style="padding-bottom: 0.75rem;">Create Route Plan</div>
        <div class="card geofence_card2" style="height: 73.1vh;">
          <form [formGroup]="createRoutePlan" (ngSubmit)="onRoutePlanSubmit()">

            <div class="form-group pb-2">
              <label for="startpoint" class="addlabels pb-2">Start Point</label>
              <input type="text" formControlName="startpoint" class="form-control inputfields" id="startpoint"
                aria-describedby="startpoint">
              <span class="text-danger validation"
                *ngIf="(RouteFormControl.startpoint.touched || submitted) && RouteFormControl.startpoint.errors?.required">
                *Startpoint is required.
              </span>
            </div>

            <div class="form-group pb-3">
              <label for="hault" class="addlabels pb-2">Add Hault</label>
              <input type="text" class="form-control inputfields" id="hault" formControlName="hault"
                aria-describedby="hault">
              <span class="text-danger validation"
                *ngIf="(RouteFormControl.hault.touched || submitted) && RouteFormControl.hault.errors?.required">
                *Hault is required.
              </span>
            </div>

            <div class="form-group pb-3">
              <select class="form-select form_select1" formControlName="poi">
                <option value="" selected>Select POI</option>
                <option value="POI1" selected>POI1</option>
                <option value="POI1">POI1</option>
                <option value="POI1">POI1</option>
                <option value="POI1">POI1</option>
                <option value="POI1">POI1</option>
              </select>
            </div>

            <div class="form-group pb-3">
              <select class="form-select form_select1" formControlName="endpoint">
                <option value="" selected>Select End Point</option>
                <option value="EndDemo" selected>EndDemo</option>
                <option value="EndDemo">EndDemo</option>
                <option value="EndDemo">EndDemo</option>
                <option value="EndDemo">EndDemo</option>
                <option value="EndDemo">EndDemo</option>
              </select>
            </div>

            <div class="form-group pb-3">
              <select class="form-select form_select1" formControlName="vehiclename">
                <option value="" selected>Search Vehicle</option>
                <option value="DL12CG1001" selected>DL12CG1001</option>
                <option value="DL12CG1001">DL12CG1001</option>
                <option value="DL12CG1001">DL12CG1001</option>
                <option value="DL12CG1001">DL12CG1001</option>
                <option value="DL12CG1001">DL12CG1001</option>
              </select>
            </div>

            <div class="row pt-1" style="padding-bottom: 0.75rem !important;">
              <div class="col-6"
                style="display: flex;flex-direction: row;justify-content:space-between;align-items: center;">
                <mat-checkbox formControlName="checkhalt" matTooltip="Speed Alert"></mat-checkbox>
                <div class="addlabels">Speed Alert</div>
              </div>
              <div class="col-6">
                <div class="input-group">
                  <div class="input-group inputborder">
                    <input type="number" formControlName="time" class="form-control inputfields" style="border:none;"
                      aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <span class="input-group-text searchIcon" style="padding: 1.1vh;color: #fff;"
                        id="basic-addon2">Km/Hr</span>
                    </div>
                  </div>
                </div>
                <span class="text-danger validation"
                  *ngIf="(RouteFormControl.time.touched || submitted) && RouteFormControl.time.errors?.required">
                  *Km/Hr is required
                </span>
              </div>
            </div>

            <div class="form-group pb-2">
              <label for="totaldistance" class="addlabels pb-2">Total Distance</label>
              <input type="number" formControlName="totaldistance" class="form-control inputfields" id="totaldistance"
                aria-describedby="totaldistance">
              <span class="text-danger validation"
                *ngIf="(RouteFormControl.totaldistance.touched || submitted) && RouteFormControl.totaldistance.errors?.required">
                *Total distance is required.
              </span>
            </div>

            <div class="row" style="padding-top: 2vh;">
              <div class="col-6">
                <button mat-raised-button type="submit" class="submitbtn">CREATE</button>
              </div>
              <div class="col-6">
                <button mat-raised-button class="cancelbtns">SHOW ROUTE</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12 col_map" id="myMap">

      <!-- GOOGLE MAP -->
      <div class="card main_card p-0">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
      </div>

      <!-- MAP RIGHT ICONS -->
      <div class="btn-group-vertical" role="group" aria-label="Basic example"
        style="position: absolute;top: 2vh;right: 3vh;">
        <button matTooltip="Back" type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;padding-bottom: 0;" (click)="onClickBack()">
            <span class="material-icons" style="color: #fff;"> 
              arrow_back
            </span>
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map1.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map4.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map2.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns openbtn" style="margin-top: 1vh;border-radius: 5px;">
          <img src="/assets/icons/map6.png" alt="mapicon">
        </button>
      </div>

      <!-- MAP BOTTOM ICONS -->
      <div class="btn-group" role="group" aria-label="Basic example" style="position: absolute;bottom: 3vh;left: 4vh;">
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
          <img src="/assets/icons/map8.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;">
          <img src="/assets/icons/map9.png" alt="mapicon">
        </button>
        <button type="button" class="btn mapbtns" style="margin-left: 2vh;border-radius: 5px;padding: 0.5vh 0.8vh;"
          (click)="getFullWidth()">
          <img src="/assets/icons/map10.png" alt="mapicon">
        </button>
      </div>

    </div>

  </div>

</div>
