<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Dealers'|translate}}</span></p> -->
<app-common-modal-popup>
<div class="" modelheadertext *ngIf="gridMode">{{'Dealers'|translate}}</div>
<div class="container-fluid " modeldata *ngIf="gridMode">

  <div class="row p-0">
    <div class="card p-0 headcard">
      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> {{'Add Dealer'|translate}}
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 47vh;">
          <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="fromdate" name="fromdate" />
          <span class="to">{{'To'|translate}}</span>
          <input type="date" id="meeting" value="2022-03-27" [(ngModel)]="todate" name="todate" />
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- DEALERS LIST TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0 common_scroller">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">{{'Name'|translate}}</th>
              <th class="table_head_text text-center">{{'Email ID'|translate}}</th>
              <th class="table_head_text text-center">{{'Mobile No'|translate}}</th>
              <th class="table_head_text text-center">{{'User Id'|translate}}</th>
              <th class="table_head_text text-center">{{'Password'|translate}}</th>
              <th class="table_head_text text-center">{{'Created On'|translate}}</th>
              <th class="table_head_text text-center">{{'Expires On'|translate}}</th>
              <th class="table_head_text text-center">{{'Last Activity '|translate}}</th>
              <th class="table_head_text text-center">{{'Login Type'|translate}}</th>
              <th class="table_head_text text-center">{{'Total Vehicles'|translate}}</th>
              <th class="table_head_text text-center">{{'Deleted Vehicles'|translate}}</th>
              <th class="table_head_text text-center">{{'Allocated Points'|translate}}</th>
              <th class="table_head_text text-center">{{'Available Points'|translate}}</th>
              <th class="table_head_text text-center">{{'Token'|translate}}</th>
              <th class="table_head_text text-center">{{'Documents'|translate}}</th>
              <th class="table_head_text text-center">{{'Suspend Account'|translate}}</th>
              <th class="table_head_text text-center">{{'Action'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="dealersList.length">
            <tr *ngFor="let dealer of dealersList |  paginate : {
              itemsPerPage: 10,
              currentPage: p,
              totalItems: this.dealersList.length
            };let i = index">
              <td class="table_data text-center">{{dealer.first_name}} {{dealer.last_name}}</td>
              <td class="table_data text-center">{{dealer.email}}</td>
              <td class="table_data text-center">{{dealer.phone}}</td>
              <td class="table_data text-center">{{dealer.user_id}}</td>
              <td class="table_data text-center">{{dealer.pass}}</td>
              <td class="table_data text-center">{{dealer.created_on | date :'medium'}}</td>
              <td class="table_data text-center">{{dealer.expire_date | date :'medium'}}</td>
              <td class="table_data text-center">{{dealer.last_activity_on | date :'medium'}}</td>
              <td class="table_data text-center">NA</td>
              <td class="table_data text-center">{{dealer.total_vehicle}}</td>
              <td class="table_data text-center">{{dealer.delDevices}}</td>
              <td class="table_data text-center">{{dealer.point_Allocated}}</td>
              <td class="table_data text-center">0</td>
              <td class="table_data text-center">{{dealer.notificationTokenCount}}</td>
              <td class="table_data text-center">{{dealer.imageDoc}}</td>
              <td class="table_data text-center">{{dealer.accountSuspended}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop4" (click)="onClickEdit(dealer)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;"
                        (click)="onClickDelete(dealer._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop1" (click)="onClickDealerPermission(dealer)">
                        <img src="/assets/icons/dealerpermission.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Dealer Permission'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop2">
                        <img src="/assets/icons/addpoints.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.8vh;">{{'Add Points'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/suspendaccount.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Suspend Account'|translate}}</span>
                      </a></li>
                    <li>
                      <a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <span class="editDeletebtns">{{'Dealer Status'|translate}}</span>
                        <mat-slide-toggle style="padding-left: 10.5vh;" [checked]="dealer.status"></mat-slide-toggle>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <span class="editDeletebtns">{{'Permission Dealer'|translate}}</span>
                        <mat-slide-toggle style="padding-left: 6vh;" [checked]="dealer.device_add_permission">
                        </mat-slide-toggle>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <span class="editDeletebtns">{{'Suspend Account'|translate}}</span>
                        <mat-slide-toggle style="padding-left: 6.7vh;" [checked]="dealer.accountSuspended">
                        </mat-slide-toggle>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>

          </tbody>

          <tbody *ngIf="!dealersList.length">
            <tr>
              <td colspan="9">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <div *ngIf="dealersList.length" class="flex_row_center_spacebetween"
        style="padding:2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{p}} {{'to'|translate}} {{dealersList.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{dealersList.length/10 |  number: '1.0-0'}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>

</div>
</app-common-modal-popup>

<!-- ADD TRIP MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content" style="border: none;">
      <div class="modal-header addtriphead">
        <div class="headtext">{{'Add Dealer'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh !important;border:none !important;">
        <form [formGroup]="addDealerForm" (ngSubmit)="onDealerSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="firstname" class="addlabels pb-2">{{'First Name'|translate}}</label>
                <input type="text" formControlName="firstname" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="firstname" aria-describedby="firstname">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.firstname.touched || submitted) && AddDealerFormControl.firstname.errors?.required">
                  *{{'First Name'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="lastname" class="addlabels pb-2">{{'Last Name'|translate}}</label>
                <input type="text" formControlName="lastname" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="lastname" aria-describedby="lastname">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.lastname.touched || submitted) && AddDealerFormControl.lastname.errors?.required">
                  *{{'Last Name'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="usernumber" class="addlabels pb-2">{{'User Number'|translate}}</label>
                <input type="number" formControlName="usernumber" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="usernumber" aria-describedby="usernumber">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.usernumber.touched || submitted) && AddDealerFormControl.usernumber.errors?.required">
                  *{{'User Number'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="email" class="addlabels pb-2">{{'User Id'|translate}}</label>
                <input type="text" formControlName="email" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="email" aria-describedby="email">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.email.touched || submitted) && AddDealerFormControl.email.errors?.required">
                  *{{'User Id'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="password" class="addlabels pb-2">{{'Password'|translate}}</label>
                <input type="password" formControlName="password" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="password" aria-describedby="password">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.password.touched || submitted) && AddDealerFormControl.password.errors?.required">
                  *{{'Password'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="confirmpassword" class="addlabels pb-2">{{'Confirm Password'|translate}}</label>
                <input type="password" formControlName="confirmpassword" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="confirmpassword"
                  aria-describedby="confirmpassword">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.confirmpassword.touched || submitted) && AddDealerFormControl.confirmpassword.errors?.required">
                  *{{'Confirm Password'|translate}} {{'is required'|translate}}
                </span>
                <span class="text-danger validation"
                  *ngIf="AddDealerFormControl.confirmpassword.touched && AddDealerFormControl.confirmpassword.errors?.passwordMismatch">
                  * {{'Passwords does not match'|translate}}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="address" class="addlabels pb-2">{{'Address'|translate}}</label>
                <input type="text" formControlName="address" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="address" aria-describedby="address">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.address.touched || submitted) && AddDealerFormControl.address.errors?.required">
                  *{{'Address'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="timezone" class="addlabels pb-2">{{'Timezone'|translate}}</label>
                <select class="form-select form_select1 common_scroller"
                  style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="timezone"
                  formControlName="timezone">
                  <option value="" disabled>{{'Select'|translate}}{{'Timezone'|translate}}</option>
                  <option *ngFor="let time of timezoneArray; let i = index" value="{{time.viewValue}}">
                    {{time.viewValue}}</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.timezone.touched || submitted) && AddDealerFormControl.timezone.errors?.required">
                  *{{'Timezone'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row pb-2">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="userid" class="addlabels pb-2">{{'User Id'|translate}}</label>
                <input type="text" formControlName="userid" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="userid" aria-describedby="userid">
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.userid.touched || submitted) && AddDealerFormControl.userid.errors?.required">
                  *{{'User Id'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6" style="display: flex;align-items: self-end;justify-content: space-around;">
              <div class="form-group d-flex flex-row align-items-center pb-2">
                <mat-slide-toggle formControlName="radiocheck" class="addlabels">{{'Inventory'|translate}}</mat-slide-toggle>
                <mat-slide-toggle formControlName="bussinessType" class="addlabels" style="margin-left: 2vh;">{{'Bussiness Type'|translate}}
                </mat-slide-toggle>
              </div>
            </div>
          </div>

          <div class="row py-1" style="border-bottom: 1px solid #E3E7EC;">
            <div class="flex_row_center_spacebetween">
              <span class="addlabels">{{'Upload Documents'|translate}}</span>
              <span class="material-icons adddocicon"> add </span>
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <div class="form-group pt-2 pb-1">
                <label for="doctype" class="addlabels pb-2">{{'Doc Type'|translate}}</label>
                <select class="form-select form_select1 common_scroller"
                  style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="doctype"
                  formControlName="doctype">
                  <option value="" disabled>{{'Select'|translate}} {{'Doc Type'|translate}}</option>
                  <option *ngFor="let doc of documentList; let i = index" value="{{doc.docName}}">
                    {{doc.docName}}</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.doctype.touched || submitted) && AddDealerFormControl.doctype.errors?.required">
                  *{{'Doc Type'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pt-2 pb-1">
                <label for="docnumber" class="addlabels pb-2">{{'Doc Number'|translate}}</label>

                <!-- <input type="number" formControlName="docnumber" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="docnumber"
                aria-describedby="docnumber"> -->

                <div class="input-group" style="width: unset;">
                  <input type="number" formControlName="docnumber" class="form-control inputfields" placeholder="{{'Search'|translate}}"
                    style="background: #FFF;box-shadow:none;color:#000;" id="docnumber">
                  <div class="input-group-append">
                    <button class="btn" type="button"
                      style="padding: 1.1vh 1.4vh 1.1vh 1.4vh;background-color: #F84A67;">
                      <img src="/assets/icons/docnumber.png" alt="search" style="width: 2.3vh;">
                    </button>
                  </div>
                </div>

                <span class="text-danger validation"
                  *ngIf="(AddDealerFormControl.docnumber.touched || submitted) && AddDealerFormControl.docnumber.errors?.required">
                  *{{'Doc Number'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-1" style="margin-top: 5.4vh;">
              <button class="deletebtn">
                <img src="/assets/icons/delete_white.png" alt="delete">
              </button>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <button mat-raised-button class="cancelbtn" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
            <button mat-raised-button type="submit" class="submit">{{'SAVE' | translate}}</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>

<!-- DEALER PERMISSION MODAL -->

<div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);border-radius: 5px;border:none">
      <div class="modal-header" style="border-bottom: 1px solid #324151;background-color:#24282E !important;">
        <div class="card-title titlehaed1 m-0">{{'Dealer Permission'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body" style="background-color:#24282E !important;">
        <form>
          <div class="d-flex flex-column pb-4 pt-1">
            <div class="flex_row_center_spacebetween" style="margin: 0 5vh;">
              <span class="addlabels2">{{'Add Device'|translate}}</span>
              <mat-slide-toggle [checked]="addDevice"></mat-slide-toggle>
            </div>
            <div class="flex_row_center_spacebetween mt-2" style="margin: 0 5vh;">
              <span class="addlabels2">{{'Add Customer'|translate}}</span>
              <mat-slide-toggle [checked]="addCustomer"></mat-slide-toggle>
            </div>
          </div>

          <div class="flex_row_center_spacebetween">
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;">{{'SAVE' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- ADD POINTS MODAL -->

<div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content content3">
      <div class="modal-header" style="border-bottom: 1px solid #324151;">
        <div class="card-title titlehaed1 m-0">{{'Add Points'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form>

          <div class="form-group pb-3">
            <label for="poiname" class="addlabels3 pb-2">{{'Points'|translate}}</label>
            <input type="number" class="form-control inputfields" id="poiname" aria-describedby="poiname">
          </div>

          <div class="form-group pb-3">
            <label for="poinames" class="addlabels3 pb-2">{{'Price/Unit'|translate}}</label>
            <input type="number" class="form-control inputfields" id="poinames" aria-describedby="poinames">
          </div>

          <div class="form-group pb-4" style="border-bottom: 1px solid #324151;">
            <label for="radius" class="addlabels3 pb-2">Payment Status</label>
            <select class="form-select form_select1" style="height: 5.7vh;">
              <option value="Paid">Paid</option>
              <option value="Credit">Credit</option>
              <option value="Send Payment Link">Send Payment Link</option>
            </select>
          </div>

          <div class="d-flex flex-column py-4">
            <div class="flex_row_center_spacebetween pb-2">
              <span class="addlabels3">{{'Total Allocated Points'|translate}}</span>
              <span class="addlabels3">77</span>
            </div>
            <div class="flex_row_center_spacebetween pb-2">
              <span class="addlabels3">{{'Total Available Points'|translate}}</span>
              <span class="addlabels3">9</span>
            </div>
            <div class="flex_row_center_spacebetween pb-2">
              <span class="addlabels3">{{'Total Amount'|translate}}</span>
              <span class="addlabels3">0</span>
            </div>
          </div>

          <div class="flex_row_center_spacebetween">
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;">{{'PROCEED'|translate}}</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<!-- EDIT DEALER MODAL -->

<div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content content3">
      <div class="modal-header" style="border-bottom: 1px solid #324151;">
        <div class="card-title titlehaed1 m-0">Edit Dealer</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding: 1vh 3vh;">
        <form [formGroup]="editDealerForm" (ngSubmit)="onEditDealerSubmit()">

          <div class="form-group pb-2">
            <label for="userids" class="addlabels3 pb-2">{{'User ID'|translate}}</label>
            <input type="text" class="form-control inputfields" formControlName="userids" aria-describedby="userids">
          </div>

          <div class="form-group pb-2">
            <label for="first_name" class="addlabels3 pb-2">{{'First Name'|translate}}</label>
            <input type="text" class="form-control inputfields" formControlName="first_name"
              aria-describedby="first_name">
          </div>

          <div class="form-group pb-2">
            <label for="last_name" class="addlabels3 pb-2">{{'Last Name'|translate}}</label>
            <input type="text" class="form-control inputfields" formControlName="last_name"
              aria-describedby="last_name">
          </div>

          <div class="form-group pb-2">
            <label for="emailid" class="addlabels3 pb-2">{{'Email ID'|translate}}</label>
            <input type="email" class="form-control inputfields" formControlName="emailid" aria-describedby="emailid">
          </div>

          <div class="form-group pb-2">
            <label for="phonenumber" class="addlabels3 pb-2">{{'Mobile Number'|translate}}</label>
            <input type="number" class="form-control inputfields" formControlName="phonenumber"
              aria-describedby="phonenumber">
          </div>

          <div class="form-group pb-2">
            <label for="expdate" class="addlabels3 pb-2">{{'Expiration Date'|translate}}</label>
            <input type="date" class="form-control inputfields" formControlName="expdate" style="width: 100% !important;height: 5.6vh !important; 
            border:1px solid #324151 !important;flex-flow: row !important;" aria-describedby="expdate">
          </div>

          <div class="form-group pb-3">
            <label for="addresss" class="addlabels3 pb-2">{{'Address'|translate}}</label>
            <input type="text" class="form-control inputfields" formControlName="addresss" aria-describedby="addresss">
          </div>

          <div class="flex_row_center_spacebetween">
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
            <button mat-raised-button class="cancelbtn" style="width: 47%;padding: 0.8vh 0;">{{'PROCEED'|translate}}</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
