import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from 'src/app/services/common.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var $: any;

@Component({
  selector: 'app-device-groups',
  templateUrl: './device-groups.component.html',
  styleUrls: ['./device-groups.component.css']
})
export class DeviceGroupsComponent implements OnInit {
  todate = "";
  fromdate = "";
  pageOption = 10;
  groupListData = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addGroupForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  sortcolumnName = '';
  sortdirection = '';
  isAscending = true;
  modalRef?: BsModalRef;
  deviceList: any[] = [];
  userData:any ;
  modelForm:FormGroup;
  selectedGroup: any = {};
  constructor(
    private fb: FormBuilder, 
    private customValidator: ValidationService,
    private commonService: CommonService,
    private modalService: BsModalService) {
    this.addGroupForm = this.fb.group(
      {
        id: [0],
        name: ['', Validators.required],
        attributes: this.fb.group({
          contact: [''],
        }),        
        groupId: [0]       
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
      this.modelForm = new FormGroup({
        searchValue: new FormControl(''),
       
      });
  }
  
  ngOnInit(): void {    
    
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData =  JSON.parse(userDataObj);
        this.superAdmin = this.userData && this.userData.administrator;
      } catch (error) {}
    }
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "deviceGroup") {
        this.gridMode = true;
        this.addMode = false;
      }
      this.getAllGroupData();
    })
  }

  get AddModelMasterControl() {
    return this.addGroupForm.controls;
  }

  getAllGroupData() {
    this.commonService.getRequest("groups", []).then((resp: any) => {
      if (resp?.status) { 
        this.groupListData = resp.data;
      }
    })
  }

  onModelMasterSubmit() {
      if (!this.addGroupForm.valid) {
        this.addGroupForm.controls.name.markAsTouched();
        return;
      }
      
      if (this.addGroupForm.value.id != null && this.addGroupForm.value.id >= 0) {
        let body = {
          id: this.addGroupForm.value.id,
          name: this.addGroupForm.value.name,
          attributes: {
            contact: this.addGroupForm.value.attributes.contact
          },
          groupId: this.addGroupForm.value.groupId
        }
        this.commonService.putRequest(`groups/${this.addGroupForm.value.id}`, body).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your groups has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addGroupForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });
      } else {
        let body = {
          name: this.addGroupForm.value.name,
          attributes: {
            contact: this.addGroupForm.value.attributes.contact
          }
        }
        this.commonService.postRequest("groups", body).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your groups has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addGroupForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });        
      }    
  }

  onEditModelMaster(model){
    this.gridMode = false;
    this.addMode = true;
    this.addGroupForm.patchValue({
      id: model.id,    
      name: model.name,
      attributes: {
        contact: model.attributes?.contact,
      },      
      groupId: model.groupId
    })
    this.modelId = model.id;
  }

  onDeleteModelMaster(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Group!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService.deleteRequest("groups/"+modelId).then(resp => {
          console.log(resp);
          this.gridMode = true;
          this.addMode = false;
          this.getAllGroupData();
        });
        Swal.fire(
          'Deleted!',
          'Group has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Group is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.gridMode = true;
    this.addMode = false;
    this.addGroupForm.reset();
  }

  onAddModelMaster() {
    this.gridMode = false;
    this.addMode = true;
    this.editForm = false;
    this.addGroupForm.reset();
  }

  values(event) {
  }

  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }
  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.groupListData.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.groupListData;
    }
  }

  assignDevice(group, modal) {
    this.modalRef = this.modalService.show(modal, { class : "modal-xl"});
    this.deviceList = [];
    console.log('---------', group);
    this.commonService.getRequest('devices', []).then((resp: any) => {
      if (resp?.status) {
        this.deviceList = resp.data;
        this.getUserDevice(this.userData.id, group);
      }
    });
  }
  getUserDevice(userId, group) {
    this.commonService
      .getRequest('devices', ['userId=' + userId])
      .then((resp: any) => {
        if (resp?.status) {
          // let mappedId = resp.data.map((d: any) => {
          //   return d.id;
          // });
          this.selectedGroup = group;
          this.deviceList.forEach((d: any, k: number) => {
            this.deviceList[k]['mapUserId'] = userId;
            this.deviceList[k]['mapGroupId'] = group.id;
            this.deviceList[k]['isSelectedDevice'] = false;
              //mappedId.indexOf(d.id) > -1;
          });
          this.getMappedGroupsDevices(group.id);
        }
      });
  }
  getMappedGroupsDevices(groupdId) {
    this.commonService
      .getRequest('positions?status=ALL&limit=10&offset=0&groupid='+groupdId, [])
      .then((resp: any) => {
        if (resp?.status) {
          resp.data.forEach((d: any) => {
            let match = this.deviceList.findIndex(x => x.id == d.deviceId);
            if (match >= 0) {
              this.deviceList[match]['isSelectedDevice'] = true;
            }
          });
        }
      });
  }
  changeEventDevices(e: any, device) {
    if (e.checked) {
      device.groupId = this.selectedGroup.id;
      this.commonService
          .putRequest('devices/' + device.id, {
            id: device.id,
            name: device.name,
            uniqueId: device.uniqueId,
            groupId: device.groupId,
            phone: device.phone,
            model: device.model,
            contact: device.contact,
            category: device.category,
            disabled: device.disabled,
            distanceForday: device.distanceForday,
            attributes: device.attributes,
            expirationTime: device.expirationTime
          })
          .then((resp: any) => {
            this.selectedGroup = {};
            // this.gridMode = true;
            // this.addMode = false;
            // this.getAllDevices();
            // $('#btnclose').click();
            // Swal.fire({
            //   icon: 'success',
            //   title: 'Your device has been Updated Successfully',
            //   showConfirmButton: false,
            //   timer: 1500,
            // });
          },(err)=>{
            //console.log(err);
             Swal.fire({
               icon: "error",
               title: "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
    }
    else {
      device.groupId = 0;
      this.commonService
            .putRequest('devices/' + device.id, {
              id: device.id,
              name: device.name,
              uniqueId: device.uniqueId,
              groupId: device.groupId,
              phone: device.phone,
              model: device.model,
              contact: device.contact,
              category: device.category,
              disabled: device.disabled,
              distanceForday: device.distanceForday,
              attributes: device.attributes,
              expirationTime: device.expirationTime
            })
            .then((resp: any) => {
              this.selectedGroup = {};
              // this.gridMode = true;
              // this.addMode = false;
              // this.getAllDevices();
              // $('#btnclose').click();
              // Swal.fire({
              //   icon: 'success',
              //   title: 'Your device has been Updated Successfully',
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
            },(err)=>{
              //console.log(err);
               Swal.fire({
                 icon: "error",
                 title: "Something Went Wrong",
                 showConfirmButton: false,
                 timer: 1500,
               });
             });
    }    
  }
  closeDevicesModal() {
    this.modalRef.hide();
  }
}
