<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Driver Master'|translate}}</span></p>

<div class="container-fluid ">
  <div class="row p-0">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">{{'Device Model'|translate}}</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop" (click)="onAddDealer()">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> {{'Add Device Model'|translate}}
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}" ([ngModel])="searchdealer" (input)="values($event)"
                name="searchdealer" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 47vh;">
          <input type="date" id="meeting" value="2022-03-26" [(ngModel)]="fromdate" name="fromdate" />
          <span class="to">{{'To'|translate}}</span>
          <input type="date" id="meeting" value="2022-03-27" [(ngModel)]="todate" name="todate" />
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- DEVICE MODEL TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0" >

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">{{'Device Type'|translate}}</th>
              <th class="table_head_text text-center">{{'Configuration Command'|translate}}</th>
              <th class="table_head_text text-center">{{'Location Command'|translate}}</th>
              <th class="table_head_text text-center">{{'Immobilzer Command'|translate}}</th>
              <th class="table_head_text text-center">{{'Resume Command'|translate}}</th>
              <th class="table_head_text text-center">{{'Reset Command'|translate}}</th>
              <th class="table_head_text text-center">{{'Immoblizer Type'|translate}}</th>
              <th class="table_head_text text-center">{{'Port'|translate}}</th>
              <th class="table_head_text text-center">{{'SMS Timezone'|translate}}</th>
              <th class="table_head_text text-center">{{'Action'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="deviceModelList.length">
            <tr *ngFor="let device of deviceModelList |  paginate : {
                itemsPerPage: 10,
                currentPage: page,
                totalItems: this.deviceModelList.length
              };let i=index">
              <td class="table_data text-center">{{device.device_type}}</td>
              <td class="table_data text-center">{{device.configuration_command}}</td>
              <td class="table_data text-center">{{device.location_command}}</td>
              <td class="table_data text-center">{{device.immoblizer_command}}</td>
              <td class="table_data text-center">{{device.resume_command}}</td>
              <td class="table_data text-center">{{device.reset_command}}</td>
              <td class="table_data text-center">{{device.imobliser_type}}</td>
              <td class="table_data text-center">{{device.deviceport}}</td>
              <td class="table_data text-center">{{device.sms_timezone}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop2" (click)="onEditDevice(device)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;" (click)="onDeleteDevice(device._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!deviceModelList.length">
            <tr>
              <td colspan="10">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <!-- PAGINATION -->
      <div *ngIf="deviceModelList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{deviceModelList.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{deviceModelList.length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

    </div>
  </div>
</div>

<!-- ADD DRIVER MASTER MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header addtriphead">
        <div class="headtext">{{'Add Device Model'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addDeviceModelForm" (ngSubmit)="onDeviceModelSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="devicename" class="addlabels pb-2">{{'Device Model Name'|translate}}</label>
                <input type="text" formControlName="devicename" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="devicename"
                  aria-describedby="devicename">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.devicename.touched || submitted) && AddDriverFormControl.devicename.errors?.required">
                  *{{'Device Model Name'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="configcommand" class="addlabels pb-2">{{'Configuration Command'|translate}}</label>
                <input type="text" formControlName="configcommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="configcommand"
                  aria-describedby="configcommand">
                <span class="text-danger validation"
                *ngIf="(AddDriverFormControl.configcommand.touched || submitted) && AddDriverFormControl.configcommand.errors?.required">
                *{{'Configuration Command'|translate}} {{'is required'|translate}}.
              </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="locationcommand" class="addlabels pb-2">{{'Location Command'|translate}}</label>
                <input type="text" formControlName="locationcommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="locationcommand"
                  aria-describedby="locationcommand">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.locationcommand.touched || submitted) && AddDriverFormControl.locationcommand.errors?.required">
                  *{{'Location Command'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="immocommand" class="addlabels pb-2">{{'Immobilzer Command'|translate}}</label>
                <input type="text" formControlName="immocommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="immocommand"
                  aria-describedby="immocommand">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.immocommand.touched || submitted) && AddDriverFormControl.immocommand.errors?.required">
                  *{{'Immobilzer Command'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="resetcommand" class="addlabels pb-2">{{'Reset Command'|translate}}</label>
                <input type="text" formControlName="resetcommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="resetcommand"
                aria-describedby="resetcommand">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.resetcommand.touched || submitted) && AddDriverFormControl.resetcommand.errors?.required">
                  *{{'Reset Command'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="resumecommand" class="addlabels pb-2">{{'Resume Command'|translate}}</label>
                <input type="text" formControlName="resumecommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="resumecommand"
                aria-describedby="resumecommand">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.resumecommand.touched || submitted) && AddDriverFormControl.resumecommand.errors?.required">
                  *{{'Resume Command'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="timezone" class="addlabels pb-2">{{'Timezone'|translate}}</label>
                <input type="text" formControlName="timezone" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="timezone"
                  aria-describedby="timezone">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.timezone.touched || submitted) && AddDriverFormControl.timezone.errors?.required">
                  *{{'Timezone'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="apn" class="addlabels pb-2">{{'APN'|translate}}</label>
                <input type="text" formControlName="apn" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="apn"
                  aria-describedby="apn">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.apn.touched || submitted) && AddDriverFormControl.apn.errors?.required">
                  *{{'APN'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="port" class="addlabels pb-2">{{'Port'|translate}}</label>
                <input type="number" formControlName="port" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="port"
                  aria-describedby="port">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.port.touched || submitted) && AddDriverFormControl.port.errors?.required">
                  *{{'Port'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smsIp" class="addlabels pb-2">{{'SMS IP'|translate}}</label>
                <input type="text" formControlName="smsIp" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smsIp"
                  aria-describedby="smsIp">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.smsIp.touched || submitted) && AddDriverFormControl.smsIp.errors?.required">
                  *{{'SMS IP'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smsApn" class="addlabels pb-2">{{'SMS APN'|translate}}</label>
                <input type="text" formControlName="smsApn" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smsApn"
                  aria-describedby="smsApn">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.smsApn.touched || submitted) && AddDriverFormControl.smsApn.errors?.required">
                  *{{'SMS APN'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smstimezone" class="addlabels pb-2">{{'SMS Timezone'|translate}}</label>
                <input type="text" formControlName="smstimezone" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smstimezone"
                  aria-describedby="smstimezone">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.smstimezone.touched || submitted) && AddDriverFormControl.smstimezone.errors?.required">
                  *{{'SMS Timezone'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="manufacture" class="addlabels pb-2">{{'Manufacturer'|translate}}</label>
                <input type="text" formControlName="manufacture" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="manufacture"
                  aria-describedby="manufacture">
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.manufacture.touched || submitted) && AddDriverFormControl.manufacture.errors?.required">
                  *{{'Manufacturer'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="Immobilzertype" class="addlabels pb-2">{{'Immobilzer Type'|translate}}</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="Immobilzertype" formControlName="Immobilzertype">
                    <option value="1.0">Server Command</option>
                    <option value="0.0">Mobile Command</option>
                  </select>
                <span class="text-danger validation"
                  *ngIf="(AddDriverFormControl.Immobilzertype.touched || submitted) && AddDriverFormControl.Immobilzertype.errors?.required">
                  *{{'Immobilzer Type'|translate}} {{'is required'|translate}}.
                </span>
              </div>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <button mat-raised-button type="button" class="cancelbtn" data-bs-dismiss="modal" (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
            <button mat-raised-button *ngIf="!editForm" type="submit" class="cancelbtn">{{'SAVE' | translate}}</button>
            <button mat-raised-button *ngIf="editForm" type="submit" class="cancelbtn">{{'UPDATE'|translate}}</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>

<!-- EDIT DRIVER MASTER MODAL -->
<div class="modal" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header addtriphead">
        <div class="headtext">{{'Edit Device Model'|translate}}</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close" (click)="onEditCloseModal()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editDeviceModelForm" (ngSubmit)="onEditDeviceModelSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="deviceName" class="addlabels pb-2">{{'Device Model Name'|translate}}</label>
                <input type="text" formControlName="deviceName" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="deviceName"
                  aria-describedby="deviceName">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="configCommand" class="addlabels pb-2">{{'Configuration Command'|translate}}</label>
                <input type="text" formControlName="configCommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="configCommand"
                  aria-describedby="configCommand">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="locationCommand" class="addlabels pb-2">{{'Location Command'|translate}}</label>
                <input type="text" formControlName="locationCommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="locationCommand"
                  aria-describedby="locationCommand">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="immoCommand" class="addlabels pb-2">{{'Immobilzer Command'|translate}}</label>
                <input type="text" formControlName="immoCommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="immoCommand"
                  aria-describedby="immoCommand">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="resetCommand" class="addlabels pb-2">{{'Reset Command'|translate}}</label>
                <input type="text" formControlName="resetCommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="resetCommand"
                aria-describedby="resetCommand">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="resumeCommand" class="addlabels pb-2">{{'Resume Command'|translate}}</label>
                <input type="text" formControlName="resumeCommand" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="resumeCommand"
                aria-describedby="resumeCommand">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="Port" class="addlabels pb-2">{{'Port'|translate}}</label>
                <input type="number" formControlName="Port" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="Port"
                  aria-describedby="Port">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smsIP" class="addlabels pb-2">{{'SMS IP'|translate}}</label>
                <input type="text" formControlName="smsIP" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smsIP"
                  aria-describedby="smsIP">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smsAPN" class="addlabels pb-2">{{'SMS APN'|translate}}</label>
                <input type="text" formControlName="smsAPN" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smsAPN"
                  aria-describedby="smsAPN">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="smsTimezone" class="addlabels pb-2">{{'SMS Timezone'|translate}}</label>
                <input type="text" formControlName="smsTimezone" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="smsTimezone"
                  aria-describedby="smsTimezone">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="Manufacture" class="addlabels pb-2">{{'Manufacturer'|translate}}</label>
                <input type="text" formControlName="Manufacture" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="Manufacture"
                  aria-describedby="Manufacture">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="ImmobilzerType" class="addlabels pb-2">{{'Immobilzer Type'|translate}}</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="ImmobilzerType" formControlName="ImmobilzerType">
                    <option value="1.0">{{'Server Command'|translate}}</option>
                    <option value="0.0">{{'Mobile Command'|translate}}</option>
                  </select>
              </div>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <button mat-raised-button type="button" class="cancelbtn" data-bs-dismiss="modal" (click)="onEditCloseModal()">{{'CANCEL'|translate}}</button>
            <button mat-raised-button type="submit" class="cancelbtn">{{'UPDATE'|translate}}</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


