// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'app.multitrack.in',
  // apiUrl: 'https://demo3.traccar.org/api/',
  apiUrl: 'https://app.multitrack.in:8082/api/',
};

export const url = `https://${environment.baseUrl}:8082/api/`;

// http://68.183.246.54:8082/
// https://demo3.traccar.org/
// gaurav.gta@gmail.com
// 123456
//wss://app.multitrack.in:8082/api/socket
//http://login.brassbizz.com/
//track.spyandsee.com
//https://login.rtrackgps.com/
//https://track.autotel.pk/


//ng build --env=prod