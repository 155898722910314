import { Component, OnInit } from '@angular/core';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-devices-master',
  templateUrl: './devices-master.component.html',
  styleUrls: ['./devices-master.component.css']
})
export class DevicesMasterComponent implements OnInit {

  deviceMasterList = [];
  IMEI_list = [];
  SimNumberList = [];
  checked = true;
  DeviceID = "";
  todaysDate= "";
  yesterdayDate="";
  page:number=1;
  userId = localStorage.getItem("userId")

  constructor(private device: UsersettingService) {
    // TODAY & YESTERDAY DATE
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate)
    yesterday.setDate(yesterday.getDate() - 1)
    this.yesterdayDate = yesterday.toISOString()
    console.log(this.todaysDate, this.yesterdayDate);
  }

  ngOnInit(): void {
    this.getDeviceidByUser();
    this.getDeviceDataTable();
    this.getLengthDeviceDatatable();
  }

  getDeviceidByUser() {
    this.device.getVehicleIdbyUser().subscribe(resp => {
      console.log(resp)
      this.IMEI_list = resp.devices;
    })
  }

  getDeviceDataTable() {
    let body = {
      "columns": [
        { "data": "_id" },
        { "data": "Device_Name" },
        { "data": "Device_ID" },
        { "data": "sim_number" }
      ],
      draw: 1,
      "find": {
        "$and": [
          { "supAdmin": this.userId },
          { "deletedDevice": { "$exists": false } }
        ]
      },
      "length": -1,
      "op": {},
      "order": [{
        "column": 0,
        "dir": "asc"
      }],
      "search": {
        "regex": false,
        "value": ""
      },
      "select": [],
      "start": 0
    }
    this.device.getDataTable(body).subscribe(resp => {
      this.SimNumberList = resp.data;
    })
  }

  getLengthDeviceDatatable() {
    let body = {
      "columns": [
        { "data": "_id" },
        { "data": "Device_Name" },
        { "data": "user.first_name" },
        { "data": "user.last_name" },
        { "data": "user.address" },
        { "data": "expiration_date" },
        { "data": "Device_ID" },
        { "data": "contact_number" },
        { "data": "sim_number" },
        { "data": "created_on" },
        { "data": "deletedDevice" },
        { "data": "iconType" },
        { "data": "currentFuelVoltage" },
        { "data": "today_odo" },
        { "data": "total_odo" },
        { "data": "expiration_date" },
        { "data": "supAdmin" },
        { "data": "numberOfSOS" },
        { "data": "isDealer" },
        { "data": "Dealer.first_name" },
        { "data": "Dealer.last_name" },
        { "data": "Dealer.address" },
        { "data": "Email_ID" },
        { "data": "last_loc" },
        { "data": "engineNo" },
        { "data": "CID_No" },
        { "data": "status" },
        { "data": "device_model.device_type" },
        { "data": "device_model.Manufacturer" },
        { "data": "typeOfVehicle" },
        { "data": "manufacturingCompany" },
        { "data": "vehicleRegDate" },
        { "data": "vehicleManufacturingDate" },
        { "data": "lastFitnessDate" },
        { "data": "fitmentDate" },
        { "data": "ICCICD" },
        { "data": "IMSI" },
        { "data": "uniqueID" },
        { "data": "vehicleType.description" },
        { "data": "invoiceNumber" },
        { "data": "invoiceDate" },
        { "data": "companyName" },
        { "data": "companyAddress" },
        { "data": "transportOfficeCity" },
        { "data": "transportOfficeState" },
        { "data": "deviceImage" },
        { "data": "ChassisNo" },
        { "data": "last_device_time" },
        { "data": "last_ping_on" },
        { "data": null, defaultContent: "" }
      ],
      draw: 1,
      "find": {
        "created_on": { 
          "$gte": this.yesterdayDate,
          "$lte": this.todaysDate
        },
        "deletedDevice":{
          "$exists": false
        },
        "supAdmin": this.userId
      },
      "length": 10,
      "op": {},
      "order": [{
        "column": 0,
        "dir": "asc"
      }],
      "search": {
        "regex": false,
        "value": ""
      },
      "select": [],
      "start": 0
    }
    this.device.getDataTable(body).subscribe(resp => {
      this.deviceMasterList = resp.data;
    })
  }

  onDeleteDeviceMaster(id){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this DeviceMaster!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.device.deleteDeviceMaster(id).subscribe(resp => {
          console.log(resp);
          this.getLengthDeviceDatatable();
        });
        Swal.fire(
          'Deleted!',
          'DeviceMaster has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'DeviceMaster is safe :)',
          'error'
        )
      }
    })
  }


}
