
<!-- LOGO PAGE -->

<div class="container-fluid" *ngIf="logoShow">
  <div class="row align-items-center section">
    <div class="content1">
      <div class="stage">
        <img src="/assets/images/logo_old.png"  class="box bounce-5" alt="logo">
      </div>
      <div class="MultiTrack">{{siteDetails.name|translate}}</div>
      <p class="Powered">{{'Powered by'|translate}} @2004-2022 </p>
    </div>
  </div>
</div>

<!-- LOGIN PAGE -->

<div class="container-fluid" *ngIf="loginShow">
  <div class="row align-items-center section">
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 logincol_1">
      <img  src="/assets/images/login.png" class="loginimg" alt="login">
      <div class="easyTraking">{{'Easy Traking'|translate}}</div>
      <p class="logintext1">{{siteDetails.text|translate}}</p>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 logincol_2">
      <img width="300px" src="{{siteDetails.logo}}" class="" alt="logo">
      <div class="accountmanage">{{'Login to manage your account'|translate}}</div>

      <div class="card">
        <div class="card-body">
          <form class="loginform" [formGroup]="reactiveForm" (ngSubmit)="onSubmit()">

            <div class="form__form-group">
              <div class="form__form-group-field">
                <input formControlName="username" type="text" placeholder="Email/Mobile/User ID"
                  class="inputfields text-white">
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.username.touched || submitted) && registerFormControl.username.errors?.required">
                *{{'Email/User ID/Phone Number is required'|translate}}</span>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field position-relative">
                <input type="{{isPasswordVisiable ? 'text':'password' }}" formControlName="password" class="inputfields text-white" placeholder="Password">
                <span (click)="isPasswordVisiable =  !isPasswordVisiable;" class="material-icons passicon" style="color: #fff; cursor: pointer;"> {{isPasswordVisiable ? 'visibility':'visibility_off' }} </span>
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                *{{'Password is required'|translate}} 
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                *{{'Password should have minimum 8 characters at least 1 uppercase letter 1 lowercaseletter and 1 number'|translate}}
              </span>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field checkbtn">
                <mat-checkbox class="example-margin" formControlName="keepsignedin">{{'Keep me Signed in' |translate}}</mat-checkbox>
              </div>
            </div>

            <div class="account__forgot-password">
              <a routerLink="/forgotpassword" class="forgotpassword">{{'Forgot Your Password'|translate}}?</a>
            </div>

            <div class="account__btns">
              <button class="btn signin" type="submit">{{'Sign in'|translate}}</button>
            </div>

          </form>

        </div>
      </div>

      <div class="newuser">{{'New user'|translate}}? <span class="signup" routerLink="/register">{{'Sign Up'|translate}}</span> </div>
    </div>
  </div>
</div>
