<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Device Model</span></p> -->
<app-common-modal-popup [isFooterNeeded]="addMode">
    <div class="" modelheadertext *ngIf="gridMode">{{'Service List'|translate}}</div>
    <div class="" modelheadertext *ngIf="addMode">
        {{ addModelMasterForm.value.id && addModelMasterForm.value.id != 0 ? ('Edit'|translate) : ('Add'|translate) }} {{'Services'|translate}}
    </div> 
    
    <div class="container-fluid " modeldata *ngIf="gridMode">
      <div class="row p-0">
        <div class="card p-0 headcard col-12">
            <div class="row">
                <ng-container>
                    <div class="col-12">
                        <!-- HEADER WITH ADD POI BTNS -->
                        <div class="bg-light d-flex align-items-center p-2">
                            <div class="d-flex align-items-center justify-content-between w-100">
                            
                            <div [formGroup]="form" class="input-group searchvehicles">
                                <input (keyup)="page =1" formControlName="searchValue" name="searchValue"
                                                        type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                                        autocomplete="off" />
                                <div class="input-group-append bg-white">
                                <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                                </button>
                                </div>
                            </div>
                            <button class="d-flex align-items-center p-2 bg-white" title="{{' Add Sensor'|translate}}" (click)="onAddModelMaster()">
                                <span class="material-icons" style="font-size: 2.1vh;">
                                add
                                </span>
                            </button>
                            </div>           
                        </div>
                        <!-- DATE DOWNLOAD HEADER -->
                        <div class="card-header flex_row_center_spacebetween">
                            <div class="flex_row_center_spacebetween">
                            <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                                <option value="10" class="select_options">10</option>
                                <option value="20" class="select_options">20</option>
                                <option value="30" class="select_options">30</option>
                                <option value="40" class="select_options">40</option>
                                <option value="50" class="select_options">50</option>
                                <option value="60" class="select_options">60</option>
                            </select>
                            </div>
                            <div style="display: flex;flex-direction: row;align-items: center;">
                            <span class="btn"><img src="/assets/icons/report_refresh_gray.png" alt="refresh" style="width: 96%;"></span>
                            <span class="btn"><img src="/assets/icons/report_download_gray.png" alt="download" style="width: 96%;"></span>
                            </div>
                        </div>
                        <!-- MODEL MASTER TABLE -->
                        <div class="card-body common_scroller fixed_height p-0">
                            <table class="table table-borderless m-0">
                    
                            <thead class="bg-light">
                                <tr>
                                
                                    <th class="table_head_text text-left" (click)="sorting('name')">{{'Name'|translate}}
                                        <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'name'"></i>
                                        <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'name'"></i>
                                    </th>
                                    <th class="table_head_text text-left" (click)="sorting('type')">{{'Type'|translate}}
                                        <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'type'"></i>
                                        <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'type'"></i>
                                    </th>
                                    <th class="table_head_text text-left" (click)="sorting('start')">{{'Start'|translate}}
                                        <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'start'"></i>
                                        <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'start'"></i>
                                    </th> 
                                    <th class="table_head_text text-left" (click)="sorting('period')">{{'Period'|translate}}
                                        <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'period'"></i>
                                        <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'period'"></i>
                                    </th>  
                                    <th class="table_head_text text-left">{{'Date'|translate}}                                        
                                    </th>                
                                    <th class="table_head_text text-left">Action</th>
                                </tr>
                            </thead>
                    
                            <tbody *ngIf="ModelMasterList.length">
                                <tr *ngFor="let model of ModelMasterList  | searchPipe : form.value.searchValue |  paginate : {
                                    itemsPerPage: 10,
                                    currentPage: page,
                                    totalItems: (ModelMasterList  | searchPipe : form.value.searchValue).length
                                };let i=index" style="cursor: pointer;" [class.bg-light]="model.id == selectedCalibration?.id" (click)="showCalibrationData(model)">
                                
                                <td class="table_data text-left">{{model.name}}</td>
                                <td class="table_data text-left">{{model.type}}</td>
                                <td class="table_data text-left">{{model.start}}</td>
                                <td class="table_data text-left">{{model.period}}</td>
                                <td class="table_data text-left">{{model.attributes?.date| date: 'dd/MM/yyyy'}}</td>
                                <td class="table_data text-center" style="cursor: pointer;">
                                    <div
                                        class="btn btn-primary btn-sm mx-1"
                                        (click)="onEditModelMaster(model)"                  
                                    >
                                        <i class="fa fa-edit"></i>
                                    </div>
                                    <div
                                        class="btn btn-danger btn-sm mx-1"
                                        (click)="onDeleteModelMaster(model.id)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                    
                            <tbody *ngIf="!ModelMasterList.length">
                                <tr>
                                <td colspan="9">
                                    <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                    
                            </table>
                        </div>
                        <!-- PAGINATION -->
                        <div *ngIf="ModelMasterList.length" class="flex_row_center_spacebetween"
                            style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                            <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(ModelMasterList  | searchPipe : form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
                            {{(ModelMasterList  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
                            <pagination-controls (pageChange)="page = $event"></pagination-controls>
                        </div>
                    </div>
                </ng-container>               
            </div>   
        </div>
      </div>
    </div>
    <div class="container-fluid " modeldata *ngIf="addMode">
      <form [formGroup]="addModelMasterForm">
  
        <div class="row">
          <div class="col-4">
            <div class="form-group pb-2">
              <label for="name" class="addlabels pb-2">Name</label>
              <input type="text" formControlName="name" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="name">
              <span class="text-danger validation"
                *ngIf="(AddModelMasterControl.name.touched || submitted) && AddModelMasterControl.name.errors?.required">
                *Name is required.
              </span>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
              <label for="type" class="addlabels pb-2">{{'Type'|translate}}</label>
              <input type="text" formControlName="type" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;">  
              <!-- <select class="form-select" formControlName="type" style="
              height: 5.7vh;
              box-shadow: none;
              background-color: white;
              border: 1px solid;
          ">
                <option value="fuel">Fuel</option>
                <option value="temprature">Temprature</option>
                <option value="load">Load</option>                
              </select>              -->
            </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
              <label for="size" class="addlabels pb-2">Start</label>
              <input type="text" formControlName="start" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="size">              
            </div>
          </div>
          <div class="col-4">
            <div class="form-group pb-2">
              <label for="size" class="addlabels pb-2">Period</label>
              <input type="text" formControlName="period" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="size">              
            </div>
          </div>
          <div class="col-4" formGroupName="attributes">
            <div class="form-group pb-2">
              <label for="size" class="addlabels pb-2">Date</label>
              <input type="date" formControlName="date" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="size">              
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="d-flex align-items-center pt-4 pb-2" footerdata *ngIf="addMode" >
        <button mat-raised-button  type="button" class="cancelbtn" (click)="onModelMasterSubmit()">SUBMIT</button>
        <button mat-raised-button type="button" class="cancelbtn bg-secondary ms-3"
          (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
        
    </div>
  </app-common-modal-popup>
  