import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeofenceService {
  baseUrl = url;
  socketBaseUrl = 'https://socket.oneqlik.in/';
  userEmail = '';
  userId = localStorage.getItem('userId');

  constructor(private geofence: HttpClient) {
    // this.userEmail = JSON.parse(window.atob(window.localStorage.token.split('.')[1])).email;
  }

  allGeofence() {
    let _url = this.socketBaseUrl + 'geofencingV2/getallgeofence';
    let httpOptions = {
      params: { uid: this.userId },
    };
    return this.geofence.get<any>(_url, httpOptions);
  }

  addingGeofence(body) {
    let _url = this.baseUrl + 'geofencing/addgeofence';
    return this.geofence.post<any>(_url, body);
  }

  deletingGeofence(geoid) {
    let _url = this.socketBaseUrl + 'geofencing/deletegeofenceNew';
    let httpOptions = {
      params: { id: geoid },
    };
    return this.geofence.get<any>(_url, httpOptions);
  }

  updatingGeofence(body) {
    let _url = this.baseUrl + 'geofencing/updateGeoFence';
    return this.geofence.post<any>(_url, body);
  }

  allPoiList() {
    let _url = this.socketBaseUrl + 'poiV2/getPois';
    let httpOptions = {
      params: { user: this.userId },
    };
    return this.geofence.get<any>(_url, httpOptions);
  }

  addPoi(body) {
    let _url = this.baseUrl + 'poi/addpoi';
    return this.geofence.post<any>(_url, body);
  }

  updatePoi(body) {
    let _url = this.baseUrl + 'poi/updatePOI';
    return this.geofence.get<any>(_url, body);
  }

  deletePoi(poiId) {
    let _url = this.baseUrl + 'poi/deletePoi';
    let httpOptions = {
      params: { _id: poiId },
    };
    return this.geofence.get<any>(_url, httpOptions);
  }
}
