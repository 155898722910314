import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: 'app-model-master',
  templateUrl: './model-master.component.html',
  styleUrls: ['./model-master.component.css']
})
export class ModelMasterComponent implements OnInit {

  todate = "";
  fromdate = "";
  pageOption = 10;
  ModelMasterList = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addModelMasterForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  sortcolumnName = '';
  sortdirection = '';
  isAscending = true;
  constructor(
    private masterModel: UsersettingService, 
    private fb: FormBuilder, 
    private customValidator: ValidationService,
    private commonService: CommonService) {
    this.addModelMasterForm = this.fb.group(
      {
        modelName: ['', Validators.required],
        apkCommand: ['', Validators.required],
        ipCommand: ['', Validators.required],
        timezoneCommand: ['', Validators.required],
       
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
  }
  
  ngOnInit(): void {    
    
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        let userData =  JSON.parse(userDataObj);
        this.superAdmin = userData && userData.administrator;
      } catch (error) {}
    }
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "masterModel") {
        this.gridMode = true;
        this.addMode = false;
      }
      this.getAllModelMaster();
    })
  }

  get AddModelMasterControl() {
    return this.addModelMasterForm.controls;
  }

  getAllModelMaster() {
    this.masterModel.getModelMaster().subscribe(resp => {
      console.log(resp);
      this.ModelMasterList = resp;
    })
  }

  onModelMasterSubmit() {
    
      let body = {
        modelName: this.addModelMasterForm.value.modelName,
        apkCommand: this.addModelMasterForm.value.apkCommand,
        ipCommand: this.addModelMasterForm.value.ipCommand,
        timezoneCommand: this.addModelMasterForm.value.timezoneCommand,
      }
      this.masterModel.addModelMaster(body).subscribe(resp => {
        Swal.fire({
          icon: "success",
          title: "Your Form has been Submitted",
          showConfirmButton: false,
          timer: 1500,
        });
        this.addModelMasterForm.reset();
        //$('#staticBackdrop').modal("hide");
        this.gridMode = true;
        this.addMode = false;
        this.getAllModelMaster();
      })
    
  }

  onEditModelMaster(model){
    this.editForm=true;
    this.addModelMasterForm.setValue({
    
      modelName: model.modelName,
      apkCommand: model.apkCommand,
      ipCommand: model.ipCommand,
      timezoneCommand:model.timezoneCommand
    })
    this.modelId = model._id;
  }

  onDeleteModelMaster(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this ModelMaster!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.masterModel.deleteModelMaster(modelId).subscribe(resp => {
          console.log(resp);
          this.gridMode = true;
          this.addMode = false;
          this.getAllModelMaster();
        });
        Swal.fire(
          'Deleted!',
          'ModelMaster has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'ModelMaster is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.gridMode = true;
    this.addMode = false;
    this.addModelMasterForm.reset();
  }

  onAddModelMaster() {
    this.gridMode = false;
    this.addMode = true;
    this.editForm = false;
    this.addModelMasterForm.reset();
  }

  values(event) {
    // if (event.target.value) {
    //   this.ModelMasterList = this.ModelMasterList.filter(s => s.ModelName.includes(event.target.value));
    //   console.log(this.ModelMasterList)
    // }
    // else {
    //   this.getAllModelMaster();
    // }
  }

  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }
  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.ModelMasterList.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.ModelMasterList;
    }
  }
}
