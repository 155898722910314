import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import * as ApexCharts from 'apexcharts';
import { Subscription } from 'rxjs';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { CommonService } from '../../services/common.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { SubjectSubscribeService } from 'src/app/services/subject-subscribe.service';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [MeterTokm]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  totalVehicles = 0;
  running = 0;
  fuelPriceList = [];
  displayFuelPrice:any= {};

  Idling = 0;
  Stopped = 0;
  outofreach = 0;
  expired = 0;
  nodata = 0;
  towwed = 0;
  dieselPrice = '';
  petrolPrice = '';
  state = '';
  vehicleData = [];
  vehicleDateArray = [];
  vehicleDistanceArray = [];
  distanceCount = [];
  totalVehicleDistance = '';
  totalVehicleFuelConsumption = '';
  totalVehicleExpense = '';
  loadCountSubscriber:any;
  options: any = {};
  optionss: any = {};
  odometerOption: any = {};
  vehicleSub: Subscription;
  lastSevenDaysSubscriber$: Subscription;
  deviceId = '';

  custom = {
    value: '',
  };

  states = [
    'Gujarat',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry',
  ];

  customs = [{ value: 'demo1' }, { value: 'demo1' }, { value: 'demo1' }];
  eventsCountsList: any[] = [];
  constructor(
    private dash_http: DashboardDataService,
    private commonService: CommonService,
    private router: Router,
    private subjectSubscribeService: SubjectSubscribeService,
    private meterTokm: MeterTokm
  ) {}

  ngOnInit(): void {
    //this.getLocation();
    this.getFulePrice();
    this.getOdometerData();
    this.getEventsData();
    this.vehicleSub = this.dash_http.DeviceId.subscribe((res) => {
      this.deviceId = res;
      
    });
    
    
    this.getDeviceList();
    this.loadCountSubscriber =  setInterval(()=>{
      this.getDeviceList();
    }, 2000);
   
    this.lastSevenDaysSubscriber$ =  this.subjectSubscribeService.DeviceId.subscribe((resp: any) => {
      if (resp) {
        this.displayLast7DaysGraph(resp)
      }
    });
  }

  getFulePrice() {
    this.commonService.getRequest('multitrack/getFuelPrices',[]).then((resp: any) => {
      if (resp?.status) {
        if(resp.data && resp.data.results) {
          this.fuelPriceList = resp.data.results;
          this.getFuelPrice_state(true);
        }
      }
    })
  }
  getDeviceList() {
    
    this.commonService
      .getRequest('devices/getDeviceStatus', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.totalVehicles = resp.data.RUNNING + resp.data.IDLE +resp.data.STOPPED +resp.data.EXPIRED+resp.data.NOT_REPORTING + resp.data.NO_DATA + resp.data.TOWWED;
          this.running = resp.data.RUNNING;
          this.Idling = resp.data.IDLE;
          this.Stopped = resp.data.STOPPED;
          this.outofreach = resp.data.NOT_REPORTING;
          this.expired = resp.data.EXPIRED;
          this.nodata = resp.data.NO_DATA;
          this.towwed = resp.data.TOWWED;
        }
      });
  }

  getOdometerData() {
    this.commonService
      .getRequest('devices/getdevicedaystats', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.odometerOption.series[0].data = [];
          if (resp?.status) {
            for (let key in resp.data) {              
              this.odometerOption.series[0].data.push(this.meterTokm.transform(resp.data[key]['distanceforday']));
              this.odometerOption.xaxis.categories.push(resp.data[key]['name']);
            }            
          }
          var chart = new ApexCharts(
            document.querySelector('#chartOdometer'),
            this.odometerOption
          );
          chart.render();
          chart.resetSeries();
        }      
      });
  }
  initiateCarousel() {
    $('#eventCarousel').owlCarousel({
      loop: false,
      nav: true,
      autoWidth: false,
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    });
  }
  getEventsData() {
    let now = moment();
    let startTime = now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString();
    let endTime = now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString();  
    this.commonService
      .getRequest(`reports/geteventcounts?from=${new Date(startTime).toISOString()}&to=${new Date(endTime).toISOString()}`, [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.eventsCountsList = resp.data;
          setTimeout(() => {
            this.initiateCarousel();
          }, 100);
        }      
      });
  }
  

  redirectTrackerUrl(type) {
    console.log('type', type);
    if (type === 'All') {
      this.router.navigate(['/main/tracking']);
    } else {
      this.router.navigateByUrl('/main/tracking?type=' + type);
    }
  }

  // USER LOCATION ( STATE )

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) =>
        this.showPosition(position)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  showPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    this.dash_http.getState(latitude, longitude).subscribe((resp) => {
      this.state = resp.results[0].address_components.filter(
        (ac) => ~ac.types.indexOf('administrative_area_level_1')
      )[0].long_name;
      console.log(this.state);
      this.getFuelPrice_state();
    });
  }

  // LOCATION END

  
  displayLast7DaysGraph(deviceId){

    let fromDate = moment();
    let toDate = moment();
        fromDate.subtract(7, 'days');
        let nfromdate = fromDate.format('YYYY-MM-DD');
        let ntodate = toDate.format('YYYY-MM-DD');
    let params = [
      'deviceId=' + deviceId,
      'from=' + nfromdate + 'T00:00:01.477Z',
      'to=' + ntodate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' +10,
      'daily=' + true,
    ];

    if (deviceId) {
      this.commonService
        .getRequest('reports/summary?' + params.join('&'), [])
        .then((resp: any) => {
          this.optionss.series[0].data = [];
          if (resp?.status) {
           
            resp.data.forEach(dItem=>{
              var km = dItem.distance/1000;
   

              this.optionss.series[0].data.push({y:(km? km.toFixed(2) :0),x:moment(dItem.startTime).format('DD-MM-YYYY')});
            })
          }
          var chart = new ApexCharts(
            document.querySelector('#chart2'),
            this.optionss
          );
          chart.render();
          chart.resetSeries();
        });
    }

    
  }
  
  
  // getDashboard_Data() {
    
  //   this.dash_http.getDashbaord().subscribe((resp) => {
  //     console.log(resp);
  //     this.running = resp.running_devices;
  //     this.Idling = resp['Ideal Devices'];
  //     this.Stopped = resp['OFF Devices'];
  //     this.outofreach = resp.OutOfReach;
  //     this.expired = resp.expire_status;
  //     this.nodata = resp.no_data;
  //     this.totalVehicles = resp.Total_Vech;
  //   });
  // }

  updateDashboard_dataonRefresh() {
    this.dash_http.vehiclesCountComponent.subscribe((res) => {
      //this.getDashboard_Data();
    });
  }

  getFuelPrice_state(isFirst=false) {
    if((this.state || isFirst)  && this.fuelPriceList.length) {
      let fuelData = isFirst ?this.fuelPriceList[0]:  this.fuelPriceList.find(fuleState=>fuleState.cityState.toLowerCase() == this.state.toLowerCase())
      if(fuelData){
        if(isFirst) {
          this.state = fuelData.cityState;
        }
        this.petrolPrice = fuelData.petrolPrice ?parseFloat(fuelData.petrolPrice).toFixed(2) : 'NA';
        this.dieselPrice = fuelData.dieselPrice ?parseFloat(fuelData.dieselPrice).toFixed(2) : 'NA';
      };
    }else {
      this.petrolPrice = 'NA';
      this.dieselPrice = 'NA';
    }
  }

  

  

  ngAfterViewInit(): void {
    // CHART 1

    var options = {
      series: [
        {
          name: 'series1',
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        
      ],
      chart: {
        height: 250,
        type: 'area',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    };

    var chart = new ApexCharts(document.querySelector('#chart1'), options);
    chart.render();

    // CHART 2

    this.optionss = {
      series: [
        {
          name: 'Manoj',
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          //console.log(series, seriesIndex, dataPointIndex, w);
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          return (
            '<ul>' +
            '<li style="margin-top:2vh;"><b>Date </b>: ' +
            data.x +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '<li><b>Distance (Kms) </b>: ' +
            data.y +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '</ul>'
          );
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['#12a370']
        // type: 'gradient',
        // gradient: {
        //   shadeIntensity: 1,
        //   opacityFrom: 0.7,
        //   opacityTo: 0.9,
        //   stops: [0, 90, 100],
        // },
      },
      xaxis: {
        categories: [],
        type: 'date',
        title: {
          text: 'Date',
          offsetX: 0,
          offsetY: 7,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      yaxis: {
        floating: false,
        decimalsInFloat: undefined,
        title: {
          text: 'Distance (Kms)',
          rotate: -90,
          offsetX: -10,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
      },
    };

    var chart2 = new ApexCharts(
      document.querySelector('#chart2'),
      this.optionss
    );
    chart2.render();

    //Odometer chart render
    this.odoMeterChartRender();
  }

  odoMeterChartRender() {
    // CHART 2

    this.odometerOption = {
      series: [
        {
          name: 'Odometer',
          data: [],
        },
      ],      
      chart: {
        height: 350,
        type: 'bar',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      grid: {
        show: false
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          console.log('odometer');
          console.log(series, seriesIndex, dataPointIndex, w);
          var data = w.globals.labels[dataPointIndex];
          return (
            '<ul>' +
            '<li style="margin-top:2vh;"><b>Devices </b>: ' +
            data +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '<li><b>Distance (Kms) </b>: ' +
            series[0][dataPointIndex] +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>' +
            '</ul>'
          );
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#000"]
        },
        formatter: function(val, opt) {
          return val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      fill: {
        colors: ['#12a370']
      },
      xaxis: {
        categories: [],
        type: 'number',        
        title: {
          text: 'Distance (Kms)',
          offsetX: 0,
          offsetY: 7,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
      },
      yaxis: {
        floating: false,
        decimalsInFloat: undefined,        
        title: {
          text: 'Devices',
          rotate: -90,
          offsetX: 10,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '2.5vh',
            fontFamily: '-webkit-pictograph',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
      },
    };

    var chartOdometer = new ApexCharts(
      document.querySelector('#chartOdometer'),
      this.odometerOption
    );
    chartOdometer.render();
  }

  ngOnDestroy() {
    clearInterval(this.loadCountSubscriber);
    this.vehicleSub.unsubscribe();
    this.lastSevenDaysSubscriber$.unsubscribe();
  }
}
