import { Component, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { convertPipe } from 'src/app/pipe/convert.pipe';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.css'],
  providers:[MeterTokm,convertPipe]
})
export class ExportDataComponent implements OnInit {
  xlsxFileData:any[];
  @Input() dataList:any[];
  @Input() selectedPage:string;
  htmlString:any;
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  pdfTable:any;
  constructor(private datePipe: DatePipe,private numberPipe:DecimalPipe, private meterTokmPipe:MeterTokm,
    private convertPipe:convertPipe) { }

  ngOnInit(): void {
  }
  downloadData(type) {
    
    let tableList = [];
    switch (this.selectedPage) {
      case 'dealer':
        tableList = [
          {
            titleName: 'Name',
            colName: 'name',
          },
          {
            titleName: 'User ID',
            colName: 'email',
          },
          {
            titleName: 'Device Limit',
            colName: 'deviceLimit',
          },
          {
            titleName: 'User Limit',
            colName: 'userLimit',
          },
          {
            titleName: 'Disabled',
            colName: 'disabled',
          },
        ];
        break;
        case 'customer':
          tableList = [
            {
              titleName: 'Name',
              colName: 'name',
            },
            {
              titleName: 'User ID',
              colName: 'email',
            },
            
            {
              titleName: 'Disabled',
              colName: 'disabled',
            },
          ];
          break;
          case 'Trips':
            tableList = [
              {
                titleName: 'Device Name',
                colName: 'deviceName',
              },
              {
                titleName: 'Start Time',
                colName: 'startTime',
              },
              {
                titleName: 'Odometer Start',
                colName: 'startOdometer',
              },
              {
                titleName: 'Start Address',
                colName: 'startAdd',
              },
              {
                titleName: 'End Time',
                colName: 'endTime',
              },
              {
                titleName: 'Odometer End',
                colName: 'endOdometer',
              },
              {
                titleName: 'End Address',
                colName: 'endAdd',
              },
              {
                titleName: 'Distance',
                colName: 'distance',
              },
              {
                titleName: 'Average Speed',
                colName: 'averageSpeed',
              },
              {
                titleName: 'Maximum Speed',
                colName: 'maxSpeed',
              },
              {
                titleName: 'Duration',
                colName: 'duration',
              },
            ];
            break;
          
      case 'Devices':
        tableList = [
          {
            titleName: 'Vehicle',
            colName: 'name',
          },
          {
            titleName: 'Group Name',
            colName: 'group_name',
          },
          {
            titleName: 'IMEI',
            colName: 'uniqueId',
          },
          {
            titleName: 'Status',
            colName: 'deviceStatus',
          },
          {
            titleName: 'LastUpdate',
            colName: 'lastUpdate',
          },
          {
            titleName: 'SimNo',
            colName: 'phone',
          },
          {
            titleName: 'Model',
            colName: 'model',
          },
          {
            titleName: 'Contact',
            colName: 'contact',
          },
          {
            titleName: 'Icon',
            colName: 'category',
          },
          {
            titleName: 'Disabled',
            colName: 'disabled',
          },
          {
            titleName: 'ExpirationTime',
            colName: 'expirationTime',
          },
          {
            titleName: 'CreationTime',
            colName: 'creation_date',
          },
          {
            titleName: 'TodaysOdo',
            colName: 'distanceForday',
          },
          {
            titleName: 'Users',
            colName: 'user',
          },
        ];
        break;
      
      
    }

    this.xlsxFileData = [];
    if (type === 'xlsx') {
      this.dataList.forEach((r: any, i: number) => {
        let dataSet = {};
        let itrations =r;
        if(this.selectedPage == 'Devices') {
           itrations = r['device'];
        }
        tableList.forEach((t: any) => {
            dataSet[t.titleName] = itrations[t.colName] ? itrations[t.colName] : '';
        });
        if(this.selectedPage == 'Devices') { 
          dataSet['Disabled'] = dataSet['Disabled'] ? 'Yes':'No';
          dataSet['LastUpdate'] = dataSet['LastUpdate'] ? this.transformDate(dataSet['LastUpdate'],'MMM d, y h:mm:ss a') : '';
          dataSet['ExpirationTime'] = dataSet['ExpirationTime'] ? this.transformDate(dataSet['ExpirationTime']) : '';
          dataSet['CreationTime'] = dataSet['CreationTime'] ? this.transformDate(dataSet['CreationTime']) : '';
          dataSet['TodaysOdo'] = dataSet['TodaysOdo'] ? this.meterTokmPipe.transform(dataSet['TodaysOdo']) : '';
          dataSet['Users'] = this.displayDeviceUsersList(r?.userData);

        } else if(this.selectedPage == 'dealer' || this.selectedPage == 'customer') {
          dataSet['Disabled'] = dataSet['Disabled'] ? 'Yes':'No';
        }else if(this.selectedPage =='Trips') {
          dataSet['Start Time'] = dataSet['Start Time'] ? this.transformDate(dataSet['Start Time'],'dd/MM/yyyy hh:mm a') : '';
          dataSet['End Time'] = dataSet['End Time'] ? this.transformDate(dataSet['End Time'],'dd/MM/yyyy hh:mm a') : '';
          dataSet['Odometer Start'] = dataSet['Odometer Start'] ? this.meterTokmPipe.transform(dataSet['Odometer Start']) : '';
          dataSet['Odometer End'] = dataSet['Odometer End'] ? this.meterTokmPipe.transform(dataSet['Odometer End']) : '';
          dataSet['Distance'] = dataSet['Distance'] ? this.meterTokmPipe.transform(dataSet['Distance']) : '';
          dataSet['Maximum Speed'] = dataSet['Maximum Speed'] ? this.numberPipe.transform(dataSet['Maximum Speed'],'1.0-2') : '';
          dataSet['Average Speed'] = dataSet['Average Speed'] ? this.numberPipe.transform(dataSet['Average Speed'],'1.0-2') : '';
          dataSet['Duration'] = dataSet['Duration'] ? this.convertPipe.transform(dataSet['Duration'],'hh:mm') : '';
        }

        this.xlsxFileData.push(dataSet);
      });
      this.exportExcel(this.xlsxFileData, this.selectedPage);
    } else {
      let html = `<style>.pdf-container { font-size:10px;} </style><table class="border pdf-container"><thead><tr>`;

      tableList.forEach((t) => {
        html += `<th>${t.titleName}</th>`;
      });
      html += `</tr></thead><tbody>`;
      this.dataList.forEach((r: any, i: number) => {
        html += `<tr>`;
        tableList.forEach((t: any) => {
          html += `<td>${r[t.colName] ? r[t.colName] : ''}</td>`;
        });
        html += `</tr>`;
      });
      html += `</tbody></table>`;

      this.htmlString = html;

      setTimeout(() => {
        const pdfTable = this.pdfTable.nativeElement;
        // this.convetHtmlToPDF(pdfTable.innerHTML);
        //this.downloadAsPDF();
      }, 2000);
    }
  }
  
  displayDeviceUsersList(data: any) {
    let str = '';
    data.forEach(ele => {
      str += ele.userName + ", ";
    });    
    return str.substr(0, str.length - 2);
  }
  transformDate(date,format='dd/MM/yyyy') {
    return this.datePipe.transform(date, format);
  }
  exportExcel(xlsxFileData: any[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxFileData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveExcelFile(excelBuffer, fileName);
  }

  saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
