<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Geofence'|translate}}</span>
</p>

<div class="container-fluid">
  <div class="row">
    <div
      class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 first_col"
      id="closeFullMap"
    >
      <!-- GEOFENCE MAIN CARD -->

      <div class="card main_card" *ngIf="maincard">
        <div class="flex_row_space_between mb-3">
          <div class="input-group searchgeo">
            <input
              type="text"
              class="form-control geoplace"
              placeholder="{{'Search Geofence'|translate}}"
              ([ngModel])="(searchGeo)"
              (input)="values($event)"
              name="searchGeo"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <button class="btn" type="button" style="padding: 1.4vh 2.4vh">
                <img src="/assets/icons/searchicon.png" alt="search" />
              </button>
            </div>
          </div>
          <div class="dropdown">
            <button
              class="btn dropdown-toggle p-0 border-none"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="material-icons addbtn" matTooltip="Add Geofence">
                add
              </span>
            </button>
            <ul
              class="dropdown-menu option_drop pb-1 pt-1"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a
                  class="dropdown-item"
                  style="justify-content: flex-start; width: 23vh"
                  (click)="onclickAddPolygon()"
                >
                  <span class="optionstext">{{'Polygon'|translate}} {{'geofence'|translate}}</span>
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  style="justify-content: flex-start; width: 23vh"
                  (click)="onclickAddCircular()"
                >
                  <span class="optionstext">{{'Circular'|translate}} {{'geofence'|translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- POI AND GEOFENCE BUTTON -->

        <div class="row pb-3">
          <div class="col-6">
            <button
              mat-raised-button
              class="poi_geo_button"
              (click)="onshowPolygonList()"
              matTooltip="Polygon Geofences"
            >
              {{'Polygon'|translate}}
            </button>
          </div>
          <div class="col-6">
            <button
              mat-raised-button
              class="poi_geo_button"
              (click)="onshowCircularList()"
              matTooltip="Circular Geofences"
            >
              {{'Circular'|translate}}
            </button>
          </div>
        </div>

        <!-- POLYGON CARD DATA (GEOFENCE) -->

        <div class="card main_card2" *ngIf="polygonGeofence">
          <ng-container *ngFor="let geo of geofenceList; let i = index">
            <div
              class="card geofence_card mb-3"
              *ngIf="geo?.geofence?.type && geo.geofence.type !== 'Point'"
              (click)="zoomPolygon(geo)"
            >
              <div class="flex_row_space_between pb-1">
                <span class="geohead">{{ geo.name }}</span>
               

                <div class="col-1" style="padding: 0.7vh 0">
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style="padding: 0.5vh 0 0 4vh"
                    >
                      <img
                        src="/assets/icons/geoedit.png"
                        alt="geoedit"
                        style="width: 0.5vh; cursor: pointer"
                        matTooltip="Options"
                      />
                    </button>
                    <ul
                      class="dropdown-menu option_drop pb-0 pt-1"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          style="justify-content: flex-start"
                          (click)="onEditPolygon(geo)"
                        >
                          <img src="/assets/icons/editgeo.png" alt="edit" />
                          <span class="optionstext" style="padding-left: 1.5vh"
                            >{{'Edit'|translate}}</span
                          >
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          style="justify-content: flex-start"
                          (click)="onDeletePolygon(geo.id)"
                        >
                          <img src="/assets/icons/deletegeo.png" alt="edit" />
                          <span class="optionstext" style="padding-left: 1.5vh"
                            >{{'Delete'|translate}}</span
                          >
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Display on map'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="checked"
                              [disabled]="disabled"
                              matTooltip="Display On Map"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Notification Entering'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="geo.entering"
                              [disabled]="disabled"
                              matTooltip="Notification Entering"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Notification Exiting'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="geo.exiting"
                              [disabled]="disabled"
                              matTooltip="Exit Notification"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-12 geo_vehicle_radius">
                  <div class="flex_row_space_around" style="float: left">
                    <span class="geovehicleradius_text">
                      {{ geo.description }}
                    </span>
                    <!-- <span
                    class="geovehicleradius_count"
                    *ngIf="geo.devicesWithin?.length"
                    >{{ geo.devicesWithin.length }}</span
                  > -->
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- CIRCULAR CARD DATA (POI)-->

        <div class="card main_card2" *ngIf="circularGeofence">
          <ng-container *ngFor="let geo of geofenceList; let i = index">
            <div
              class="card geofence_card mb-3"
              *ngIf="geo?.geofence?.type && geo.geofence.type === 'Point'"
              (click)="zoomCircle(geo)"
            >
              <div class="flex_row_space_between pb-1">
                <span class="geohead">{{ geo.name }}</span>
               

                <div class="col-1" style="padding: 2vh 1vh">
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle p-0"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/icons/geoedit.png"
                        alt="geoedit"
                        style="width: 0.6vh; cursor: pointer"
                        matTooltip="Options"
                      />
                    </button>
                    <ul
                      class="dropdown-menu option_drop pb-0 pt-1"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          style="justify-content: flex-start"
                          (click)="onEditPoi(geo.id)"
                        >
                          <img src="/assets/icons/editgeo.png" alt="edit" />
                          <span class="optionstext" style="padding-left: 1.5vh"
                            >{{'Edit'|translate}}</span
                          >
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          style="justify-content: flex-start"
                          (click)="onDeletePoi(geo)"
                        >
                          <img src="/assets/icons/deletegeo.png" alt="edit" />
                          <span class="optionstext" style="padding-left: 1.5vh"
                            >{{'Delete'|translate}}</span
                          >
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Display on map'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="checked"
                              [disabled]="disabled"
                              matTooltip="Display On Map"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Notification Entering'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="checked"
                              [disabled]="disabled"
                              matTooltip="Notification Entering"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item">
                          <span class="optionstext">{{'Notification Exiting'|translate}}</span>
                          <span>
                            <mat-slide-toggle
                              class="example-margin"
                              [color]="color"
                              [checked]="checked"
                              [disabled]="disabled"
                              matTooltip="Exit Notification"
                            >
                            </mat-slide-toggle>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-11">
             
                </div>
              </div>
              <div class="row">
                <div class="col-6 geo_vehicle_radius">
                  <div class="flex_row_space_around">
                    <span class="geovehicleradius_text">
                      {{ geo.description }}
                    </span>
                  </div>
                </div>
                <div class="col-6 geo_vehicle_radius">
                  <div class="flex_row_space_around">
                    <span class="geovehicleradius_text">{{'Radius'|translate}}</span>
                    <span
                      class="geovehicleradius_count"
                      *ngIf="geo?.geofence?.radius"
                    >
                      {{ geo.geofence.radius | number: "1.2-2" }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- ADD/EDIT POLYGON GEOFENCE CARD -->

      <div class="card addmain_card" *ngIf="addpolygon">
        <div class="addgeohead pb-3">{{'Add Polygon Geofence'|translate}}</div>
        <div class="card geofence_card2">
          <form [formGroup]="polygonForm" (ngSubmit)="onPolygonSubmit()">
            <div class="form-group pb-2">
              <label for="fencename" class="addlabels pb-2"
                >{{'Fence Name'|translate}}</label
              >
              <input
                type="text"
                formControlName="fencename"
                class="form-control inputfields"
                id="fencename"
                aria-describedby="fencename"
              />
              <span
                class="text-danger validation"
                *ngIf="
                  (polygonFormControl.fencename.touched || submitted) &&
                  polygonFormControl.fencename.errors?.required
                "
              >
                *{{'Fence Name'|translate}} {{'is required'|translate}}.
              </span>
            </div>

            <div class="form-group">
              <label for="location" class="addlabels pb-2">{{'Add Location'|translate}}</label>
              <div class="input-group inputborder">
                <div class="input-group-prepend">
                  <div class="input-group-text searchIcon">
                    <img src="/assets/icons/searchwhite.png" alt="search" />
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control inputfields"
                  style="border: none !important"
                  id="location"
                  formControlName="geoaddress"
                  placeholder="Search Location"
                />
              </div>
            </div>

            <div class="address py-2">
              <div class="flex_row_space_between">
                <div class="flex_row_space_between align-items-center pb-1">
                  <img
                    src="/assets/icons/address.png"
                    alt="addressicon"
                    class="address_img"
                  />
                  <div class="geobody" style="font-size: 1.5vh !important">
                    Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                    394210 (India)
                  </div>
                </div>
                <span
                  ><img
                    src="/assets/icons/close.png"
                    alt="close"
                    style="cursor: pointer"
                /></span>
              </div>
            </div>

            <div class="row pt-4">
              <div class="col-6">
                <button
                  mat-raised-button
                  class="cancelbtn"
                  (click)="onCancelPolygon()"
                >
                  {{'CANCEL'|translate}}
                </button>
              </div>
              <div class="col-6">
                <button mat-raised-button type="submit" class="submitbtn">
                   {{'SUBMIT'|translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- ADD/EDIT CIRCULAR GEOFENCE CARD -->

      <div class="card addmain_card" *ngIf="addcircular">
        <div class="addgeohead" style="padding-bottom: 0.75rem">
          {{'Add Circular Geofence'|translate}}
        </div>
        <div class="card geofence_card2" style="height: 73.1vh">
          <form [formGroup]="circularForm" (ngSubmit)="onCircularSubmit()">
            <div class="form-group pb-2">
              <label for="circularName" class="addlabels pb-2"
                >{{'Fence Name'|translate}}</label
              >
              <input
                type="text"
                formControlName="circularName"
                class="form-control inputfields"
                id="circularName"
                aria-describedby="circularName"
              />
              <span
                class="text-danger validation"
                *ngIf="
                  (circularFormControl.circularName.touched || submitted) &&
                  circularFormControl.circularName.errors?.required
                "
              >
                *{{'Fence Name'|translate}} {{'is required'|translate}}.
              </span>
            </div>

            <div class="form-group">
              <label for="location1" class="addlabels pb-2">{{'Add Location'|translate}}</label>
              <div class="input-group inputborder">
                <div class="input-group-prepend">
                  <div class="input-group-text searchIcon">
                    <img src="/assets/icons/searchwhite.png" alt="search" />
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control inputfields"
                  style="border: none !important"
                  id="location1"
                  formControlName="circularAddress"
                  placeholder="Search Location"
                />
              </div>
            </div>

            <div class="address py-3">
              <div class="flex_row_space_between">
                <div class="flex_row_space_between align-items-center pb-1">
                  <img
                    src="/assets/icons/address.png"
                    alt="addressicon"
                    class="address_img"
                  />
                  <div class="geobody" style="font-size: 1.5vh !important">
                    Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                    394210 (India)
                  </div>
                </div>
                <span
                  ><img
                    src="/assets/icons/close.png"
                    alt="close"
                    style="cursor: pointer"
                /></span>
              </div>
            </div>
            
            <div class="row pt-4">
              <div class="col-6">
                <button
                  mat-raised-button
                  class="cancelbtn"
                  (click)="onCancelCircular()"
                >
                  {{'CANCEL'|translate}}
                </button>
              </div>
              <div class="col-6">
                <button mat-raised-button type="submit" class="submitbtn">
                   {{'SUBMIT'|translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-12 col_map" id="myMap">
      <!-- GOOGLE MAP -->

      <div class="card main_card p-0" *ngIf="googleMap">
        <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="true"
        >
          <!-- <ng-container *ngIf="circular"> -->
          <ng-container *ngFor="let poi of geofenceList; let i = index">
            <agm-circle
              *ngIf="circularGeofence && poi.geofence?.type && poi.geofence.type === 'Point'"
              [latitude]="poi.geofence.coordinates[0]"
              [longitude]="poi.geofence.coordinates[1]"
              [radius]="poi.geofence.radius"
              [fillColor]="'red'"
              [circleDraggable]="false"
              [editable]="false"
            >
            </agm-circle>

            <agm-polygon
              *ngIf="polygonGeofence && poi.geofence?.type && poi.geofence.type === 'Feature'"
              [visible]="true"
              [paths]="poi.geofence.geometry.coordinates"
              [fillColor]="'red'"
              [clickable]="true"
            >
            </agm-polygon>

           
          </ng-container>

          <!-- <ng-container *ngIf="polygons">
            <ng-container *ngFor="let geo of polygonList; let i = index">
              <agm-polygon
                *ngIf="geo.show"
                [visible]="true"
                [paths]="geo.coordinates"
                [fillColor]="'red'"
                [clickable]="false"
              >
              </agm-polygon>
            </ng-container>
          </ng-container> -->
        </agm-map>
      </div>

      <!-- DRAWING POLYGON ( GEOFENCE ) MAP -->

      <div class="card main_card p-0" *ngIf="drawPolygonGeofence">
        <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="true"
          (mapReady)="polygonDrawingManager($event)"
        >
        </agm-map>
      </div>

      <!-- DRAWING CIRCULAR ( GEOFENCE ) MAP -->

      <div class="card main_card p-0" *ngIf="drawCircularGeofence">
        <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="true"
          (mapReady)="circularDrawingManager($event)"
        >
        </agm-map>
      </div>

      <!-- MAP RIGHT ICONS -->

      <div
        class="btn-group-vertical"
        role="group"
        aria-label="Basic example"
        style="position: absolute; top: 2vh; right: 3vh"
      >
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map1.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map2.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map3.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map4.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map5.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns openbtn"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map6.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map7.png" alt="mapicon" />
        </button>
      </div>

      <!-- MAP BOTTOM ICONS -->

      <div
        class="btn-group"
        role="group"
        aria-label="Basic example"
        style="position: absolute; bottom: 3vh; left: 4vh"
      >
        <button
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
        >
          <img src="/assets/icons/map8.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
        >
          <img src="/assets/icons/map9.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
          (click)="getFullWidth()"
        >
          <img src="/assets/icons/map10.png" alt="mapicon" />
          <!-- <img src="/assets/icons/zoomout.png" alt="zoomout"> -->
        </button>
      </div>
    </div>
  </div>
</div>
