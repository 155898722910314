<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Announcement</span></p> -->

<div class="container-fluid mt-3">
    <div class="row p-0">
        <div class="card p-0 headcard">

            <!-- HEADER WITH ADD POI BTNS -->
            <div class="card-header1 flex_row_center_spacebetween">
                <div class="cardHead_text text-dark">{{'Announcement'|translate}} </div>
                <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item">
                        <div style="display: flex;flex-direction: row;">
                            <button *ngIf="superAdmin" mat-flat-button class="addbtns px-3 py-1"
                                style="margin-right: 1vh;" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                (click)="onAddModelMaster()">
                                <span class="material-icons" style="font-size: 2.1vh;">
                                    add
                                </span>
                                <!-- {{' Add Announcement'|translate}} -->
                            </button>
                            <div [formGroup]="form" class="input-group searchvehicles">
                                <input (keyup)="page =1" formControlName="searchValue" name="searchValue" type="text"
                                    class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                    autocomplete="off" />
                                <div class="input-group-append">
                                    <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                                        <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- DATE DOWNLOAD HEADER -->
            <div class="card-header flex_row_center_spacebetween">
                <div class="flex_row_center_spacebetween">
                    <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                        <option value="10" class="select_options">10</option>
                        <option value="20" class="select_options">20</option>
                        <option value="30" class="select_options">30</option>
                        <option value="40" class="select_options">40</option>
                        <option value="50" class="select_options">50</option>
                        <option value="60" class="select_options">60</option>
                    </select>
                </div>
                <div style="display: flex;flex-direction: row;align-items: center;">
                    <span class="btn"><img src="/assets/icons/report_refresh_gray.png" alt="refresh"
                            style="width: 96%;"></span>
                    <span class="btn"><img src="/assets/icons/report_download_gray.png" alt="download"
                            style="width: 96%;"></span>
                </div>
            </div>
            <!-- MODEL MASTER TABLE -->
            <div class="card-body common_scroller fixed_height p-0">
                <table class="table table-borderless m-0">

                    <thead style="background-color:  rgb(248,249,250);">
                        <tr>

                            <th class="table_head_text text-left text-dark">{{'Description'|translate}}</th>
                            <th class="table_head_text text-left text-dark">{{'Media link'|translate}}</th>
                            <th class="table_head_text text-left text-dark">{{'status'|translate}}</th>
                            <th class="table_head_text text-left text-dark">{{'Expiry date'|translate}}</th>
                            <th class="table_head_text text-left text-dark">Action</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="annoucmentsList.length">
                        <tr *ngFor="let model of annoucmentsList  | searchPipe : form.value.searchValue |  paginate : {
                itemsPerPage: 10,
                currentPage: page,
                totalItems: (annoucmentsList  | searchPipe : form.value.searchValue).length
              };let i=index">

                            <td class="table_data text-left">{{model.description}}</td>
                            <td class="table_data text-left">{{model.medialink}}</td>
                            <td class="table_data text-left">{{model.status}}</td>
                            <td class="table_data text-left">{{model.expirydate |date :'fullDate'}}</td>

                            <td class="table_data text-center" style="cursor: pointer;">
                                <div class="btn btn-primary btn-sm mx-1" (click)="edit(model)" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop">
                                    <i class="fa fa-edit"></i>
                                </div>
                                <div class="btn btn-danger btn-sm mx-1" (click)="delete(model.id)">
                                    <i class="fa fa-trash"></i>
                                </div>

                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!annoucmentsList.length">
                        <tr>
                            <td colspan="9">
                                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                                    <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <!-- PAGINATION -->
            <div *ngIf="annoucmentsList.length" class="flex_row_center_spacebetween"
                style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(annoucmentsList | searchPipe :
                    form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
                    {{(annoucmentsList | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
                <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </div>

        </div>
    </div>
</div>

<!-- ADD MODEL MASTER MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content content1">
            <div class="modal-header addtriphead">
                <div class="headtext text-dark">{{'Announcement' | translate}}</div>
                <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close"
                    (click)="onCloseModal()">
                    <span class="material-icons">
                        close
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="annoucmentForm" (ngSubmit)="onModelMasterSubmit()">

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group pb-2">
                                <label for="manufacturer" class="addlabels pb-2">{{'Description'|translate}}</label>
                                <input type="text" formControlName="description" class="form-control inputfields"
                                    style="background: #FFF;box-shadow:none;color:#000;" id="description"
                                    aria-describedby="description">
                                <span class="text-danger validation"
                                    *ngIf="(annoucmentFormControl.description.touched || submitted) && annoucmentFormControl.description.errors?.required">
                                    *description is required.
                                </span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group pb-2">
                                <label for="medialink" class="addlabels pb-2">{{'Media link'|translate}}</label>
                                <input type="text" formControlName="medialink" class="form-control inputfields"
                                    style="background: #FFF;box-shadow:none;color:#000;" id="medialink"
                                    aria-describedby="medialink">
                                <span class="text-danger validation"
                                    *ngIf="(annoucmentFormControl.medialink.touched || submitted) && annoucmentFormControl.medialink.errors?.required">
                                    *Media Link is required.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group pb-2">
                                <div class="form-check">
                                    <input formControlName="status" class="form-check-input" type="checkbox"
                                        id="status" />
                                    <label class="form-check-label" for="status">Status</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group pb-2">
                                <label for="expirydate" class="addlabels pb-2">{{'Expiry date'|translate}}</label>
                                <input type="date" formControlName="expirydate" class="form-control  w-100"
                                    id="expiration" />
                                <span class="text-danger validation"
                                    *ngIf="(annoucmentFormControl.expirydate.touched || submitted) && annoucmentFormControl.expirydate.errors?.required">
                                    *Expiry Date is required.
                                </span>
                            </div>

                        </div>
                    </div>



                    <div class="flex_row_center_spacebetween pt-4 pb-2">
                        
                       
                        <button
                        mat-raised-button
                        type="submit"
                        class="cancelbtn"
                       
                      >
                      {{'SAVE' | translate}}
                      </button>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>