import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchPipe'
  })
  export class searchPipe implements PipeTransform {
    transform(values: any[], filter: string,filterKey:string = '', forDeviceListPage?:boolean): any {
      if (!values || !values.length) return [];
      if (!filter) return values;
  
      filter = filter.toUpperCase();
      if (forDeviceListPage) {
        if (filter && Array.isArray(values)) {
          if(filterKey) {
            const keys = Object.keys(values[0][filterKey]);
            return values.filter(valData => {
              let v = valData[filterKey];
              let z = valData['userData'];
              let match = false;
              z.forEach(element => {
                match = element.userName.toLowerCase().indexOf(filter.toLowerCase()) >= 0      
              });
              return v && (keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter.toUpperCase()) >= 0) || match);              
            });
          } else {
            const keys = Object.keys(values[0]);
            return values.filter(v => v && keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter) >= 0));
          }
          
        }
      }
      else {
        if (filter && Array.isArray(values)) {
          if(filterKey) {
            const keys = Object.keys(values[0][filterKey]);
            return values.filter(valData => {
              let v = valData[filterKey];
              return v && keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter) >= 0)
            });
          } else {
            const keys = Object.keys(values[0]);
            return values.filter(v => v && keys.some(k => v[k] && v[k].toString().toUpperCase().indexOf(filter) >= 0));
          }
          
        }
      }
    }
  }