import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortColumn'
})
export class SortColumnPipe implements PipeTransform {

  transform(value: any, column: string, direction: string = 'asc'): any {
    if (column) {
      let sortedArray = (value[0].device || []).sort((a,b)=>{
        if(a[column] > b[column]){
          return (direction === 'desc') ? 1 : -1;
        }
        if(a[column] < b[column]){
          return (direction === 'desc') ? -1 : 1;
        }
        return 0;
      })
      return sortedArray;
    } else {
      return value;
    }
  }

}
