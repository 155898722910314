
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  todate = "";
  fromdate = "";
  pageOption = 10;
  annoucmentsList = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  annoucmentForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  constructor(private masterModel: UsersettingService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.annoucmentForm = this.fb.group(
      {
        description: ['', Validators.required],
        medialink: ['', Validators.required],
        status: ['', Validators.required],
        expirydate: ['', Validators.required],
       
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
  }

  ngOnInit(): void {
    
    this.getAnnoucment();
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        let userData =  JSON.parse(userDataObj);
        this.superAdmin = userData && (userData.administrator || (!userData.administrator && userData.userLimit != 0));
      } catch (error) {}
    }
  }

  get annoucmentFormControl() {
    return this.annoucmentForm.controls;
  }

  getAnnoucment() {
    this.masterModel.getAnnoucment().subscribe(resp => {
      this.annoucmentsList = resp;
    })
  }

  onModelMasterSubmit() {
    
      let body = this.annoucmentForm.value;
      if(this.modelId) {
        this.masterModel.updateAnnoucment(body,this.modelId).subscribe(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Updated",
            showConfirmButton: false,
            timer: 1500,
          });
          this.annoucmentForm.reset();
          $('#staticBackdrop').modal("hide");
          this.getAnnoucment();
        })
      } else {
        this.masterModel.addAnnoucment(body).subscribe(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.annoucmentForm.reset();
          $('#staticBackdrop').modal("hide");
          this.getAnnoucment();
        })
      }
      
    
  }

  edit(model){
    this.editForm=true;
    this.annoucmentForm.setValue({
    
description: model.description,
      medialink: model.medialink,
      status: model.status,
      expirydate:model.expirydate ? model.expirydate.split("T")[0] : null
    })
    this.modelId = model.id;
  }

  delete(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Announcement!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.masterModel.deleteAnnoucment(modelId).subscribe(resp => {
          console.log(resp);
          this.getAnnoucment();
        });
        Swal.fire(
          'Deleted!',
          'ModelMaster has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'ModelMaster is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.annoucmentForm.reset();
  }

  onAddModelMaster() {
    this.editForm = false;
    
    this.modelId = '';
    this.annoucmentForm.reset();
  }

  values(event) {
    // if (event.target.value) {
    //   this.annoucmentsList = this.annoucmentsList.filter(s => s.ModelName.includes(event.target.value));
    //   console.log(this.annoucmentsList)
    // }
    // else {
    //   this.getAnnoucment();
    // }
  }


}

