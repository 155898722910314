import { Pipe, PipeTransform } from '@angular/core';

import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
  pure: true,
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let dateFormat = args ? args : ' MMM d, y h:mm:ss a ';
      return super.transform(value, dateFormat);
    } else {
      return value;
    }
  }
}
