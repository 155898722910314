import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: 'app-device-services',
  templateUrl: './device-services.component.html',
  styleUrls: ['./device-services.component.css']
})
export class DeviceServicesComponent implements OnInit {
  todate = "";
  fromdate = "";
  pageOption = 10;
  ModelMasterList = [];
  modelId = "";
  form:FormGroup;
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addModelMasterForm: FormGroup;
  submitted = false;
  searchMasterModel = "";
  superAdmin :boolean = false;
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  sortcolumnName = '';
  sortdirection = '';
  isAscending = true;
  userData: any;
  CalibrationList: any[] = [];
  selectedCalibration: any = {};
  showClibration = true;
  constructor(
    private masterModel: UsersettingService, 
    private fb: FormBuilder, 
    private customValidator: ValidationService,
    private commonService: CommonService) {
    this.addModelMasterForm = this.fb.group(
      {
        id: [0],
        name: ['', Validators.required],
        type: [''],
        start: [''],
        period: [''],
        attributes: this.fb.group({
          date: ['']
        })        
      });
      this.form = new FormGroup({
        searchValue: new FormControl(''),
      });
  }  
  ngOnInit(): void {    
    
    let userDataObj = localStorage.getItem('userData');
    if (userDataObj) {
      try {
        this.userData =  JSON.parse(userDataObj);
        this.superAdmin = this.userData && this.userData.administrator;
      } catch (error) {}
    }
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "services") {
        this.gridMode = true;
        this.addMode = false;
      }
      this.getAllMaintenanceData();
    })
  }
  get AddModelMasterControl() {
    return this.addModelMasterForm.controls;
  }
  getAllMaintenanceData() {
    this.commonService.getRequest("maintenance", []).then((resp: any) => {
      if (resp?.status) { 
        this.ModelMasterList = resp.data;    
      }
    })
  }

  onModelMasterSubmit() { 
      //console.log(this.addModelMasterForm.value);  

      // let body = {
      //   name: this.addModelMasterForm.value.name,
      //   type: this.addModelMasterForm.value.sensortype,
      //   start: this.addModelMasterForm.value.size,
      //   period: t
      //   attributes: {"voltage_calibration": this.addModelMasterForm.value.attributes}
      // }
      if (this.addModelMasterForm.value.id == 0) {
        this.commonService.postRequest("maintenance", this.addModelMasterForm.value).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addModelMasterForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllMaintenanceData();
        })
      } else {
        //body['id'] = this.addModelMasterForm.value.id;
        this.commonService.putRequest("maintenance/"+this.addModelMasterForm.value.id, this.addModelMasterForm.value).then(resp => {
          Swal.fire({
            icon: "success",
            title: "Your Form has been Submitted",
            showConfirmButton: false,
            timer: 1500,
          });
          this.addModelMasterForm.reset();
          this.gridMode = true;
          this.addMode = false;
          this.getAllMaintenanceData();
        })
      }
    
  }

  onEditModelMaster(model){
    this.addMode=true;
    this.gridMode = false;
    this.addModelMasterForm.patchValue(model);
    //this.modelId = model._id;
  }

  onDeleteModelMaster(modelId){
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Sensor record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService.deleteRequest("maintenance/"+modelId, {}).then(resp => {
          console.log(resp);
          this.gridMode = true;
          this.addMode = false;
          this.getAllMaintenanceData();
        });
        Swal.fire(
          'Deleted!',
          'Service record has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Service is safe :)',
          'error'
        )
      }
    })
  }

  onCloseModal(){
    this.gridMode = true;
    this.addMode = false;
    this.addModelMasterForm.reset();
  }

  onAddModelMaster() {
    this.gridMode = false;
    this.addMode = true;
    this.editForm = false;
    this.addModelMasterForm.reset();
    this.addModelMasterForm.patchValue({id: 0});
  }

  values(event) {
    // if (event.target.value) {
    //   this.ModelMasterList = this.ModelMasterList.filter(s => s.ModelName.includes(event.target.value));
    //   console.log(this.ModelMasterList)
    // }
    // else {
    //   this.getAllModelMaster();
    // }
  }

  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }
  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.ModelMasterList.sort((a,b)=>{
          if(a[column] > b[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a[column] < b[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.ModelMasterList;
    }
  }
  deleteAttr(val: any) {
    (this.addModelMasterForm.controls.attributes as FormArray).controls.splice(val, 1);
    this.addModelMasterForm.controls.attributes.value.splice(val, 1);
    //this.attributes.controls.splice(val, 1);
  }
  showCalibrationData(val: any) {
    this.showClibration = true;
    this.selectedCalibration = val;
    this.CalibrationList = val.attributes.voltage_calibration;
  }
  closeClibration() {
    this.showClibration = false;
    this.selectedCalibration = {};
    this.CalibrationList = [];
  }
}
