<form class="example-form w-100">
    <div class="row w-100">
        <div class="col-12 p-0 d-flex align-items-center">
            <mat-form-field *ngIf="!isTrackingScreen" class="example-full-width w-100" appearance="fill">
                <mat-label>{{'Search Vehicle'|translate}}</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                  [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption [displayWith]="displayFn" #auto="matAutocomplete"
                  (optionSelected)="getVehicleDeviceID($event.option.value)">
                  <mat-option [value]="'All Vehicle'">
                    All Vehicle
                  </mat-option>
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <ng-container >
                
                 <mat-form-field *ngIf="isTrackingScreen" class="example-full-width w-100" appearance="fill">
                  <!-- <mat-label>{{'Search Vehicle'|translate}}</mat-label> -->
                  
                    <input type="text" #searchDevice matInput [formControl]="myInputSearch" 
                    placeholder="{{'Search Vehicle'|translate}}">
                </mat-form-field>
              </ng-container>
              <span *ngIf="!isTrackingScreen"><img src="/assets/icons/report_refresh.png" class="headicon ms-1" style="width: 5vh; object-fit: scale-down"
                alt="Refresh" matTooltip="Refresh" (click)="refreshComponent()" /></span>
        </div>
    </div>
    
  </form>