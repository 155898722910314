import { Injectable } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { WebSocket } from 'ws';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  // constructor(private socket: Socket) {}

  // sendMessage(msg: string) {
  //   this.socket.emit('message', msg);
  // }
  getMessage() {
    // return Observable.create((observer) => {
    //   this.socket.on('new-message', (message) => {
    //     observer.next(message);
    //   });
    // });
  }

  connect() {
    let basicToken: string = localStorage.getItem('basicToken');
    let myWebSocket = new WebSocket(`wss://${environment.baseUrl}:8082/api/socket`, {
      headers: {
        'Set-Cookie': basicToken,
      },
    });
    myWebSocket.asObservable().subscribe((resp: any) => {
      console.log('------------', resp);
    });

    // const socket = io.connect('wss://app.multitrack.in:8082/api/socket', {
    //   transports: ['websocket', 'polling'],
    //   extraHeaders: {
    //     Cookie: localStorage.getItem('basicToken'),
    //   },
    // });
    // socket.on('connect', () => {
    //   console.log(socket.connected); // true
    // });
    // socket.on('disconnect', () => {
    //   console.log(socket.connected); // false
    // });
  }
}
