<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ RTO Master</span></p>

<div class="container-fluid ">
  <div class="row p-0">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD RTO MASTER BTN -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">RTO Master</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop" (click)="onAddRTOMaster()">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span> Add RTO Master
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}" ([ngModel])="searchRTOModel"
                  (input)="values($event)" name="searchRTOModel" aria-label="Recipient's username"
                  aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- PAGINATION DROPDOWN & DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween">
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>
      <!-- RTO MASTER TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">State</th>
              <th class="table_head_text text-center">City</th>
              <th class="table_head_text text-center">RTO Name</th>
              <th class="table_head_text text-center">RTO Number</th>
              <th class="table_head_text text-center">Status</th>
              <th class="table_head_text text-center">Action</th>
            </tr>
          </thead>

          <tbody *ngIf="RtoMasterList.length">
            <tr *ngFor="let rto of RtoMasterList |  paginate : {
                itemsPerPage: 10,
                currentPage: page,
                totalItems: this.RtoMasterList.length
              };let i=index">
              <td class="table_data text-center">{{rto.state}}</td>
              <td class="table_data text-center">{{rto.city}}</td>
              <td class="table_data text-center">{{rto.RTO_Name}}</td>
              <td class="table_data text-center">{{rto.RTO_Code}}</td>
              <td class="table_data text-center">{{rto.status}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop" (click)="onEditRtoMaster(rto)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;"
                        (click)="onDeleteRtoMaster(rto._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!RtoMasterList.length">
            <tr>
              <td colspan="6">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
      <!-- PAGINATION -->
      <div *ngIf="RtoMasterList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{RtoMasterList.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{RtoMasterList.length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>

    </div>
  </div>
</div>

<!-- ADD MODEL MASTER MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header addtriphead">
        <div class="headtext" *ngIf="!editForm">Add RTO Master</div>
        <div class="headtext" *ngIf="editForm">Edit RTO Master</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close" (click)="onCloseModal()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addRTOMasterForm" (ngSubmit)="onRTOMasterSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="rtoName" class="addlabels pb-2">Name</label>
                <input type="text" formControlName="rtoName" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="rtoName" aria-describedby="rtoName">
                <span class="text-danger validation"
                  *ngIf="(AddRtoMasterControl.rtoName.touched || submitted) && AddRtoMasterControl.rtoName.errors?.required">
                  *Name is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="rtoCode" class="addlabels pb-2">RTO Code</label>
                <input type="text" formControlName="rtoCode" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="rtoCode" aria-describedby="rtoCode">
                <span class="text-danger validation"
                  *ngIf="(AddRtoMasterControl.rtoCode.touched || submitted) && AddRtoMasterControl.rtoCode.errors?.required">
                  *RTO Code is required.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="city" class="addlabels pb-2">City</label>
                <input type="text" formControlName="city" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="city" aria-describedby="city">
                <span class="text-danger validation"
                  *ngIf="(AddRtoMasterControl.city.touched || submitted) && AddRtoMasterControl.city.errors?.required">
                  *City is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="status" class="addlabels pb-2">{{'Status'|translate}}</label>
                <input type="text" formControlName="status" class="form-control inputfields"
                  style="background: #FFF;box-shadow:none;color:#000;" id="status" aria-describedby="status">
                <span class="text-danger validation"
                  *ngIf="(AddRtoMasterControl.status.touched || submitted) && AddRtoMasterControl.status.errors?.required">
                  *Status is required.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group pb-2">
                <label for="state" class="addlabels pb-2">State</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="state" formControlName="state">
                  <option value="" disabled>Select State</option>
                  <option *ngFor="let state of states;let i = index" value="{{state}}">{{state}}</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(AddRtoMasterControl.state.touched || submitted) && AddRtoMasterControl.state.errors?.required">
                  *State is required.
                </span>
              </div>
            </div>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2">
            <button mat-raised-button type="button" class="cancelbtn" data-bs-dismiss="modal"
              (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
            <button mat-raised-button *ngIf="!editForm" type="submit" class="cancelbtn">SUBMIT</button>
            <button mat-raised-button *ngIf="editForm" type="submit" class="cancelbtn">{{'UPDATE'|translate}}</button>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>
