<button #openModal [hidden]="true" data-bs-toggle="modal" data-bs-target="#openModal">Open Modal</button>
<div
  class="modal"
  id="openModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" [ngClass]="applySize()">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead bg-light fw-bold" style="border-top: 4px solid #4285f4;">
        <div class="headtext"><ng-content select="[modelheadertext]"></ng-content></div>
        <button
          *ngIf="isCloseButton"
          #btnCloseModel
          (click)="closePopup()"
          type="button"
          class="btnclose d-flex"
          data-bs-dismiss="modal"
          aria-label="Close"
          style="border: none; background: none;"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 1vh">        
        <div class="container-fluid">
            <ng-content select="[modeldata]"></ng-content>
        </div>
      </div>
      <div class="modal-footer pt-0 pb-0 d-block" *ngIf="isFooterNeeded">
        <ng-content select="[footerdata]"></ng-content>
      </div>
    </div>
  </div>
</div>