import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { parse } from 'path';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import { SubjectSubscribeService } from 'src/app/services/subject-subscribe.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonService } from '../../services/common.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isCustomer:boolean = false;
  isDealer:boolean = false;
  isLicense: boolean = false;
  isReports: boolean = false;
  isDevices: boolean = false;
  isMasterModel: boolean = false;
  isDeviceGroup: boolean = false;
  isSensor: boolean = false;
  isService: boolean = false;
  modalCalledFrom = '';
  constructor(
    private dash_http: DashboardDataService,
    public commonService: CommonService,
    private subjectSubscribeService: SubjectSubscribeService,
    private router: Router,
    private user: UsersettingService,
    private cookieService: CookieService
  ) {
    this.commonService.headerEvent.subscribe((result: any) => {
      if (result.value == 'devices') {
        this.modalCalledFrom = result?.calledFrom;
        this.openModel(result.value, result.calledFrom);        
      } else if (result.value == 'customer') {
        this.openModel(result.value, result.calledFrom, result.response);         
      }
    })
  }
  @ViewChild('searchDevice') searchDevice: ElementRef;
  page=0;
  myControl = new FormControl();
  myInputSearch = new FormControl();
  deviceDetails = [];
  vehicleID = [];
  DeviceID: string;
  isTrackingScreen=false;
  username = '';
  useremail = '';
  userDetails:any;
  notifications = [];
  isBackToAdmin:number = 0;
  filteredOptions: Observable<any[]>;
  dealer = false;
  customer = false;

  userData: any = {};
  siteDetails :any;
  ngAfterViewInit() {
    
//this.addedSearchEvent();
}
// addedSearchEvent() {
//   fromEvent(this.searchDevice.nativeElement,'keyup')
//     .pipe(
//         filter(Boolean),
//         debounceTime(300),
//         distinctUntilChanged(),
//         tap((text) => {
//           console.log(this.searchDevice.nativeElement.value);
//           this.subjectSubscribeService.searchDevice.next(this.searchDevice.nativeElement.value)
//         })
//     )
//     .subscribe();
// }
  ngOnInit() {
    this.siteDetails = this.commonService.getSiteDetails();
    let tmpAdmId = localStorage.getItem('temp_adm_user_id');
    this.isBackToAdmin = tmpAdmId ? parseInt(tmpAdmId) : 0;
    this.commonService.isAdminLoginForOtherUser.subscribe(resp=>{
      this.router.navigateByUrl('/main/tracking').then(() => {
        let user = localStorage.getItem('userData');
        if (user) {
          this.userDetails = JSON.parse(user);
          this.username = this.userDetails.name;
          this.useremail = this.userDetails.email;
        }
        window.location.reload();
      });
    })
    let userData = localStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        console.log('userData', this.userData);
      } catch (error) {}
    }
   // this.isTrackingScreen =  this.router && this.router.url == '/main/tracking';
    setTimeout(()=>{
      //this.addedSearchEvent();
    },100)
  //   this.router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  // )
  //     .subscribe(event => {
  //       this.isTrackingScreen =  event && event['url'] == '/main/tracking';
  //       if(this.isTrackingScreen) {
  //         this.myInputSearch.setValue('');
  //         setTimeout(()=>{
  //           //this.addedSearchEvent();
  //         },1)
  //       }
  //     });
  //   // this.onShareProfileImage();
  //   this.getDeviceName();

    this.commonService.getUserDetails().subscribe((resp: any) => {
      if (resp) {
        this.userDetails = resp;
        this.username = resp.name;
        this.useremail = resp.email;
      }
    });
    this.commonService.commonModelReset.subscribe((result) => {
      this.resetModalVariable();
    })
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => {
    //     const name = typeof value === 'string' ? value : value?.name;
    //     return name ? this._filter(name as string) : this.deviceDetails.slice();
    //   }),
    // );
  }
  // displayFn(vehicle: any): string {
  //   return vehicle && vehicle.name ? vehicle.name : '';
  // }
  // private _filter(name: string): any[] {
  //   const filterValue = name.toLowerCase();

  //   return this.deviceDetails.filter(option => option.name.toLowerCase().includes(filterValue));
  // }
  loadMoreNotification(e) {
   this.loadNotification();
      e.stopPropagation();
    
  }
  loadNotification() {
   
    let userId  = localStorage.getItem("userData");
    if(userId) {
      userId = (JSON.parse(userId)).id;
    } else {
      userId = '';
    }

    this.commonService
        .getRequest('reports/events/'+ userId +'?limit=25&offset='+this.page, [])
       // https://app.multitrack.in:8082/api/reports/events/5?limit=10&offset=0
        .then((resp: any) => {
          this.page++;
          console.log('-----------------', resp);
          if (resp?.status) {
            this.notifications = this.notifications.concat(resp.data);
          }
        });
  }
  // getDeviceName() {

  //   this.commonService.getRequest('devices?all=all', []).then((resp: any) => {

  //     if (resp?.status) {
  //       this.deviceDetails = resp.data;
  //       localStorage.setItem(
  //         'vehicleIDArray',
  //         JSON.stringify(this.deviceDetails)
  //       );
        
  //       // TODO: handle by dashboard url
  //       this.myControl.setValue(this.deviceDetails[0]);
  //       this.getVehicleDeviceID(this.deviceDetails[0]);
  //     }
  //   });
  //   // this.dash_http.searchDeviceName().subscribe((resp) => {
  //   //   console.log(resp);
  //   //   this.deviceDetails = resp.devices;
  //   //   //  this.vehicleID=this.deviceDetails.map((b) => b["Device_ID"]);
  //   //   localStorage.setItem(
  //   //     'vehicleIDArray',
  //   //     JSON.stringify(this.deviceDetails)
  //   //   );
  //   //   this.myControl.setValue(this.deviceDetails[0]['Device_Name']);
  //   //   this.getVehicleDeviceID(this.deviceDetails[0]['Device_Name']);
  //   // });
  // }
  backToAdmin(){
    let isFailed = false;
    const basicToken = localStorage.getItem('basicToken');
    let authData = atob(basicToken);
    if(authData){
      let userDetails = authData.split(':');
      if(userDetails[0] && userDetails[1]) {
        this.adminLogin(userDetails[0],userDetails[1],basicToken)
      } else {
        isFailed =true;
      }
    } else{
      isFailed =true;
    }
    if(isFailed) {
      Swal.fire({
        icon: "error",
        title: "Unauthorized user",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  adminLogin(username,password,basicToken){
    
     const body = new HttpParams()
        .set('email', username)
        .set('password', password);
    this.commonService.postFormRequest('session', body).then((resp: any) => {
      if (resp?.status) {
        localStorage.clear();
        localStorage.setItem('userData', JSON.stringify(resp.data));
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem(
          'basicToken',
          basicToken
        );
        this.router.navigateByUrl('/main/tracking').then(()=>{
          window.location.reload();
        });
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Unauthorized user",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    (err) => {
    });
  }
  // getVehicleDeviceID(value) {
  //   //let filterData = this.deviceDetails.find((x) => x.name === value.name);
  //   this.DeviceID = value ?  value.id : '';
  //   // this.dash_http.DeviceId.next(this.DeviceID);
  //   this.subjectSubscribeService.DeviceId.next(this.DeviceID);
  // }

  // refreshComponent() {
  //   this.dash_http.getDashbaord().subscribe((resp) => {
  //     console.log(resp);
  //     this.dash_http.vehiclesCountComponent.next(true);
  //   });
  // }
  // changeSearchTxt() {
  //   this.subjectSubscribeService.searchDevice.next(this.myInputSearch.value);
  // }
  onLogout() {
    localStorage.clear();
    this.cookieService.delete("JSESSIONID");
    this.commonService.closeSocket();
    this.router.navigate(['/login']);
  }

  onShareProfileImage() {
    this.dash_http.shareProfileImage().subscribe((resp) => {
      console.log(resp);
      this.username = resp.first_name + ' ' + resp.last_name;
      this.useremail = resp.email;
    });
  }
  onUserSetting() {
    this.user.userSetting().subscribe((resp) => {
      console.log('ok');
      let SuperAdmin = resp.isSuperAdmin;
      let Dealer = resp.isDealer;
      if (SuperAdmin == true) {
        this.dealer = true;
        this.customer = true;
      }
      if (Dealer) {
        this.dealer = false;
        this.customer = true;
      }
    });
  }

  get check() {
    return window.location.href.includes('admin');
  }
  resetModalVariable() {
    this.isCustomer = false;
    this.isDealer = false;
    this.isLicense = false;
    this.isReports = false;
    this.isDevices = false;
    this.isMasterModel = false;
    this.isDeviceGroup = false;
    this.isSensor = false;
    this.isService = false;
  }
  openModel(val: any, calledFrom = '', response? : any) {
    this.resetModalVariable();
    this.modalCalledFrom = calledFrom;
    if (val == 'customer') {      
      this.isCustomer = true;
    } else if (val == 'dealer') {
      this.isDealer = true;
    } else if (val == 'license') {
      this.isLicense = true;
    } else if (val == 'reports') {
      this.isReports = true;
    } else if (val == 'devices') {      
      this.isDevices = true;
    } else if (val == 'masterModel') {
      this.isMasterModel = true;
    } else if (val == 'deviceGroup') {
      this.isDeviceGroup = true;
    } else if (val == 'sensors') {
      this.isSensor = true;
    } else if (val == 'services') {
      this.isService = true;
    }
    if (this.isCustomer || 
      this.isDealer ||
      this.isLicense ||
      this.isReports ||
      this.isDevices ||
      this.isMasterModel ||
      this.isDeviceGroup ||
      this.isSensor ||
      this.isService) {
        setTimeout(() => {
          if (val == 'customer') {
            this.commonService.pageLoadInModal.next({val: val, res: response, from: calledFrom});
          } else {
            this.commonService.pageLoadInModal.next(val);
          }
          this.commonService.commonModel.next(true);  
        }, 100);        
      }
  }
}
