import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesService } from 'src/app/services/routes.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-routeplan',
  templateUrl: './routeplan.component.html',
  styleUrls: ['./routeplan.component.css']
})
export class RouteplanComponent implements OnInit {

  lat: number = 20.5937;
  lng: number = 78.9629;
  zoom = 6;
  userId = localStorage.getItem("userId");
  selectvehicle="";
  selectroute="";
  selectpoi="";
  pageOptionModal=10;
  pageOption=10;
  RoutePlanTable=true;
  fullScreen=false;
  createRoutePlan:FormGroup;
  vehiclename="";
  submitted = false;
  routePlanList=[];
  todaysDate="";
  yesterdayDate="";

  constructor(private routeplan:RoutesService,private fb:FormBuilder,private customValidator: ValidationService,private router:Router) { 
    this.createRoutePlan = this.fb.group(
      {
        startpoint: ['', Validators.required],
        hault: ['', Validators.required],
        poi: ['', Validators.required],
        endpoint: ['', Validators.required],
        vehiclename: ['', Validators.required],
        checkhalt: [false],
        time: ['60', Validators.compose([Validators.required])],
        totaldistance : ['', Validators.required],
      })

    // TODAY AND YESTERDAY DATE
      this.todaysDate = new Date().toISOString();
      const yesterday = new Date(this.todaysDate)
      yesterday.setDate(yesterday.getDate() - 1)
      this.yesterdayDate=yesterday.toISOString()
      console.log(this.todaysDate, this.yesterdayDate);
  }

  ngOnInit(): void {
    this.getAllRoutePlan();
  }

  get RouteFormControl() {
    return this.createRoutePlan.controls;
  }

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById("routePlanForm").style.display = "flex";
      document.getElementById("myMap").style.width = "66.6%";
    }
    else {
      document.getElementById("myMap").style.width = "100%";
      document.getElementById("myMap").style.paddingLeft = "0";
      document.getElementById("routePlanForm").style.display = "none";
    }
    this.fullScreen = !this.fullScreen;
  }

  onRoutePlanSubmit() {
    console.log("ok");
  }

  onCreateRoutePlanBtn(){
    this.RoutePlanTable = false;
    console.log("ok");
  }

  onClickBack(){
   this.RoutePlanTable=true;
   this.createRoutePlan.reset();
  }

  getAllRoutePlan(){
    let body={
      "from": this.yesterdayDate,
      "to": this.todaysDate,
      "user": this.userId,
    }
   this.routeplan.getRoutePlan(body).subscribe(resp=>{
     console.log(resp);
     this.routePlanList=resp;
   })
  }

}
