
<!-- <p class="component my-1">
  {{'Dashboard'|translate}} <span (click)="closeEndMarker()"  class="trackcolor">/ {{'Traking'|translate}} </span>
</p> -->

    
 
<div class="container-fluid" [class.screen-size]="commonService.isFullscreenMap">
  <div class="row">
    <div
      class="col-sm-4 col-12 device-list-setting"
      id="closeFullMap"
    >
      <!-- MAIN CARD -->
      <div *ngIf="!commonService.isFullscreenMap" class="card expand-object-list" [class.device-list]="isDeviceListVisible" title="{{'Expand Object list'|translate}}s" (click)="expandDeviceList()">
       >
      </div>
      <div class="card main_card" *ngIf="main_col && !commonService.isFullscreenMap" [class.device-list]="!isDeviceListVisible">
        <div style="position: relative">
          <div class="owl-carousel d-flex mb-2" id="startCarousel" >
            <div class="item p-1">
              <div class="card_component dash_head1 d-flex align-items-center justify-content-center flex-column" title="{{'All Vehicle'|translate}}" (click)="statusFilter != 'ALL' && deviceFilter('ALL')">
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head1"
                  />
                </span> -->
                <!-- <span class="ok"></span> -->
                <span class="count">{{ allCounts.ALL ? allCounts.ALL:0 }}</span>
                <span class="font-size-12 overflow-dot">{{'All'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div
                class="card_component dash_head2 d-flex align-items-center justify-content-center flex-column" title="{{'Running'|translate}}"
                (click)="statusFilter != 'RUNNING' && deviceFilter('RUNNING')"
              >
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head2"
                  />
                </span> -->
                <!-- <span class="ok">{{'Running'|translate}}</span> -->
                <span class="count">{{ allCounts.RUNNING ? allCounts.RUNNING : 0 }}</span>
                <span class="font-size-12 overflow-dot">{{'Running'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div class="card_component dash_head3 d-flex align-items-center justify-content-center flex-column" title="{{'Idling'|translate}}" (click)="statusFilter != 'IDLE' && deviceFilter('IDLE')">
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head3"
                  />
                </span> -->
                <!-- <span class="ok">{{'Idling'|translate}}</span> -->
                <span class="count">{{ allCounts.IDLE  ? allCounts.IDLE  : 0 }}</span>
                <span class="font-size-12 overflow-dot">{{'Idling'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div
                class="card_component dash_head4 d-flex align-items-center justify-content-center flex-column" title="{{'Stopped'|translate}}"
                (click)="statusFilter != 'STOPPED' && deviceFilter('STOPPED')"
              >
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head4"
                  />
                </span> -->
                <!-- <span class="ok">{{'Stopped'|translate}}</span> -->
                <span class="count">{{ allCounts.STOPPED ?allCounts.STOPPED : 0 }}</span>
                <span class="font-size-12 overflow-dot">{{'Stopped'|translate}}</span>
              </div>
            </div>
            <div class="item p-1" *ngIf="false">
              <div class="card_component dash_head4 d-flex align-items-center justify-content-center flex-column" title="{{'Towwed'|translate}}" (click)="statusFilter != 'TOWWED' && deviceFilter('TOWWED')">
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head4"
                  />
                </span> -->
                <!-- <span class="ok">{{'Towwed'|translate}}</span> -->
                <span class="count">{{ allCounts.TOWWED ?allCounts.TOWWED :0}}</span>
                <span class="font-size-12 overflow-dot">{{'Towwed'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div
                class="card_component dash_head5 d-flex align-items-center justify-content-center flex-column" title="{{'No Reporting'|translate}}"
                (click)="statusFilter != 'NOT_REPORTING' && deviceFilter('NOT_REPORTING')"
              >
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head5"
                  />
                </span> -->
                <!-- <span class="ok">{{'No Reporting'|translate}}</span> -->
                <span class="count">{{ allCounts.NOT_REPORTING ?allCounts.NOT_REPORTING : 0 }}</span>
                <span class="font-size-12 overflow-dot">{{'Inactive'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div
                class="card_component dash_head6 d-flex align-items-center justify-content-center flex-column" title="{{'Expired'|translate}}"
                (click)="statusFilter != 'EXPIRED' && deviceFilter('EXPIRED')"
              >
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head6"
                  />
                </span> -->
                <!-- <span class="ok">{{'Expired'|translate}}</span> -->
                <span class="count">{{ allCounts.EXPIRED ? allCounts.EXPIRED : 0 }}</span>
                <span class="font-size-12 overflow-dot">{{'Expired'|translate}}</span>
              </div>
            </div>
            <div class="item p-1">
              <div
                class="card_component dash_head7 d-flex align-items-center justify-content-center flex-column" title="{{'No Data'|translate}}"
                (click)="statusFilter != 'NO_DATA' && deviceFilter('NO_DATA')"
              >
                <!-- <span>
                  <img
                    src="/assets/icons/dash_head.png"
                    alt="dash_head"
                    class="dash_head7"
                  />
                </span> -->
                <!-- <span class="ok">{{'No Data'|translate}}</span> -->
                <span class="count">{{ allCounts.NO_DATA ?allCounts.NO_DATA :0 }}</span>
                <span class="font-size-12 overflow-dot"> {{'No Data'|translate}} </span>
              </div>
            </div>
          </div>
          <div title="Collapse object list" class="collapse-object-list" (click)="collapseDeviceList()">
            <
          </div>
        </div>

        <!-- <div class="row m-0 dropbtn_row">
          <div class="tracking_dropdown">
            <select class="form-select mb-4">
              <option value="Live Tracking">Live Tracking</option>
              <option value="Geofence">Geofence</option>
              <option value="History Track">History Track</option>
            </select>
          </div>
          <div class="tracking_dropdown">
            <select class="form-select mb-4">
              <option value="">All</option>
              <option value="Kanpur">Kanpur</option>
              <option value="Home">Home</option>
              <option value="Delhi">Delhi</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Udhna">Udhna</option>
            </select>
          </div>
        </div> -->
        <div class="row bg-light m-0">
          <div 
          class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" 
          title="{{'Objects'|translate}}" [class.select]="isObjectsTab" (click)="tabChange('object')">
            {{'Objects'|translate}}
          </div>
          <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" 
          [class.select]="isEventsTab"
          title="{{'Notifications'|translate}}" (click)="tabChange('event')">
            {{'Notifications'|translate}}
          </div>
          <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" [class.select]="isHistoryTab"
          title="{{'History'|translate}}" (click)="tabChange('history')">
            {{'History'|translate}}
          </div>
          <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" [class.select]="isGeofenceTab"
          title="{{'Geofence'|translate}}" (click)="tabChange('Geofence')">
            {{'Geofence'|translate}}
          </div>
          <div class="col-2 d-flex align-items-center justify-content-center font-size-12 device-tabs" [class.select]="isLandmarkTab"
          title="{{'Landmark'|translate}}" (click)="tabChange('Landmark')">
            {{'Landmark'|translate}}
          </div>
        </div>
        <ng-contaier *ngIf="isObjectsTab">
          <div class="row bg-light m-0">
            <div class="col-8 p-1 d-flex align-items-center justify-content-center">
              <div class="row d-flex align-items justify-content-center w-100">
                <div class="col-4 p-0 d-flex align-items-center">
                  <select [(ngModel)]="isObjectSearchBy" style="height: 4.7vh;border: none;"
                  [class.widthDeviceDrd]="isObjectSearchBy == 'device'"
                  [class.widthGroupDrd]="isObjectSearchBy == 'group'" (change)="objectTabDeviceFilterChange()">
                    <option value="device">Device</option>
                    <option value="group">Group</option>
                  </select>
                </div>
                <div class="col-8 p-0 d-flex align-items-center">
                  <app-search-device class="w-100" *ngIf="isObjectSearchBy == 'device'"></app-search-device> 
                  <input type="text" style="height: 4.7vh;border: none;" *ngIf="isObjectSearchBy == 'group'" [(ngModel)]="groupSearchText" placeholder="Search Group">     
                </div>
              </div>  
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center">
              <span  (click)="refreshObjectList()" class="me-3">
                <i class="bi bi-arrow-clockwise font-size-3_8-vh" style="color: #f8526e;" matTooltip="{{'Refresh'| translate}}"></i>
                </span>
              <span (click)="goToDeviceList()" class="me-3"><i class="bi bi-card-list font-size-4-vh" style="color: #f8526e;" matTooltip="{{'Device List'| translate}}"></i></span>
              <span (click)="goToDeviceAdd()" *ngIf="!isNormalUser"><i class="bi bi-plus-square font-size-3-vh" style="color: #f8526e;" matTooltip="{{'Add Device'| translate}}"></i></span>
            </div>
          </div>
          
          <div class="row bg-light m-0">
            <div 
            class="col-1 border-end d-flex align-items-center justify-content-center" 
            title="Show/Hide all">
              <i class="bi bi-eye ms-1"></i>
            </div>
            <div class="col-1 border-end" title="Follow/Unfollow all">
              <img
                    src="/assets/icons/follow.svg"
                    [ngStyle]="{ width: '14px', height: '15px' }"
                    alt="img"
                  />
            </div>
            <div class="col-2 border-end"></div>
            <div class="col-7 border-end d-flex align-items-center justify-content-center font-size-12">{{'Object'|translate}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row m-0">
            <div 
            class="col-1 d-flex align-items-center justify-content-center" 
            >
              <div class="form-check ms-2">
                <input class="form-check-input" type="checkbox" [(ngModel)]="selectAllObjects" (change)="selectAllObjectChange()">              
              </div>
            </div>
            <div class="col-1" title="Follow/Unfollow all">
              <div class="form-check">
                <input class="form-check-input" type="checkbox">              
              </div>
            </div>
            <div class="col-2"></div>
            <div class="col-4 d-flex align-items-center justify-content-center"></div>
            <div class="col-3"></div>
            <div class="col-1"></div>
          </div>
          
          <div
          class="scroller" 
          (scroll)="allCounts[statusFilter] > devicesList.length && onScroll($event)">
            <!-- |  paginate : {
              itemsPerPage: pageOption,
              currentPage: devicePage,
              totalItems: devicesList.length
            }; -->
            <ng-container *ngFor="let g of groupListData">
              <div class="row m-0 d-flex align-items-center justify-content-center car-content bg-light lh-lg" *ngIf="g.name.toLowerCase().indexOf(groupSearchText.toLowerCase()) >= 0">
                <div 
                class="col-1 d-flex align-items-center justify-content-center" 
                >
                  <div class="form-check ms-2">                
                    <input class="form-check-input" type="checkbox" [(ngModel)]="g.isSelected" (change)="groupSelectionChange(g, $event)">              
                  </div>
                </div>
                <div class="col-1" title="Follow/Unfollow all">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox">              
                  </div>
                </div>
                <div class="col-8" style="cursor: pointer;" (click)="expanCollapseGroup(g, 'collapse')">
                  <span>{{g.name}}</span>
                  <span class="badge bg-secondary ms-2">{{g.deviceData.length}}</span>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-end" style="cursor: pointer;">
                  <i class="bi bi-plus-square-fill" *ngIf="!g.isGroupOpen" (click)="expanCollapseGroup(g, 'collapse')"></i>
                  <i class="bi bi-dash-square-fill" *ngIf="g.isGroupOpen" (click)="expanCollapseGroup(g, 'expand')"></i>
                </div>
              </div>
              <ng-container *ngIf="g.name.toLowerCase().indexOf(groupSearchText.toLowerCase()) >= 0">
                <div (click)="onFullVehicleDetail(device, true)" 
                [class.bg-light]="selectedDevice?.deviceId == device.deviceId" 
                class="row m-0 d-flex align-items-center justify-content-center car-content"
                *ngFor="let device of g.deviceData;let i=index;" [class.d-none]="!g.isGroupOpen">
                  <div 
                  class="col-1 d-flex align-items-center justify-content-center" 
                  >
                    <div class="form-check ms-2">                
                      <input class="form-check-input" type="checkbox" (change)="onObjectSelectionChange(device, $event)" [(ngModel)]="device.isSelected">              
                    </div>
                  </div>
                  <div class="col-1" title="Follow/Unfollow all">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox">              
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="fix_align d-flex align-items-center justify-content-center">
                      <!-- {{ device | json }} -->
                      <img
                        [src]="
                          device?.deviceDetails?.vehicleImg ||
                          '/assets/icons/devices/car/card.png'
                        "
                        [ngStyle]="{ width: '33px', height: '39px' }"
                        alt="img"
                        class="vehicleimg"
                      />
                      <ng-container *ngIf="device.statuss === 'STOPPED'">
                        <img
                          src="/assets/icons/red_car.png"
                          alt="img"
                          class="vehicleimg"
                        />
                      </ng-container>
                      <ng-container *ngIf="device.statuss === 'RUNNING'">
                        <img
                          src="/assets/icons/running_car.png"
                          alt="img"
                          class="vehicleimg"
                        />
                      </ng-container>
                      <ng-container *ngIf="device.statuss === 'IDLE'">
                        <img
                          src="/assets/icons/idle_car.png"
                          alt="img"
                          class="vehicleimg"
                        />
                      </ng-container>
                      <ng-container *ngIf="device.statuss === 'NOT_REPORTING'">
                        <img
                          src="/assets/icons/no_reporting_car.png"
                          alt="img"
                          class="vehicleimg"
                        />
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-4 d-flex justify-content-center flex-column">
                    <span class="font-size-12">{{ device.name }}</span>
                    <span
                          class="d-flex align-items-center"
                          *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1"
                        >
                          <img
                            src="/assets/icons/vehicletimer.png"
                            alt="timer"
                            class="img_timer"
                          />
                          <!-- {{'Last Updated'|translate}} -  -->
                          <span class="update pl-1"
                            >{{ device.lastUpdate | dateFormat }}
                          </span>
                    </span>              
                  </div>
                  <div class="col-3 d-flex align-items-center justify-content-center">
                    <!-- <span
                        class="vehicle_run"                  
                        *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1"
                      >
                      <span class="mat_icon min-width-60">
                        <span class="material-icons speedalign"> speed </span>
                        <span class="dist_fuel_speed" style="padding-left: 5px">
                          {{ device?.speed | number : '1.0-2'  }} {{'kmph'|translate}}
                        </span>
                      </span>
                    </span> -->
                    <div
                      class="vehicle_features mt-1"                
                      *ngIf="['EXPIRED', 'NO_DATA'].indexOf(device.status) === -1"
                    >
                      <div class="bottomIcons">
                        <span class="mat_icon d-flex align-items-center justify-content-center flex-column">
                          <span class="speedalign"> {{ device?.speed | number : '1.0-2'  }} </span>
                          <span class="dist_fuel_speed " style="padding-left: 5px;white-space: nowrap">
                            {{'kmph'|translate}}
                          </span>
                        </span>
                      </div>
                      <div class="bottomIcons">
                        <img
                          src="/assets/icons/engine-on.svg"
                          alt="img"
                          title="{{'Engine on'|translate}}"
                          *ngIf="device?.attributes?.ignition; else showIgnRed"
                        />
                        <span class="bottom_tags">{{'IGN'|translate}}</span>
                      </div>
                      <div class="bottomIcons">
                        <img 
                        src="/assets/icons/bottom6.png" 
                        alt="img" 
                        title="{{'GPS'|translate}}"
                        *ngIf="device?.valid; else showGPSGray"/>
                        <span class="bottom_tags">{{'GPS'|translate}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <div class="dropdown">
                      <div
                        class="dropdown-toggle"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        [ngStyle]="{ width: '10px' }"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </div>
                      <ul
                        class="dropdown-menu option_drop pb-1 pt-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdropAdd"
                            (click)="editDevice(device)"
                          >
                            <span class="optionstext">
                              <i class="fa fa-edit"></i> {{'Edit'|translate}}
                            </span>
                          </a>
                        </li>                    
                        <li *ngIf="userData.administrator">
                          <a
                            class="dropdown-item"
                            (click)="deleteDevice(device.deviceId)"
                          >
                            <span class="optionstext">
                              <i class="fa fa-trash"></i> {{'Delete'|translate}}
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            (click)="sendGprsCommand(device)"
                          >
                            <span class="optionstext">
                              <i class="fa fa-trash"></i> {{'Send GPRS Command'|translate}}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-contaier>
        <ng-contaier *ngIf="isEventsTab">
          <div class="row bg-light m-0">
            <div class="col-10 p-1">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                  <input type="text" #searchDevice matInput [(ngModel)]="eventFilterText" 
                  placeholder="{{'Search Vehicle'|translate}}" (input)="eventSearch()">
              </mat-form-field>
            </div>
            <div class="col-2"></div>
          </div>
          <div class="row bg-light m-0">
            <div class="col-3 border-end d-flex align-items-center justify-content-center font-size-12">{{"Object"|translate}}</div>
            <div 
            class="col-5 border-end d-flex align-items-center justify-content-center font-size-12" 
            title="Time">
              {{"Time"|translate}}
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center font-size-12">
              {{"Event"|translate}}
            </div>
          </div>
          <div
          class="scroller" 
          (scroll)="eventsDataList.length && onEventsScroll($event)">           
            <ng-container *ngFor="let evnt of eventsDataList;let i=index;">
              <div [class.bg-light]="i%2 !== 0" class="row m-0 pb-2 pt-2 d-flex align-items-center justify-content-center car-content" (click)="drawEventMarkerOnMap(evnt)" *ngIf="evnt?.deviceName?.indexOf(eventFilterText) >= 0">
                <div class="col-3 d-flex align-items-center font-size-12">
                  {{evnt.deviceName}}
                </div>
                <div class="col-5 d-flex align-items-center font-size-12">
                  {{evnt.eventTime| dateFormat}}
                </div>                
                <div class="col-4 d-flex align-items-center justify-content-between font-size-12">
                  <div> {{evnt.type}} <span *ngIf="evnt.attributes?.alarm" class="ms-1">({{evnt.attributes?.alarm}})</span></div>
                  <div class="dropdown">
                    <div
                      class="dropdown-toggle"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      [ngStyle]="{ width: '10px' }"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </div>
                    <ul
                      class="dropdown-menu option_drop pb-1 pt-1"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          href="javascript:void(0);"
                          class="dropdown-item"                          
                          (click)="addRemarks(evnt)"
                        >
                          <span class="optionstext">
                            <i class="fa fa-edit"></i> {{'Add Remarks'|translate}}
                          </span>
                        </a>
                      </li>                    
                    </ul>
                  </div>
                </div>                
              </div>
            </ng-container>
            
            
          </div>
        </ng-contaier>
        <ng-contaier *ngIf="isHistoryTab" >
          <form action="" [formGroup]="historyForm">
          <div class="row d-flex align-items-center mb-1 mt-1">
            <div class="col-3 font-size-12">
              {{'Object'|translate}}
            </div>
            <div class="col-9">
              <!-- <form class="example-form"> -->
                <input type="text"
                       placeholder="Search for a object"
                       formControlName="object"
                       [matAutocomplete]="auto"
                       class="example-input">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let street of filteredobjectNameList | async" [value]="street.name">
                    {{street.name}}
                  </mat-option>
                </mat-autocomplete>
              <!-- </form> -->
              <!-- <select class="form-select history form-select-sm" aria-label=".form-select-sm example" formControlName="object">
                <option selected value=""></option>
                <option *ngFor="let o of objectNameList" value="{{o.name}}">{{o.name}}</option>
              </select> -->
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-3 font-size-12">
              {{'Filter'|translate}}
            </div>
            <div class="col-9">
              <select class="form-select history form-select-sm" aria-label=".form-select-sm example" formControlName="filter" (change)="setHistoryDate(historyForm.get('filter')?.value)">
                <option selected value=""></option>
                <option value="0">{{'Today'|translate}}</option>
                <option value="1">{{'Last 24 Hour'|translate}}</option>
                <option value="2">{{'Yesterday'|translate}}</option>
                <option value="3">{{'This Week'|translate}}</option>
                <option value="4">{{'Last Week'|translate}}</option>
                <option value="5">{{'This Month'|translate}}</option>
                <option value="6">{{'Last Month'|translate}}</option>
              </select>
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-3 font-size-12">
              {{'Time from'|translate}}
            </div>
            <div class="col-9">
              <input                  
                    type="datetime"
                    formControlName="timeFrom"                   
                  />
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-3 font-size-12">
              {{'Time to'|translate}}
            </div>
            <div class="col-9">
              <input
                    type="datetime"
                    formControlName="timeTo"
                    name="todate"
                    id="meeting"
                   
                  />
            </div>
          </div>
          <div class="row d-flex align-items-center mb-1">
            <div class="col-3 font-size-12">
              {{'Stops'|translate}}
            </div>
            <div class="col-9">
              <select class="form-select history form-select-sm" aria-label=".form-select-sm example">
                <option selected value=""></option>
                <option value="0">> 1 min</option>
                <option value="1">>2 min</option>
                <option value="2">> 5 min</option>
                <option value="3">> 10 min</option>
                <option value="4">> 20 min</option>
                <option value="5">> 30 min</option>
                <option value="6">> 1 h</option>
                <option value="6">> 2 h</option>
                <option value="6">> 3 h</option>
              </select>
            </div>
          </div>
          <div class="row d-flex align-items-center mt-3 mb-3">
            <div class="col-3">
              <button type="button" class="btn btn-light w-100" (click)="showTracking(historyForm.get('filter')?.value)">{{'Show'|translate}}</button>
              
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-light w-100" (click)="hideTracking($event)">{{'Hide'|translate}}</button>
              
            </div>
            <div class="col-4">
              <button type="button" class="btn btn-light w-100" (click)="exportHistoryWithMap()">{{'Import/Export'|translate}}</button>
            </div>
          </div>
          </form>
        </ng-contaier>
        <ng-container *ngIf="isGeofenceTab">
          <ng-container *ngIf="isGeofenceList">
            <div class="row bg-light m-0 d-flex align-items-center">
              <div class="col-10 p-1">
                <mat-form-field class="example-full-width w-100" appearance="fill">
                    <input type="text" matInput 
                    [(ngModel)]="searchGeo" 
                    placeholder="{{'Search Geofence'|translate}}" 
                    
                    name="searchGeo"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2">
                </mat-form-field>
              </div>
              <div class="col-2">
                <div class="dropdown">
                  <button
                    class="btn dropdown-toggle p-0 border-none"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="material-icons addbtn" matTooltip="Add Geofence">
                      add
                    </span>
                  </button>
                  <ul
                    class="dropdown-menu option_drop pb-1 pt-1"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        style="justify-content: flex-start; width: 23vh"
                        (click)="onclickAddPolygon()"
                      >
                        <span class="optionstext">{{'Polygon'|translate}} {{'geofence'|translate}}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        style="justify-content: flex-start; width: 23vh"
                        (click)="onclickAddCircular()"
                      >
                        <span class="optionstext">{{'Circular'|translate}} {{'geofence'|translate}}</span>
                      </a>
                    </li>                    
                  </ul>
                </div>
              </div>
            </div>
            <div class="row bg-light m-0">
              <div 
              class="col-1 border-end d-flex align-items-center justify-content-center" 
              title="Show/Hide all">
                <i class="bi bi-eye ms-1"></i>
              </div>            
              <div class="col-1 border-end"></div>
              <div class="col-5 border-end d-flex align-items-center justify-content-center font-size-12">{{'Name'|translate}}</div>
              <div class="col-4 border-end d-flex align-items-center justify-content-center font-size-12">{{'Radius'|translate}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row m-0">
              <div 
              class="col-1 d-flex align-items-center justify-content-center" 
              >
                <div class="form-check ms-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="selectAllGeofense" (change)="selectAllGeofenseChange('geofence')">              
                </div>
              </div>            
              <div class="col-1"></div>
              <div class="col-5 d-flex align-items-center justify-content-center"></div>
              <div class="col-4"></div>
              <div class="col-1"></div>
            </div>
            <div
            class="scroller" 
            (scroll)="geofenceList.length && onGeofenceScroll($event)"> 
              
              <ng-container *ngFor="let geo of geofenceList;let i=index;">
                <div class="row m-0 d-flex align-items-center justify-content-center car-content" 
                    *ngIf="geo?.geofence?.type && geo.geofence.type !== 'Point' && !geo.attributes?.type && geo.name.indexOf(searchGeo) >= 0"
                    (click)="zoomPolygon(geo)">
                    <div 
                    class="col-1 d-flex align-items-center justify-content-center" 
                    >
                      <div class="form-check ms-2">
                        <input class="form-check-input" type="checkbox" (change)="onGeofenceSelectionChange(geo, $event)" [(ngModel)]="geo.isSelected">              
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">
                      <i class="bi bi-heptagon icoimg"></i>                    
                    </div>
                    <div class="col-5 d-flex justify-content-center flex-column">
                      <span class="font-size-12 fw-bold">{{ geo.name }}</span>
                      <span class="d-flex align-items-center font-size-12">
                        {{ geo.description }}
                      </span>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-1">
                      <div class="dropdown">
                        <div
                          class="dropdown-toggle"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          [ngStyle]="{ width: '10px' }"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </div>
                        <ul
                            class="dropdown-menu option_drop pb-0 pt-1"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                class="dropdown-item"
                                style="justify-content: flex-start"
                                (click)="onEditPolygon(geo)"
                              >
                                <img src="/assets/icons/editgeo.png" alt="edit" />
                                <span class="optionstext" style="padding-left: 1.5vh"
                                  >{{'Edit'|translate}}</span
                                >
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                style="justify-content: flex-start"
                                (click)="onDeletePolygon(geo.id)"
                              >
                                <img src="/assets/icons/deletegeo.png" alt="edit" />
                                <span class="optionstext" style="padding-left: 1.5vh"
                                  >{{'Delete'|translate}}</span
                                >
                              </a>
                            </li>  
                            <li>
                              <a
                                class="dropdown-item"
                                style="justify-content: flex-start"
                                (click)="assingDevicetoGeofence(geo)"
                              >
                                <i class="fa fa-car"></i>
                                <span class="optionstext" style="padding-left: 1.5vh"
                                  >{{'Assign Device'|translate}}</span
                                >
                              </a>
                            </li>                          
                          </ul>
                      </div>
                    </div>                  
                </div>
                <div class="row m-0 d-flex align-items-center justify-content-center car-content" 
                    *ngIf="geo?.geofence?.type && geo.geofence.type == 'Point' && !geo.attributes?.type && geo.name.indexOf(searchGeo) >= 0"
                    (click)="zoomCircle(geo, $event)">
                    <div 
                    class="col-1 d-flex align-items-center justify-content-center" 
                    >
                      <div class="form-check ms-2">                
                        <input class="form-check-input" type="checkbox" (change)="onGeofenceSelectionChange(geo, $event)" [(ngModel)]="geo.isSelected">              
                      </div>
                    </div>
                    <div class="col-1 d-flex align-items-center justify-content-center">
                      <i class="bi bi-circle icoimg"></i>                    
                    </div>
                    <div class="col-5 d-flex justify-content-center flex-column">
                      <span class="font-size-12 fw-bold">{{ geo.name }}</span>
                      <span class="d-flex align-items-center font-size-12">
                        {{ geo.description }}
                      </span>
                    </div>
                    <div class="col-4 d-flex justify-content-center">
                      <!-- <span class="geovehicleradius_text font-size-12"></span> -->
                      <span
                        class="geovehicleradius_count ms-1 font-size-12"
                        *ngIf="geo?.geofence?.radius"
                      >
                        {{ geo.geofence.radius | number: "1.2-2" }}
                      </span>
                    </div>
                    <div class="col-1">
                      <div class="dropdown">
                        <div
                          class="dropdown-toggle"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          [ngStyle]="{ width: '10px' }"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </div>
                        <ul
                              class="dropdown-menu option_drop pb-0 pt-1"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="justify-content: flex-start"
                                  (click)="onEditPoi(geo)"
                                >
                                  <img src="/assets/icons/editgeo.png" alt="edit" />
                                  <span class="optionstext" style="padding-left: 1.5vh"
                                    >{{'Edit'|translate}}</span
                                  >
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="justify-content: flex-start"
                                  (click)="onDeletePoi(geo.id)"
                                >
                                  <img src="/assets/icons/deletegeo.png" alt="edit" />
                                  <span class="optionstext" style="padding-left: 1.5vh"
                                    >{{'Delete'|translate}}</span
                                  >
                                </a>
                              </li>   
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="justify-content: flex-start"
                                  (click)="assingDevicetoGeofence(geo)"
                                >
                                  <i class="fa fa-car"></i>
                                  <span class="optionstext" style="padding-left: 1.5vh"
                                    >{{'Assign Device'|translate}}</span
                                  >
                                </a>
                              </li>                          
                            </ul>
                      </div>
                    </div>                  
                </div>
              </ng-container>
                
                            
                  <!-- <ng-container *ngFor="let geo of geofenceList; let i = index">
                    <div
                      class="card geofence_card mb-3"
                      *ngIf="geo?.geofence?.type && geo.geofence.type === 'Point'"
                      (click)="zoomCircle(geo)"
                    >
                      <div class="flex_row_space_between pb-1">
                        <span class="geohead">{{ geo.name }}</span>
                        
        
                        <div class="col-1" style="padding: 2vh 1vh">
                          <div class="dropdown">
                            <button
                              class="btn dropdown-toggle p-0"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src="/assets/icons/geoedit.png"
                                alt="geoedit"
                                style="width: 0.6vh; cursor: pointer"
                                matTooltip="Options"
                              />
                            </button>
                            <ul
                              class="dropdown-menu option_drop pb-0 pt-1"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="justify-content: flex-start"
                                  (click)="onEditPoi(geo.id)"
                                >
                                  <img src="/assets/icons/editgeo.png" alt="edit" />
                                  <span class="optionstext" style="padding-left: 1.5vh"
                                    >{{'Edit'|translate}}</span
                                  >
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="justify-content: flex-start"
                                  (click)="onDeletePoi(geo)"
                                >
                                  <img src="/assets/icons/deletegeo.png" alt="edit" />
                                  <span class="optionstext" style="padding-left: 1.5vh"
                                    >{{'Delete'|translate}}</span
                                  >
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item">
                                  <span class="optionstext">{{'Display on map'|translate}}</span>
                                  <span>
                                    <mat-slide-toggle
                                      class="example-margin"
                                      [color]="color"
                                      [checked]="checked"
                                      [disabled]="disabled"
                                      matTooltip="Display On Map"
                                    >
                                    </mat-slide-toggle>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item">
                                  <span class="optionstext">{{'Notification Entering'|translate}}</span>
                                  <span>
                                    <mat-slide-toggle
                                      class="example-margin"
                                      [color]="color"
                                      [checked]="checked"
                                      [disabled]="disabled"
                                      matTooltip="Notification Entering"
                                    >
                                    </mat-slide-toggle>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item">
                                  <span class="optionstext">{{'Notification Exiting'|translate}}</span>
                                  <span>
                                    <mat-slide-toggle
                                      class="example-margin"
                                      [color]="color"
                                      [checked]="checked"
                                      [disabled]="disabled"
                                      matTooltip="Exit Notification"
                                    >
                                    </mat-slide-toggle>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-11">
                      
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 geo_vehicle_radius">
                          <div class="flex_row_space_around">
                            <span class="geovehicleradius_text">
                              {{ geo.description }}
                            </span>
                          </div>
                        </div>
                        <div class="col-6 geo_vehicle_radius">
                          <div class="flex_row_space_around">
                            <span class="geovehicleradius_text">{{'Radius'|translate}}</span>
                            <span
                              class="geovehicleradius_count"
                              *ngIf="geo?.geofence?.radius"
                            >
                              {{ geo.geofence.radius | number: "1.2-2" }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>                 -->
                
            </div>
          </ng-container>
          <!-- ADD/EDIT POLYGON GEOFENCE CARD -->

          <div class="addmain_card" *ngIf="addpolygon">
            <dir class="row p-0 mt-2">
              <div class="col-12 fw-bold">
                {{'Add Polygon Geofence'|translate}}
              </div>
            </dir>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="polygonForm" (ngSubmit)="onPolygonSubmit()">
                  <div class="form-group pb-2">
                    <label for="fencename" class="pb-2"
                      >{{'Fence Name'|translate}}</label
                    >
                    <input
                      type="text"
                      formControlName="fencename"
                      class="form-control inputfields"
                      id="fencename"
                      aria-describedby="fencename"
                    />
                    <span
                      class="text-danger validation"
                      *ngIf="
                        (polygonFormControl.fencename.touched || submitted) &&
                        polygonFormControl.fencename.errors?.required
                      "
                    >
                      *{{'Fence Name'|translate}} {{'is required'|translate}}.
                    </span>
                  </div>
  
                  <div class="form-group">
                    <label for="location" class="pb-2">{{'Add Location'|translate}}</label>
                    <div class="input-group inputborder">
                      <div class="input-group-prepend">
                        <div class="input-group-text searchIcon">
                          <img src="/assets/icons/searchwhite.png" alt="search" />
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control inputfields"
                        style="border: none !important"
                        id="location"
                        formControlName="geoaddress"
                        placeholder="Search Location"
                      />
                    </div>
                  </div>
  
                  <div class="d-flex align-items-center mt-2" *ngIf="false">
                    <img
                          src="/assets/icons/address.png"
                          alt="addressicon"
                          class="address_img me-3"
                    />
                    <div class="" style="font-size: 1.5vh !important">
                      
                    </div>
                     
                      <span class="ms-3"
                        style="background-color: #E3E7EC; padding: 0 5px;"><img
                          src="/assets/icons/close.png"
                          alt="close"
                          style="cursor: pointer"
                      /></span>
                    
                  </div>
  
                  <div class="row pt-4 pb-3">
                    <div class="col-6">
                      <button                        
                        class="btn btn-light w-100"
                        (click)="onCancelPolygon()"
                      >
                        {{'CANCEL'|translate}}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-light w-100">
                        {{'SUBMIT'|translate}}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>            
          </div>

          <!-- ADD/EDIT CIRCULAR GEOFENCE CARD -->

          <div class="addmain_card" *ngIf="addcircular">
            <dir class="row p-0 mt-2">
              <div class="col-12 fw-bold">
                {{'Add Circular Geofence'|translate}}
              </div>
            </dir>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="circularForm" (ngSubmit)="onCircularSubmit()">
                <div class="form-group pb-2">
                  <label for="circularName" class="pb-2"
                    >{{'Fence Name'|translate}}</label
                  >
                  <input
                    type="text"
                    formControlName="circularName"
                    class="form-control inputfields"
                    id="circularName"
                    aria-describedby="circularName"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="
                      (circularFormControl.circularName.touched || submitted) &&
                      circularFormControl.circularName.errors?.required
                    "
                  >
                    *{{'Fence Name'|translate}} {{'is required'|translate}}.
                  </span>
                </div>

                <div class="form-group">
                  <label for="location1" class="pb-2">{{'Add Location'|translate}}</label>
                  <div class="input-group inputborder">
                    <div class="input-group-prepend">
                      <div class="input-group-text searchIcon">
                        <img src="/assets/icons/searchwhite.png" alt="search" />
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control inputfields"
                      style="border: none !important"
                      id="location1"
                      formControlName="circularAddress"
                      placeholder="Search Location"
                    />
                  </div>
                </div>

                <div class="d-flex align-items-center mt-2" *ngIf="false">
                  <img
                        src="/assets/icons/address.png"
                        alt="addressicon"
                        class="address_img me-3"
                  />
                  <div class="" style="font-size: 1.5vh !important">
                    Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                    394210 (India)
                  </div>
                   
                    <span class="ms-3"
                      style="background-color: #E3E7EC; padding: 0 5px;"><img
                        src="/assets/icons/close.png"
                        alt="close"
                        style="cursor: pointer"
                    /></span>
                  
                </div>
                
                <div class="row pt-4 pb-3">
                  <div class="col-6">
                    <button                      
                      class="btn btn-light w-100"
                      (click)="onCancelCircular()"
                    >
                      {{'CANCEL'|translate}}
                    </button>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-light w-100">
                      {{'SUBMIT'|translate}}
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>          
        </ng-container>
        <ng-container *ngIf="isLandmarkTab">
          <ng-container *ngIf="isGeofenceLandmarkList">
            <div class="row bg-light m-0 d-flex align-items-center">
              <div class="col-10 p-1">
                <mat-form-field class="example-full-width w-100" appearance="fill">
                    <input type="text" matInput 
                    [(ngModel)]="searchGeo" 
                    placeholder="{{'Search Landmark'|translate}}"                    
                    name="searchGeo"
                    >
                </mat-form-field>
              </div>
              <div class="col-2">
                <div class="dropdown">
                  <button
                    class="btn p-0 border-none"
                    type="button"  
                    style="margin-top: 0.5vh;"  
                    (click)="onclickAddCircular()"                
                  >
                    <span class="material-icons addbtn" matTooltip="Add Landmark">
                      add
                    </span>
                  </button>                  
                </div>
              </div>
            </div>
            <div class="row bg-light m-0">
              <div 
              class="col-1 border-end d-flex align-items-center justify-content-center" 
              title="Show/Hide all">
                <i class="bi bi-eye ms-1"></i>
              </div>            
              <div class="col-1 border-end"></div>
              <div class="col-5 border-end d-flex align-items-center justify-content-center font-size-12">{{'Name'|translate}}</div>
              <div class="col-2 border-end d-flex align-items-center justify-content-center font-size-12">{{'Radius'|translate}}</div>
              <div class="col-2 border-end d-flex align-items-center justify-content-center font-size-12">{{'Type'|translate}}</div>
              <div class="col-1"></div>
            </div>
            <div class="row m-0">
              <div 
              class="col-1 d-flex align-items-center justify-content-center" 
              >
                <div class="form-check ms-2">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="selectAllGeofense" (change)="selectAllGeofenseChange('landmark')">              
                </div>
              </div>            
              <div class="col-1"></div>
              <div class="col-5 d-flex align-items-center justify-content-center"></div>
              <div class="col-2"></div>
              <div class="col-2"></div>
              <div class="col-1"></div>
            </div>
            <div
            class="scroller" 
            (scroll)="geofenceList.length && onGeofenceScroll($event)"> 
              
              <ng-container *ngFor="let geo of geofenceList;let i=index;">
                <div class="row m-0 d-flex align-items-center justify-content-center car-content" 
                *ngIf="geo?.geofence?.type && geo.geofence.type == 'Point' && geo.attributes?.type && geo.name.indexOf(searchGeo) >= 0"
                (click)="zoomLandmarkCircle(geo, $event)">
                <div 
                class="col-1 d-flex align-items-center justify-content-center" 
                >
                  <div class="form-check ms-2">                
                    <input class="form-check-input" type="checkbox" (change)="onGeofenceSelectionChange(geo, $event)" [(ngModel)]="geo.isSelected">              
                  </div>
                </div>
                <div class="col-1 d-flex align-items-center justify-content-center">
                  <i class="bi bi-circle icoimg"></i>                    
                </div>
                <div class="col-5 d-flex justify-content-center flex-column">
                  <span class="font-size-12 fw-bold">{{ geo.name }}</span>
                  <span class="d-flex align-items-center font-size-12">
                    {{ geo.attributes?.address }}
                  </span>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <!-- <span class="geovehicleradius_text font-size-12"></span> -->
                  <span
                    class="geovehicleradius_count ms-1 font-size-12"
                    *ngIf="geo?.geofence?.radius"
                  >
                    {{ geo.geofence.radius | number: "1.2-2" }}
                  </span>
                </div>
                <div class="col-2 d-flex ps-0">
                  <span class="geovehicleradius_count ms-1 font-size-12">{{ geo.attributes?.type }}</span>
                </div>
                <div class="col-1">
                  <div class="dropdown">
                    <div
                      class="dropdown-toggle"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      [ngStyle]="{ width: '10px' }"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </div>
                    <ul
                          class="dropdown-menu option_drop pb-0 pt-1"
                          aria-labelledby="dropdownMenuButton1"
                        >                          
                          <li>
                            <a
                              class="dropdown-item"
                              style="justify-content: flex-start"
                              (click)="onDeletePoi(geo.id)"
                            >
                              <img src="/assets/icons/deletegeo.png" alt="edit" />
                              <span class="optionstext" style="padding-left: 1.5vh"
                                >{{'Delete'|translate}}</span
                              >
                            </a>
                          </li>   
                          <li>
                            <a
                              class="dropdown-item"
                              style="justify-content: flex-start"
                              (click)="assingDevicetoGeofence(geo)"
                            >
                              <i class="fa fa-car"></i>
                              <span class="optionstext" style="padding-left: 1.5vh"
                                >{{'Assign Device'|translate}}</span
                              >
                            </a>
                          </li>                          
                        </ul>
                  </div>
                </div>                  
            </div>             
              </ng-container>
            </div>
          </ng-container>
          
          <!-- ADD/EDIT CIRCULAR GEOFENCE CARD -->

          <div class="addmain_card" *ngIf="addcircular">
            <dir class="row p-0 mt-2">
              <div class="col-12 fw-bold">
                {{'Add Landmark'|translate}}
              </div>
            </dir>
            <div class="row">
              <div class="col-12">
                <form [formGroup]="circularForm" (ngSubmit)="onCircularLandmarkSubmit()">
                <div class="form-group pb-2">
                  <label for="circularName" class="pb-2"
                    >{{'Name'|translate}}</label
                  >
                  <input
                    type="text"
                    formControlName="circularName"
                    class="form-control inputfields"
                    id="circularName"
                    aria-describedby="circularName"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="
                      (circularFormControl.circularName.touched || submitted) &&
                      circularFormControl.circularName.errors?.required
                    "
                  >
                    *{{'Name'|translate}} {{'is required'|translate}}.
                  </span>
                </div>

                <div class="form-group">
                  <label for="location1" class="pb-2">{{'Add Location'|translate}}</label>
                  <div class="input-group inputborder">
                    <div class="input-group-prepend">
                      <div class="input-group-text searchIcon">
                        <img src="/assets/icons/searchwhite.png" alt="search" />
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control inputfields"
                      style="border: none !important"
                      id="location1"
                      formControlName="circularAddress"
                      placeholder="Search Location"
                    />
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label for="location1" class="pb-2">{{'Type'|translate}}</label>
                  <select class="form-select" formControlName="circularType" style="
                  height: 5.7vh;
                  box-shadow: none;
                  background-color: white;
                  border: 1px solid;
              ">
                    <option value="provider">Provider</option>
                    <option value="customer">Customer</option>
                    <option value="garage">Garage</option>
                    <option value="emergency">Emergency</option>
                    <option value="others">Others</option>
                    <option value="gasstation">Gas Station</option>
                    <option value="residence">Residence</option>
                    <option value="city">City</option>
                    <option value="work">Work</option>
                    <option value="restorant">Restorant</option>
                    <option value="shopping">Shopping</option>
                    <option value="bank">Bank</option>
                    <option value="school">School</option>
                    <option value="supermarket">Super Market</option>
                    <option value="gym">Gym</option>
                    <option value="home">Home</option>
                    <option value="grocerystore">Grocery Store</option>
                  </select>
                </div>
                <div class="form-group mt-2">
                  <label for="exampleFormControlTextarea1" class="form-label">Address</label>
                  <textarea class="form-control" formControlName="circularAddr" id="exampleFormControlTextarea1" rows="2"></textarea>
                  
                </div>
                
                <div class="row pt-4 pb-3">
                  <div class="col-6">
                    <button                      
                      class="btn btn-light w-100"
                      (click)="onCancelCircular()"
                    >
                      {{'CANCEL'|translate}}
                    </button>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-light w-100">
                      {{'SUBMIT'|translate}}
                    </button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div> 
        </ng-container>
      </div>
      <div class="card main_card mt-2" *ngIf="isHistoryTab && historyActivityList.length && !commonService.isFullscreenMap" [class.device-list]="!isDeviceListVisible">
        <ng-contaier>          
          <div
          class="scroller scroller-activity" 
          (scroll)="historyActivityList.length > 0 && onHistoryActivityScroll($event)">
            
            <div
            class="row m-0 d-flex align-items-center justify-content-center car-content" 
            *ngFor="let h of historyActivityList" style="height: 30px;" (click)="activityClicked(h)">
              <div class="col-4 d-flex justify-content-center flex-column">
                <span class="font-size-12 fw-bold">{{h.startTime | date: 'MMM dd, hh:mm a'}}</span>                      
              </div>
              <div class="col-1 p-0 font-size-12 d-flex align-items-center justify-content-center">
                <span *ngIf="!h.startPositionId && !h.endPositionId" class="activity-status">
                  P
                </span>
                <!-- <div style="border: 1px solid gray;
                height: 35px;
                width: 1px;
                position: absolute;
                top: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                /* left: 21px; */
                margin-left: 12px;
                z-index: 0;"></div> -->
                <span *ngIf="h.startPositionId && h.endPositionId" class="activity-status activity-status-parking">
                  <i class="fa fa-car"></i>
                </span>
              </div>
              <div class="col-3 d-flex align-items-center justify-content-center">
                <span>{{calculateTripTime(h.startTime, h.endTime) |convert:'hh:mm'}} min</span>
              </div>
              <div class="col-4">
                {{h.distance | meterTokm}} {{'Kms'|translate}}
              </div>
            </div>
          </div>
        </ng-contaier>
      </div>
      <!-- DETAILS AND STATUS CARD -->

      <div class="card main_card2" *ngIf="fulldetails">
        <!-- BUTTON GROUP (VEHICLE-DETAILS AND STATUS) -->

        <div class="btngroup">
          <div>
            <div class="btn detailbtn" (click)="onClickDetails()">
              {{'Vehicle Details'|translate}}

             
            </div>
            <!-- <div class="btn detailbtn" (click)="onClickStatus()">{{'Status'|translate}}</div> -->
          </div>
          <div class="btn" style="padding: 0 0.5vh">
            <span class="material-icons" style="color: #fff" (click)="onBack()">
              arrow_back
            </span>
          </div>
        </div>

        <!-- VEHICLE DETAILS BOX ( CARD-2 ) -->

        <div class="vehicle_fullDetail" *ngIf="vehicle_fullDetail">
          <div class="mat_icon">
            <div class="fix_align">
              <img
                [src]="
                  selectedDevice?.deviceDetails?.vehicleImg ||
                  '/assets/icons/devices/car/card.png'
                "
                [ngStyle]="{ width: '45px', height: '60px' }"
                alt="img"
                class="vehicleimg"
              />
            </div>
            <span class="vehicle_update" style="margin-left: 1vh">
              <span class="vehicle_number">{{ selectedDevice.name }}</span>
              <span class="height-17" >
                <img
                  [src]="
                    selectedDevice?.deviceDetails?.dotImg ||
                    '/assets/icons/no_data.png'
                  "
                  alt="dot"
                  class="dot"
                />
                <span class="update" style="font-size: 1.4vh">{{'Status'|translate}}
                  <span
                    [ngStyle]="{
                      color: selectedDevice?.deviceDetails?.color,
                      'padding-left': '5px'
                    }"
                  >
                    {{ selectedDevice?.deviceDetails?.msg }}
                  </span>
                </span>
                
              </span>
              <span class="">
                <img
                  src="/assets/icons/vehicletimer.png"
                  alt="timer"
                  class="img_timer2"
                />
                <span class="update" style="font-size: 1.4vh"
                  >{{'Last Updated'|translate}} -
                  {{ selectedDevice.deviceTime | dateFormat }}</span
                >
              </span>
            </span>
            <div class="speed-large">
              <span class="dist_fuel_speed_span" style="padding-left: 5px">
                {{ selectedDevice?.speed | number : '1.0-2'}}<small>{{'kmph'|translate}}</small>
              </span>
            </div>
          </div>

          <!-- <div class="row pt-1  m-0">
            <div class="col-6 p-0">
              <span class="mat_icon">
                <span class="material-icons speedalign"> {{'speed'|translate}} </span>
                <span class="dist_fuel_speed" style="padding-left: 5px"
                  >{{ selectedDevice?.speed || 0 }}{{'kmph'|translate}}</span
                >
              </span>
            </div>
            <div class="col-6 p-0">
              <span class="mat_icon">
                <img src="/assets/icons/petrolremaining.png" alt="dot" />
                <span class="dist_fuel_speed">NA</span>
              </span>
            </div>
          </div> -->

          <!-- <div class="row py-1 m-0">
            <div class="col-6 p-0">
              <span class="mat_icon">
                <span class="material-icons speedalign"> schedule </span>
                <span
                  class="dist_fuel_speed"
                  style="padding-left: 5px"
                  *ngIf="selectedDevice?.attributes?.hours; else showDuration"
                  >{{ selectedDevice?.attributes?.hours | dateDuration }}</span
                >
                <ng-template #showDuration>
                  <span class="dist_fuel_speed" style="padding-left: 5px"
                    >0</span
                  >
                </ng-template>
              </span>
            </div>
            <div class="col-6 p-0">
              <span class="mat_icon">
                <img src="/assets/icons/speed.png" alt="dot" />
                <span class="dist_fuel_speed"
                  >{{ selectedDevice?.attributes?.distance || 0 }}Km</span
                >
              </span>
            </div>
          </div> -->

          <div class="row py-1 m-0">
            <div class="mat_icon" style="padding-left: 4px">
              <img
                src="/assets/icons/address.png"
                alt="address"
                class="address_img"
              />
              <span class="address"
                >{{selectedDevice.address ? selectedDevice.address : 'NA' }}
                </span
              >
            </div>
          </div>
          <div class="position-relative" style="margin: 3vh 0;">
            <hr style="height: 0.3vh;color: black;">
            <div class="d-flex flex-row timer_boxes">
              <!-- {{selectedDevice?.today_odo | number : '1.0-0'}} -->
              <div class="num_box">{{my_string[0]}}</div>
              <div class="num_box">{{my_string[1]}}</div>
              <div class="num_box">{{my_string[2]}}</div>
              <div class="num_box">{{my_string[3]}}</div>
              <div class="num_box">{{my_string[4]}}</div>
              <div class="num_box">{{my_string[5]}}</div>
              <div class="num_box">{{my_string[6]}}</div>
            </div>
          </div>
          <div class="vehicle_features my-1 px-2">
            <div class="bottomIcons">
              <img
                src="/assets/icons/bottom1.png"
                alt="img"
                *ngIf="selectedDevice?.attributes?.ignition; else showIgnRed"
              />
              <span class="bottom_tags">{{'IGN'|translate}}</span>
            </div>
            <div class="bottomIcons">
              <img src="/assets/icons/bottom2.png" alt="img" />
              <span class="bottom_tags">{{'AC'|translate}}</span>
            </div>
            <div class="bottomIcons">
              <img src="/assets/icons/bottom3.png" alt="img" />
              <span class="bottom_tags">{{'Fuel'|translate}}</span>
            </div>
            <div class="bottomIcons">
              <img
                src="/assets/icons/bottom4.png"
                alt="img"
                *ngIf="selectedDevice?.attributes?.charge; else showPowerRed"
              />
              <span class="bottom_tags">{{selectedDevice?.attributes?.power | number : '1.0-2'}} v</span>
            </div>
            <div class="bottomIcons">
              <img src="/assets/icons/bottom5.png" alt="img" />
              <span class="bottom_tags">{{'Temp'|translate}}</span>
            </div>
            <div class="bottomIcons">
              <img src="/assets/icons/bottom6.png" alt="img" />
              <span class="bottom_tags">{{'GPS'|translate}}</span>
            </div>
            <div class="bottomIcons">
              <img src="/assets/icons/bottom7.png" alt="img" />
              <span class="bottom_tags">{{'Door'|translate}}</span>
            </div>
          </div>

          <div class="day_calendar pt-3 pb-2 m-0 px-2">
            <div class="date">{{selectedDate | date:'mediumDate'}}</div>
            <input
            type="date"
            id="meeting"
            value="2022-03-26"
            [(ngModel)]="selectedDate"
            name="selectedDate"
            (change)="onDateSelection()"
          />
            <!-- <img
              src="/assets/icons/vehc_calendar.png"
              alt="calendar"
              class="cal_img"
            /> -->
          </div>

          <div class="row m-0 borderrow">
            <div
              class="col-4"
              style="border-right: 2px solid #324151; padding: 0.75rem 0"
            >
              <div class="mat_icon justify-content-center">
                <span class="material-icons movingimg"> schedule </span>
                <span class="flexcolumn" style="padding-left: 1vh">
                  <span class="daytext">{{'Moving Time'|translate}}</span>
                  <span class="dayconts">{{vehicleDetailsReport.summary.engineHours |convert:'hh:mm'}}</span>
                </span>
              </div>
            </div>
            <div
              class="col-4"
              style="border-right: 2px solid #324151; padding: 0.75rem 0"
            >
              <div class="mat_icon justify-content-center">
                <span
                  class="material-icons movingimg"
                  style="background-color: #e8ad21"
                >
                  speed
                </span>
                <span class="flexcolumn" style="padding-left: 1vh">
                  <span class="daytext">{{'Total Kilometer'|translate}}</span>
                  <span class="dayconts">{{vehicleDetailsReport.summary.distance | meterTokm}} {{'Kms'|translate}}</span>
                </span>
              </div>
            </div>
            <div class="col-4" style="padding: 0.75rem 0">
              <div class="mat_icon justify-content-center">
                <img
                  src="/assets/icons/time_icon.png"
                  alt="moving"
                  class="movingimg"
                  style="background-color: #f9536f"
                />
                <span class="flexcolumn" style="padding-left: 1vh">
                  <span class="daytext">{{'Parked'|translate}}</span>
                  <span class="dayconts">{{vehicleDetailsReport.summary.parkedTime |convert:'hh:mm'}}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="btngroup1">
            <!-- <div class="btn detailbtn">All</div> -->
            <div [class.active]="activeSection == 1" (click) ="activeSection = 1" class="btn detailbtn">{{'Moving'|translate}}</div>
            <div [class.active]="activeSection == 2" (click) ="activeSection = 2" class="btn detailbtn">{{'Parked'|translate}}</div>
            <div [class.active]="activeSection == 3" (click) ="activeSection = 3" class="btn detailbtn">{{'Alert'|translate}}</div>
          </div>

          <div class="row timelinerow scroller2">
            <div id="content">
              <ul *ngIf="activeSection == 1" class="timeline">
                <li *ngFor="let item of vehicleDetailsReport.moving" class="event" [attr.data-date]="item.startTime | date:'shortTime'">
                  <h3>{{item.endTime | date:'shortTime'}}</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >{{'Distance'|translate}} - {{item.distance | meterTokm}} KM</span
                    >
                  </div>
                </li>
                <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
              </ul>
              <ul *ngIf="activeSection == 2" class="timeline">
                <li *ngFor="let item of vehicleDetailsReport.parked" class="event" [attr.data-date]="item.startTime | date:'shortTime'">
                  <h3>{{item.endTime | date:'shortTime'}}</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >{{'Duration'|translate}} - {{item.duration |convert:'hh:mm'}} </span
                    >
                  </div>
                </li>
                <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
              </ul>
              <ul *ngIf="activeSection == 3" class="timeline">
                <li *ngFor="let item of vehicleDetailsReport.alert" class="event" [attr.data-date]="item.eventTime | date:'shortTime'">
                  <!-- <h3>{{item.eventTime | date:'shortTime'}}</h3> -->
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >{{'Type'|translate}} - {{item.type}} </span
                    >
                  </div>
                </li>
                <!-- <li class="event" data-date="2:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="5:00 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li>
                <li class="event" data-date="8:30 AM">
                  <h3>18h 17m</h3>
                  <div
                    class="mat_icon"
                    style="padding-left: 4px; margin-bottom: 3vh"
                  >
                    <img
                      src="/assets/icons/address.png"
                      alt="address"
                      class="address_img"
                    />
                    <span class="address" style="font-size: 1.6vh"
                      >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                      394210 (India)</span
                    >
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <!-- VEHICLE STATUS BOX ( CARD-3 ) -->

        <div class="row timelinerow1" *ngIf="vehicle_status">
          <!-- DESTINATION ADDRESS -->

          <div class="destination">
            <ul class="timeline2" style="padding: 4px 7px">
              <li>
                <span class="dot" style="padding: 0 9px"></span>
                <span class="address2"
                  >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                  394210 (India)
                </span>
              </li>
              <li style="margin-top: 1vh">
                <span class="dot" style="padding: 0 9px"></span>
                <span class="address2"
                  >Mahavir Market, Udhna Main Road, udhna, Surat, Gujarat -
                  394210 (India)
                </span>
              </li>
            </ul>
          </div>

          <!-- TOTAL TIME & DISTANCE -->

          <div class="row m-0 py-3">
            <div class="col-6">
              <div class="mat_icon" style="padding: 0 3vh">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg"
                />
                <span class="flexcolumn" style="padding-left: 1vh">
                  <span class="totaltimedistance">{{'Total Travel Time'|translate}}</span>
                  <span class="timedistancecounts">11h : 35m</span>
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="mat_icon" style="padding: 0 3vh">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg"
                />
                <span class="flexcolumn" style="padding-left: 1vh">
                  <span class="totaltimedistance">{{'Total Distance'|translate}}</span>
                  <span class="timedistancecounts">350.8 {{'km'|translate}} </span>
                </span>
              </div>
            </div>
          </div>

          <!-- VEHICLE STATUS SCROLLER -->

          <div class="vehcleDataRows scroller2">
            <!-- VEHICLE CURRENT STATUS -->

            <div class="row m-0 odometer_row">
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/odometer.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">85,660.18</span>
                <span class="vehicle_info_text">{{'Odometer'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/speedify.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">54 {{'kmph'|translate}}</span>
                <span class="vehicle_info_text">{{'Speed'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Avg.speed.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">35 {{'kmph'|translate}}</span>
                <span class="vehicle_info_text"> {{'Avg. Speed'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Max.speed.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">91 {{'kmph'|translate}}</span>
                <span class="vehicle_info_text">{{'Max. Speed'|translate}}</span>
              </div>
            </div>
            <div class="row m-0 odometer_row">
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Overspeed.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">10 {{'km'|translate}} </span>
                <span class="vehicle_info_text">{{'Over speed'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img
                    src="/assets/icons/thermometer_pink.png"
                    alt="odometer"
                  />
                </span>
                <span class="vehicle_info_data">29°C</span>
                <span class="vehicle_info_text">{{'Temp'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/FuelPump.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">50L</span>
                <span class="vehicle_info_text">{{'Fuel'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/NoOfStops.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">05</span>
                <span class="vehicle_info_text">{{'No of stops'|translate}}</span>
              </div>
            </div>
            <div class="row m-0 odometer_row">
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Mileage.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">10 {{'km'|translate}} </span>
                <span class="vehicle_info_text">Mileage</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/movingtime_pink.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">59m 34s</span>
                <span class="vehicle_info_text">{{'Moving Time'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Parked.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">8hr 24m 22s</span>
                <span class="vehicle_info_text">{{'Parked'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Idling.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">36m 24s</span>
                <span class="vehicle_info_text">36m 24s</span>
              </div>
            </div>
            <div class="row m-0 odometer_row">
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Voltage.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">90%</span>
                <span class="vehicle_info_text">{{'Voltage'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/Battery.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">100%</span>
                <span class="vehicle_info_text">{{'Battery'|translate}}</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/PrimaryEng.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">1hr 24m 22s</span>
                <span class="vehicle_info_text">{{'primary Eng'|translate}}.</span>
              </div>
              <div class="card odometerbox">
                <span>
                  <img src="/assets/icons/sec.eng.png" alt="odometer" />
                </span>
                <span class="vehicle_info_data">0s</span>
                <span class="vehicle_info_text">{{'Sec. Eng'|translate}}.</span>
              </div>
            </div>

            <!-- VEHICLE TIME STATUS -->

            <div class="activity_head">{{'Today Activity'|translate}}</div>
            <div class="mat_icon alignmat">
              <span class="mat_icon">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg1"
                />
                <span class="activity_status">{{'Running'|translate}}</span>
              </span>
              <span class="activity_time">00.13 {{'Hrs'|translate}}</span>
            </div>
            <div class="mat_icon alignmat">
              <span class="mat_icon">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg2"
                />
                <span class="activity_status">{{'Idle'|translate}}</span>
              </span>
              <span class="activity_time">00.40 {{'Hrs'|translate}}</span>
            </div>
            <div class="mat_icon alignmat">
              <span class="mat_icon">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg3"
                />
                <span class="activity_status">{{'Stop'|translate}}</span>
              </span>
              <span class="activity_time">00.00 {{'Hrs'|translate}}</span>
            </div>
            <div class="mat_icon alignmat pb-3">
              <span class="mat_icon">
                <img
                  src="/assets/icons/vector_timedistance.png"
                  alt="timedistance"
                  class="vectorimg4"
                />
                <span class="activity_status">{{'Inactive'|translate}}</span>
              </span>
              <span class="activity_time">17.35 {{'Hrs'|translate}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 col_map"
    id="myMap"
    *ngIf="!devicesList.length">
    <ng-container>
      <agm-map
        [mapTypeId]="mapTypeId"
        class="multiple-vehicle"
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        (mapClick)="mapClicked($event)"
        (mapReady)="onMapReady($event)"
        (zoomChange)="changeMapZoom($event)"
        [zoomControl]="false"
      >
      <ng-container *ngFor="let poi of geofenceList; let i = index">
        <agm-circle
          *ngIf="isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Point'"
          [latitude]="poi.geofence.coordinates[0]"
          [longitude]="poi.geofence.coordinates[1]"
          [radius]="poi.geofence.radius"
          [fillColor]="'red'"
          [circleDraggable]="false"
          [editable]="false"
        >
        </agm-circle>

        <agm-polygon
          *ngIf=" isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Feature'"
          [visible]="true"
          [paths]="poi.geofence.geometry.coordinates"
          [fillColor]="'red'"
          [clickable]="true"
        >
        </agm-polygon>

       
      </ng-container>
      </agm-map>
      <div
        class="btn-group-vertical"
        role="group"
        aria-label="Basic example"
        style="position: absolute; top: 2vh; right: 3vh"
      >
        <button
          *ngIf="back_button"
          matTooltip="Back"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0"
          (click)="closeHistorytrack()"
        >
          <span class="material-icons" style="color: #fff"> arrow_back </span>
        </button>
        <button
        (click)="changeMapTypeId()"
          type="button"
          class="btn mapbtns"
          [class.active]="mapTypeId == 'satellite'"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map1.png" alt="mapicon" />
        </button>
        <button
        (click)="changeSetToBound()"
          [class.active]="isSetFitBound"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map2.png" alt="mapicon" />
        </button>
        <button
        
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map3.png" alt="mapicon" />
        </button>
        <button
        (click)="trafficLayerOnMap()"
        [class.active]="isTrafficEnabled"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map4.png" alt="mapicon" />
        </button>
        <button
          type="button"
          [attr.disabled]="!oneVehicle ? true : null"
          (click)="setMapPath()"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map5.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns openbtn"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <!-- (click)="openNav()" -->
          <img src="/assets/icons/map6.png" alt="mapicon" />
        </button>
        <button
          type="button"
          [class.active]="isGeofenceVisiable"
          [attr.disabled]="oneVehicle ? true : null"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
          (click)="geofenceToggle()"
        >
          <img src="/assets/icons/map7.png" alt="mapicon" />
        </button>
        
        <!-- <button
        *ngIf="userData.administrator || userData.userLimit !== 0"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdropAdd"
        (click)="addDevicepopup()"
          type="button"
          class="btn mapbtns btn-white"
          style="margin-top: 1vh; border-radius: 5px"
        >
        <i title="New Device"  class="fa fa-plus"></i>
        </button> -->
      </div>
    </ng-container>
    </div>
    <div
      class="col-12 col_map"
      id="myMap"
      *ngIf="devicesList.length"
    >
      <!-- GOOGLE MAP -->

      <div class="card main_card p-0" id="mymap-new">
        <ng-container *ngIf="allvehicles">
          
          <agm-map
            [mapTypeId]="mapTypeId"
            
        class="multiple-vehicle"
            [latitude]="lat"
            [longitude]="lng"
            [zoom]="zoom"
            [disableDefaultUI]="false"
            (mapClick)="mapClicked($event)"
            (mapReady)="onMapReady($event)"
            (zoomChange)="changeMapZoom($event)"
            [zoomControl]="false"
          >
          <agm-marker-cluster imagePath="/assets/icons/clusterImg" [minimumClusterSize]="minimumClusterSize" *ngIf="minimumClusterSize == 2">
            <ng-container *ngFor="let device of markersList; let i = index">
              <agm-marker
                *ngIf="device.latitude && device.longitude && !device.isHide"
                (markerClick)="clickedMarker(device.deviceId)"
                [latitude]="device.latitude"
                [longitude]="device.longitude"
                [label]="{ fontSize: '10px',color: '#fbfbfc', text: device.name }"
                [markerDraggable]="device.draggable"
                (dragEnd)="markerDragEnd(device, $event)"
                [iconUrl]="device.deviceDetails.vehicleMapImg"              
              >
              <agm-info-window [isOpen]="previousInfoWindow === device.deviceId" #infowindow>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Object'|translate}} :</div>
                  <div class="col-8 ps-0">{{device.name}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Address'|translate}} :</div>
                  <div class="col-8 ps-0">{{device.address ? device.address : 'NA' }}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Position'|translate}} :</div>
                  <div class="col-8 ps-0">
                    <a href="https://maps.google.com/maps?q={{device?.latitude}},{{device.longitude}}&amp;t=m" 
                    target="_blank">{{device?.latitude}} °, {{device.longitude}} °</a></div>
                </div>
                
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Altitude'|translate}} :</div>
                  <div class="col-8 ps-0">{{ device?.altitude }} m</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Angle'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.course}} °</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Speed'|translate}} :</div>
                  <div class="col-8 ps-0">{{ device?.speed | number : '1.0-3'  }} {{'kmph'|translate}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Time'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.lastUpdate | date:'dd-MM-yyyy hh:mm:ss'}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Odometer'|translate}} :</div>
                  <div class="col-8 ps-4">{{device?.attributes?.totalDistance | meterTokm}} {{'km'|translate}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Engine hours'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.hours}} h</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Voltage'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.attributes?.power| number:'1.0-2'}} v</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Device battery'|translate}} :</div>
                  <div class="col-8 ps-0">{{selectedDevice?.attributes?.batteryLevel > 100 ? 100 : (selectedDevice?.attributes?.batteryLevel??0)}}%</div>
                </div>
                <div class="row w-100">
                  <div class="col-12 mt-2 ms-1 mb-2">
                    <button type="button" class="btn btn-outline-secondary" (click)="addGeofence()">Add Geofence</button>
                    <button type="button" class="btn btn-outline-secondary ms-2" (click)="addLandmark()">Add Landmark</button>
                  </div>
                </div>
                <!-- <div><strong style="padding-bottom: 5px; font-size:medium"> </strong>{{device.name}}</div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;"></strong>  </div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;"></strong> </div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;">{{'Total Distance'|translate}} :</strong> {{device?.attributes?.distanceForday | meterTokm}} {{'km'|translate}}</div> -->
              </agm-info-window>
              </agm-marker>
            </ng-container>
            <ng-container *ngIf="selectedEvent.latitude && selectedEvent.longitude">
              <agm-marker [latitude]="selectedEvent.latitude" [longitude]="selectedEvent.longitude" (markerClick)="eventInfoMarkerClick(infowindow99)">
                <agm-info-window [isOpen]="true" #infowindow99>
                  <div class="row m-0 pb-2 pt-2 d-flex align-items-center justify-content-center car-content">
                    <div class="col-3 d-flex align-items-center font-size-12">
                      {{selectedEvent.deviceName}}
                    </div>
                    <div class="col-5 d-flex align-items-center font-size-12">
                      {{selectedEvent.eventTime| dateFormat}}
                    </div>                
                    <div class="col-4 d-flex align-items-center justify-content-between font-size-12">
                      <div> {{selectedEvent.type}} <span *ngIf="selectedEvent.attributes?.alarm" class="ms-1">({{selectedEvent.attributes?.alarm}})</span></div>
                    </div>
                  </div>   
                </agm-info-window>
              </agm-marker>
            </ng-container>
           </agm-marker-cluster>
           <agm-marker-cluster imagePath="/assets/icons/clusterImg" [minimumClusterSize]="minimumClusterSize" *ngIf="minimumClusterSize !== 2">
            <ng-container *ngFor="let device of markersList; let i = index">
              <agm-marker
                *ngIf="device.latitude && device.longitude && !device.isHide"
                (markerClick)="clickedMarker(device.deviceId)"
                [latitude]="device.latitude"
                [longitude]="device.longitude"
                [label]="{ fontSize: '10px',color: '#fbfbfc', text: device.name }"
                [markerDraggable]="device.draggable"
                (dragEnd)="markerDragEnd(device, $event)"
                [iconUrl]="device.deviceDetails.vehicleMapImg"              
              >
              <agm-info-window [isOpen]="previousInfoWindow === device.deviceId" #infowindow>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Object'|translate}} :</div>
                  <div class="col-8 ps-0">{{device.name}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Address'|translate}} :</div>
                  <div class="col-8 ps-0">{{device.address ? device.address : 'NA' }}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Position'|translate}} :</div>
                  <div class="col-8 ps-0">
                    <a href="https://maps.google.com/maps?q={{device?.latitude}},{{device.longitude}}&amp;t=m" 
                    target="_blank">{{device?.latitude}} °, {{device.longitude}} °</a></div>
                </div>
                
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Altitude'|translate}} :</div>
                  <div class="col-8 ps-0">{{ device?.altitude }} m</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Angle'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.course}} °</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Speed'|translate}} :</div>
                  <div class="col-8 ps-0">{{ device?.speed | number : '1.0-3'  }} {{'kmph'|translate}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Time'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.lastUpdate | date:'yy-mm-dd h:mm:ss'}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Odometer'|translate}} :</div>
                  <div class="col-8 ps-4">{{device?.attributes?.totalDistance | meterTokm}} {{'km'|translate}}</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Engine hours'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.hours}} h</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Voltage'|translate}} :</div>
                  <div class="col-8 ps-0">{{device?.attributes?.power| number:'1.0-2'}} v</div>
                </div>
                <div class="row w-100">
                  <div class="col-4 fw-bold">{{'Device battery'|translate}} :</div>
                  <div class="col-8 ps-0">{{selectedDevice?.attributes?.batteryLevel > 100 ? 100 : (selectedDevice?.attributes?.batteryLevel??0)}}%</div>
                </div>
                <div class="row w-100">
                  <div class="col-12 mt-2 ms-1 mb-2">
                    <button type="button" class="btn btn-outline-secondary" (click)="addGeofence()">Add Geofence</button>
                  </div>
                </div>
                <!-- <div><strong style="padding-bottom: 5px; font-size:medium"> </strong>{{device.name}}</div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;"></strong>  </div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;"></strong> </div> -->
                <!-- <div><strong style="min-width: 90px;display: inline-flex;">{{'Total Distance'|translate}} :</strong> {{device?.attributes?.distanceForday | meterTokm}} {{'km'|translate}}</div> -->
              </agm-info-window>
              </agm-marker>
            </ng-container>
            <ng-container *ngIf="selectedEvent.latitude && selectedEvent.longitude">
              <agm-marker [latitude]="selectedEvent.latitude" [longitude]="selectedEvent.longitude" (markerClick)="eventInfoMarkerClick(infowindow98)">
                <agm-info-window [isOpen]="true" #infowindow98>
                  <div class="row m-0 pb-2 pt-2 d-flex align-items-center justify-content-center car-content">
                    <div class="col-3 d-flex align-items-center font-size-12">
                      {{selectedEvent.deviceName}}
                    </div>
                    <div class="col-5 d-flex align-items-center font-size-12">
                      {{selectedEvent.eventTime| dateFormat}}
                    </div>                
                    <div class="col-4 d-flex align-items-center justify-content-between font-size-12">
                      <div> {{selectedEvent.type}} <span *ngIf="selectedEvent.attributes?.alarm" class="ms-1">({{selectedEvent.attributes?.alarm}})</span></div>
                    </div>
                  </div>   
                </agm-info-window>
              </agm-marker>
            </ng-container>
           </agm-marker-cluster>
           <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
            <agm-circle
              *ngIf="circularGeofence && poi.geofence?.type && !poi.attributes?.type && poi.geofence.type === 'Point'"
              [latitude]="poi.geofence.coordinates[0]"
              [longitude]="poi.geofence.coordinates[1]"
              [radius]="poi.geofence.radius"
              [fillColor]="'red'"
              [circleDraggable]="false"
              [editable]="false" 
              (mouseOver)="infoWindowCi.open();"
              (mouseOut)="infoWindowCi.close();"             
            >
            <agm-info-window
                [latitude]="poi.geofence.coordinates[0]"
                [longitude]="poi.geofence.coordinates[1]"
                [disableAutoPan]="false" #infoWindowCi>

                <div>
                    <span>
                      <b>{{poi.name}} </b>
                    </span>
                </div>

              </agm-info-window>
            </agm-circle>
            <!-- for landmark -->
            <agm-circle
            *ngIf="circularLandmarkGeofence && poi.geofence?.type && poi.attributes?.type && poi.geofence.type === 'Point'"
            [latitude]="poi.geofence.coordinates[0]"
            [longitude]="poi.geofence.coordinates[1]"
            [radius]="poi.geofence.radius"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false" 
            (mouseOver)="infoWindowCi.open();"
            (mouseOut)="infoWindowCi.close();"             
          >
            <agm-marker [latitude]="poi.geofence.coordinates[0]" [longitude]="poi.geofence.coordinates[1]" [iconUrl]="whiteFlagIcon">
              <agm-info-window
                [latitude]="poi.geofence.coordinates[0]"
                [longitude]="poi.geofence.coordinates[1]"
                [disableAutoPan]="false" #infoWindowCi>

                <div>
                    <span>
                      <b>{{poi.name}} </b>
                    </span>
                </div>

              </agm-info-window>
            </agm-marker>
          </agm-circle>
            <agm-polygon
              *ngIf="polygonGeofence && poi.geofence?.type && poi.geofence.type === 'Feature'"
              [visible]="true"
              [paths]="poi.geofence.geometry.coordinates"
              [fillColor]="'red'"
              [clickable]="true"
              (polyClick)="onPolyClick($event, poi, infoWindowPi)"
            >          
            <agm-info-window
                [latitude]="poi.geofence.geometry.coordinates[0].lat"
                [longitude]="poi.geofence.geometry.coordinates[0].lng"
                [disableAutoPan]="false" #infoWindowPi>

                <div>
                    <span>
                      <b>test</b>
                    </span>
                </div>

              </agm-info-window>    
            </agm-polygon>

           
          </ng-container>
           <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
            <agm-circle
              *ngIf="isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Point'"
              [latitude]="poi.geofence.coordinates[0]"
              [longitude]="poi.geofence.coordinates[1]"
              [radius]="poi.geofence.radius"
              [fillColor]="'red'"
              [circleDraggable]="false"
              [editable]="false"
            >
            </agm-circle>

            <agm-polygon
              *ngIf=" isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Feature'"
              [visible]="true"
              [paths]="poi.geofence.geometry.coordinates"
              [fillColor]="'red'"
              [clickable]="true"
            >
            </agm-polygon>

           
          </ng-container>
          </agm-map>
        </ng-container>
        <ng-container *ngIf="!allvehicles && selectedDevice?.latitude">         
          <agm-map
          class="single-vehicle"
          [mapTypeId]="mapTypeId"
            [latitude]="allLocationTrackData.length && values && allLocationTrackData[values] &&  allLocationTrackData[values].latitude ? allLocationTrackData[values].latitude : selectedDevice.latitude"
            [longitude]="allLocationTrackData.length && values && allLocationTrackData[values] &&  allLocationTrackData[values].longitude ? allLocationTrackData[values].longitude : selectedDevice.longitude"
            [zoom]="zoomSingle"
            (mapReady)="onMapReady($event)"
            [zoomControl]="false"
            (zoomChange)="changeMapZoomForHistory($event)"
            [fitBounds]="true"
          >
            <ng-container *ngFor="let device of stoppagesReports; let i = index">
              <agm-marker
                *ngIf="device.latitude && device.longitude"
                (markerClick)="openWindow(device.positionId)" 
                [latitude]="device.latitude"
                [longitude]="device.longitude"
                label={{i+1}}    
                [agmFitBounds]="false"            
              >
              <agm-info-window 
              [isOpen]="isInfoWindowOpen(device.positionId)"
              [latitude]="device.latitude" 
              [longitude]="device.longitude">                
                <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Start Time'|translate}} :</strong>  {{device.startTime | date: 'dd/MM/yyyy hh:mm a'}} </span>
                <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'End Time'|translate}} :</strong> {{device.endTime | date: 'dd/MM/yyyy hh:mm a'}}</span>
                <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Duration'|translate}} :</strong> {{device.duration |convert:'hh:mm'}}</span>
                <span style="width: 280px;color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Address'|translate}} :</strong> {{device.address ? device.address : 'NA' }}</span>
                
            
            </agm-info-window>
              </agm-marker>
            </ng-container>
            <ng-container *ngIf="oneVehicle && isDataPointsVisiable">

            
              <ng-container  *ngFor="let device of allLocationTrackData; let i = index">
                <agm-marker
                  *ngIf=" !(i%2) &&  device.latitude && device.longitude"
                  (markerClick)="openWindowDots(device.latitude +'_'+ device.longitude)" 
                  [latitude]="device.latitude"
                  [longitude]="device.longitude"
                  [iconUrl]="blueDotIcon"
                  [agmFitBounds]="true"
                >
                <agm-info-window 
                [isOpen]="isInfoWindowOpenDots(device.latitude +'_'+ device.longitude)"
                [latitude]="device.latitude" 
                [longitude]="device.longitude">
              
                  <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Time'|translate}} :</strong>  {{device.serverTime |dateFormat}} </span>
                  <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'speed'|translate}} :</strong> {{device.speed | number : '1.0-2'}} {{'kmph'|translate}}</span>
                  <!-- <span style="color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Duration'|translate}} :</strong> {{device.duration |convert:'hh:mm'}}</span> -->
                  <span style="width: 280px;color:black;display: block;"><strong style="min-width: 90px;display: inline-flex;">{{'Address'|translate}} :</strong> <a *ngIf="!device.address" (click)="getAddress(device)"> Get Address</a>{{device.address}}</span>
                  
              
              </agm-info-window>
                </agm-marker>
              </ng-container>
            </ng-container>
          </agm-map>
        </ng-container>
        <ng-container *ngIf="drawPolygonGeofence">
          <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          (mapReady)="polygonDrawingManager($event)"
          (zoomChange)="changeMapZoom($event)"
        >
        <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
          <agm-circle
            *ngIf="circularGeofence && poi.geofence?.type && poi.geofence.type === 'Point'"
            [latitude]="poi.geofence.coordinates[0]"
            [longitude]="poi.geofence.coordinates[1]"
            [radius]="poi.geofence.radius"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false"
          >
          </agm-circle>

          <agm-polygon
            *ngIf="polygonGeofence && poi.geofence?.type && poi.geofence.type === 'Feature'"
            [visible]="true"
            [paths]="poi.geofence.geometry.coordinates"
            [fillColor]="'red'"
            [clickable]="true"
          >
          </agm-polygon>

         
        </ng-container>
         <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
          <agm-circle
            *ngIf="isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Point'"
            [latitude]="poi.geofence.coordinates[0]"
            [longitude]="poi.geofence.coordinates[1]"
            [radius]="poi.geofence.radius"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false"
          >
          </agm-circle>

          <agm-polygon
            *ngIf=" isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Feature'"
            [visible]="true"
            [paths]="poi.geofence.geometry.coordinates"
            [fillColor]="'red'"
            [clickable]="true"
          >
          </agm-polygon>

         
        </ng-container>
        </agm-map>
        </ng-container>
        <ng-container *ngIf="drawCircularGeofence">
          <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          (mapReady)="circularDrawingManager($event)"
          (zoomChange)="changeMapZoom($event)"
        >
        <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
          <agm-circle
            *ngIf="circularGeofence && poi.geofence?.type && poi.geofence.type === 'Point'"
            [latitude]="poi.geofence.coordinates[0]"
            [longitude]="poi.geofence.coordinates[1]"
            [radius]="poi.geofence.radius"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false"
          >
          </agm-circle>

          <agm-polygon
            *ngIf="polygonGeofence && poi.geofence?.type && poi.geofence.type === 'Feature'"
            [visible]="true"
            [paths]="poi.geofence.geometry.coordinates"
            [fillColor]="'red'"
            [clickable]="true"
          >
          </agm-polygon>

         
        </ng-container>
         <ng-container *ngFor="let poi of markerGeofenceList; let i = index">
          <agm-circle
            *ngIf="isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Point'"
            [latitude]="poi.geofence.coordinates[0]"
            [longitude]="poi.geofence.coordinates[1]"
            [radius]="poi.geofence.radius"
            [fillColor]="'red'"
            [circleDraggable]="false"
            [editable]="false"
          >
          </agm-circle>

          <agm-polygon
            *ngIf=" isGeofenceVisiable && poi.geofence?.type && poi.geofence.type === 'Feature'"
            [visible]="true"
            [paths]="poi.geofence.geometry.coordinates"
            [fillColor]="'red'"
            [clickable]="true"
          >
          </agm-polygon>

         
        </ng-container>
        </agm-map>
        </ng-container>
      </div>
      <!-- TOP PLAYBACK BUTTTON -->

      <!-- <div class="dropdown playdrop" *ngIf="playback">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="/assets/icons/play.png" alt="play" /> {{'Playback'|translate}}
        </button>
        <ul
          class="dropdown-menu playmenu"
          aria-labelledby="dropdownMenuButton1"
        >
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(0)"
              >{{'Today'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(1)"
              >{{'Last 24 Hour'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(2)"
              >{{'Yesterday'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(3)"
              >{{'This Week'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(4)"
              >{{'Last Week'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(5)"
              >{{'This Month'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(6)"
              >{{'Last Month'|translate}}</a
            >
          </li>
          <li>
            <a class="dropdown-item playitem" (click)="onTrackVehicleHistory(7)"
              >{{'Custom'|translate}}</a
            >
          </li>
        </ul>
      </div> -->

      <!-- BOTTOM RIGHT ICONS -->
      
      <div
        class="btn-group-vertical btn-vertical-bar-map"
        [class.right-3-vh]="!rightPanelExpand"
        [class.right-29-vh]="rightPanelExpand"
        role="group"
        aria-label="Basic example"
      >
        <button
          matTooltip="FullScreen Map"
          type="button"
          class="btn mapbtns"
          style="border-radius: 5px;"
          (click)="fullScreenMap()"
          *ngIf="!commonService.isFullscreenMap"
        > 
        <i class="bi bi-arrows-fullscreen text-white"></i>
        </button>
        <button
          matTooltip="Normal Screen Map"
          type="button"
          class="btn mapbtns"
          style="border-radius: 5px;"
          (click)="returnToNormalScreenMap()"
          *ngIf="commonService.isFullscreenMap"
        > 
        <i class="bi bi-fullscreen-exit text-white"></i>
        </button>
        <button
          matTooltip="Cluster {{minimumClusterSize == 2?'on':'off'}}"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px;"
          [class.active]="minimumClusterSize == 2"
          (click)="handleCluster()"
          
        > 
          <img src="assets/images/cluster.png" alt="" style="height: 25px;">
        </button>
        <button
          *ngIf="back_button && !commonService.isFullscreenMap"
          matTooltip="Back"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0"
          (click)="closeHistorytrack()"
        > 
          <span class="material-icons" style="color: #fff"> arrow_back </span>
        </button>
        
        <button
          *ngIf="rightPanelExpand && selectedDevice?.deviceId && !commonService.isFullscreenMap"
          matTooltip="Collapse"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0"
          (click)="collapseRightPanel()"
        > 
        <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1" style="color: #fff"> >> </span>
      </button>
      <button
          *ngIf="!rightPanelExpand && selectedDevice?.deviceId && !commonService.isFullscreenMap"
          matTooltip="Expand"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px; padding-bottom: 0"
          (click)="expandRightPanel()"
        > 
        <span class="d-flex align-items-center justify-content-center fw-bold font-size-18 mb-1" style="color: #fff"> << </span>
      </button>
        <button
        matTooltip="Satelite View"
        *ngIf="!commonService.isFullscreenMap"
        (click)="changeMapTypeId()"
          type="button"
          class="btn mapbtns"
          [class.active]="mapTypeId == 'satellite'"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map1.png" alt="mapicon" />
        </button>
        <button
        matTooltip="Show Places"
        *ngIf="!commonService.isFullscreenMap"
        (click)="changeSetToBound()"
          [class.active]="isSetFitBound"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map2.png" alt="mapicon" />
        </button>
        <button
          *ngIf="!commonService.isFullscreenMap && false"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map3.png" alt="mapicon" />
        </button>
        <button
        matTooltip="Traffic"
        *ngIf="!commonService.isFullscreenMap"
        (click)="trafficLayerOnMap()"
        [class.active]="isTrafficEnabled"
          type="button"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map4.png" alt="mapicon" />
        </button>
        <button
          matTooltip="Navigation"
          *ngIf="!commonService.isFullscreenMap"
          type="button"
          [attr.disabled]="!oneVehicle ? true : null"
          (click)="setMapPath()"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <img src="/assets/icons/map5.png" alt="mapicon" />
        </button>
        <button *ngIf="!isHistoryTab"
        matTooltip="Zoom In"
        (click)="changeMapZoom('p')"
          type="button"
          class="btn mapbtns"
          [style.pointer-events]="zoom == 22?'none':'auto'"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <i class="bi bi-plus-lg text-white"></i>
        </button>
        <button *ngIf="!isHistoryTab"
        matTooltip="Zoom Out"
        (click)="changeMapZoom('m')"
          type="button"
          class="btn mapbtns"
          [style.pointer-events]="zoom == 0?'none':'auto'"
          style="margin-top: 1vh; border-radius: 5px"
        >
        <i class="bi bi-dash text-white"></i>
        </button>
        <button *ngIf="isHistoryTab"
        (click)="changeMapZoomForHistory('p')"
          type="button"
          class="btn mapbtns"
          [style.pointer-events]="zoomSingle == 22?'none':'auto'"
          style="margin-top: 1vh; border-radius: 5px"
        >
          <i class="bi bi-plus-lg text-white"></i>
        </button>
        <button *ngIf="isHistoryTab"
        (click)="changeMapZoomForHistory('m')"
          type="button"
          class="btn mapbtns"
          [style.pointer-events]="zoomSingle == 0?'none':'auto'"
          style="margin-top: 1vh; border-radius: 5px"
        >
        <i class="bi bi-dash text-white"></i>
        </button>
        <!-- <button
          type="button"
          class="btn mapbtns openbtn"
          style="margin-top: 1vh; border-radius: 5px"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop1"
        > -->
          <!-- (click)="openNav()" -->
          <!-- <img src="/assets/icons/map6.png" alt="mapicon" />
        </button> -->
        <!-- <button *ngIf="false"
          type="button"
          [class.active]="isGeofenceVisiable"
          [attr.disabled]="oneVehicle ? true : null"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
          (click)="geofenceToggle()"
        >
          <img src="/assets/icons/map7.png" alt="mapicon" />
        </button> -->
        <button
        *ngIf="oneVehicle && !commonService.isFullscreenMap"
          type="button"
          [class.active]="isDataPointsVisiable"
          title="Data Points"
          class="btn mapbtns"
          style="margin-top: 1vh; border-radius: 5px"
          (click)="dataPointsToggle()"
        >
          <img src="/assets/icons/tracking.png" alt="mapicon" />
        </button>
        <!-- <button

        data-bs-toggle="modal"
        data-bs-target="#staticBackdropAdd"
        (click)="addDevicepopup()"
          type="button"
          class="btn mapbtns btn-white"
          style="margin-top: 1vh; border-radius: 5px"
        >
        <i title="New Device"  class="fa fa-plus"></i>
        </button> -->
      </div>
      <!-- Right vertical bar panel -->
      <div class="btn-vertical-bar-right-panel scroller scroller-right-panel" 
      (scroll)="onRigthSidePanelScroll($event)"
      [class.d-none]="!rightPanelExpand"
      *ngIf="!commonService.isFullscreenMap"
      >
        <div class="card shadow-set">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              {{selectedDevice?.name}}
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <img src="/assets/images/{{getDeviceImage(selectedDevice?.category)}}" style="height: 100px;" alt="">
            </div>            
            <div class="bg-light d-flex align-items-center justify-content-between fw-bold font-size-10 p-1">
              <span class="p-1 text-white" 
              [class.running-bg-color]="selectedDevice?.status == 'RUNNING'"
              [class.stopped-bg-color]="selectedDevice?.status == 'STOPPED'"
              [class.idle-bg-color]="selectedDevice?.status == 'IDLE'"
              [class.not-reporting-bg-color]="selectedDevice?.status == 'NOT_REPORTING' || selectedDevice?.status == 'EXPIRED' || selectedDevice?.status == 'NO_DATA' || selectedDevice?.status == 'TOWWED'"
              >{{selectedDevice?.status == 'NOT_REPORTING'?'Not Reporting':(selectedDevice?.status == 'NO_DATA'?'No Data':selectedDevice?.status)|titlecase}}</span>
              <span>{{selectedDevice.statusLastUpdated|convert:'hh:mm:ss'}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-center p-2">              
              <span class="bg-light p-1 border-end font-size-12" *ngFor="let o of selectedDevice?.odometerArray">{{o}}</span>
              <!-- <span class="bg-light p-1 border-end font-size-12">1</span>
              <span class="bg-light p-1 border-end font-size-12">4</span>
              <span class="bg-light p-1 border-end font-size-12">8</span>
              <span class="bg-light p-1 border-end font-size-12">5</span>
              <span class="bg-light p-1 font-size-12">2</span> -->
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="font-size-12">Driver</span>
              <span class="font-size-12" *ngIf="driverDetails.length > 0 && driverDetails[0].name">{{driverDetails[0].name}}</span>
              <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="font-size-12">Mobile</span>
              <span class="font-size-12" *ngIf="driverDetails.length > 0 && driverDetails[0].attributes?.phone">{{driverDetails[0].attributes?.phone}}</span>
              <span class="font-size-12" *ngIf="driverDetails.length == 0">NA</span>
            </div>
            <div class="d-flex align-items-center justify-content-around mt-2 bg-light">
                <span><i class="bi bi-snow" 
                  [class.text-secondary]="selectedDevice?.attributes?.ac == undefined"
                  [class.text-danger]="selectedDevice?.attributes?.ac == false"
                  [class.text-success]="selectedDevice?.attributes?.ac == true"></i>
                </span>
                <span><i class="bi bi-key-fill"
                  [class.text-secondary]="selectedDevice?.attributes?.ignition == undefined"
                  [class.text-danger]="selectedDevice?.attributes?.ignition == false"
                  [class.text-success]="selectedDevice?.attributes?.ignition == true"></i></span>
                <span><i class="bi"
                  [class.bi-battery]="selectedDevice?.attributes?.batteryLevel == undefined || selectedDevice?.attributes?.batteryLevel < 30"
                  [class.text-danger]="selectedDevice?.attributes?.batteryLevel == undefined || selectedDevice?.attributes?.batteryLevel < 30"
                  [class.bi-battery-half]="selectedDevice?.attributes?.batteryLevel && selectedDevice?.attributes?.batteryLevel > 30 && selectedDevice?.attributes?.batteryLevel < 80"
                  [class.text-danger]="selectedDevice?.attributes?.batteryLevel && selectedDevice?.attributes?.batteryLevel > 30 && selectedDevice?.attributes?.batteryLevel < 80"
                  [class.bi-battery-full]="selectedDevice?.attributes?.batteryLevel && selectedDevice?.attributes?.batteryLevel > 80"
                  [class.text-success]="selectedDevice?.attributes?.batteryLevel && selectedDevice?.attributes?.batteryLevel > 80" title="{{selectedDevice?.attributes?.batteryLevel > 100 ? 100 : (selectedDevice?.attributes?.batteryLevel??0)}}%"></i></span>
                <span><i class="bi bi-power"
                  [class.text-secondary]="selectedDevice?.attributes?.charge == undefined"
                  [class.text-danger]="selectedDevice?.attributes?.charge == false"
                  [class.text-success]="selectedDevice?.attributes?.charge == true"></i></span>
                <span><i class="bi bi-lock-fill" style="color: gray"
                  [class.text-secondary]="selectedDevice?.attributes?.blocked == undefined"
                  [class.text-danger]="selectedDevice?.attributes?.blocked == false"
                  [class.text-success]="selectedDevice?.attributes?.blocked == true"></i></span>
            </div>
          </div>
        </div>
        <div class="card shadow-set mt-3">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              Location
            </div>
            <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
              {{selectedDevice?.address}}
            </div>
            <div class="d-flex align-items-center font-size-10 p-1">
              <a href="https://maps.google.com/maps?q={{selectedDevice?.latitude}},{{selectedDevice.longitude}}&amp;t=m" 
                    target="_blank">{{selectedDevice?.latitude|number : '1.0-6'}} °, {{selectedDevice.longitude|number : '1.0-6'}} °</a>
            </div>           
          </div>
        </div>
        <div class="card shadow-set mt-3">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              Today Activity
            </div>
            <div class="d-flex align-items-center font-size-12 mt-2 mb-2">
              <div class="d-flex align-items-center justify-content-around w-100">
                <span><img src="../../../assets/icons/trip_start_flag.svg" alt=""></span>
                <span class="text-center" style="border-bottom: 2px dashed black; width: 100%;">{{selectedDevice?.attributes?.distanceForday | meterTokm}} {{'km'|translate}}</span>
                <span><img src="../../../assets/icons/trip_end_flag.svg" alt=""></span>
              </div>
              
            </div>
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Running</span>
              <span class="running-text-color">{{selectedDevice?.attributes?.hoursForday |convert:'hh:mm'}} hrs</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Idle</span>
              <span class="idle-text-color">{{selectedDevice?.attributes?.idlehoursForday |convert:'hh:mm'}} hrs</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Stop</span>
              <span class="stopped-text-color">{{selectedDevice?.attributes?.stophoursForday |convert:'hh:mm'}} hrs</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Inactive</span>
              <span class="not-reporting-text-color">00:00 hrs</span>
            </div>          
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-3">
          <div class="avg-speed d-flex align-items-center justify-content-center">
            <div class="font-size-12 text-center">{{"Avg Speed"|translate}}</div>
            <div class="font-size-12 text-center">{{onSpeedgenerateBasedOnuserPreference(vehicleDetailsReport?.summary?.averageSpeed)|number:'1.0-2'}} {{userData.attributes.speedUnit|translate}}</div>
          </div>
          <div class="max-speed d-flex align-items-center justify-content-center ms-1">
            <div class="font-size-12 text-center">{{"Max Speed"|translate}}</div>
            <div class="font-size-12 text-center">
              {{onSpeedgenerateBasedOnuserPreference(vehicleDetailsReport?.summary?.maxSpeed)|number:'1.0-2'}} {{userData.attributes.speedUnit|translate}}
            </div>
          </div>
        </div>
        
        <div class="card shadow-set mt-3" *ngIf="selectedDevice?.attributes?.fuel">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              Fuel
            </div>   
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <div class="gauge">
                <div class="gauge__body">
                  <img src="../../../assets/images/fuel-meter1-removebg.png" alt="" style="height: 80px;width: 170px;margin-left: -14px;">
                  <div class="gaugeg__fill" [ngStyle]="getFuelFill(selectedDevice?.attributes?.fuel?selectedDevice?.attributes?.fuel:0)">
                    
                    <!-- <img src="../../../assets/images/fuel-meter-arrow-removebg.png" alt="" style="height: 78px;
                    position: absolute;
                    top: 4px;
                    /* width: 50px; */
                    transform: rotate(155deg) translateX(-13px);"> -->
                  </div>
                  <div class="gauge__cover"> {{selectedDevice?.attributes?.fuel}} </div>
                </div>
              </div>
              <!-- <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.vh]="selectedDevice?.attributes?.fuel?selectedDevice?.attributes?.fuel:0" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div> -->
            </div> 
            <div class="d-flex align-items-center justify-content-around font-size-12 mt-1">
              <span class="stopped-text-color">Empty</span>
              <span>{{selectedDevice?.attributes?.fuel}} ltr</span>
              <span class="running-text-color">Full</span>
            </div>         
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Refill</span>
              <span class="running-text-color">0 ltr</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Drain</span>
              <span class="idle-text-color">0 ltr</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Consumption</span>
              <span>0 ltr</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Waste</span>
              <span class="stopped-text-color">0 ltr</span>
            </div> 
            <div class="d-flex align-items-center justify-content-end font-size-12 mt-1">
              <span>Due to 0 hrs idling</span>              
            </div>  
          </div>
        </div>
        <div class="card shadow-set mt-3" *ngIf="false">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              Driving Behaviour
            </div>            
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Harsh Braking</span>
              <span>No</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Sharp Cornering</span>
              <span>No</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Harsh Acceleration</span>
              <span>No</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Over Speeding</span>
              <span>No</span>
            </div>   
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>Seat Belt</span>
              <span>NA</span>
            </div>          
          </div>
        </div>
        <div class="card shadow-set mt-3">
          <div class="card-body p-1 w-170">
            <div class="font-size-12 fw-bold">
              {{"Network Parameter"|translate}}
            </div>            
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>{{"Radio Type"|translate}}</span>
              <span *ngIf="selectedDeviceNetworkData">{{selectedDeviceNetworkData?.radioType}}</span>              
              <span *ngIf="!selectedDeviceNetworkData">NA</span>
            </div>  
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>{{"Cell Id"|translate}}</span>
              <span *ngIf="selectedDeviceNetworkData">{{selectedDeviceNetworkData?.cellTowers[0]?.cellId}}</span>
              <span *ngIf="!selectedDeviceNetworkData">NA</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>{{"Area Code"|translate}}</span>
              <span *ngIf="selectedDeviceNetworkData">{{selectedDeviceNetworkData?.cellTowers[0]?.locationAreaCode}}</span>
              <span *ngIf="!selectedDeviceNetworkData">NA</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>{{"Country Code"|translate}}</span>
              <span *ngIf="selectedDeviceNetworkData">{{selectedDeviceNetworkData?.cellTowers[0]?.mobileCountryCode}}</span>
              <span *ngIf="!selectedDeviceNetworkData">NA</span>
            </div> 
            <div class="d-flex align-items-center justify-content-between font-size-12 mt-1">
              <span>{{"Network Code"|translate}}</span>
              <span *ngIf="selectedDeviceNetworkData">{{selectedDeviceNetworkData?.cellTowers[0]?.mobileNetworkCode}}</span>
              <span *ngIf="!selectedDeviceNetworkData">NA</span>
            </div>  
          </div>
        </div>
        <div class="card shadow-set mt-3">
          <div class="card-body p-1 w-170">
            <!-- <iframe src="https://www.google.com/maps?cbll=26.5562333,80.5288433&layer=c"  width="600"
            frameborder="0" style="border:0"
            allowfullscreen>
              </iframe> -->
            <agm-map style="height: 20vh;" [latitude]="selectedDevice?.latitude" [longitude]="selectedDevice.longitude" [zoom]="10.5" [mapTypeId]="'hybrid'"></agm-map>
          </div>
        </div>
      </div>
      <!-- BOTTOM ICONS -->

      <div
        *ngIf="!commonService.isFullscreenMap && false"
        class="btn-group"
        role="group"
        aria-label="Basic example"
        style="position: absolute; bottom: 7vh; left: 4vh"
        [style.bottom.vh]="isHistoryTab?7:3">
        <button
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
        >
          <img src="/assets/icons/map8.png" alt="mapicon" />
        </button>
        <button
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
        >
          <img src="/assets/icons/map9.png" alt="mapicon" />
        </button>
        <button
          *ngIf="fullwidth"
          type="button"
          class="btn mapbtns"
          style="margin-left: 2vh; border-radius: 5px; padding: 0.5vh 0.8vh"
          (click)="getFullWidth()"
        >
          <img src="/assets/icons/map10.png" alt="mapicon" />
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content"
        style="background-color: #324151; border-radius: 9px"
      >
        <div class="modal-header" style="border: none !important">
          <h5 class="modal-title" id="exampleModalLabel">{{'Custom Tracking'|translate}}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group pb-2">
              <label for="device_id" class="addlabels pb-2"
                >{{'Vehicle Name'|translate}} :</label
              >
              <input
                type="text"
                [(ngModel)]="device_id"
                name="device_id"
                class="form-control inputfields"
                id="device_id"
                aria-describedby="device_id"
                disabled
              />
            </div>

            <div class="d-flex flex-row align-items-center py-3">
              <div class="col-6">
                <div class="form-group">
                  <label for="location" class="addlabels pb-2">{{'From'|translate}} :</label>
                  <input
                  
                    type="datetime-local"
                    [(ngModel)]="fromdate"
                    name="fromdate"
                    id="meeting"
                   
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="location" class="addlabels pb-2">{{'To'|translate}} :</label>
                  <input
                    type="datetime-local"
                    [(ngModel)]="todate"
                    name="todate"
                    id="meeting"
                   
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="showTrack"
              data-bs-dismiss="modal"
              aria-label="Close"
              (click)="onCustomSubmit()"
            >
              {{'Show Track'|translate}} 
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BOTTOM NAVIGATION MENU -->

<div class="container-fluid" *ngIf="bottomNav && !commonService.isFullscreenMap">
  <div class="row" style="margin-right: 0">
    <div class="navbar px-4" id="myNavbar">
      <a class="bottom_navs" [class.history-tab-selected]="fuelsummary" (click)="onClickFuel()">{{'Summary'|translate}}</a>
      <a class="bottom_navs" [class.history-tab-selected]="history" (click)="onClickHistory()">{{'Logs'|translate}} </a>      
      <a><img src="/assets/icons/doubledown.png" alt="doubledown" /></a>
      <a class="playbox">
        <div class="card playcard pt-2" style="width: 71vh">
          <div class="row m-0">
            <div class="col-2">
              <img
                src="{{playpause ?'/assets/icons/pause.png' : '/assets/icons/play.png'}}"
                alt="play"
                class="iconPlay"
                (click)="onPlayPause()"
                style="cursor: pointer"
              />
            </div>
            <div class="col-5 d-flex align-items-center">
              <mat-slider
                [min]="0"
                [max]="this.arrlength"
                [value]="values"
                (change)="onSlideEvent($event)"
              ></mat-slider>
            </div>
            <div
              class="col-5"
              style="justify-content: space-between; display: flex"
            >
              <span
                class="playIcons"
                style="padding: 0.8vh 1.5vh"
                (click)="on1xSpeed()"
                >{{speedCount}}x</span
              >
              <img
                src="/assets/icons/play_rewind.png"
                alt="1x"
                class="playIcons"
                id="speed"
                (click)="onReset()"
              />
              <img
                src="/assets/icons/distance_play.png"
                alt="1x"
                class="playIcons"
                style="padding: 0.9vh 1.1vh"
              />
              <img
                src="/assets/icons/folder_play.png"
                alt="1x"
                class="playIcons"
                style="padding: 0.9vh 1.1vh"
              />
              <img
                src="/assets/icons/out_play.png"
                alt="1x"
                class="playIcons"
                style="padding: 0.9vh 1.1vh"
              />
            </div>
          </div>

          <div class="row m-0 py-3 playaddrow">
            <div class="col-8">
              <span class="mat_icon">
                <img
                  src="/assets/icons/address.png"
                  alt="adressicon"
                  style="height: 2.5vh"
                />
                <span class="playaddress"
                  >{{historyPath && historyPath.displayAddress ? historyPath.displayAddress : 'NA'}}</span
                >
              </span>
            </div>
            <div class="col-4">
              <span class="mat_icon">
                <img src="/assets/icons/watch_play.png" alt="watch" />
                <span class="playaddress"> {{ allLocationTrackData[values] && allLocationTrackData[values].deviceTime ? (allLocationTrackData[values].deviceTime |dateFormat ): ''}}</span>
              </span>
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-between text-center ps-2 pe-2 m-0">
            <div class="colplay">
              <span class="iconstext">
                <img src="/assets/icons/battery_play.png" alt="battery" />
                0v
              </span>
            </div>
            <div class="colplay">
              <span style="min-width: 100px;" class="iconstext">
                <img src="/assets/icons/speedometer.png" alt="battery" />
                {{allLocationTrackData[values] && allLocationTrackData[values].speed ? (onSpeedgenerateBasedOnuserPreference(allLocationTrackData[values].speed) | number : '1.0-2'):'0'}} {{userData.attributes.speedUnit|translate}}
              </span>
            </div>
            <div class="colplay">
              <span style="min-width: 100px;" class="iconstext">
                <img src="/assets/icons/watch_play.png" alt="battery" />
                {{vehicleDetailsReport.summary.engineHours |convert:'hh:mm'}}
              </span>
            </div>
            <div class="colplay">
              <span class="iconstext">
                <img src="/assets/icons/distance_meter.png" alt="battery" />
                {{onSpeedgenerateBasedOnuserPreference(vehicleDetailsReport?.summary?.averageSpeed)|number:'1.0-2'}} {{userData.attributes.speedUnit|translate}}
              </span>
            </div>
            <div class="colplay">
              <span class="iconstext">
                <img src="/assets/icons/route_play.png" alt="battery" />
                {{vehicleDetailsReport.summary.distance | meterTokm:userData.attributes.distanceUnit}} {{userData.attributes.distanceUnit|translate}}
              </span>
            </div>
          </div>
        </div>
      </a>
      <a> <img src="/assets/icons/doubledown.png" alt="doubledown" /> </a>
      <a class="bottom_navs" [class.history-tab-selected]="parking" (click)="onClickParking()">{{'Parking'|translate}} </a>
      <a class="bottom_navs" [class.history-tab-selected]="event" (click)="onClickEvent()">{{'Events'|translate}} </a>
    </div>
  </div>

  <div class="row" style="margin-right: 0">
    <div *ngIf="history">
    <table
      class="table table-light table-borderless historytable"
      
    >
      <thead class="historyheadBorder">
        <tr>
          <th scope="col" class="bottomNavHeads">{{'Status'|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Fix Time'|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Latitude '|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Longitude '|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Speed'|translate}} </th>
          <th scope="col" class="bottomNavHeads">{{'Address'|translate}}</th>
          
        </tr>
      </thead>
      <tbody *ngIf="allLocationTrackData.length">
        <tr *ngFor="let repo of allLocationTrackData |  paginate : {
          itemsPerPage: 10,
          currentPage: page,
          totalItems: allLocationTrackData.length
        };let i=index">
          <td  class="bottomNavBody">{{repo?.attributes?.motion ? "Running" : "Stopped"}}</td>
          <td
          class="bottomNavBody"
            style="padding-left: 3vh"
          >
            <img
              src="/assets/icons/table_vehicle.png"
              alt="car"
              style="margin-right: 1vh"
            />{{ repo.serverTime | date: 'dd/MM/yyyy hh:mm a' }}
          </td>
          <td  class="bottomNavBody">{{ repo.latitude| number : '1.0-6' }}</td>
          <td  class="bottomNavBody">{{ repo.longitude| number : '1.0-6' }}</td>
          <td  class="bottomNavBody">{{ onSpeedgenerateBasedOnuserPreference(repo.speed) | number : '1.0-2'}} {{userData.attributes.speedUnit|translate}}</td>
          <td  class="bottomNavBody">{{ repo.address }}</td>
        </tr>
      </tbody>
     </table>
    <div *ngIf="allLocationTrackData.length" class="pagination-flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{allLocationTrackData.length/10 | number: '1.0-0'}} {{'of'|translate}}
          {{allLocationTrackData.length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
    <table
      class="table table-light table-borderless parkingtable"
      *ngIf="parking"
    >
      <thead class="historyheadBorder">
        <tr>
          <th scope="col" class="bottomNavHeads">{{'Vehicle Name'|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'From'|translate}} Time</th>
          <th scope="col" class="bottomNavHeads">{{'To Time'|translate}} </th>
          <th scope="col" class="bottomNavHeads">{{'Duration'|translate}} </th>
          <th scope="col" class="bottomNavHeads">{{'Location'|translate}} </th>
        </tr>
      </thead>

      <tbody>
        
        <tr *ngFor="let item of stoppagesReports; index as i;">
          <td class="bottomNavBody">{{item.deviceName}}</td>
          <td class="bottomNavBody">{{item.startTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
          <td class="bottomNavBody">{{item.endTime | date: 'dd/MM/yyyy hh:mm a'}}</td>
          <td class="bottomNavBody">{{item.duration |convert:'hh:mm'}}</td>
          <td class="bottomNavBody">{{item.address}}</td>
        </tr>
      </tbody>
    </table>

    <table class="table table-light table-borderless eventtable" *ngIf="event">
      <thead class="historyheadBorder">
        <tr>
          <th scope="col" class="bottomNavHeads">{{'Event'|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Time'|translate}}</th>
          <th scope="col" class="bottomNavHeads">{{'Address'|translate}}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="bottomNavBody">Idle 1</td>
          <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
          <td class="bottomNavBody">
            Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
            Uttar Pradesh (SE)
          </td>
        </tr>
        <tr>
          <td class="bottomNavBody">Idle 1</td>
          <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
          <td class="bottomNavBody">
            Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
            Uttar Pradesh (SE)
          </td>
        </tr>
        <tr>
          <td class="bottomNavBody">Idle 1</td>
          <td class="bottomNavBody">21-02-2022, 12:57:58 AM</td>
          <td class="bottomNavBody">
            Mother India Intermediate College, 147 E, Kalpi Rd, Panki, Kanpur,
            Uttar Pradesh (SE)
          </td>
        </tr>
      </tbody>
    </table>

    <div class="container-fluid fuelsummarycontainer" *ngIf="fuelsummary">
      <div class="row fuelsummaryrow" *ngIf="false">
        <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
          <span class="bottomNavHeads">{{'Fuel Capacity'|translate}}</span>
          <span class="fuelcapcity">250 L</span>
        </div>
        <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
          <span class="bottomNavHeads">{{'Fuel Consumed'|translate}}</span>
          <span class="fuelcapcity">230.46 L</span>
        </div>
        <div class="col-3 border_right colfuel_summary" style="padding: 3vh">
          <span class="bottomNavHeads">{{'Refuel'|translate}}</span>
          <span class="fuelcapcity">209.95 L</span>
          <span class="bottomNavHeads">Total Refueled : 1 {{'times'|translate}}</span>
        </div>
        <div class="col-3 colfuel_summary" style="padding: 3vh">
          <span class="bottomNavHeads">{{'Fuel Theft'|translate}}</span>
          <span class="fuelcapcity">0 L</span>
          <span class="bottomNavHeads">{{'Total Theft'|translate}} : 0 {{'times'|translate}}</span>
        </div>
      </div>

      <div class="p-4 fuelheads">{{'Driving Metrics'|translate}}</div>

      <div class="row fuelsummaryrow">
        <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Distance'|translate}}</span>
          <span class="metricspeed">{{vehicleDetailsReport.summary.distance | meterTokm:userData.attributes.distanceUnit}} {{userData.attributes.distanceUnit|translate}} </span>
        </div>
        <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Average Speed'|translate}}</span>
          <span class="metricspeed">{{onSpeedgenerateBasedOnuserPreference(vehicleDetailsReport?.summary?.averageSpeed)|number:'1.0-2'}} {{userData.attributes.speedUnit|translate}}</span>
        </div>
        <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Top Speed'|translate}}</span>
          <span class="metricspeed">{{onSpeedgenerateBasedOnuserPreference(vehicleDetailsReport?.summary?.maxSpeed)|number:'1.0-2'}} {{userData.attributes.speedUnit|translate}}</span>
        </div>
        <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Sudden Acceleration'|translate}}</span>
          <span class="metricspeed">0 {{'times'|translate}}</span>
        </div>
        <div class="col-2 border_right colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Sudden Brake'|translate}}</span>
          <span class="metricspeed">0 {{'times'|translate}}</span>
        </div>
        <div class="col-2 colfuel_summary" style="padding: 1.5vh">
          <span class="metrics">{{'Fuel Efficiency'|translate}}</span>
          <span class="metricspeed">0 Km/L</span>
        </div>
      </div>

      <div class="p-4 fuelheads">{{'Todays Fuel & Efficiency Graph'|translate}}</div>

      <div class="row">
        <div class="col-9">
          <div id="chart"></div>
        </div>
        <div
          class="col-3 colfuel_summary"
          style="justify-content: space-evenly; padding-bottom: 3rem"
        >
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isHistorySpeedChart" (change)="resetHistoryChartSeries()">
            <label class="form-check-label" for="flexCheckDefault">
              {{'Speed'|translate}}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isHistoryFuelChart" (change)="resetHistoryChartSeries()">
            <label class="form-check-label" for="flexCheckDefault">
              {{'Fuel'|translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="staticBackdropAdd"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropAddLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead">
        <div class="headtext addlabels">
          {{ addDevice.id ?('Edit'|translate) : ('Add'|translate) }} {{'Device'|translate}}
        </div>
        <button
          type="button"
          class="btnclose"
          id="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
        <div class="row">
          <div class="col-12">
            <accordion [isAnimated]="true">
              <accordion-group heading="Required" [isOpen]="true">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Registration No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.name"
                    class="form-control inputfields"
                    id="name"
                    aria-describedby="name"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="errors?.Devicename"
                  >
                    {{ errors.Devicename }}
                  </span>
                </div>
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'IMEI'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.identifier"
                    class="form-control inputfields"
                    id="identifier"
                    aria-describedby="identifier"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="errors?.Deviceidentifier"
                  >
                    {{ errors.Deviceidentifier }}
                  </span>
                </div>
              </accordion-group>
              <accordion-group heading="Extra">
                <div class="form-group pb-2">
                  <label for="group" class="pb-2">{{'Group'|translate}}</label>
                  <select class="form-control" [(ngModel)]="addDevice.groupId">
                    <option></option>
                  </select>
                </div>
                <div class="form-group pb-2">
                  <label for="phone" class="pb-2">{{'Sim number'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.phone"
                    class="form-control inputfields"
                    id="phone"
                    aria-describedby="phone"
                  />
                </div>
                <div class="form-group pb-2">
                  <label for="model" class="pb-2">{{'Model'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.model"
                    class="form-control inputfields"
                    id="model"
                    aria-describedby="model"
                  />
                </div>
                <div class="form-group pb-2">
                  <label for="contact" class="pb-2">{{'Driver no'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.contact"
                    class="form-control inputfields"
                    id="contact"
                    aria-describedby="contact"
                  />
                </div>
                <div class="form-group pb-2">
                  <label for="category" class="pb-2">{{'Icon'|translate}}</label>
                  <select class="form-control" [(ngModel)]="addDevice.category">
                    <option
                      [value]="deviceCategory.id"
                      *ngFor="let deviceCategory of deviceCategoryList"
                    >
                      {{ deviceCategory.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group pb-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="addDevice.disabled"
                      (change)="checkboxEvent($event.target)"
                      id="disabled"
                    />
                    <label class="form-check-label" for="disabled"
                      >{{'Disabled'|translate}}</label
                    >
                  </div>
                </div>
                <div class="form-group pb-2 date-p">
                 
                  <label for="expirationTime" class="pb-2">{{'Expiration'|translate}}</label>
                  <input
                    type="text"
                    value="{{addDevice.expirationTime}}"
                    [(ngModel)]="addDevice.expirationTime"
                    class="form-control inputfields"
                    id="expirationTime"
                    aria-describedby="expirationTime"
                  />
                </div>
              </accordion-group>
              <accordion-group heading="Attributes">
                <ng-container
                  *ngFor="let attribute of addDevice.attributes; index as i"
                >
                  <div class="row">
                    <div
                      class="col-11 form-group mb-2"
                      *ngIf="attribute.attribute === 'speedLimit'"
                    >
                      <label>{{'Speed Limit(km)'|translate}}</label>
                      <input
                        type="number"
                        min="1"
                        class="form-control"
                        [(ngModel)]="attribute.value"
                      />
                    </div>
                    <div
                      class="col-11 form-group mb-2"
                      *ngIf="attribute.attribute === 'timezone'"
                    >
                      <label>{{'Timezone'|translate}}</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="attribute.value"
                      />
                    </div>
                    <div
                      class="col-11 form-group mb-2"
                      *ngIf="attribute.attribute === 'deviceInactivityStart'"
                    >
                      <label>{{'Device Inactivity Start'|translate}}</label>
                      <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="attribute.value"
                      />
                    </div>
                    <div
                      class="col-11 form-group mb-2"
                      *ngIf="attribute.attribute === 'deviceInactivityPeriod'"
                    >
                      <label>{{'Device Inactivity Period'|translate}}</label>
                      <input
                        type="number"
                        class="form-control"
                        [(ngModel)]="attribute.value"
                      />
                    </div>
                    <div class="col-1">
                      <label>&nbsp;</label>
                      <i
                        class="fa fa-trash text-danger"
                        (click)="deleteAttr(i)"
                      ></i>
                    </div>
                  </div>
                </ng-container>

                <div
                  class="btn btn-outline-dark w-100"
                  (click)="addAttri(template)"
                >
                  <i class="fa fa-plus"></i> {{'ADD'|translate}}
                </div>
              </accordion-group>
            </accordion>
          </div>
        </div>

        <div class="flex_row_center_spacebetween pt-4 pb-2">
            <div class="col-8"></div>
            
            <div class="col-2 ">
              <button
                mat-raised-button
                class="cancelbtn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {{'Cancel'|translate}}
              </button>
            </div>
            <div class="col-2">
              <button
                mat-raised-button
                class="cancelbtn"
                (click)="deviceSubmit()"
              >
                {{'Save'|translate}}
              </button>
            </div>
         
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label>{{'Attribute'|translate}}</label>
        <select
          class="form-control"
          [(ngModel)]="addAttr.attribute"
          (change)="changeAttrEvent($event.target)"
        >
          <option *ngFor="let attr of attrList" [value]="attr.id">
            {{ attr.name }}
          </option>
        </select>
      </div>
      <div class="col-12">
        <label>{{'Type'|translate}}</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="addAttr.type"
          [readonly]="true"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-light btn-sm" (click)="addAttrbute()">{{'ADD'|translate}}</div>
    <div class="btn btn-light btn-sm" (click)="modalRef.hide()">{{'CANCEL'|translate}}</div>
  </div>
</ng-template>

<ng-template #showIgnRed>
  <img src="/assets/icons/engine-off.svg" alt="img" title="{{'Engine off'|translate}}"/>
</ng-template>

<ng-template #showPowerRed>
  <img src="/assets/icons/Voltage.png" alt="img" />
</ng-template>
<ng-template #showGPSGray>
  <img src="/assets/icons/bottom6_gray.png" alt="img" />
</ng-template>
<app-common-modal-popup [modelSize]="'modal-xs'" *ngIf="isSendGPRSCommandPopupOpen">
  <div class="" modelheadertext>
    {{'Send GPRS Command'|translate}}
  </div>
  <div class="container-fluid" modeldata>
    <div class="dropdown mt-2">
      <!-- <select class="form-select selectimportant common_scroller" style="width: 95% !important; height: 7vh !important; margin-left: 2%" name="Save Command" id="subject">
        <option value="New" selected="selected">New</option>
      </select>   -->
      <span class="me-4 ms-2">{{'Object'|translate}}:</span>
      <span>{{selectedDevice?.name}}</span>
    </div>
    <br>
    <div class="dropdown">
      <select class="form-select history selectimportant common_scroller" 
      style="width: 95% !important; height: 5vh !important; margin-left: 2%" 
      name="type" id="subject" [(ngModel)]="sendCommand.type">
        <option value="{{data.type}}" selected="selected" *ngFor="let data of typesList">{{data.type}}</option>
      </select>
    </div>
    <br>
    <div class="mb-3 form-check" style="margin-left: 2%;">
      <input type="checkbox" [(ngModel)]="sendCommand.textChannel" class="form-check-input" id="exampleCheck1">
      <label class="form-check-label" for="exampleCheck1">{{'Send SMS'|translate}}</label>
    </div>
    <div class="row" style="margin-bottom: 4%; margin-left: 2%;">
      
      <div class="col-8 ps-0 d-flex align-items-center">
        <button
          mat-raised-button
          class="cancelbtn theme2"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="sendCommandDetails()"
        >
        {{'Send'|translate}}
          
        </button>
        
          <button
            mat-raised-button
            class="cancelbtn bg-secondary text-white ms-2"
            (click)="cancelCommandDetails()"
            aria-label="Close"
          >
          {{'Cancel'|translate}}
          </button>
        
      </div>
    </div>
  </div>
</app-common-modal-popup>
<app-common-modal-popup [modelSize]="'modal-xl'" *ngIf="isAssignDevicePopupOpen">
  <div class="" modelheadertext>
    {{'Assign Devices'|translate}}
  </div>
  <div class="container-fluid" modeldata>
    <form [formGroup]="deviceListModelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
      
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row fw-bold d-flex align-items-center mt-2 mb-2">
      <div class="col-2 text-center">
        {{'Vehicle No'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'IMEI'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Status'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Last Update'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Sim No'|translate}}
      </div>
      <div class="col-1 text-center">
        {{'Model'|translate}}
      </div>
      <div class="col-1 text-center">
        {{'Icon'|translate}}
      </div>
    </div>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let device of deviceList | searchPipe : deviceListModelForm.value.searchValue; index as i"
      >
        <div class="row">
          <div class="col-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [id]="'device-' + i"
                [checked]="device.isSelectedDevice"
                (change)="changeGeofenceEvent($event.target, device.id)"
              />
              <label class="form-check-label" for="device-{{ i }}">
                {{ device.name }}
              </label>
            </div>
          </div>
          <div class="col-2">
            {{ device.uniqueId }}
          </div>
          <div class="col-2">
            <img *ngIf="device.status == 'online';else offline"
                              src="/assets/icons/dotgreen.png"
                              alt="checkbox"
                              style="width: 1.5vh"
                            />
                            <ng-template #offline>
                            <img
              src="/assets/icons/stoped.png"
              alt="checkbox"
              style="width: 1.5vh"
            /></ng-template> {{ device.deviceStatus |titlecase}}
          </div>
          <div class="col-2">
            {{ device.lastUpdate |dateFormat }}
          </div>
          <div class="col-2">
            {{ device.phone }}
          </div>
          <div class="col-1">
            {{ device.model }}
          </div>
          <div class="col-1">
            {{ device.category }}
          </div>
        </div>
        
      </div>
    </div>
  </div>
</app-common-modal-popup>
<app-events-remarks *ngIf="isEventsRemarksPopupOpen"></app-events-remarks>