import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardDataService } from 'src/app/services/dashboard-data.service';
import * as ApexCharts from 'apexcharts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare const google: any;
declare var $: any;
import { AgmInfoWindow, LatLngBounds, MapsAPILoader, MouseEvent } from '@agm/core';
import {
  EventType,
  TravelData,
  TravelMarker,
  TravelMarkerOptions,
} from 'travel-marker';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { SubjectSubscribeService } from '../../services/subject-subscribe.service';

import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

import { WebSocketService } from 'src/app/services/web-socket.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';
import { parse } from 'path';
import { GeofenceService } from 'src/app/services/geofence.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css'],
  providers:[MeterTokm]
})
export class TrackingComponent implements OnInit, AfterViewInit, OnDestroy {
  vehicleDetailsReport = {
    alert: [],
    moving: [],
    parked: [],
    summary: {},
  };
  mapParkingIcon = {
    url: '/assets/icons/mapparking.png',
  scaledSize: { height: 38, width: 38 },
  size: new google.maps.Size(38, 38),
  scale: 2,}
  blueDotIcon = {
    url: '/assets/icons/bluedot.png',
  scaledSize: { height: 10, width: 10 },
  size: new google.maps.Size(10,10),
  scale: 2,}
  whiteFlagIcon = {
    url: '/assets/icons/white-flag-symbol.png',
  scaledSize: { height: 30, width: 30 },
  size: new google.maps.Size(30,30),
  scale: 2,}
  
  historyPath: any = {};
  statusFilter = 'ALL';
  previousInfoWindow;
  polyLineArray = [];
  loadCountSubscriber: any;
  geofenceList: any = [];
  markerGeofenceList: any = [];
  landmarkList: any = [];
  markerLandmarkList: any = [];
  isGeofenceVisiable = false;
  mapTypeId = 'roadmap';
  page = 0;
  eventPage = 0;  
  devicePage = 0;
  pageOption = 10;
  activeSection = 1;
  allCounts={
    ALL:0,
    RUNNING:0,
    IDLE:0,
    STOPPED:0,
    NOT_REPORTING:0,
    EXPIRED:0,
    NO_DATA:0,
    TOWWED:0,
  };
  devicesList = [];
  selectedDevice: any = {};
  options: any = {};
  locations = [];
  origin: any;
  destination: any;
  loaders: any;
  icon = '/assets/icons/greencar.png';
  markers: marker[] = [];
  zoom: number = 9;
  lat: number;
  lng: number;
  fullScreen = false;
  playback = false;
  fulldetails = false;
  vehicle_fullDetail = false;
  vehicle_status = false;
  main_col = true;
  markersList = [];
  history: boolean = true;
  parking: boolean = false;
  event: boolean = false;
  fuelsummary: boolean = false;
  bottomNav: boolean = false;
  allvehicles = true;
  oneVehicle = false;
  gloableSearchText= '';
  fullwidth = true;
  back_button = false;
  numbDelta = 1000;
  selectedDate: any;
  // TRAVEL MARKER VARIABLES
  device_id = '';
  to = '';
  from = '';
  todayDate = '';
  speedMultiplier = 2;
  map: any;
  marker: TravelMarker = null;
  origin_lat: any;
  origin_lng: any;
  destination_lat: any;
  destination_lng: any;
  locationData = [];
  playpause = false;
  arrlength: number;
  values: number;
  locationArray = [];
  selcetvehicle = {};
  allLocationTrackData = [];
  historyChartData = [];
  fromdate = '';
  todate = '';
  selectedDeviceDistance = 0;
  locationTotalDistance = 0;
  opts = {
    extraHeaders: {
      Cookie:
        '_ga=GA1.2.17432343994.1475611967; _gat=1; __cfduid=dc232334gwdsd23434542342342342475611928',
    },
  };

  attrList: any = [
    {
      id: 'speedLimit',
      name: 'Speed Limit',
      type: 'number',
    },
    {
      id: 'timezone',
      name: 'Timezone',
      type: 'string',
    },
    {
      id: 'deviceInactivityStart',
      name: 'Device Inactivity Start',
      type: 'number',
    },
    {
      id: 'deviceInactivityPeriod',
      name: 'Device Inactivity Period',
      type: 'number',
    },
  ];
  addAttr: any = {
    attribute: '',
    type: '',
    value: null,
  };
  deviceCategoryList: any = [
    { id: 'default', name: 'Default' },
    { id: 'animal', name: 'Animal' },
    { id: 'bicycle', name: 'Bicycle' },
    { id: 'boat', name: 'Boat' },
    { id: 'bus', name: 'Bus' },
    { id: 'car', name: 'Car' },
    { id: 'crane', name: 'Crane' },
    { id: 'helicopter', name: 'Helicopter' },
    { id: 'motorcycle', name: 'Motorcycle' },
    { id: 'offroad', name: 'Offroad' },
    { id: 'person', name: 'Person' },
    { id: 'pickup', name: 'Pickup' },
    { id: 'plane', name: 'Plane' },
    { id: 'ship', name: 'Ship' },
    { id: 'tractor', name: 'Tractor' },
    { id: 'train', name: 'Train' },
    { id: 'tram', name: 'Tram' },
    { id: 'trolleybus', name: 'Trolleybus' },
    { id: 'truck', name: 'Truck' },
    { id: 'van', name: 'Van' },
    { id: 'scooter', name: 'Scooter' },
  ];
  addDevice: any;
  errors: any = {};

  modalRef?: BsModalRef;
  trafficLayer: any;
  isTrafficEnabled = false;
  isSetFitBound = false;
  typesList: any = [];
  userData: any;
  // socket = io(
  //   'wss://app.multitrack.in:8082/api/session?token=' +
  //     localStorage.getItem('basicToken'),
  //   this.opts
  // );
  sendCommand: any = {};
  selectAllObjects: boolean = false;
  selectAllGeofense: boolean = false;
  isDeviceListVisible:boolean = true;
  isObjectsTab: boolean = true;  
  isEventsTab: boolean = false;
  isHistoryTab: boolean = false;
  isGeofenceTab: boolean = false;
  isLandmarkTab: boolean = false;
  eventsDataList: any[] = [];
  eventFilterText: any = '';
  objectNameList: any[] = [];
  filteredobjectNameList: Observable<string[]>;
  historyForm:FormGroup;
  historySelectedDevice: any = {};
  rightPanelExpand: boolean = false;
  driverDetails: any[] = [];
  selectedDeviceNetworkData: any = null;
  searchGeo = '';
  polygons = true;
  circular = true;
  maincard = true;
  addpolygon = false;
  addcircular = false;
  checked = false;
  disabled = false;
  polygonGeofence = true;
  circularGeofence = true;
  circularLandmarkGeofence = true;
  //fullScreen = false;
  googleMap = true;
  drawPolygonGeofence = false;
  drawCircularGeofence = false;
  submitted = false;
  poiList = [];
  polygonForm: FormGroup;
  circularForm: FormGroup;
  geofenceDataList: any[] = [];
  circleArrayLatLng = [];
  polyArrayLatLng = [];
  circleRadius;
  dd: any = [
    {
      geofence: {
        coordinates: [
          [
            [30.340540087432323, 78.04063285692901],
            [30.29608489517005, 78.09075797899932],
            [30.336391789690683, 77.89918388231963],
            [30.405704529085938, 77.88682426317901],
            [30.406888934979417, 78.29057182177276],
            [30.381421042396628, 78.28439201220245],
            [30.340540087432323, 78.04063285692901],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      id: '61ae1a0d0584d2483b86d60e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'new Geofences',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: false,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        coordinates: [
          [
            [30.480710635331903, 78.02569103134559],
            [30.33858686954149, 78.25640392197059],
            [30.234231418847923, 78.06414317978309],
            [30.490178192225784, 77.69610118759559],
            [30.480710635331903, 78.02569103134559],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c18cb5edaf507ea9d21379',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'pimpari',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.728417, 18.616194],
            [73.731056, 18.61653],
            [73.730037, 18.615782],
            [73.728938, 18.615167],
            [73.728417, 18.616194],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '61c3152fcebac3309f069fdd',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'chinchavad',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
      vehicleGroup: null,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [18.548322, 73.793796],
            [18.609436, 73.847954],
            [18.577417, 73.902886],
            [18.514814, 73.841609],
            [18.548322, 73.793796],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '628f1759263b9503701ce7c6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'Farm1',
      entering: true,
      exiting: true,
      status: true,
      circle_center: [],
      v: 0,
      type: 'Unloading',
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [73.904012, 18.594385],
            [73.904173, 18.595056],
            [73.905353, 18.595173],
            [73.905648, 18.594344],
            [73.905187, 18.59376],
            [73.904012, 18.594385],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: false,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a84609f0d5c03267be935e',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'home',
      entering: true,
      exiting: false,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        coordinates: [
          [
            [19.443354490073318, 81.95704227639173],
            [18.97649898052674, 82.38550907326673],
            [18.872574205562298, 81.84717899514173],
            [19.215279630450194, 81.74830204201673],
            [19.443354490073318, 81.95704227639173],
          ],
        ],
        type: 'Polygon',
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62a96ce4008c3c0585c00d9d',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'abnuj second poly 2',
      entering: true,
      exiting: true,
      type: 'Unloading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [21.68594279230725, 78.63558466949462],
            [21.205337286988406, 79.06405146636962],
            [21.097752507367517, 78.56966670074462],
            [21.40492956020722, 78.41585810699462],
            [21.68594279230725, 78.63558466949462],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56b416f5a8732069e77f6',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      v: 0,
    },
    {
      geofence: {
        type: 'Polygon',
        coordinates: [
          [
            [28.684576017318168, 77.27052673563996],
            [28.632156091045463, 77.29936584696809],
            [28.63155341081541, 76.86815246806184],
            [28.75984608375382, 76.87501892313996],
            [28.684576017318168, 77.27052673563996],
          ],
        ],
      },
      trackedDevice: [],
      devicesWithin: [],
      halt_notif: true,
      halt_time: '60',
      devWithin: [],
      untrackedDevice: [],
      _id: '62c56c1b289c9631eeea8f84',
      uid: '5cee7da97a38f414a4e5dfa3',
      geoname: 'tester ',
      entering: true,
      exiting: true,
      type: 'Loading',
      status: true,
      circle_center: [],
      _v: 0,
    },
  ];
  polygonList = [];
  isGeofenceList:boolean = true;
  isGeofenceLandmarkList: boolean = true;
  historyActivityList = [];
  selectedHistoryActivity: any = {};
  isHistorySpeedChart = true;
  isHistoryFuelChart = true;
  isSendGPRSCommandPopupOpen = false;
  isAssignDevicePopupOpen = false;
  isEventsRemarksPopupOpen = false;
  deviceList:any[] = [];
  selectedGeofenceForDevice = '';
  deviceListModelForm:FormGroup;
  isNormalUser = true;
  selectedEvent:any = {};
  selectedLandmark:any = {};
  previousEventInfoWindow;
  minimumClusterSize = 2;
  bounds: LatLngBounds = new google.maps.LatLngBounds();
  inactiveTabInterval: any;
  groupListData: any[] = [];
  isObjectSearchBy: any = 'device';
  groupSearchText: any = '';
  pageLimitForDevices: any = '';
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private track_http: DashboardDataService,
    private fb: FormBuilder,
    private webSocketService: WebSocketService,
    public commonService: CommonService,
    private cookieService: CookieService,
    private subjectSubscribeService: SubjectSubscribeService,
    private mapsAPILoader: MapsAPILoader,
    private geofence: GeofenceService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private meterTokm: MeterTokm,
    private translate: TranslateService
  ) {

    this.historyPath['poly'];
    this.historyPath['line'];
    this.historyPath['start'];
    this.historyPath['end'];

    this.subjectSubscribeService.searchDevice.subscribe((resp: any) => {
      this.gloableSearchText = resp;
      this.devicePage = 0;
      this.devicesList = [];
      this.deviceFilter(this.statusFilter,true);
      // this.devicePage = 1;
      // if (resp) {
      //   this.devicesList.forEach((d, k) => {
      //     if (d.deviceId === resp) {
      //       this.devicesList[k]['isShow'] = true;
      //     } else {
      //       this.devicesList[k]['isShow'] = false;
      //     }
      //   });
      //   this.markersList.map((item) => {
      //     if (item.deviceId == resp) {
      //       item.isHide = false;
      //     } else {
      //       item.isHide = true;
      //     }
      //   });
      // } else {
      //   this.devicesList.map((item) => {
      //     item.isShow = true;
      //   });
      //   this.markersList.map((item) => {
      //     item.isHide = false;
      //   });
      // }
      // setTimeout(() => {
      //   this.roatedAllDevice();
      // }, 100);
    });

    this.addDevicepopup();
    this.historyForm = this.fb.group({
      object: [''],
      filter: ['0'],
      timeFrom: [],
      timeTo: [],
      stops: ['1']
    });
    (this.polygonForm = this.fb.group({
      fencename: ['', Validators.required],
      geoaddress: ['']
    }),
    this.circularForm = this.fb.group({
        circularName: ['', Validators.required],
        circularAddress: [''],
        circularType: [''],
        circularAddr: ['']
    }));
    this.commonService.pageLoadInModal.subscribe((result: string) => { 
      this.resetModalVariable();
      if (result == "devicemodel") {
        this.isSendGPRSCommandPopupOpen = true;
        this.commonService.commonModel.next(true); 
      } else if (result == "assigndevicemodal") {
        this.isAssignDevicePopupOpen = true;
        this.commonService.commonModel.next(true); 
      } else if (result == "eventsremarksmodal") {
        this.isEventsRemarksPopupOpen = true;
        this.commonService.commonModel.next(true);
      }
    });
    this.commonService.commonModelReset.subscribe((result) => {
      this.resetModalVariable();
    });
    this.commonService.reportsParameterForHistory.subscribe((result: any) => {
      this.tabChange("history");
      setTimeout(() => {
        this.historyForm.patchValue({
          timeFrom: moment(result.fromDate).format("yyyy-MM-DD hh:mm:ss A").toString(),
          timeTo: moment(result.toDate).format("yyyy-MM-DD hh:mm:ss A").toString(),
          object: result.deviceId,
          filter: result.dateType     
        });
        this.showTracking({});
      }, 1000);
      
    })
    this.deviceListModelForm = new FormGroup({
      searchValue: new FormControl('')     
    });
    this.commonService.updateNotificationtabInTracking.subscribe((res: any) => {
      if (res.val == 'notification') {
        this.eventPage = 0;
        this.eventsDataList = [];
        this.loadNotification();

      }
    })
    document.addEventListener('visibilitychange', () => {
      if (this.selectedDevice && this.selectedDevice.deviceId) {
        if(document.hidden) {
          this.inactiveTabInterval = setInterval(() => {
            console.log('interval started');
            this.moveMarker();
          }, 5000);
        }
        else {
            console.log('first time call -- active');
            clearInterval(this.inactiveTabInterval);
            // restart timers
        }
      }
    });
  }
  newmethodcall(val) {
    //console.log(' tab is inactive again');        
    if (val) {
      this.newmethodcall(true);
    }
  }
  openedWindow : number = 0; // alternative: array of numbers
  openedWindowDots : string = '';
  openWindow(id) {
      this.openedWindow = id; // alternative: push to array of numbers
  }
  get polygonFormControl() {
    return this.polygonForm.controls;
  }

  get circularFormControl() {
    return this.circularForm.controls;
  }
  resetModalVariable() {
    this.isSendGPRSCommandPopupOpen = false;
    this.isAssignDevicePopupOpen = false;
    this.isEventsRemarksPopupOpen = false;
  }
  isInfoWindowOpen(id) {
      return this.openedWindow == id; // alternative: check if id is in array
  }
  openWindowDots(id) {
    this.openedWindowDots = id; // alternative: push to array of numbers
  }

  isInfoWindowOpenDots(id) {
    return this.openedWindowDots == id; // alternative: check if id is in array
  }
  showSuccess(title, message) {
    this.toastr.info(title, message);
  }
  changeMapTypeId() {
    this.mapTypeId = this.mapTypeId == 'satellite' ? 'roadmap' : 'satellite';
  }
  changeSetToBound() {
    this.isSetFitBound = !this.isSetFitBound;
    this.zoom = this.isSetFitBound ? 13.2 : 9;
  }
  my_string;
  displayODa() {
    let dInMeter = this.selectedDevice['attributes']['totalDistance'] ? this.selectedDevice['attributes']['totalDistance'] : 0
    let km = dInMeter/1000
   
    let kmAfterMeter =  km? km.toFixed(0) :0;
    this.my_string = kmAfterMeter.toString().padStart(7,'0');
    // while (this.my_string.length < 7) {
    //   this.my_string = '0' + this.my_string;
    // }
    this.my_string[0]; this.my_string[1]; this.my_string[2]; this.my_string[3]; this.my_string[4]; this.my_string[5]; this.my_string[6];

  }
  getDateIntimes(actions) {
    let now = moment();

    switch (actions) {
      case 'startToday':
        return now.startOf('day').utc().toISOString();
        break;
      case 'endToday':
        return now.endOf('day').utc().toISOString();
        break;
      case 'start24':
        return now.subtract(1, 'day').utc().toISOString();
        break;
      case 'end24':
        return now.utc().toISOString();
        break;
      case 'fromYest':
        return now.subtract(1, 'day').startOf('day').utc().toISOString();
        break;
      case 'toYest':
        return now.subtract(1, 'day').endOf('day').utc().toISOString();
        break;
      case 'weekFrom':
        return now.startOf('week').startOf('day').utc().toISOString();
        break;
      case 'weekTo':
        return now.startOf('week').endOf('day').utc().toISOString();
        break;
    
      default:
        break;
    }
  }
  setHistoryDate(val: any) {
    let now = moment();
    switch (val) {
        case '0':
          //console.log(now.startOf('day').format("yyyy-MM-DDThh:mm:ss A"));
          //setTimeout(() => {
            this.historyForm.patchValue({            
              timeFrom: now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
              timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
            });            
          //},10);
          break;
        case '1':
          this.historyForm.patchValue({            
            timeFrom: now.format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.subtract(1, 'day').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '2':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(1, 'day').startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString(),
            timeTo: now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()          
          });  
          break;
        case '3':
          this.historyForm.patchValue({            
            timeFrom: now.startOf('week').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('week').format("yyyy-MM-DD hh:mm:ss A")          
          });

          break;
        case '4':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(7, 'day').startOf('week').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('week').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '5':
          this.historyForm.patchValue({            
            timeFrom: now.startOf('month').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('month').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        case '6':
          this.historyForm.patchValue({            
            timeFrom: now.subtract(1, 'month').startOf('month').format("yyyy-MM-DD hh:mm:ss A"),
            timeTo: now.endOf('month').format("yyyy-MM-DD hh:mm:ss A")          
          });
          break;
        default:
          break;
    }
  }
  onPeriodChange(newValue) {
    var day, first, from, to, custom = newValue === '7';
    if (!custom) {
        from = new Date();
        to = new Date();
        switch (newValue) {
            case '0':
                to.setDate(to.getDate() + 1);                              
                break;
            case '1':
              from.setDate(to.getDate() - 1);
              break;
            case '2':
                from.setDate(to.getDate() - 1);
                break;
            case '3':
              from = moment().startOf('week');
              to = moment().endOf('week');

                break;
            case '4':
              from = moment().subtract(1, 'week').startOf('week');
              to = moment().subtract(1, 'week').endOf('week');
                break;
            case '5':
                from.setDate(1);
                to.setDate(1);
                to.setMonth(from.getMonth() + 1);
                break;
            case '6':
                from.setDate(1);
                from.setMonth(from.getMonth() - 1);
                to.setDate(1);
                break;
            default:
                break;
        }
        // if(newValue != '1' && newValue != '3' && newValue != '4') {
        //   from.setHours(0, 1, 0, 0);
        //   to.setHours(23, 59, 0, 0);
        // }
        
       this.from = from.toISOString();
       this.to = to.toISOString();
      //  setTimeout(() => {
      //   this.historyForm.patchValue({
      //     timeTo: moment(this.to).local().format("yyyy-MM-DDThh:mm:ss "),
      //     timeFrom: moment(this.from).local().format("yyyy-MM-DDThh:mm:ss")          
      //   }); 
      //  }, 10);              
    }
}
  setMapPath() {
    //console.log(this.selectedDevice);
    let url =
      'https://www.google.com/maps/dir/?api=1&destination=' +
      this.selectedDevice['latitude'] +
      ',' +
      this.selectedDevice['longitude'] +
      '&travelmode=driving';
    window.open(url, '_blank');
  }
  trafficLayerOnMap() {
    if (this.isTrafficEnabled) {
      this.trafficLayer.setMap(null);
      this.isTrafficEnabled = false;
    } else {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.map);
      this.isTrafficEnabled = true;
    }
  }
  ngOnDestroy() {
    clearInterval(this.inactiveTabInterval);
    clearInterval(this.loadCountSubscriber);
    this.main_col = true;
  }
  ngOnInit(): void {
    let userData = localStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        //console.log('userData', this.userData);
      } catch (error) {}
    }
    this.activeSection = 1;
    let selectedDateM = moment();
    this.selectedDate = selectedDateM.format('YYYY-MM-DD');
    this.route.queryParams.subscribe((params) => {
        setTimeout(() => {
          this.deviceFilter(params.type ?params.type : 'ALL' );
        }, 10);
      this.getOnlyCounts();
      this.loadCountSubscriber = setInterval(() => {
        if (!this.fulldetails) {
          this.getOnlyCounts();
        }
      }, 10000);
    });

    setTimeout(() => {
      // this.getVehicleCounts();
      this.initiateCarousel();
    }, 1);

    //  let opts = {
    //    extraHeaders: {
    //      Cookie:
    //        '_ga=GA1.2.17432343994.1475611967; _gat=1; __cfduid=dc232334gwdsd23434542342342342475611928',
    //    },
    //  };
    // var socket = io(websiteURL, opts);
    // var patch = require('socketio-wildcard')(io.Manager);
    // patch(socket);

    // this.socket.on('connect', () => {
    //   console.log('this.socket', this.socket.id); // x8WIv7-mJelg7on_ALbx
    // });

    // let myWebSocket: WebSocketSubject = webSocket('wss://app.multitrack.in:8082/api/session');
    // myWebSocket.asObservable().subscribe((resp: any) => {
    //   console.log('------------', resp);
    // });
    this.getConnection();
    // this.getCurrentLocation().then((pos) => {
    //   this.lat = pos.lat;
    //   this.lng = pos.lng;
    // });
    this.getAllTypes();
    this.getAllGeofence();
    this.filteredobjectNameList = this.historyForm.controls.object.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.isNormalUser = !(this.userData.administrator || this.userData.userLimit !== 0);
  }

  getAllTypes() {
    this.commonService.getRequest('commands/types', []).then((resp: any) => {
      if (resp?.status) {
        this.typesList = resp.data;
        //console.log('-------------', resp.data);
      }
    });
  }

  sendCommandDetails() {
    this.sendCommand.attributes = {};
    let object = {
      attributes: {},
      deviceId: this.device_id,
      textChannel: this.sendCommand.textChannel,
      type: this.sendCommand.type,
    };
    this.commonService
      .postRequest('commands/send', object)
      .then((resp: any) => {
        $('#btnclose1').click();
        Swal.fire({
          icon: 'success',
          title: 'Send command has been Submitted Successfully',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }
  cancelCommandDetails() {
    this.commonService.commonModel.next(false);
  }
  // getCurrentLocation(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(
  //       (resp) => {
  //         resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
  //       },
  //       (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }
  roatedAllDevice() {
    this.markersList.forEach((mList) => {
      this.roatedDevice(mList, 10);
    });
  }
  roatedDevice(device, refreshTime = 100, isCourse = 0) {
    setTimeout(() => {
      const element = document.querySelector(
        `img[src~='${device.deviceDetails.vehicleMapImg.url}']`
      ) as HTMLImageElement;
      if (element) {
        element.style.transform =
          'rotate(' + (isCourse ? isCourse : device.course) + 'deg)';
      }
    }, refreshTime);
  }
  getVehicleCounts() {
    this.track_http.getDashbaord().subscribe((resp) => {
      //console.log(resp);
      // this.running = resp.running_devices;
      // this.Idling = resp['Ideal Devices'];
      // this.Stopped = resp['OFF Devices'];
      // this.outofreach = resp.OutOfReach;
      // this.expired = resp.expire_status;
      // this.nodata = resp.no_data;
      // this.totalVehicles = resp.Total_Vech;
    });
  }

  getConnection() {
    //close sesstion if aleady exits
    try {
      this.commonService.closeSocket();
    } catch (error) {
      // no open connection found
    }
    const basicToken = localStorage.getItem('basicToken');
    this.commonService.socketConnection = new WebSocket(
      `wss://${environment.baseUrl}:8082/api/socket?token=` + basicToken
    );
    if (this.commonService.socketConnection) {
      this.commonService.socketConnection.onmessage = (event) => {
        var msg = JSON.parse(event.data);
        if (msg.devices) {
          msg.devices.forEach((device) => {
            this.devicesList.forEach((displayDevice) => {
              if (displayDevice.deviceId == device.id) {
                displayDevice.deviceTime = device.lastUpdate;
                if(device.totalDistance) {
                  //displayDevice.totalDistance = device.totalDistance;
                }
                //displayDevice.deviceTime = new Date( device.lastUpdate);
                //displayDevice.status = device.status;
                //device.speed
                if (
                  this.selectedDevice && this.selectedDevice['deviceId'] && 
                  this.selectedDevice['deviceId'] == device.deviceId
                ) {
                  //this.displayODa();
                }
              }
            });
          });
        } else if (msg.positions) {
          // console.log("Positions");
          // console.log(msg.positions);
          msg.positions.forEach((device) => {
            this.markersList.forEach((displayMarker) => {
              if (displayMarker.deviceId == device.deviceId) {
                if (device.attributes.lastUpdate) {
                  displayMarker.lastUpdate = device.attributes.lastUpdate;
                }
                //displayMarker.lastUpdate = device.attributes.lastUpdate;                 
                if (!device.isHide && !this.oneVehicle) {
                  //console.log('Is animated');
                  this.animatedMove(displayMarker, displayMarker, device);
                  this.roatedDevice(displayMarker, 10, device.course);
                } else {
                  displayMarker.latitude = device.latitude;
                  displayMarker.longitude = device.longitude;
                  displayMarker.serverTime = device.serverTime;
                  displayMarker.valid = device.valid;
                  displayMarker.attributes = device.attributes;
                  displayMarker.status = device.status; 
                  displayMarker.lastUpdate = device.attributes.lastUpdate;                 
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId
                ) {
                  this.selectedDevice.serverTime = device.serverTime;
                  this.selectedDevice.valid = device.valid;
                  this.selectedDevice.status = device.status;
                  this.selectedDevice.lastUpdate = device.attributes.lastUpdate;
                  this.selectedDevice.attributes = device.attributes;
                  if (device.attributes.statusLastUpdated) {
                    //alert(device.statusLastUpdated);
                    this.selectedDevice.statusLastUpdated = device.attributes.statusLastUpdated;
                  }
                  let odometerValueInArray = this.selectedDevice?.attributes?.totalDistance / 1000;
                  let odometerValueInArrayRound = odometerValueInArray ? Math.round(odometerValueInArray):0;
                  let odometerValueInFinalArray:any[] = odometerValueInArrayRound.toString().split('');
                  for (let i = odometerValueInFinalArray.length; i <= 7; i++) {
                    odometerValueInFinalArray.unshift(0);
                  }                  
                  this.selectedDevice['odometerArray'] = odometerValueInFinalArray;
                  this.getAddress(device);
                  this.selectedDeviceNetworkData = device.network;
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId &&
                  device.address
                ) {
                  this.selectedDevice['displayAddress'] = device.address;
                }
                if (
                  this.selectedDevice &&
                  this.selectedDevice['deviceId'] == device.deviceId &&
                  !this.allLocationTrackData.length
                ) {
                  this.secondLastLat = this.origin_lat;
                  this.secondLastLng = this.origin_lng;
                  //console.log('Is Transition');                  
                  this.transition([device.latitude, device.longitude]);
                  setTimeout(() => {
                    const element = document.querySelector(
                      `img[src~='${this.icon}']`
                    ) as HTMLImageElement;
                    if (element) {
                      element.style.transform =
                        'rotate(' + device.course + 'deg)';
                    }
                  }, 100);
                } else {
                  displayMarker.latitude = device.latitude;
                  displayMarker.longitude = device.longitude;
                }
              }
            });
            this.devicesList.forEach((displayDevice) => {
              if (displayDevice.deviceId == device.deviceId) {
                if (displayDevice) {

                  displayDevice.lastLatLng = {
                    latitude : device.latitude,
                    longitude : device.longitude}
                 
                   

                  displayDevice.speed = device.speed;
                  displayDevice.serverTime = device.serverTime;
                  displayDevice.attribute = device.attributes;
                  displayDevice.attributes = device.attributes;
                  displayDevice.valid = device.valid;
            
                  if (displayDevice.status != device.status) {
                    displayDevice.status = device.status;
                    displayDevice.deviceDetails =
                      this.getDeviceDetails(displayDevice);
                    if (
                      this.selectedDevice &&
                      this.selectedDevice['deviceId'] == device.deviceId &&
                      !this.allLocationTrackData.length
                    ) {
                      this.updateMap();
                    }
                  }
                }
              }
            });
          });
        } else if (msg.events) {
          msg.events.forEach((device) => {
            //moment(device.eventTime).format("dddd, MMMM Do YYYY, h:mm:ss a")
            if (this.eventsDataList.length > 0) {
              this.eventsDataList.unshift(device);
            }
            this.showSuccess(device.attributes.message, device.type);
            this.commonService.playAudio(1);
          });
        }
        //console.clear();
      };
    
      this.commonService.socketConnection.onopen = function (event) {
        // this.commonService.socketConnection.send(
        //   "Here's some text that the server is urgently awaiting!"
        // );
      };

      this.commonService.socketConnection.onclose = function (event) {
        // By the server/me or both?
        //console.log('Connection closed');
      };
    }
  }

  initiateCarousel() {
    $('#startCarousel').owlCarousel({
      loop: false,
      nav: true,
      autoWidth: false,
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    });
  }
  getUserDeviceDetails() {
    this.deviceFilter(this.statusFilter);
  }
  getOnlyCounts() {
    
    this.commonService
      .getRequest('devices/getDeviceStatus', [],true)
      .then((resp: any) => {
        if (resp?.status) {
          this.allCounts.ALL = resp.data.RUNNING + resp.data.IDLE +resp.data.STOPPED +resp.data.EXPIRED+resp.data.NOT_REPORTING + resp.data.NO_DATA + resp.data.TOWWED;
          this.allCounts.RUNNING = resp.data.RUNNING;
          this.allCounts.IDLE = resp.data.IDLE;
          this.allCounts.STOPPED = resp.data.STOPPED;
          this.allCounts.NOT_REPORTING = resp.data.NOT_REPORTING;
          this.allCounts.EXPIRED = resp.data.EXPIRED;
          this.allCounts.NO_DATA = resp.data.NO_DATA;
          this.allCounts.TOWWED = resp.data.TOWWED;
        }
      });
  }
  loadMore(){
    this.devicePage = this.devicePage + 1;
    this.getUserDeviceDetails();
  }
  deviceFilter(type = 'ALL',isManualFilter = false) {
//    this.getAllGroupData();
    if(this.statusFilter != type || isManualFilter) {
      this.statusFilter = type;
      this.devicePage = 0;
      this.devicesList = [];
    }
    let limit = 150;
    if (this.userData.administrator) {
      limit = 150;
    }
    else {
      limit = 9999999;
    }
    this.pageLimitForDevices = limit;
    let searchParameter = ['status=' + type, 'isAddressRequired=false','limit='+limit,'offset='+limit *this.devicePage];
    if(this.gloableSearchText) {
      searchParameter.push('deviceName='+this.gloableSearchText)
    }
    this.commonService
      .getRequest('positions', searchParameter)
      .then((resp: any) => {
        if (resp?.status) {          
          resp.data.forEach((r) => {
            if (r.status === type || type === 'ALL') {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;
              this.selectAllObjects = true;
              if(!this.devicesList.find(dItem=>dItem.deviceId == r.deviceId)) {
                //console.log(r);                
                this.devicesList.push(r);
                //this.markersList.push(r);
              }
            }
          });
          //this.markersList = this.devicesList;
          this.markersList = [];
          // let groupData = this.groupBy(this.devicesList, g => g.groupId);
          // console.log(groupData);
          this.getAllGroupData();
          // groupData.forEach(x => {
          //   console.log(x);
          // });
          Object.assign(this.markersList, this.devicesList);
          setTimeout(() => {
            this.roatedAllDevice();
          }, 100);
          if (this.markersList?.length) {
            this.lat = this.markersList[0]['latitude'];
            this.lng = this.markersList[0]['longitude'];
          }
        }
      });
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }
  getDevicePosition(device) {
    this.commonService
      .getRequest(
        'reports/route?deviceId=' + device.id + '&id=' + device.positionId + '',
        []
      )
      .then((resp: any) => {
        let devicesData = device;
        if (resp?.status) {
          devicesData['positionConfig'] = resp.data?.length ? resp.data[0] : {};
        }
        this.devicesList.push(devicesData);
        this.markersList.push(devicesData);
        // console.log('this.devicesList', this.devicesList);
        // console.log('this.markersList', this.markersList);
      });
  }

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById('closeFullMap').style.display = 'flex';
      document.getElementById('myMap').style.width = '64%';
    } else {
      document.getElementById('myMap').style.width = '100%';
      document.getElementById('myMap').style.paddingLeft = '0';
      document.getElementById('closeFullMap').style.display = 'none';
    }
    this.fullScreen = !this.fullScreen;
  }

  async onFullVehicleDetail(device, isInternalCalling) {
      if (this.inactiveTabInterval) {
        clearInterval(this.inactiveTabInterval);
      }
      this.main_col = true;      
      this.vehicle_status = false;
      this.playback = true;
      this.fulldetails = false;
      this.vehicle_fullDetail = true;
      if(device.lastLatLng && device.lastLatLng.latitude) {
        device.latitude = device.lastLatLng.latitude;
        device.longitude = device.lastLatLng.longitude;
      }
      this.selectedDevice = device;
      let odometerValueInArray = this.selectedDevice?.attributes?.totalDistance / 1000;
      let odometerValueInArrayRound = odometerValueInArray ? Math.round(odometerValueInArray):0;
      let odometerValueInFinalArray:any[] = odometerValueInArrayRound.toString().split('');
      for (let i = odometerValueInFinalArray.length; i <= 7; i++) {
        odometerValueInFinalArray.unshift(0);
      }
      this.selcetvehicle = this.selectedDevice;
      this.selectedDevice['odometerArray'] = odometerValueInFinalArray;
      
      
      //this.markersList = [];
      //this.markersList.push(this.selectedDevice);
      this.device_id = device.deviceId;//this.markersList[0].deviceId;
      this.allvehicles = true;
      this.oneVehicle = false;
      
      this.onDateSelection();
      this.selectedDevice['displayAddress'] = '';
      if (isInternalCalling) {
        this.origin_lat = this.selcetvehicle['latitude'];
        this.origin_lng = this.selcetvehicle['longitude'];
      }
      this.selectedDeviceNetworkData = device?.network;
      this.getAddress(device, true);
      if (isInternalCalling) {
        await this.getDriverDetails();
        this.rightPanelExpand = true;
      }
      if (!isInternalCalling) {
        this.rightPanelExpand = false;
      }
      //start change - code to change focus marker from one to another
      setTimeout(() => {
        this.zoom = 13.5;        
        this.initialise(isInternalCalling);
        //this.roatedAllDevice();
        this.roatedDevice(this.selectedDevice, 10);
      }, 100);
      if (this.markersList?.length) {
        if (isInternalCalling) {
          this.lat = device.latitude;
          this.lng = device.longitude;
        }        
      }
      //end change
       setTimeout(() => {
       //  this.initialise();
      //   //this.updateMap();
         //this.roatedDevice(this.selectedDevice, 10);
       }, 10);
      this.displayODa();
  }

  closeHistorytrack() {
    this.stoppagesReports = [];
    let lastSelectedDevice = this.selcetvehicle;
    this.icon = '/assets/icons/greencar.png';
    //document.getElementById('closeFullMap').style.display = 'flex';
    //document.getElementById('myMap').style.width = '66%';
    //document.getElementById('closeFullMap').style.width = '34%';
    this.bottomNav = false;
    this.back_button = false;
    this.oneVehicle = false;
    this.main_col = true;
    this.vehicle_status = false;
    this.fullwidth = true;
    this.playback = true;
    this.fulldetails = false;
    this.vehicle_fullDetail = true;
    this.allvehicles = true;

    this.allLocationTrackData = [];
    if (this.marker && this.marker !== null) {
      this.marker.setMap(null);
    }
    if (this.historyPath && this.historyPath.line) {
      this.historyPath.line.setMap(null);
    }    
    this.secondLastLat = null;
    this.secondLastLng = null;
    this.onBack(true);
    this.historyActivityList = [];
    this.selectedHistoryActivity = {};
    // setTimeout(() => {
    //   this.onFullVehicleDetail(lastSelectedDevice);
    // }, 101);
    setTimeout(()=>{
      this.closeEndMarker();
    },200)
  }

  onClickDetails() {
    this.vehicle_status = false;
    this.vehicle_fullDetail = true;
  }

  onClickStatus() {
    this.vehicle_fullDetail = false;
    this.vehicle_status = true;
  }

  onBack(ovoidExtra = false) {
    Object.assign(this.markersList, this.devicesList);
    this.allLocationTrackData = [];
    this.vehicle_status = false;
    this.playback = false;
    this.fulldetails = false;
    this.vehicle_fullDetail = false;
    this.main_col = true;

    this.allvehicles = true;
    this.oneVehicle = false;
    this.clearHistorySection();
    // if (!ovoidExtra) {
    //   setTimeout(() => {
    //     this.initiateCarousel();
    //   }, 10);
    // }
  }

  onClickHistory() {
    this.parking = false;
    this.event = false;
    this.fuelsummary = false;
    this.history = true;
  }

  onClickParking() {
    this.event = false;
    this.fuelsummary = false;
    this.history = false;
    this.parking = true;
  }

  onClickEvent() {
    this.fuelsummary = false;
    this.history = false;
    this.parking = false;
    this.event = true;
  }

  onClickFuel() {
    this.history = false;
    this.parking = false;
    this.event = false;
    this.fuelsummary = true;
    //this.mockDirections();
    this.generateChart(this.allLocationTrackData);
    // setTimeout(() => {
    //   var chart = new ApexCharts(
    //     document.querySelector('#chart'),
    //     this.options
    //   );
    //   chart.render();
    // }, 1000);
  }
  clearHistorySection() {
    this.locationData = [];
    try {
      this.marker.setMap(null);
    } catch (error) {}

    if (this.historyPath) {
      try {
        if (this.historyPath.line) this.historyPath.line.setMap(null);
      } catch (error) {}
      try {
        if (this.historyPath.end) this.historyPath.end.setMap(null);
      } catch (error) {}
      try {
        if (this.historyPath.poly) this.historyPath.poly.setMap(null);
      } catch (error) {}

      try {
        if (this.historyPath.start) this.historyPath.start.setMap(null);
      } catch (error) {}
    }

    try {
      this.mark.setMap(null);
    } catch (error) {}
  }
  zoomSingle = 16;
  onTrackVehicleHistory(data) {
    this.zoomSingle = 16;
    this.locationData = [];
    this.clearHistorySection();
    this.onPeriodChange(data.toString());
    // this.marker.reset();
    if (data == 0) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = this.getDateIntimes('end24');
      // // const yesterday = new Date(this.to);
      // // yesterday.setDate(yesterday.getDate() - 1);
      // this.from = this.getDateIntimes('start24');

      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            this.calculateDistance();
            let lengthIndex = Math.trunc(this.arrlength / 4);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });

            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    
    } else if (data == 1) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;
      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            this.calculateDistance();
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
            });

            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 2) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      //this.getFullWidth();
      //document.getElementById('closeFullMap').style.display = 'none';
      //document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      this.todayDate = new Date().toISOString();
      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 3) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = this.getDateIntimes('weekTo');
      // const thisweek = new Date(this.to);
      // thisweek.setDate(thisweek.getDate() - 7);
      // this.from = this.getDateIntimes('weekFrom');

      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 4) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.todayDate = new Date().toISOString();
      // const lastweek = new Date(this.todayDate);
      // lastweek.setDate(lastweek.getDate() - 7);
      // this.to = lastweek.toISOString();
      // const lastweeks = new Date(this.todayDate);
      // lastweeks.setDate(lastweeks.getDate() - 14);
      // this.from = lastweeks.toISOString();

      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 5) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.to = new Date().toISOString();
      // const thismonth = new Date(this.to);
      // thismonth.setDate(thismonth.getDate() - 30);
      // this.from = thismonth.toISOString();

      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 6) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;

      // this.todayDate = new Date().toISOString();
      // const lastmonth = new Date(this.todayDate);
      // lastmonth.setDate(lastmonth.getDate() - 30);
      // this.to = lastmonth.toISOString();
      // const lastmonths = new Date(this.todayDate);
      // lastmonths.setDate(lastmonths.getDate() - 60);
      // this.from = lastmonths.toISOString();

      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            this.from +
            '&to=' +
            this.to,
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            let lengthIndex = Math.trunc(this.arrlength / 2);
            this.lat = resp.data[lengthIndex]?.latitude;
            this.lng = resp.data[lengthIndex]?.longitude;
            this.origin_lat = resp.data[0]?.latitude;
            this.origin_lng = resp.data[0]?.longitude;
            this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.locationData = [];
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude]);
            });
            this.calculateDistance();
            this.mockDirections();
          } else {
            alert('No Data Found');
          }
        });
    } else if (data == 7) {
      $('#staticBackdrop').modal('show');
    }
    if(data != 7) {
      this.stoppagesReport(this.device_id,this.from,this.to);
    }
  }

  onCustomSubmit() {
    if (this.historyForm.value.timeFrom && this.historyForm.value.timeTo) {
      this.fullwidth = false;
      this.icon = '';
      this.allvehicles = false;
      this.oneVehicle = true;
      this.back_button = false;
      // this.getFullWidth();
      // document.getElementById('closeFullMap').style.display = 'none';
      // document.getElementById('myMap').style.width = '100%';
      this.bottomNav = true;
      this.from = this.historyForm.value.timeFrom;
      this.to = this.historyForm.value.timeTo;
      this.stoppagesReport(this.device_id,new Date(this.from).toISOString(),new Date(this.to).toISOString());
      this.commonService
        .getRequest(
          'reports/route?deviceId=' +
            this.device_id +
            '&from=' +
            new Date(this.from).toISOString() +
            '&to=' +
            new Date(this.to).toISOString(),
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.arrlength = resp.data.length;
            this.allLocationTrackData = resp.data;
            //Object.assign(this.historyChartData, resp.data);
            let lengthIndex = Math.trunc(this.arrlength / 2);
            // this.lat = resp.data[lengthIndex]?.latitude;
            // this.lng = resp.data[lengthIndex]?.longitude;
            // this.origin_lat = resp.data[0]?.latitude;
            // this.origin_lng = resp.data[0]?.longitude;
            // this.destination_lat = resp.data[this.arrlength - 1]?.latitude;
            // this.destination_lng = resp.data[this.arrlength - 1]?.longitude;
            this.values = lengthIndex;
            //this.zoomSingle = 15;
            this.locationData = [];
            this.bounds = new google.maps.LatLngBounds();
            resp.data.map((r) => {
              this.locationData.push([r.latitude, r.longitude, r.id]);
              this.bounds.extend(new google.maps.LatLng(r.latitude, r.longitude));
            });
            this.calculateDistance();
            this.mockDirections();
            //this.generateChart(resp.data);
          } else {
            alert('No Data Found');
          }
        });
    } else {
      this.toastr.error('Invalid Date Selected', 'error');
    }
  }

  generateChart(val: any[]) {
     
   
      for (let i = 0; i < val.length; i++) {              
        this.options.series[0].data.push(this.onSpeedgenerateBasedOnuserPreference(val[i]['speed']));
        this.options.series[1].data.push(val[i]['attributes']['fuel']?val[i]['attributes']['fuel']:0);
        this.options.xaxis.categories.push(moment(val[i]['deviceTime']).toDate().toString());
      }           
    setTimeout(() => {
      var chart = new ApexCharts(
        document.querySelector('#chart'),
        this.options
      );
      chart.render();
      chart.resetSeries();
    }, 1000);
    // var chart = new ApexCharts(
    //   document.querySelector('#chart'),
    //   this.options
    // );
    // chart.render();
    //chart.resetSeries();
    //chart.hideSeries('Speed');
    //this.resetHistoryChartSeries();
  }
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true,
    });
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    //console.log('dragEnd', m, $event);
  }

  onMapReady(map: any) {
    this.map = map;
    this.mockDirections();
    //this.polygonDrawingManager(map);
    //this.circularDrawingManager(map);
    if (this.isHistoryTab) {
      setTimeout(() => {
        this.map.fitBounds(this.bounds);
      }, 1000);
      
    }
  }

  circularDrawingManager(map: any) {
    const circularOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['circle'],
      },
      circularOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: 'circle',
    };
    //console.log('drawingManager', google);
    const drawingManager = new google.maps.drawing.DrawingManager(
      circularOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, 'circlecomplete', (event) => {
      //console.log('event', event);
      var circle;
      circle = event;
      this.circleRadius = circle.getRadius();
      this.circularForm.value.radius = this.circleRadius;
      this.circleArrayLatLng = [
        circle.getCenter().lat(),
        circle.getCenter().lng(),
      ];
      //console.log(this.circleArrayLatLng);
    });
  }
  polygonDrawingManager(map: any) {
    const managerOptions = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: 'red',
      },
      drawingMode: 'polygon',
    };
    const drawingManager = new google.maps.drawing.DrawingManager(
      managerOptions
    );
    drawingManager.setMap(map);
    google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      (polygon) => {
        const len = polygon.getPath().getLength();
        this.polyArrayLatLng = [];

        for (let i = 0; i < len; i++) {
          const vertex = polygon.getPath().getAt(i);
          const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
          this.polyArrayLatLng.push(vertexLatLng);
        }
        this.polyArrayLatLng.push(this.polyArrayLatLng[0]);
        //console.log(this.polyArrayLatLng);
      }
    );
  }
  mockDirections() {
    this.zoom = 5;//13.2; --change zoom level to display all markers on one go.
    
    this.locationArray = this.locationData.map(
      (l) => new google.maps.LatLng(l[0], l[1])
    );

    //#1a73e8
    //strokeColor: '#FF0000',

    this.historyPath.line = new google.maps.Polyline({
      strokeOpacity: 0.5,
      strokeWeight: 2.5, 
      //strokeColor: '#7c7d7d',     
      path: [],
      map: this.map,
      icons: [{
        icon: {
          strokeColor: '#056608',
          fillColor: '#056608',
          strokeOpacity: 0.8,
          strokeWeight: parseInt('2px'),
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        },
        offset: '0',
        repeat: '100px'
      }
        // ,
        // {
        //   icon: {
        //     strokeColor: '#FF0000 ',
        //     fillColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: parseInt(this.thicknessVal),
        //     path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW
        //   },
        //   offset: '0',
        //   repeat: '100px'
        // }
      ],
    });    
    this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // if (this.selectedHistoryActivity && this.selectedHistoryActivity.startPositionId) {
    //   this.locationData.forEach((l, i) => {
    //     if (l[2] >= this.selectedHistoryActivity.startPositionId && l[2] <= this.selectedHistoryActivity.endPositionId) {
    //       this.historyPath.line.setOptions({ 
    //         strokeColor: '#0000FF',
    //         strokeOpacity: 1
    //       });
    //       //this.historyPath.line['strokeColor'] = '#1a73e8';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#1a73e8';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     } else {
    //       // this.historyPath.line.setOptions({ 
    //       //   strokeColor: '#7c7d7d',
    //       //   strokeOpacity: 0.5
    //       // });
    //       //this.historyPath.line['strokeColor'] = '#7c7d7d';
    //       //this.historyPath.line.icons[0].icon.strokeColor = '#056608';
    //       this.historyPath.line.getPath().push(this.locationArray[i]);
    //     }
    //   });
    // } else {
    //   this.locationArray.forEach((l) => this.historyPath.line.getPath().push(l));
    // }
    this.historyPath.start = new google.maps.LatLng(
      this.origin_lat,
      this.origin_lng
    );
    const end = new google.maps.LatLng(
      this.destination_lat,
      this.destination_lng
    );
    
    this.historyPath.end = new google.maps.Marker({
      position: end,
      map: this.map,
    });
    // setTimeout(() => {
    //   this.map.fitBounds(this.bounds);
    // }, 500);
    
    this.initRoute();
  }
  calculateDistance() {
    this.locationTotalDistance = 0
    this.allLocationTrackData.map(loc=>{
      this.locationTotalDistance =
      this.locationTotalDistance +
      (loc &&
      loc.attributes &&
      loc.attributes.distance
        ? loc.attributes.distance
        : 0)

        loc.displayDistance = this.locationTotalDistance;
    })
  }
  initRoute() {
    try {
      this.marker.setMap(null);
    } catch (error) {}
    try {
      const route = this.historyPath.line.getPath().getArray();
      const options: TravelMarkerOptions = {
        map: this.map,
        speed: 50,
        interval: 10,
        speedMultiplier: this.speedMultiplier,
        markerType: 'overlay',
        overlayOptions: {
          offsetX: 0,
          offsetY: 0,
          offsetAngle: 0,
          imageUrl:
            this.selcetvehicle &&
            this.selectedDevice['deviceDetails'] &&
            this.selectedDevice['deviceDetails'].runningVehicleUrl
              ? this.selectedDevice['deviceDetails'].runningVehicleUrl
              : '/assets/icons/devices/car/running-map.png',
          imageWidth: 20,
          imageHeight: 38,
        },
      };
      this.marker = new TravelMarker(options);
      if (route && route.length) {
        this.marker.addLocation(route);
      }
      let addressLocationIndex = 0;
      this.historyPath['displayAddress'] = '';
      this.marker.event.onEvent((event: EventType, data: TravelData) => {
        //console.log(data.index, this.allLocationTrackData[this.values]);
        this.values = data.index;
        if (!this.values) {
          this.selectedDeviceDistance = 0;
        }
        if (data.index == addressLocationIndex) {
          if (this.allLocationTrackData[this.values]) {
            addressLocationIndex += 10;
            this.getDeviceAddress(
              this.allLocationTrackData[this.values].latitude,
              this.allLocationTrackData[this.values].longitude,
              true
            );            
          }
        }        
        this.selectedDeviceDistance =
          this.selectedDeviceDistance +
          (this.allLocationTrackData[this.values] &&
          this.allLocationTrackData[this.values].attributes &&
          this.allLocationTrackData[this.values].attributes.distance
            ? this.allLocationTrackData[this.values].attributes.distance
            : 0); 
      });
      setTimeout(() => this.marker.pause(), 2000);
    } catch (error) {}
  }
  index = 0;
  transition(result) {
    this.index = 0;
    this.destination_lat = (result[0] - this.origin_lat) / this.numbDelta;
    this.destination_lng = (result[1] - this.origin_lng) / this.numbDelta;

    this.polyLineArray = [{ lat: this.origin_lat, lng: this.origin_lng }];

    // this.polyLineArray.forEach((l) => this.historyPath.poly.getPath().push(l));

    this.moveMarker();
  }
  poly: any;
  moveMarker() {
    this.origin_lat += this.destination_lat ? this.destination_lat: 0;
    this.origin_lng += this.destination_lng ? this.destination_lng: 0;
    if (!this.origin_lat && !this.origin_lng) {
      return;
    }
    var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
    this.polyLineArray.push({ lat: this.origin_lat, lng: this.origin_lng });
    this.historyPath.poly = new google.maps.Polyline({
      strokeColor: '#FF0000',
      strokeOpacity: 0.00001,
      strokeWeight: 0,
      path: this.polyLineArray,
      map: this.map,
    });
    if (this.mark && this.mark != null) {
      this.mark.setPosition(latlng);
    }
    //this.selectedDevice.setPosition(latlng);
    this.historyPath.poly.setMap(this.map);
    try {
      if (latlng) {
        this.map.setCenter(latlng);
      }
    } catch (error) {}
    if (this.index != this.numbDelta) {
      this.index++;
      setTimeout(() => {
        if (!this.allLocationTrackData.length) {
          this.moveMarker();
        }
      }, 10);
    }
  }
  onSlideEvent(e) {
    //console.log(e);
    this.values = e.value;
    // this.locationArray = this.locationData[this.values];
    // this.marker.addLocation(this.locationArray);
  }

  speedCount = 1;
  on1xSpeed() {
    this.speedCount++;
    if(this.speedCount > 5) {
      this.speedCount = 1;
    }
    this.speedMultiplier = this.speedCount * 3;
    this.marker.setSpeedMultiplier(this.speedMultiplier);
    //console.log('ok');
  }

  onReset() {
    //console.log('ok');
    this.marker.reset();
  }

  onPlayPause() {
    if (this.playpause) {
      this.marker.pause();
    } else {
      this.marker.play();
    }
    this.playpause = !this.playpause;
  }
  mark: any;
  secondLastLat: any;
  secondLastLng: any;
  initialise(isCalledFromObjectClick:boolean = false) {
    var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
      var secLast = new google.maps.LatLng(
      this.secondLastLat ? this.secondLastLat : this.origin_lat,
      this.secondLastLng ? this.secondLastLng : this.origin_lng
    );
    // const myRunningOptions = {
    //   zoom: 18,
    //   center: latlng,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    //   disableDefaultUI: false,
    // };
    if (isCalledFromObjectClick) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}    
      let markerIndex = this.markersList.findIndex(x => x.deviceId == this.selectedDevice.deviceId);
      this.markersList[markerIndex].isHide = true;
    }
    this.mark = new google.maps.Marker({
      position: latlng,
      map: this.map,
      title: this.selectedDevice['name'],
      label: { fontSize: '10px',color: '#fbfbfc', text: this.selectedDevice['name']},
    });
    if (isCalledFromObjectClick) {
      this.mark.addListener("click", () => {
        infowindow.open({
          anchor: this.mark,
          map: this.map
        });  
        setTimeout(() => {
          $('#addGeofenceModal').on('click', () => {
            this.addGeofence();
          });
          $('#addLandmarkModal').on('click', () => {
            this.addLandmark();
          });  
        }, 100);   
         
      });
      const contentString = this.createInforWindowContent();
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
        ariaLabel: "Uluru",
      });
    }
    //this.mark = new google.maps.marker(this.markersList[markerIndex]);
    this.historyPath.poly = new google.maps.Polyline({
      strokeColor: '#FF0000',
      strokeOpacity: 0.00001,
      strokeWeight: 0,
      path: this.polyLineArray,
      map: this.map,
      
    });
    this.icon = this.selectedDevice['deviceDetails'].vehicleMapImg.url;
    this.mark.setIcon({
      url: this.icon,
      scaledSize: { height: 38, width: 20 },
      size: new google.maps.Size(60, 60),
      origin: new google.maps.Point(-30, 0),
      anchor: new google.maps.Point(38, 20),
      scale: 2
    });
    
    const initialiRotation = google.maps.geometry.spherical.computeHeading(
      secLast,
      latlng
    );
    this.zoom = 15.2;
    setTimeout(() => {
      const element = document.querySelector(
        `img[src~='${this.icon}']`
      ) as HTMLImageElement;
      if (element) {
        element.style.transform = 'rotate(' + initialiRotation + 'deg)';
      }
    }, 2000);
  }
  
  closeEndMarker() {
    try {
      if(this.historyPath) {
        this.historyPath.end.setMap(null);
      }
    } catch (error) {
      
    }
  }
  createInforWindowContent() {
    let content = `<div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Object')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice.name}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Address')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice.address ? this.selectedDevice.address : 'NA' }</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Position')} :</div>
    <div class="col-8 ps-0">
      <a href="https://maps.google.com/maps?q=${this.selectedDevice?.latitude},${this.selectedDevice.longitude}&amp;t=m" 
      target="_blank">${this.selectedDevice?.latitude} °, ${this.selectedDevice.longitude} °</a></div>
  </div>
  
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Altitude')} :</div>
    <div class="col-8 ps-0">${ this.selectedDevice?.altitude } m</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Angle')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.course} °</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Speed')} :</div>
    <div class="col-8 ps-0">${this.decimalPipe.transform(this.selectedDevice?.speed,'1.0-3')  } ${this.translate.instant('kmph')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Time')} :</div>
    <div class="col-8 ps-0">${this.datePipe.transform(this.selectedDevice?.lastUpdate,'dd-MM-yyyy hh:mm:ss')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Odometer')} :</div>
    <div class="col-8 ps-0">${this.meterTokm.transform(this.selectedDevice?.attributes?.totalDistance)} ${this.translate.instant('km')}</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Engine hours')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.hours?this.selectedDevice?.hours :'0'} h</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Voltage')} :</div>
    <div class="col-8 ps-0">${this.decimalPipe.transform(this.selectedDevice?.attributes?.power, '1.0-2')} v</div>
  </div>
  <div class="row w-100">
    <div class="col-4 fw-bold">${this.translate.instant('Device battery')} :</div>
    <div class="col-8 ps-0">${this.selectedDevice?.attributes?.batteryLevel > 100 ? 100 : (this.selectedDevice?.attributes?.batteryLevel??0)}%</div>
  </div>
  <div class="row w-100">
                  <div class="col-12 mt-2 ms-1 mb-2">
                    <button id="addGeofenceModal" type="button" class="btn btn-outline-secondary">Add Geofence</button>
                    <button id="addLandmarkModal" type="button" class="btn btn-outline-secondary ms-2">Add Landmark</button>
                  </div>
                </div>`;
  return content;
  }
  onCustomMarkerClick() {
    // alert('clicked');
    // alert(this.previousInfoWindow);
  }
  updateMap() {
    this.icon = this.selectedDevice['deviceDetails'].vehicleMapImg.url;
    // this.icon['scaledSize'].width = 38;
    // this.mark.setIcon({
    //   url: this.icon,
    //   scaledSize: { height: 38, width: 20 },

    //   size: new google.maps.Size(60, 60),
    //   origin: new google.maps.Point(-10, 0),
    //   anchor: new google.maps.Point(38, 20),
    //   scale: 2,
    // });
    //console.log(this.icon);
  }
  // END GOOGLE MAP

  ngAfterViewInit(): void {
    this.options = {
      series: [
        {
          name: 'Speed',
          data: [],
        },
        {
          name: 'Fuel',
          data: [],
        },
      ],
      chart: {
        height: 300,
        type: 'area',
        events: {
          dataPointMouseEnter: (event) => {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        theme: 'dark',
        // x: {
        //   format: 'dd/MM/yy HH:mm',
        // },
      },
    };
    //var chart = new ApexCharts(document.querySelector("#chart"), this.options);
    //chart.render();
    
  }

  deviceSubmit() {
    this.errors = {};
    if (this.addDevice.name && this.addDevice.identifier) {
      //console.log('this.addDevice', this.addDevice);
      let attributes = {};
      // decoder.timezone: "24"
      // deviceInactivityPeriod: 26
      // deviceInactivityStart: 25
      // speedLimit: 23

      if (this.addDevice?.attributes?.length) {
        this.addDevice.attributes.forEach((a) => {
          switch (a.attribute) {
            case 'speedLimit':
              attributes['speedLimit'] = a.value;
              break;
            case 'timezone':
              attributes['decoder.timezone'] = a.value;
              break;
            case 'deviceInactivityStart':
              attributes['deviceInactivityStart'] = a.value;
              break;
            case 'deviceInactivityPeriod':
              attributes['deviceInactivityPeriod'] = a.value;
              break;
          }
        });
      }

      if (this.addDevice?.id) {
        this.commonService
          .putRequest('devices/' + this.addDevice.id, {
            id: this.addDevice.id,
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: this.addDevice.model,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            // attributes: attributes,
          })
          .then(
            (resp: any) => {
              this.getUserDeviceDetails();
              $('#btnclose').click();
              Swal.fire({
                icon: 'success',
                title: 'Your device has been Updated Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
            },
            (err) => {
              Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.commonService
          .postRequest('devices', {
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: this.addDevice.model,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            attributes: attributes,
          })
          .then(
            (resp: any) => {
              this.getUserDeviceDetails();
              $('#btnclose').click();
              Swal.fire({
                icon: 'success',
                title: 'Your device has been Submitted Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
            },
            (err) => {
              //console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      }
    } else {
      if (!this.addDevice.name) {
        this.errors['Devicename'] = 'This field is required';
      }
      if (!this.addDevice.identifier) {
        this.errors['Deviceidentifier'] = 'This field is required';
      }
    }
  }

  deleteDevice(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          deleteuser: true,
          userId: id,
        };
        this.commonService
          .deleteRequest('devices/' + id)
          .then((resp) => {
            this.getUserDeviceDetails();
          })
          .catch((e) => {
            this.getUserDeviceDetails();
          });
        Swal.fire('Deleted!', 'Device has been deleted.', 'success');
      }
    });
  }

  addDevicepopup() {
    this.addDevice = {
      id: null,
      name: '',
      identifier: '',
      groupId: '',
      phone: '',
      model: '',
      contact: '',
      category: 'Default',
      disabled: false,
      attributes: [],
    };
  }

  editDevice(device) {
    //console.log('device', device);
    let attributes = [];

    let attrLength = Object.keys(device.attributes);
    if (attrLength?.length) {
      attrLength.forEach((a) => {
        let dataSet = {
          attribute: '',
          type: '',
          value: null,
        };
        switch (a) {
          case 'speedLimit':
            if (device.attributes[a]) {
              dataSet.attribute = 'speedLimit';
              dataSet.type = 'number';
              dataSet.value = device.attributes[a];
            }
            break;
          case 'decoder.timezone':
            dataSet.attribute = 'timezone';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityStart':
            dataSet.attribute = 'deviceInactivityStart';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityPeriod':
            dataSet.attribute = 'deviceInactivityPeriod';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
        }
        //console.log('dataSet', dataSet);
        if (dataSet.attribute) {
          attributes.push(dataSet);
        }
      });
    }

    this.addDevice = {
      id: device.deviceId,
      name: device.name,
      identifier: device.uniqueId,
      groupId: device.groupId,
      phone: device.phone,
      model: device.model,
      contact: device.contact,
      category: device.category,
      disabled: device.disabled,
      attributes: attributes,
    };
  }

  checkboxEvent(e: any) {
    this.addDevice.disabled = e.checked;
  }

  addAttri(template: TemplateRef<any>) {
    this.addAttr = {
      attribute: '',
      type: '',
      value: null,
    };
    this.modalRef = this.modalService.show(template);
  }

  deleteAttr(key) {
    this.addDevice.attributes = this.addDevice.attributes.filter(
      (a, k) => k !== key
    );
  }

  changeAttrEvent(e) {
    let findAttrName = this.attrList.find((a) => a.id === e.value);
    if (findAttrName) {
      this.addAttr.type = findAttrName.type;
    } else {
      this.addAttr.type = '';
    }
  }

  addAttrbute() {
    if (this.addAttr.attribute) {
      let find = this.addDevice.attributes.find(
        (a) => a.attribute === this.addAttr.attribute
      );
      if (!find) {
        this.addDevice.attributes.push(this.addAttr);
      }
      this.modalRef?.hide();
    }
  }

  getDeviceDetails(device: any) {
    let details: any = {
      vehicleImg: '/assets/icons/devices/car/no-data.png',
      vehicleMapImg: '/assets/icons/devices/car/no-data-map.png',
      dotImg: '/assets/icons/no_data.png',
      msg: 'No Data',
      color: '#646464',
    };

    let folderName = 'devices/car';
    if (device.category === 'boat') {
      folderName = 'devices/boat';
    } else if (device.category === 'bus') {
      folderName = 'devices/bus';
    } else if (device.category === 'motorcycle') {
      folderName = 'devices/motorcycle';
    } else if (device.category === 'tractor') {
      folderName = 'devices/tractor';
    } else if (device.category === 'truck') {
      folderName = 'devices/truck';
    } else if (device.category === 'scooter') {
      folderName = 'devices/scooter';
    }

    switch (device.status) {
      case 'STOPPED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Stopped';
        details.color = '#fd1b41';
        break;
      case 'TOWWED':
        details.vehicleImg = '/assets/icons/' + folderName + '/stop.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/stop-map.png';
        details.dotImg = '/assets/icons/stoped.png';
        details.msg = 'Towwed';
        details.color = '#fd1b41';
        break;
      case 'RUNNING':
        details.vehicleImg = '/assets/icons/' + folderName + '/running.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/running-map.png';
        details.dotImg = '/assets/icons/dotgreen.png';
        details.msg = 'Running';
        details.color = '#17A573';
        break;
      case 'IDLE':
        details.vehicleImg = '/assets/icons/' + folderName + '/idle.png';
        details.vehicleMapImg = '/assets/icons/' + folderName + '/idle-map.png';
        details.dotImg = '/assets/icons/idle_dot.png';
        details.msg = 'Idle';
        details.color = '#E8AD21';
        break;
      case 'NOT_REPORTING':
        details.vehicleImg =
          '/assets/icons/' + folderName + '/not-reporting.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/not-reporting-map.png';
        details.dotImg = '/assets/icons/not_reporting_dot.png';
        details.msg = 'No Reporting';
        details.color = '#529BA8';
        break;
      case 'EXPIRED':
        details.vehicleImg = '/assets/icons/' + folderName + '/expired.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/expired-map.png';
        details.dotImg = '/assets/icons/expired_dot.png';
        details.msg = 'Expired';
        details.color = '#EB563E';
        break;
      default:
        details.vehicleImg = '/assets/icons/' + folderName + '/no-data.png';
        details.vehicleMapImg =
          '/assets/icons/' + folderName + '/no-data-map.png';
        details.dotImg = '/assets/icons/no_data.png';
        details.msg = 'No Data';
        details.color = '#646464';
        break;
    }
    details.runningVehicleUrl =
      '/assets/icons/' + folderName + '/running-map.png';
    details.vehicleMapImg = {
      url: details.vehicleMapImg + '#' + device.deviceId,
      scaledSize: { height: 38, width: 20 },
      size: new google.maps.Size(60, 60),
      origin: new google.maps.Point(-30, 0),
      anchor: new google.maps.Point(38, 20),
      scale: 2,
    };
    return details;
  }
  movingReport(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/trips?' + params.join('&'), [])
        .then((resp: any) => {
          //console.log('-----------------', resp);

          if (resp?.status) {
            this.vehicleDetailsReport.moving = resp.data;
          }
        })
        .catch((e) => {});
    }
  }
  onDistanceCLick(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/stops?' + params.join('&'), [])
        .then((resp: any) => {
          //console.log('-----------------', resp);

          if (resp?.status) {
            this.vehicleDetailsReport.parked = resp.data;
          }
        })
        .finally(() => {});
    }
  }
  getAddress(data, updateToObj=true) {
    this.getDeviceAddress(data.latitude,data.longitude,false,updateToObj,data)
  }
  getDeviceAddress(longitude, latitude, isHistory = false,updateToObj=false,data={}) {
    let params = [
      'latitude=' + latitude,
      'longitude=' + longitude,
      'maxDistance=1000',
    ];
    if (latitude && longitude) {
      this.commonService
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => { 
          if(updateToObj){
            if (resp.data && resp.data.address) { 

              data['address'] = resp.data.address;
            }
          } else {
            if (resp.data && resp.data.address) {
              this.selectedDevice['displayAddress'] = resp.data.address;
            }
            if (isHistory) {
              this.historyPath['displayAddress'] = resp.data.address;
            }
          }
          
        })
        .finally(() => {});
    }
  }
  stoppagesReports  = [];
  stoppagesReport(Device_ID, fromdate, todate) {
    this.stoppagesReports  = [];
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate,
      'to=' + todate,
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/stops?' + params.join('&'), [])
        .then((resp: any) => {
          //console.log('-----------------', resp);

          if (resp?.status) {
            this.stoppagesReports = resp.data;
            this.stoppagesReports.forEach(data=>{
              this.getDeviceAddress(data.latitude,data.longitude,false,true,data)
            })
          }
        })
        .finally(() => {});
    }
  }
  onDailyCLick(Device_ID, fromdate, todate) {
    let params = [
      'deviceId=' + Device_ID,
      'from=' + fromdate + 'T00:00:01.477Z',
      'to=' + todate + 'T23:59:01.477Z',
      'page=1',
      'start=0',
      'limit=' + 50,
    ];

    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/events?' + params.join('&'), [])
        .then((resp: any) => {
          //console.log('-----------------', resp);
          if (resp?.status) {
            this.vehicleDetailsReport.alert = resp.data;
          }
        })
        .catch((e) => {});
    }
  }
  onDateSelection() {
    let selectedDate = this.selectedDate;
    this.vehicleDetailsReport = {
      alert: [],
      moving: [],
      parked: [],
      summary: {},
    };
    this.movingReport(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.onDistanceCLick(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.onDailyCLick(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
    this.dailySumary(
      this.selectedDevice['deviceId'],
      selectedDate,
      selectedDate
    );
  }
  dailySumary(Device_ID, fromdate, todate) {
    let now = moment();
    let params = [
      'deviceId=' + Device_ID,
      'from=' + new Date(now.startOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()).toISOString(),
      'to=' + new Date(now.endOf('day').format("yyyy-MM-DD hh:mm:ss A").toString()).toISOString(),
      'page=1',
      'start=0',
      'limit=' + 10,
      'daily=' + false,
    ];
    if (fromdate && todate && Device_ID) {
      this.commonService
        .getRequest('reports/summary?' + params.join('&'), [])
        .then((resp: any) => {
          if (resp?.status) {
            this.vehicleDetailsReport.summary =
              resp.data && resp.data.length ? resp.data[0] : {};
            let timeDiff =
              new Date(this.vehicleDetailsReport.summary['endTime']).getTime() -
              new Date(
                this.vehicleDetailsReport.summary['startTime']
              ).getTime();
            // console.log('timeDiff', timeDiff);
            // console.log(
            //   timeDiff - this.vehicleDetailsReport.summary['engineHours']
            // );
            this.vehicleDetailsReport.summary['parkedTime'] =
              timeDiff - this.vehicleDetailsReport.summary['engineHours'];
          } else {
            this.vehicleDetailsReport.summary['parkedTime'] = 0;
            this.vehicleDetailsReport.summary['distance'] = 0;
            this.vehicleDetailsReport.summary['engineHours'] = 0;
          }
        });
    }
  }
  
  geofenceToggle() {
    this.isGeofenceVisiable = !this.isGeofenceVisiable;
    if (this.isGeofenceVisiable) {
      this.zoom = 8;
      this.lat = 0;
      this.lng = 0;
      const geo = this.geofenceList.length ? this.geofenceList[0] : false;
      if (geo && geo.geofence) {
        if (geo.geofence.type === 'Feature') {
          this.lat = geo.geofence.geometry.coordinates[0].lat;
          this.lng = geo.geofence.geometry.coordinates[0].lng;
        } else {
          this.lat = geo.geofence.coordinates[0];
          this.lng = geo.geofence.coordinates[1];
        }
      }
    }
  }
  getAllGeofence() {
    this.circular = true;
    this.polygons = true;
    this.zoom = 6;
    this.lat = 20.5937;
    this.lng = 78.9629;

    this.commonService
      .getRequest('geofences?all=true', [])
      .then((resp: any) => {
        if (resp?.status) {
          this.geofenceList = [];
          this.landmarkList = [];
          this.polygonList = [];
          resp.data.forEach(async (r, k) => {
            r['isSelected'] = true;
            this.selectAllGeofense = true;
            this.geofenceList.push(r);
            await this.parseGeoJson(r, k);
            let arr = [];

            if (this.geofenceList[k]?.geofence?.coordinates?.length) {
              for (
                let j = 0;
                j < this.geofenceList[k].geofence.coordinates.length;
                j++
              ) {
                arr.push({
                  lat: this.geofenceList[k].geofence.coordinates[j].lat,
                  lng: this.geofenceList[k].geofence.coordinates[j].long,
                });
              }
            }
            let data = {
              coordinates: arr,
              type: r?.geofence?.type,
              show: true,
            };
            this.polygonList.push(data);
          });

          setTimeout(() => {            
            this.markerGeofenceList = [];
            Object.assign(this.markerGeofenceList, this.geofenceList);
          }, 2000);
          //console.log(this.polygonList);
        }
      });
  }

  parseGeoJson(geofence, key) {
    return new Promise((resolve) => {
      if (geofence.area.indexOf('CIRCLE') > -1) {
        const coordinates = geofence.area
          .replace(/CIRCLE|\(|\)|,/g, ' ')
          .trim()
          .split(/ +/);

        this.geofenceList[key]['geofence'] = {
          type: 'Point',
          coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
          radius: parseFloat(coordinates[2]),
        };
        this.geofenceList[key]['show'] = true;
        resolve(true);
      } else if (geofence.area.indexOf('POLYGON') > -1) {
        const coordinates = geofence.area
          .replace(/POLYGON|\((|\))|,/g, ' ')
          .trim()
          .split(/ +/);

        let dataSet = [];
        let arr = [];
        coordinates.forEach((c, k) => {
          if (k % 2 === 1) {
            dataSet.push({ lat: parseFloat(arr[0]), lng: parseFloat(c) });
            arr = [];
          } else {
            arr.push(c);
          }
        });

        this.geofenceList[key]['geofence'] = {
          id: geofence.id,
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: dataSet,
          },
          properties: { name: geofence.name },
        };
        this.geofenceList[key]['show'] = true;

        resolve(true);
      } else {
        resolve(true);
      }
    });
  }
  // getAllGeofence() {
  //   this.isGeofenceVisiable = !this.isGeofenceVisiable;
  //   if(this.isGeofenceVisiable && !this.geofenceList.length) {
  //     this.commonService
  //     .getRequest('geofences?all=true', [])
  //     .then((resp: any) => {
  //       if (resp?.status) {
  //         this.geofenceList = [];
  //         resp.data.forEach(async (r, k) => {
  //           this.geofenceList.push(r);
  //           await this.parseGeoJson(r, k);
  //           let arr = [];
  //           if (this.geofenceList[k]?.geofence?.coordinates?.length) {
  //             for (
  //               let j = 0;
  //               j < this.geofenceList[k].geofence.coordinates.length;
  //               j++
  //             ) {
  //               arr.push({
  //                 lat: this.geofenceList[k].geofence.coordinates[j].lat,
  //                 lng: this.geofenceList[k].geofence.coordinates[j].long,
  //               });
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }

  // }

  // parseGeoJson(geofence, key) {
  //   return new Promise((resolve) => {
  //     if (geofence.area.indexOf('CIRCLE') > -1) {
  //       const coordinates = geofence.area
  //         .replace(/CIRCLE|\(|\)|,/g, ' ')
  //         .trim()
  //         .split(/ +/);

  //       this.geofenceList[key]['geofence'] = {
  //         type: 'Point',
  //         coordinates: [parseFloat(coordinates[0]), parseFloat(coordinates[1])],
  //         radius: parseFloat(coordinates[2]),
  //       };
  //       this.geofenceList[key]['show'] = true;
  //       resolve(true);
  //     } else if (geofence.area.indexOf('POLYGON') > -1) {
  //       const coordinates = geofence.area
  //         .replace(/POLYGON|\((|\))|,/g, ' ')
  //         .trim()
  //         .split(/ +/);

  //       let dataSet = [];
  //       let arr = [];
  //       coordinates.forEach((c, k) => {
  //         if (k % 2 === 1) {
  //           dataSet.push({ lat: parseFloat(arr[0]), lng: parseFloat(c) });
  //           arr = [];
  //         } else {
  //           arr.push(c);
  //         }
  //       });

  //       this.geofenceList[key]['geofence'] = {
  //         id: geofence.id,
  //         type: 'Feature',
  //         geometry: {
  //           type: 'Polygon',
  //           coordinates: dataSet,
  //         },
  //         properties: { name: geofence.name },
  //       };
  //       this.geofenceList[key]['show'] = true;

  //       resolve(true);
  //     } else {
  //       resolve(true);
  //     }
  //   });
  // }
  clickedMarker(infowindow) {
    //console.log('marker clicked');
    this.previousInfoWindow = infowindow;
    // if (this.previousInfoWindow) {
    //     this.previousInfoWindow.close();
    // }
    // this.previousInfoWindow = infowindow;
  }
  animatedMove(marker, current, moveto) {
    let deltalat = (moveto.latitude - current.latitude) / 500;
    let deltalng = (moveto.longitude - current.longitude) / 500;
    let i = 0;
    function moveItSelf(){
      setTimeout( ()=> {
        let lat = marker.latitude;
        let lng = marker.longitude;
        lat += deltalat;
        lng += deltalng;
        //let latlng = new google.maps.LatLng(lat, lng);
        marker.latitude = lat;
        marker.longitude = lng;
        if(i<500 && this && !this.oneVehicle) {
          i++;
          moveItSelf();
        } else {
          marker.latitude = moveto.latitude;
          marker.longitude = moveto.longitude;
        }
      }, 10);
    }
    moveItSelf();
    
  }
  isDataPointsVisiable = false;
  dataPointsToggle() {
    this.isDataPointsVisiable = !this.isDataPointsVisiable;
  }
  onScroll(event: any) {
    //console.log(event.target.offsetHeight + event.target.scrollTop,event.target.scrollHeight - 1)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      if (this.pageLimitForDevices != 9999999) {
        this.loadMore();
      }
    }
  }
  onHistoryActivityScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      //this.loadMore();
    }
  }
  selectAllGeofenseChange(type) {
    this.selectDeselectGeofense(this.selectAllGeofense, type);
    if (type == 'geofence') {
      this.circularGeofence = this.selectAllGeofense;
      this.polygonGeofence = this.selectAllGeofense;
    } else if (type == 'landmark') {
      this.circularLandmarkGeofence = this.selectAllGeofense;
    }    
  }
  selectAllObjectChange() {
    if (this.mark && this.mark.setMap) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}
    }
    this.selectDeselectDevices(this.selectAllObjects);
    if (this.selectAllObjects) {
      //this.markersList = this.devicesList;
      Object.assign(this.markersList, this.devicesList);
      this.groupListData.forEach(ele => {
        ele.isSelected = true;
      })
    } else {
      this.markersList = [];
      this.groupListData.forEach(ele => {
        ele.isSelected = false;
      })
    }
    
  }
  selectDeselectDevices(val: boolean) {
    if (this.mark && this.mark.setMap) {
      try {
        this.mark.setMap(null);
      } catch(e: any) {}
    }
    this.devicesList.forEach(ele => {
      ele.isSelected = val;
    })
  }
  selectDeselectGeofense(val: boolean, type: any) {
    this.geofenceList.forEach(ele => {
      if (type == 'geofence' && !ele.attributes.type) {
        ele.isSelected = val;
      } 
      if (type == 'landmark' && ele.attributes.type) {
        ele.isSelected = val;
      }
    })
  }
  onObjectSelectionChange(val: any, event: any) {
    event.stopPropagation();
    if (val.isSelected) {
      this.markersList.push(val);
    } else {
      let objIndex = this.markersList.findIndex(x => x.deviceId == val.deviceId);
      if (objIndex >= 0) {
        this.markersList.splice(objIndex, 1);
      }
    }
    
  }
  changeMapZoom(e: any) {
    if (e == 'p') {
      this.zoom += 1;
      if (this.zoom == 22) {
        this.zoom = 22;
      }
    } else if (e == 'm') {
      this.zoom -= 1;
      // if (this.zoom == 0) {
      //   this.zoom = 0;
      // }
    } else {
      this.zoom = e;
    }
  }
  changeMapZoomForHistory(e: any) {
    if (e == 'p') {
      this.zoomSingle += 1;
      if (this.zoomSingle == 22) {
        this.zoomSingle = 22;
      }
    } else if (e == 'm') {
      this.zoomSingle -= 1;
      if (this.zoomSingle == 0) {
        this.zoomSingle = 0;
      }
    } else {
      this.zoomSingle = e;
    }
  }
  collapseDeviceList() {
    this.isDeviceListVisible = false;
  }
  expandDeviceList() {
    this.isDeviceListVisible = true;
  }
  tabChange(val: any) {
    clearInterval(this.inactiveTabInterval);
    this.isObjectsTab = false;
    this.isEventsTab = false;
    this.isHistoryTab = false;
    this.isGeofenceTab = false;
    this.isLandmarkTab = false;
    this.minimumClusterSize = 2;
    if (val == 'object') {
      this.isObjectsTab = true;
      this.hideTracking({});
    } else if (val == 'event') {
      this.isEventsTab = true;
      this.minimumClusterSize = 999999999999;
      this.hideTracking({});
      this.loadNotification();
    } else if (val == 'history') {
      this.isHistoryTab = true;
      this.selectedDevice = {};
      this.rightPanelExpand = false;
      this.getAllObjectNames();
      this.setHistoryDate("0");
    } else if (val == 'Geofence') {
      this.isGeofenceTab = true;
      this.hideTracking({});
      this.rightPanelExpand = false;
      this.selectedDevice = {};      
      //this.onFullVehicleDetail(this.selectedDevice, false);
    } else if (val == 'Landmark') {
      this.isLandmarkTab = true;
      this.hideTracking({});
      this.rightPanelExpand = false;
      this.selectedDevice = {};  
    }
  }
  onEventsScroll(event: any) {
    this.loadMoreNotification(event);
  }
  loadMoreNotification(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 3) {
      this.loadNotification();
    }
    event.stopPropagation();
  }
  loadNotification() {
    let userId = '';
    if(this.userData) {
      userId = this.userData.id;
    } else {
      userId = '';
    }
 
    this.commonService
    .getRequest('reports/events/'+ userId +'?limit=25&offset='+this.eventPage, [])
  // https://app.multitrack.in:8082/api/reports/events/5?limit=10&offset=0
    .then((resp: any) => {
      this.eventPage++;
      //console.log('-----------------', resp);
      if (resp?.status) {
        this.eventsDataList = this.eventsDataList.concat(resp.data);
        //console.log(this.eventsDataList);
      }
    });
  }
  eventSearch() {   
  }
  getAllObjectNames() {
    this.commonService
      .getRequest('devices', [],true)
      .then((resp: any) => {
        //console.log(resp);
        if (resp?.status) {
          this.objectNameList = resp.data;
          
          setTimeout(() => {
            this.historyForm.patchValue({
              object: this.objectNameList[0].name
            });
          }, 10);
          
        }
      });
  }
  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.objectNameList.filter(street => this._normalizeValue(street.name).includes(filterValue));
  }

  private _normalizeValue(value: any): string {
    return value && value != "" ? value.toLowerCase().replace(/\s/g, ''): "";
  }

  async showTracking(val: any) {
    if (this.historySelectedDevice) {
      this.hideTracking({});
    }
    //await this.closeHistorytrack();
    //this.device_id = this.historyForm.value.object;
    // this.selectedDevice = this.objectNameList.filter(x => x.id == this.historyForm.value.object)[0];
    // this.selectedDevice['deviceId'] = this.selectedDevice.id;
    await this.getSingleDeviceInformation();    
    this.onFullVehicleDetail(this.historySelectedDevice, false);
    this.getHistoryEventformation();
    //this.onTrackVehicleHistory(val);
    this.onCustomSubmit();
    this.playpause = false;
    setTimeout(() => {
      this.onClickHistory();  
    }, 1000);    
  }
  async getSingleDeviceInformation() {    
    let searchParameter = ['status=ALL','isAddressRequired=true','limit=10','offset=0'];    
    searchParameter.push('deviceName='+this.historyForm.value.object);
    
    await this.commonService
      .getRequest('positions', searchParameter)
      .then(async(resp: any) => {
        if (resp?.status) {          
          resp.data.forEach((r) => {
            //if (r.status) {
              r.deviceDetails = this.getDeviceDetails(r);
              r.isShow = true;
              r["isSelected"] = true;
            //}
            this.historySelectedDevice = r;
          });          
        }
      });
  }
  getHistoryEventformation() {
      this.from = this.historyForm.value.timeFrom;
      this.to = this.historyForm.value.timeTo;    
      this.commonService
        .getRequest(
          'reports/tripsandstops?deviceId=' +
            this.device_id +
            '&from=' +
            new Date(this.from).toISOString() +
            '&to=' +
            new Date(this.to).toISOString(),
          []
        )
        .then((resp: any) => {
          if (resp && resp.data && resp.data.length) {
            this.historyActivityList = resp.data;
            
          }
        });
  }
  calculateTripTime(startTime, endTime) {
    let startdate = moment(startTime);
    let enddate = moment(endTime);
    return enddate.diff(startdate);
  }
  async hideTracking(val: any) {
    this.closeHistorytrack();
  }
  async getDriverDetails() {
    let searchParameter = [];    
    searchParameter.push('deviceId='+this.selectedDevice.deviceId);
    
    await this.commonService
      .getRequest('drivers', searchParameter)
      .then(async(resp: any) => {
        if (resp.length > 0) {                 
          this.driverDetails = resp;
        }
      });
  }
  onRigthSidePanelScroll(event: any) {}
  collapseRightPanel() {
    this.rightPanelExpand = false;
  }
  expandRightPanel() {
    this.rightPanelExpand = true;
  }
  searchGeoEvent(event: any) {
    if (this.polygons) {
      if (event.target.value) {
        this.geofenceList = this.geofenceList.filter((s) =>
          s.name.includes(event.target.value)
        );
        //console.log(this.geofenceList);
      } else {
        this.geofence.allGeofence().subscribe((resp) => {
          this.geofenceList = resp.data;
        });
      }
    } else if (this.circular) {
      if (event.target.value) {
        this.poiList = this.poiList.filter((s) =>
          s.poi.poiname.includes(event.target.value)
        );
        //console.log(this.poiList);
      } else {
        this.geofence.allPoiList().subscribe((resp) => {
          this.poiList = resp.data;
        });
      }
    }
  }
  onclickAddPolygon() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addcircular = false;
    this.googleMap = false;
    this.drawCircularGeofence = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.isGeofenceLandmarkList = false;
    //this.initialise();
  }

  onclickAddCircular() {
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addpolygon = false;
    this.googleMap = false;
    this.drawPolygonGeofence = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.isGeofenceLandmarkList = false;
    //console.log('-----------', this.lat, this.lng, this.zoom);
  }

  onCancelPolygon() {
    this.drawPolygonGeofence = false;
    this.addpolygon = false;
    this.circularGeofence = true;
    this.circularLandmarkGeofence = true;
    this.polygonGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.allvehicles = true;
    this.isGeofenceList = true;
    this.isGeofenceLandmarkList = true;
    this.getAllGeofence();
  }

  onCancelCircular() {
    this.drawCircularGeofence = false;
    this.addcircular = false;
    this.polygonGeofence = true;
    this.circularGeofence = true;
    this.circularLandmarkGeofence = true;
    this.googleMap = true;
    this.maincard = true;
    this.allvehicles = true;
    this.isGeofenceList = true;
    this.isGeofenceLandmarkList = true;
    this.getAllGeofence();
  }

  onEditPolygon(geo) {
    // this.maincard = false;
    // this.googleMap = false;
    // this.drawPolygonGeofence = true;
    // this.addpolygon = true;
    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addcircular = false;
    this.googleMap = false;
    this.drawCircularGeofence = false;
    this.drawPolygonGeofence = true;
    this.addpolygon = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.isGeofenceLandmarkList = false;
    this.markerGeofenceList = [];
    this.markerGeofenceList.push(this.geofenceList.filter(x => x.id == geo.id)[0]);
    this.polygonForm.patchValue({fencename: geo.name});
    // this.polygonForm.value.type = geo.type;
    // this.polygonForm.value.enableEntering = geo.entering;
    // this.polygonForm.value.enableExiting = geo.exiting;
  }

  onEditPoi(geo: any) {
    // this.maincard = false;
    // this.googleMap = false;
    // this.drawCircularGeofence = true;
    // this.addcircular = true;

    this.polygons = false;
    this.circular = false;
    this.maincard = false;
    this.addpolygon = false;
    this.googleMap = false;
    this.drawPolygonGeofence = false;
    this.drawCircularGeofence = true;
    this.addcircular = true;
    this.allvehicles = false;
    this.isGeofenceList = false;
    this.isGeofenceLandmarkList = false;
    this.markerGeofenceList = [];
    this.markerGeofenceList.push(this.geofenceList.filter(x => x.id == geo.id)[0]);
    this.circularForm.patchValue({circularName: geo.name});
  }

  onDeletePolygon(geoid) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        // this.geofence.deletingGeofence(geoid).subscribe((resp) => {
        //   console.log(resp);
        // });
        this.commonService
          .deleteRequest('geofences/' + geoid)
          .then((resp: any) => {
            //console.log('-------', resp);
            this.onshowPolygonList();
            Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
            this.getAllGeofence();
          }, error => {
            Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
          });        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onDeletePoi(poiId) {
    //console.log('poiId', poiId);
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this geofence!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        this.commonService
        .deleteRequest('geofences/' + poiId)
        .then((resp: any) => {
          //console.log('-------', resp);
          this.onshowCircularList();
          Swal.fire('Deleted!', 'Your geofence has been deleted.', 'success');
          this.getAllGeofence();
        }, error => {
          Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
        });       
        
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your geofence is safe :)', 'error');
      }
    });
  }

  onPolygonSubmit() {
    //console.log('this.polygonForm', this.polygonForm);
    if (this.polygonForm.invalid) {
      this.polygonForm.controls.fencename.markAsTouched();
      return;
    }
    
    if (this.polyArrayLatLng?.length) {
      let latLng = [];
      this.polyArrayLatLng.forEach((p) => {
        //console.log('------------------p', p);
        latLng.push(p.lat + ' ' + p.lng);
      });
      let dataSet = {
        id: -2,
        name: this.polygonForm.value.fencename,
        description: this.polygonForm.value.fencename,
        area: 'POLYGON((' + latLng.join(', ') + '))',
        calendarId: 0,
        attributes: {},
      };
      //console.log('this.polyArrayLatLng', dataSet);

      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          this.polygonForm.reset();          
          this.circularGeofence = true;
          this.circularLandmarkGeofence = true;
          this.polygonGeofence = true;
          this.drawPolygonGeofence = false;
          this.addpolygon = false;
          this.onshowPolygonList();
          this.maincard = true;
          this.googleMap = true;
          this.maincard = true;
          this.allvehicles = true;
          this.isGeofenceList = true;
          this.isGeofenceLandmarkList = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });      
    } else {
      alert('Pease Create Polygon Geofence.');
    }
  }

  onCircularSubmit() {
    let error = false;

    //console.log('dataSet---------', this.circularForm);
    if (this.circularForm.invalid) {
      this.circularForm.controls.circularName.markAsTouched();
      return;
    }
    //console.log('dataSet---------', this.circleArrayLatLng);
    if (this.circleArrayLatLng?.length) {
      //console.log('dataSet', this.circleArrayLatLng);
      let dataSet = {
        id: -1,
        name: this.circularForm.value.circularName,
        description: this.circularForm.value.circularName,
        area:
          'CIRCLE (' +
          this.circleArrayLatLng[0] +
          ' ' +
          this.circleArrayLatLng[1] +
          ', ' +
          this.circleRadius +
          ')',
        calendarId: 0,
        attributes: {},
      };
      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          //console.log(resp);
          this.circularForm.reset();
          this.circleArrayLatLng = [];

          this.drawCircularGeofence = false;
          this.addcircular = false;
          this.polygonGeofence = true;
          this.circularGeofence = true;
          this.circularLandmarkGeofence = true;
          this.onshowCircularList();
          this.maincard = true;
          this.googleMap = true;
          this.allvehicles = true;
          this.isGeofenceList = true;
          this.isGeofenceLandmarkList = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Geofence Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });
    } else {
      alert('Pease Create Circular Geofence.');
    }
  }

  onCircularLandmarkSubmit() {
    let error = false;

    //console.log('dataSet---------', this.circularForm);
    if (this.circularForm.invalid) {
      this.circularForm.controls.circularName.markAsTouched();
      return;
    }
    //console.log('dataSet---------', this.circleArrayLatLng);
    if (this.circleArrayLatLng?.length) {
      //console.log('dataSet', this.circleArrayLatLng);
      let dataSet = {
        id: -1,
        name: this.circularForm.value.circularName,
        description: this.circularForm.value.circularName,
        area:
          'CIRCLE (' +
          this.circleArrayLatLng[0] +
          ' ' +
          this.circleArrayLatLng[1] +
          ', ' +
          30.00 +
          ')',
        calendarId: 0,
        attributes: {
          type: this.circularForm.value.circularType,
          address: this.circularForm.value.circularAddr
        },
      };
      this.commonService.postRequest('geofences', dataSet).then((resp: any) => {
        if (resp) {
          //console.log(resp);
          this.circularForm.reset();
          this.circleArrayLatLng = [];

          this.drawCircularGeofence = false;
          this.addcircular = false;
          this.polygonGeofence = true;
          this.circularGeofence = true;
          this.circularLandmarkGeofence = true;
          this.onshowCircularList();
          this.maincard = true;
          this.googleMap = true;
          this.allvehicles = true;
          this.isGeofenceList = true;
          this.isGeofenceLandmarkList = true;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Landmark Added Successfully!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.getAllGeofence();
      });
    } else {
      alert('Pease click on map to add landmark.');
    }
  }

  onshowPolygonList() {
    this.circularGeofence = true;
    this.polygonGeofence = true;
    this.circularLandmarkGeofence = true;
    this.getAllGeofence();
  }

  onshowCircularList() {
    this.polygonGeofence = true;
    this.circularGeofence = true;
    this.circularLandmarkGeofence = true;
    this.getAllPoiList();
  }
  getAllPoiList() {
    this.polygons = true;
    this.circular = true;
  }
  
  zoomPolygon(geo) {
    this.zoom = 8;
    this.lat = 0;
    this.lng = 0;
    this.lat = geo.geofence.geometry.coordinates[0].lat;
    this.lng = geo.geofence.geometry.coordinates[0].lng;
  }
  zoomCircle(geo, event) {
    //this.circularDrawingManager(event);
    setTimeout(() => {
      this.zoom = 8;
      this.lat = 0;
      this.lng = 0;
      this.lat =  geo.geofence.coordinates[0];
      this.lng =  geo.geofence.coordinates[1];
    }, 100);
    
  }
  zoomLandmarkCircle(geo, event) {
    setTimeout(() => {
      this.zoom = 15;
      this.lat = 0;
      this.lng = 0;
      this.lat =  geo.geofence.coordinates[0];
      this.lng =  geo.geofence.coordinates[1];
    }, 100);
  }
  onGeofenceScroll(event: any) {}
  onGeofenceSelectionChange(val: any, event: any) {
    //console.log(val);
    event.stopPropagation();
    if (val.isSelected) {
      this.markerGeofenceList.push(val);
    } else {
      let objIndex = this.markerGeofenceList.findIndex(x => x.id == val.id);
      if (objIndex >= 0) {
        this.markerGeofenceList.splice(objIndex, 1);
      }
    }
  }
  getDeviceImage(category: any) {
    //alert(category);
    if (category == 'car') {
      return "car.jpeg";
    } else if (category == 'truck') {
      return "Truck.jpg";
    } else if (category == "motorcycle") {
      return "bike-bnw.PNG";
    } else if (category == "tractor") {
      return "tractor.jpeg";
    } else if (category == "bus") {
      return "bus-bnw.PNG";
    } else if (category == "crane") {
      return "crane.jpeg";
    } else if (category == "rickshaw") {
      return "auto-bnw.PNG";
    } else if (category == "ambulance") {
      return "ambulance-bnw.PNG";
    } else if (category == "animal") {
      return "pet-bnw.PNG";
    } else if (category == "scooter") {
      return "scoot-bnw.PNG";
    } else if (category == "van") {
      return "van.jpeg";
    } else {
      return "Truck.jpg";
    }
  }
  onSpeedgenerateBasedOnuserPreference(speed: any) {
    if (this.userData) {
      let s = 0;
      switch(this.userData.attributes.speedUnit) {
        case "kmh":
        case "kmph":
          s = speed * 1.852;
          break;
        case "kn":
          s = speed;
          break;
        case "mph":
          s = speed * 1.150;
          break;
      }
      return s; 
    }
    return speed;
  }
  getFuelFill(fuelAmt: any) {
    let fuel = (fuelAmt / 2)/100 > 0.5? 0.5:(fuelAmt / 2)/100;
    return {'transform': `rotate(${fuel}turn)`};
  }
  onPolyClick(evnt: any, polygon: any, infoWinRef: AgmInfoWindow) {
    alert(polygon.name);
  }
  sendGprsCommand(device) {
    this.commonService.pageLoadInModal.next('devicemodel');
    setTimeout(() => {
      this.commonService.pageLoadInModal.next('devicemodel');
    }, 100);     
  }
  activityClicked(val: any) {
    if (val.startPositionId && val.endPositionId) {
      //console.log(val);
      this.selectedHistoryActivity = val;
    } else {
      this.selectedHistoryActivity = {};
    }
    if (this.marker != null) {
      this.marker.setMap(null);
    }
    this.historyPath.line.setMap(null);       
    setTimeout(()=>{
      //this.closeEndMarker();
      this.mockDirections();
    },200);
    setTimeout(()=>{
      this.hightlightSelectedActivity();
    }, 1200);
  }
  hightlightSelectedActivity() {
    this.zoom = 5;//13.2; --change zoom level to display all markers on one go.
    this.locationArray = this.locationData.map(
      (l) => new google.maps.LatLng(l[0], l[1])
    );    
    this.historyPath.line = new google.maps.Polyline({
      strokeOpacity: 1,
      strokeWeight: 2.5, 
      strokeColor: '#FF0000',     
      path: [],
      map: this.map,      
    }); 
    let last = 0;   
    if (this.selectedHistoryActivity && this.selectedHistoryActivity.startPositionId) {
      let isFirst = true;
      this.locationData.forEach((l, i) => {        
        if (l[2] >= this.selectedHistoryActivity.startPositionId && l[2] <= this.selectedHistoryActivity.endPositionId) {
          if (isFirst) {
            this.origin_lat = l[0]?.latitude;
            this.origin_lng = l[1]?.longitude;
            isFirst = false;
          }                  
          this.historyPath.line.getPath().push(this.locationArray[i]);
          last++;
        }
      });
    }    
    this.destination_lat = this.locationData[last - 1]?.latitude;
    this.destination_lng = this.locationData[last - 1]?.longitude;
    this.historyPath.start = new google.maps.LatLng(
      this.origin_lat,
      this.origin_lng
    );
    const end = new google.maps.LatLng(
      this.destination_lat,
      this.destination_lng
    );
    
    this.historyPath.end = new google.maps.Marker({
      position: end,
      map: this.map,
    });
    // try {
    //   var latlng = new google.maps.LatLng(this.origin_lat, this.origin_lng);
    //   this.historyPath.poly.setMap(this.map);
    //   this.map.setCenter(latlng);
    // } catch (error) {}
    this.initRoute();
  }
  resetHistoryChartSeries() {
    var chart = new ApexCharts(
      document.querySelector('#chart'),
      this.options
    );   
    chart.render();
    chart.resetSeries();
    if (this.isHistorySpeedChart) {
      chart.showSeries('Speed');
    } else {
      chart.hideSeries('Speed');
    }
    if (this.isHistoryFuelChart) {
      chart.showSeries('Fuel');
    } else {
      chart.hideSeries('Fuel');
    }
    
  }
  exportHistoryWithMap() {
    let stylesUrl = window.location.protocol+"//"+window.location.host;
    let popupWinindow:any;
    let innerContents = document.querySelector("div.main-content")?.innerHTML;
    popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="'+stylesUrl+'/styles.css" /><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" /> </head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
    
    //window.print();
  }
  addGeofence() {
    this.tabChange("Geofence");
    this.onclickAddCircular();
  }
  addLandmark() {
    this.tabChange("Landmark");
    this.onclickAddCircular();
  }
  assingDevicetoGeofence(val: any) {
    //this.assignDevice(val,"assignDevicePopup");
    this.commonService.pageLoadInModal.next('assigndevicemodal');
    setTimeout(() => {
      this.commonService.pageLoadInModal.next('assigndevicemodal');
    }, 100);
    this.assignDevice(val);
  }
  assignDevice(geofence) {
    //this.modalRef = this.modalService.show(modal, { class : "modal-xl"});
    this.deviceList = [];
    //console.log('---------', geofence);
    this.commonService.getRequest('devices', []).then((resp: any) => {
      if (resp?.status) {
        this.deviceList = resp.data;
        this.selectedGeofenceForDevice = geofence.id;
        this.getUserDevice(geofence.id);
      }
    });
  }
  // getMappedGeofenceData(geofence) {
  //   this.commonService.getRequest('geofences/geofencesbyid/'+geofence.id, []).then((resp: any) => {
  //     if (resp?.status) {
  //       this.deviceList = resp.data;
        
  //       //this.getUserDevice(geofence.id);
  //     }
  //   });
  // }
  getUserDevice(geofenceId) {
    this.commonService
      .getRequest('geofences/geofencesbyid/'+geofenceId, [])
      .then((resp: any) => {
        if (resp?.status) {
          let mappedId = resp.data.map((d: any) => {
            return d.device_id;
          });
          this.deviceList.forEach((d: any, k: number) => {
            //this.deviceList[k]['mapUserId'] = userId;
            this.deviceList[k]['isSelectedDevice'] =
              mappedId.indexOf(d.id) > -1;
          });
          // console.log(
          //   'resp----this.deviceList-------',
          //   mappedId,
          //   this.deviceList
          // );
        }
      });
  }
  changeGeofenceEvent(e: any, deviceId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: deviceId,
          geofenceId: this.selectedGeofenceForDevice,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: deviceId,
          geofenceId: this.selectedGeofenceForDevice,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  refreshObjectList() {
    this.deviceFilter('ALL');
  }
  goToDeviceList() {
    this.commonService.commonModel.next(false);
    setTimeout(() => {
      this.commonService.headerEvent.next({value:'devices', calledFrom:''});
    }, 100);
  }
  goToDeviceAdd() {
    this.commonService.commonModel.next(false);
    setTimeout(() => {
      this.commonService.headerEvent.next({value:'devices', calledFrom:'tracking'});
    }, 100);
  }
  addRemarks(val: any) {
    //this.commonService.commonModel.next(false);
    this.isEventsRemarksPopupOpen = true;
    this.commonService.pageLoadInModal.next('eventsremarksmodal');
    this.commonService.eventRemarksDataEvent.next({events: val});
    setTimeout(() => {
      this.isEventsRemarksPopupOpen = true;
      this.commonService.pageLoadInModal.next('eventsremarksmodal');
      this.commonService.eventRemarksDataEvent.next({events: val});
    }, 100);
  }
  fullScreenMap() {
    this.commonService.isFullscreenMap = true;
    this.collapseRightPanel();
  }
  returnToNormalScreenMap() {
    this.commonService.isFullscreenMap = false;
    setTimeout(() => {
      // this.getVehicleCounts();
      this.initiateCarousel();
    }, 1);
  }
  drawEventMarkerOnMap(evnt: any) {
    this.commonService.getRequest('positions?id='+evnt.positionId, []).then((resp: any) => {
      if (resp?.status) {
        
        console.log('-------------', resp.data[0].latitude, resp.data[0].longitude);
        //this.selectedEvent = resp.data[0];
        this.selectedEvent = {...resp.data[0], ...evnt};
        this.selectedEvent.attributes = {...resp.data[0].attributes, ...evnt.attributes};
        //this.selectedEvent['lon'] = resp.data[0].longitude;
        this.lat = resp.data[0].latitude;
        this.lng = resp.data[0].longitude;
        setTimeout(() => {
          this.zoom = 14;
        }, 100);    
        setTimeout(() => {
          this.zoom = 14;
        }, 1000);        
      }
    });
  } 
  eventInfoMarkerClick(infowindow1) {
    this.previousEventInfoWindow = this.selectedEvent;
    infowindow1.open();
  }
  handleCluster() {
    if (this.minimumClusterSize == 2) {
      this.minimumClusterSize = 999999999999;
    } else {
      this.minimumClusterSize = 2;
    }
  }
  getAllGroupData() {
    this.groupListData = [];
    this.commonService.getRequest("groups", []).then((resp: any) => {
      if (resp?.status) { 
        //this.groupListData = resp.data; 
        this.groupListData = resp.data.map(x => ({...x, 'isGroupOpen': false, 'isSelected': true})); 
        this.groupListData.push({id: 0, name: 'Ungrouped', 'isGroupOpen': false, 'isSelected': true});
        

        this.groupListData.forEach(ele => {
          ele['deviceData'] = this.devicesList.filter(x => x.groupId == ele.id);
        });

        var sortedGrouplistData = this.groupListData.sort(function (one, other) {          
          return other.deviceData.length - one.deviceData.length;
        });
        this.groupListData = sortedGrouplistData;
        this.groupListData[0].isGroupOpen = true;
        //console.log(this.groupListData);
        //this.getGroupsAssignedToUser(custId);      
      }
    })
  }
  expanCollapseGroup(grp, val) {
    grp.isGroupOpen = !grp.isGroupOpen;
  }
  groupSelectionChange(grp, event) {
    event.stopPropagation();
    if (grp.isSelected) {
      let listData = this.devicesList.filter(x => x.groupId == grp.id);

      this.markersList.push(...listData);
      grp.deviceData.forEach(ele => {
        ele.isSelected = true;
      });
    } else {
      let listData = this.markersList.filter(x => x.groupId == grp.id);
      listData.forEach(ele => {
        let match = this.markersList.findIndex(x => x.id == ele.id);
        if (match >= 0) {
          this.markersList.splice(match,1);
        }
      });
      grp.deviceData.forEach(ele => {
        ele.isSelected = false;
      });
    }
  }
  objectTabDeviceFilterChange() {
    this.gloableSearchText = '';
    this.deviceFilter();
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
