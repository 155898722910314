<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ {{'Devices'|translate}}</span></p> -->
<app-common-modal-popup #devicesModel>
  <div class="" modelheadertext *ngIf="gridMode">{{'Devices'|translate}}</div>
  <div class="" modelheadertext *ngIf="addMode">
    {{ addDevice.id ? ('Edit'|translate) : ('Add'|translate) }} {{'Device'|translate}}
  </div>
  <div class="" modelheadertext *ngIf="reportMode">
    {{ 'Create Installation Certificate'|translate }}    
  </div>
  <div class="container-fluid" modeldata *ngIf="gridMode">
      <div class="row p-0">
          <div class="card p-0 headcard">
              <!-- HEADER WITH ADD POI BTNS -->
              <div class="bg-light d-flex align-items-center p-2">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <form [formGroup]="form" class="d-flex">
                    <div class="form-floating">
                    <select class="form-select selectimportant filter-drd group-search-data me-4" style="height: 7vh !important;" (change)="searchData('key')" formControlName="searchKey">
                      <option value="-1" class="select_options"></option>
                      <option value="uniqueId" class="select_options">IMEI</option>
                      <option value="name" class="select_options">Vehicle no</option>
                      <option value="phone" class="select_options">Sim</option>
                      <option value="deviceStatus" class="select_options">Status</option>
                      <option value="model" class="select_options">Model</option>
                      <option value="category" class="select_options">Vehicle type</option>
                      <option value="groupId" class="select_options">Group</option>
                  </select>
                  <label for="floatingSelect">Filter By</label>
                </div>
                    <div class="input-group searchvehicles" *ngIf="form.value.searchKey != 'groupId'">
                        
                        <input (keyup)="searchData('value')" formControlName="searchValue" name="searchValue"
                            type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                            autocomplete="off" />

                        <div class="input-group-append">
                            <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh">
                                <img (click)="searchData('button')" src="/assets/icons/searchicon.png" alt="search"
                                    style="width: 2.3vh" />
                            </button>
                        </div>
                    </div>
                    <select class="form-select selectimportant group-search-data" (change)="searchData('group')" formControlName="searchValue" *ngIf="form.value.searchKey == 'groupId'">
                      <option *ngFor="let model of groupListData" value="{{model.id}}">{{model.name}}</option>
                    </select>
                </form>
                <div class="d-flex">
                  <button 
                  *ngIf="!isNormalUser" 
                  class="d-flex align-items-center p-2 bg-white" style="margin-right: 1vh"                
                  (click)="addDevicepopup()" title="{{'New Device'|translate}}">
                      <span class="material-icons" style="font-size: 2.1vh">
                          add
                      </span>
                      
                  </button>
                        
                          
                    <app-bulk-upload (closePopup)="onPopupClose($event)" class="px-2"></app-bulk-upload>
                  </div>
                </div>
              </div>
              <!-- DATE DOWNLOAD HEADER -->
              <div class="card-header flex_row_center_spacebetween">
                  <div class="flex_row_center_spacebetween">
                      
                  </div>
                  <div style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 27vh;
            ">
                      <div class="d-flex flex-row align-items-center" style="padding: 0.4vh 0 0 0">
                          <span class="addlabels">{{'Active'|translate}}</span>
                          <mat-slide-toggle style="padding-left: 1vh"></mat-slide-toggle>
                      </div>
                      <span (click)="getAllDevices()" class="btn"><img src="/assets/icons/report_refresh_gray.png"
                              alt="refresh" style="width: 96%" /></span>
                      
                      <app-export-data [selectedPage]="'Devices'" [dataList]="devicesList"></app-export-data>
                  </div>
              </div>
              <!-- device TABLE -->
              <div class="card-body common_scroller fixed_height p-0">
                  <table class="table table-borderless m-0" style="width: max-content;min-width: 100%;">
                      <thead class="bg-light">

                          <tr>
                              <th class="table_head_text" (click)="sorting('name')">{{'Vehicle No'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'name'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'name'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('group_name')">{{'Group Name'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'group_name'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'group_name'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('uniqueId')">{{'IMEI'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'uniqueId'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'uniqueId'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('deviceStatus')">{{'Status'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceStatus'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceStatus'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('lastUpdate')">{{'Last Update'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'lastUpdate'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'lastUpdate'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('phone')">{{'Sim No'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'phone'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'phone'"></i>
                              </th>
                              <th class="table_head_text">{{'Model'|translate}}</th>
                              <th class="table_head_text">{{'Contact'|translate}}</th>
                              <th class="table_head_text">{{'Icon'|translate}}</th>
                              <th class="table_head_text">{{'Disabled'|translate}}</th>
                              <th class="table_head_text" (click)="sorting('expirationTime')">{{'Expiration Time'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'expirationTime'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'expirationTime'"></i>
                              </th>
                              <th class="table_head_text" (click)="sorting('creation_date')">{{'Creation Time'|translate}}
                                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'creation_date'"></i>
                                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'creation_date'"></i>
                              </th>
                              <th class="table_head_text">{{'Todays Odo'|translate}}</th>
                              <th class="table_head_text">{{'Total Odo'|translate}} </th>
                              <th class="table_head_text">{{'Users'|translate}} </th>
                              <th class="table_head_text">{{'Actions'|translate}}</th>
                          </tr>
                      </thead>
                      
                      <tbody *ngIf="devicesList.length">                        
                        <tr *ngFor="let device of devicesList | paginate : {
                          itemsPerPage: pageOption,
                          currentPage: page,
                          totalItems: devicesListTotalCount
                        };let i=index">
                          <td class="table_data">{{ device.device.name }}</td>
                          <td class="table_data">{{ device.device.group_name }}</td>
                          <td class="table_data">{{ device.device.uniqueId }}</td>
                          <td class="table_data"><img *ngIf="device.device.status == 'online';else offline"
                            src="/assets/icons/dotgreen.png"
                            alt="checkbox"
                            style="width: 1.5vh"
                          />
                          <ng-template #offline>
                          <img
            src="/assets/icons/stoped.png"
            alt="checkbox"
            style="width: 1.5vh"
          /></ng-template> {{ device.device.deviceStatus |titlecase}}</td>
                          <td class="table_data">{{ device.device.lastUpdate |dateFormat }}</td>
                          <td class="table_data">{{ device.device.phone }}</td>
                          <td class="table_data">{{ device.device.model }}</td>
                          <td class="table_data">{{ device.device.contact }}</td>
                          <td class="table_data">{{ device.device.category }}</td>
                          <td class="table_data">{{ device.device.disabled ? 'Yes':'No' }}</td>
                          <td class="table_data">{{ device.device.expirationTime | date: 'dd/MM/yyyy'}}</td>
                          <td class="table_data">{{ device.device.creation_date  ?(device.device.creation_date | date: 'dd/MM/yyyy') : ''}}</td>
                          <td class="table_data">{{ device.device && device.device.distanceForday ? (device.device.distanceForday | meterTokm) : '' }}</td>
                          
                          <td class="table_data">
                            <span *ngIf="!device?.isEdit">
                              {{ device.device && device.device.attributes && device.device.attributes.totalDistance ?( device.device.attributes.totalDistance | meterTokm)  : 0}} 
                              <i class="bi bi-pencil" matTooltip="Edit Odo"  (click)="editOdoData(device, i)"></i>
                            </span>
                            <span *ngIf="device?.isEdit">
                              <form [formGroup]="resetODOForm">
                                <div class="row">
                                  <div class="col-6">
                                    <div class="form-group pb-2">
                                      <label for="licencenumber" class="addlabels pb-2">{{'Hours'|translate}}</label>
                                      <input type="number" formControlName="hours" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="odohours"
                                      aria-describedby="hours">
                                      <span class="text-danger validation"
                                        *ngIf="(resetODOForm.controls.hours.touched || submitted) && resetODOForm.controls.hours.errors?.required">
                                        *{{'Hours'|translate}} {{'is required'|translate}}.
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="form-group pb-2">
                                      <label for="totalDistance" class="addlabels pb-2">{{'Total ODO (km)'|translate}}</label>
                                      <input type="number" formControlName="totalDistance" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="totalDistance"
                                      aria-describedby="totalDistance">
                                      <span class="text-danger validation"
                                        *ngIf="(resetODOForm.controls.totalDistance.touched || submitted) && resetODOForm.controls.totalDistance.errors?.required">
                                        *{{'Total ODO'|translate}} {{'is required'|translate}}.
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div class="d-flex align-items-center pt-2 pb-2">
                                <button
                                  mat-raised-button
                                  class="cancelbtn"
                                  (click)="submitODO()"
                                >
                                  {{'Save'|translate}}
                                </button>
                                <button
                                mat-raised-button
                                class="cancelbtn bg-secondary  ms-3"
                                id="btncloseResetSubmit"  
                                (click)="cancelODO()"                                  
                              >
                                {{'Cancel'|translate}}
                                </button>
                              </div>
                            </span>
                            <!-- <i (click)="resetODODetails(device.device, resetODO)" class="fa fa-cog"></i> -->
                          </td>

                          <td class="table_data">{{ device?.userData[0].userName }} 
                            <a href="javascript:void(0);" class="link-primary ms-2" *ngIf="device?.userData.length > 1" title="{{displayDeviceUsersList(device?.userData)}}">+ {{device?.userData.length - 1}}</a>
                          </td>
                          <td class="table_data d-flex">
                              <div class="btn btn-primary btn-sm mx-1" (click)="editDevice(device.device)"
                              *ngIf="!isNormalUser" matTooltip="Edit" >
                                  <i class="fa fa-edit"></i>
                              </div>
                              <div *ngIf="!isNormalUser && userData.administrator" 
                              matTooltip="Delete" class="btn btn-danger btn-sm mx-1" (click)="deleteDevice(device.device.id)">
                                  <i class="fa fa-trash"></i>
                              </div>
                              <div
                                class="btn btn-primary btn-sm mx-1"
                                matTooltip="Assign Geofence"
                                (click)="assignGeofence(device.device.id, genfencesList)"
                              >
                                  <i class="bi bi-heptagon icoimg"></i>                    
                              </div>
                              <div (click)="displyDealerUserList(device, userModel)" 
                              matTooltip="Assigned Users" class="btn btn-primary btn-sm mx-1"
                              *ngIf="!isNormalUser">
                                  <i class="fa fa-user"></i>
                              </div>
                              <div
                              *ngIf="!isNormalUser"
                                  class="btn btn-warning btn-sm mx-1"
                                  matTooltip="Assign Notification"
                                  (click)="deviceNotifications(device.device, staticBackdrop6)">
                                  <i class="fa fa-bell"></i>
                              </div>
                              <div
                                class="btn btn-primary btn-sm mx-1"
                                matTooltip="Assign Sensor"
                                (click)="assignSensor(device.device.id, sensorsListModel)"
                              >
                                <i class="fa fa-podcast icoimg"></i>
                                                   
                              </div>
                              <div class="btn btn-primary btn-sm mx-1" (click)="generateReport(device.device)"
                              matTooltip="Create Installation Certificate" >
                              <i class="bi bi-file-earmark-post"></i>
                              </div>
                              <div
                                class="btn btn-primary btn-sm mx-1"
                                matTooltip="Assign Service"
                                (click)="assignServices(device.device.id, maintanenceListModel)"
                              >
                                <i class="bi bi-tools icoimg"></i>
                                                   
                              </div>
                          </td>
                        </tr>
                      </tbody>

                      <tbody *ngIf="!devicesList.length">
                          <tr>
                              <td colspan="7">
                                  <div class="d-flex justify-content-center align-items-center" style="height: 48vh">
                                      <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE" />
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div *ngIf="devicesList.length" class="flex_row_center_spacebetween"
                  style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
                  <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{devicesListTotalCount/pageOption |
                      number: '1.0-0'}} {{'of'|translate}}
                      {{devicesListTotalCount}} {{'entries'|translate}}</div>
                    <div class="d-flex align-items-center">
                    <select class="form-select selectimportant" [(ngModel)]="pageOption" (change)="getAllDevices()" name="pageOption">
                      <option value="10" class="select_options">10</option>
                      <option value="20" class="select_options">20</option>
                      <option value="30" class="select_options">30</option>
                      <option value="40" class="select_options">40</option>
                      <option value="50" class="select_options">50</option>
                      <option value="60" class="select_options">60</option>
                      <option value="-1" class="select_options">All</option>
                  </select>
                  <pagination-controls (pageChange)="pageClickedEvent($event)"></pagination-controls>
                </div>
              </div>
          </div>
      </div>
  </div>
  <div class="container-fluid add-form" modeldata *ngIf="addMode">
    <div class="row">
      <div class="col-12">
        <accordion [isAnimated]="true">
          <accordion-group heading="Required" [isOpen]="true">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Registration No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.name"
                    class="form-control inputfields"
                    id="name"
                    aria-describedby="name"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="errors?.Devicename"
                  >
                    {{ errors.Devicename }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'IMEI'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.identifier"
                    class="form-control inputfields"
                    id="identifier"
                    aria-describedby="identifier"
                  />
                  <span
                    class="text-danger validation"
                    *ngIf="errors?.Deviceidentifier"
                  >
                    {{ errors.Deviceidentifier }}
                  </span>
                </div>
              </div>
            </div>
          </accordion-group>
          <accordion-group heading="Extra">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="group" class="pb-2">{{'Group'|translate}}</label>
                  <select class="form-control" [(ngModel)]="addDevice.groupId">
                    <option *ngFor="let model of groupListData" value="{{model.id}}">{{model.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="phone" class="pb-2">{{'Sim number'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.phone"
                    class="form-control inputfields"
                    id="phone"
                    aria-describedby="phone"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="model" class="pb-2">{{'Model'|translate}}</label>
                  <select class="form-control" [(ngModel)]="addDevice.model">
                    <option value="other">Other</option>
                    <option
                      [value]="dModel.modelName"
                      *ngFor="let dModel of ModelMasterList"
                    >
                      {{ dModel.modelName }}
                    </option>
                  </select>
                  <input
                  *ngIf="addDevice.model == 'other'"
                    type="text"
                    [(ngModel)]="addDevice.otherModelValue"
                    class="form-control inputfields"
                    id="model"
                    aria-describedby="model"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="contact" class="pb-2">{{'Driver no'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="addDevice.contact"
                    class="form-control inputfields"
                    id="contact"
                    aria-describedby="contact"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <label for="category" class="pb-2">{{'Icon'|translate}}</label>
                  <select class="form-control" [(ngModel)]="addDevice.category">
                    <option
                      [value]="deviceCategory.id"
                      *ngFor="let deviceCategory of deviceCategoryList"
                    >
                      {{ deviceCategory.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="addDevice.disabled"
                      (change)="checkboxEvent($event.target)"
                      id="disabled"
                    />
                    <label class="form-check-label" for="disabled"
                      >{{'Disabled'|translate}}</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group pb-2 date-p">
             
                  <label for="expirationTime" class="pb-2">{{'Expiration'|translate}}</label>
                  <input
                    type="date"
                    value="{{addDevice.expirationTime}}"
                    [(ngModel)]="addDevice.expirationTime"
                    class="form-control inputfields w-100"
                    id="expirationTime"
                    aria-describedby="expirationTime"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div *ngIf="addDevice.id" class="form-group pb-2 date-p">
             
                  <label for="distanceForday" class="pb-2">{{'Todays Odo (mtr)'|translate}}</label>
                  <input
                    type="number"
                    value="{{addDevice.distanceForday}}"
                    [(ngModel)]="addDevice.distanceForday"
                    class="form-control inputfields"
                    id="distanceForday"
                    aria-describedby="distanceForday"
                  />
                </div>
              </div>
            </div>
          </accordion-group>
          <accordion-group heading="Attributes">
            <ng-container
              *ngFor="let attribute of addDevice.attributes; index as i"
            >
              <div class="row">
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'speedLimit'"
                >
                  <label>{{'Speed Limit(km)'|translate}}</label>
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'mileage'"
              >
                <label>{{'Mileage'|translate}}</label>
                <input
                  type="string"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'ignitionwire'"
                >
                <div class="form-check">
                 
                  <input
                  type="checkbox"
                
                  class="form-check-input"
                    [(ngModel)]="attribute.value"
                  />
                  <label class="form-check-label">{{'Ignitionwire'|translate}}</label>
                </div>
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'parkingMode'"
                >
                <div class="form-check">
                  <input
                  type="checkbox"
                  
                  class="form-check-input"
                    [(ngModel)]="attribute.value"
                  />
                  <label class="form-check-label">{{'Parking Mode'|translate}}</label>
                  </div>
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'relay'"
                >
                  <div class="form-check">
                  
                    <input
                    type="checkbox"
                  
                    class="form-check-input"
                      [(ngModel)]="attribute.value"
                    />
                    <label class="form-check-label">{{'Relay'|translate}}</label>
                  </div>
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'timezone'"
                >
                  <label>{{'Timezone'|translate}}</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'deviceInactivityStart'"
                >
                  <label>{{'Device Inactivity Start'|translate}}</label>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'deviceInactivityPeriod'"
                >
                  <label>{{'Device Inactivity Period'|translate}}</label>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'manufacturer'"
              >
                <label>{{'Manufacturer'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'model'"
              >
                <label>{{'Model'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'colors'"
              >
                <label>{{'Colors'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'chasis'"
              >
                <label>{{'Chasis No'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'engine'"
              >
                <label>{{'Engine No'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
                <div class="col-1" 
                *ngIf="attribute.attribute === 'speedLimit' ||
                attribute.attribute === 'mileage' || 
                attribute.attribute === 'ignitionwire' || 
                attribute.attribute === 'parkingMode' || 
                attribute.attribute === 'relay' || 
                attribute.attribute === 'timezone' || 
                attribute.attribute === 'deviceInactivityStart' || 
                attribute.attribute === 'deviceInactivityPeriod' || 
                attribute.attribute === 'manufacturer' || 
                attribute.attribute === 'model' ||
                attribute.attribute === 'colors' || 
                attribute.attribute === 'chasis' ||
                attribute.attribute === 'engine'">
                  <label>&nbsp;</label>
                  <i
                    class="fa fa-trash text-danger"
                    (click)="deleteAttr(i)"
                  ></i>
                </div>
              </div>
            </ng-container>

            <div
              class="btn btn-outline-dark w-100"
              (click)="addAttri(template)"
            >
              <i class="fa fa-plus"></i> {{'ADD'|translate}}
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>

    <div class="d-flex align-items-center pt-4 pb-2">        
            
      <button
        mat-raised-button
        class="cancelbtn"
        (click)="deviceSubmit()"
      >
        {{'Save'|translate}}
      </button> 
      <button
        mat-raised-button
        class="cancelbtn bg-secondary ms-3"
        (click)="onDeviceCancel()"
      >
        {{'Cancel'|translate}}
      </button>         
    </div>
  </div>
  <div class="container-fluid add-form" modeldata *ngIf="reportMode">
    <div class="row">
      <div class="col-12">
        <accordion [isAnimated]="true">
          <accordion-group heading="Required" [isOpen]="true">
            <div class="row">
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Engine No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.engineno"
                    class="form-control inputfields"
                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Chassis No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.chassisno"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Owner Name'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.ownername"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Owner Address'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.owneraddress"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Owner Mobile No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.ownermobileno"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <!-- <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="group" class="pb-2">{{'Owner ID Type'|translate}}</label>
                  <select class="form-control" [(ngModel)]="reportData.ownertype">
                    <option *ngFor="let model of groupListData" value="{{model.id}}">{{model.name}}</option>
                  </select>
                </div>
              </div> -->
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Owner ID No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.owneridno"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Distributor Name'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.distributorname"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Distributor Address'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.distributoraddress"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group pb-2">
                  <label for="name" class="pb-2">{{'Distributor Mobile No'|translate}}</label>
                  <input
                    type="text"
                    [(ngModel)]="reportData.distributormobileno"
                    class="form-control inputfields"                    
                  />                  
                </div>
              </div>
            </div>
          </accordion-group>          
        </accordion>
      </div>
    </div>

    <div class="d-flex align-items-center pt-4 pb-2">        
            
      <button
        mat-raised-button
        class="cancelbtn"
        (click)="createReport()"
      >
        {{'Generate Report'|translate}}
      </button> 
      <button
        mat-raised-button
        class="cancelbtn bg-secondary ms-3"
        (click)="onDeviceCancel()"
      >
        {{'Cancel'|translate}}
      </button>         
    </div>
  </div>
</app-common-modal-popup>
<div id="htmlString-container" #pdfTable [innerHtml]="htmlString" style="display: none;"></div>
<ng-template #userModel>
  <div class="modal-header addtriphead">
    <div class="headtext addlabels">
        {{'Users'|translate}}
    </div>
    <button
      type="button"
      class="btnclose"
      id="btnclose"
      (click)="closeUserModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    <div class="row">
      
      <ul class="list-group w-100">
        <li *ngFor="let user of dealerUsersList" class="list-group-item ">{{user.userName}}</li>
        
    </ul>
    </div>

    <div class="flex_row_center_spacebetween pt-4 pb-2">
        <div class="col-8"></div>
        
        
      
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label>{{'Attribute'|translate}}</label>
        <select
          class="form-control"
          [(ngModel)]="addAttr.attribute"
          (change)="changeAttrEvent($event.target)"
        >
          <option *ngFor="let attr of attrList" [value]="attr.id">
            {{ attr.name }}
          </option>
        </select>
      </div>
      <div class="col-12">
        <label>{{'Type'|translate}}</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="addAttr.type"
          [readonly]="true"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-light btn-sm" (click)="addAttrbute()">{{'ADD'|translate}}</div>
    <div class="btn btn-light btn-sm" (click)="modalRef.hide()">{{'CANCEL'|translate}}</div>
  </div>
</ng-template>

<ng-template #staticBackdrop6>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Notifications'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeNotificationModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-1">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let notification of notificationsMasterList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'notification-' + i"
            [checked]="notification.isSelectedDevice"
            (change)="
              changeEvent($event.target, notification.id, notification.mapDeviceId)
            "
          />
          <label class="form-check-label" for="notification-{{ i }}">
            {{ notification.type  }} ({{notification.notificators}})
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #resetODO>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Hours'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeHoursModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="resetODOForm">
      <div class="row">
        <div class="col-12">
          <div class="form-group pb-2">
            <label for="licencenumber" class="addlabels pb-2">{{'Hours'|translate}}</label>
            <input type="number" formControlName="hours" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="odohours"
            aria-describedby="hours">
            <span class="text-danger validation"
              *ngIf="(resetODOForm.controls.hours.touched || submitted) && resetODOForm.controls.hours.errors?.required">
              *{{'Hours'|translate}} {{'is required'|translate}}.
            </span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group pb-2">
            <label for="totalDistance" class="addlabels pb-2">{{'Total ODO (km)'|translate}}</label>
            <input type="number" formControlName="totalDistance" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="totalDistance"
            aria-describedby="totalDistance">
            <span class="text-danger validation"
              *ngIf="(resetODOForm.controls.totalDistance.touched || submitted) && resetODOForm.controls.totalDistance.errors?.required">
              *{{'Total ODO'|translate}} {{'is required'|translate}}.
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="d-flex align-items-center pt-4 pb-2">
        <button
          mat-raised-button
          class="cancelbtn"
          (click)="submitODO()"
        >
          {{'Save'|translate}}
        </button>
        <button
        mat-raised-button
        class="cancelbtn bg-secondary  ms-3"
        id="btncloseResetSubmit"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        {{'Cancel'|translate}}
        </button>
    </div>
  </div>
</ng-template>


<ng-template #genfencesList>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Geofence'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeNotificationModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form class="bg-light p-1">
      <div class="input-group searchvehicles">
        
        <input
        
          [(ngModel)]="geofenceSearch"
          name="geofenceSearch"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          #geofenceSearchEle
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchGeofenceData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let geo of geofencesList | searchPipe : geofenceSearch; index as i;"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'geo-' + i"
            [checked]="geo.isSelected"
            (change)="
              changeGeofenceEvent($event.target, geo.id)
            "
          />
          <label class="form-check-label" for="geo-{{ i }}">
            {{ geo.name  }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sensorsListModel>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Sensors'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeNotificationModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form class="bg-light p-1">
      <div class="input-group searchvehicles">
        
        <input
        
          [(ngModel)]="sensorsSearch"
          name="sensorsSearch"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          #sensorsSearchEle
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchSensorData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let geo of sensorsList | searchPipe : sensorsSearch; index as i;"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'geo-' + i"
            [checked]="geo.isSelected"
            (change)="
              changeSensorEvent($event.target, geo.id)
            "
          />
          <label class="form-check-label" for="geo-{{ i }}">
            {{ geo.name  }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #maintanenceListModel>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Service'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeNotificationModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form class="bg-light p-1">
      <div class="input-group searchvehicles">
        
        <input
        
          [(ngModel)]="sensorsSearch"
          name="sensorsSearch"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          #sensorsSearchEle
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchSensorData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let geo of servicesList | searchPipe : sensorsSearch; index as i;"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'geo-' + i"
            [checked]="geo.isSelected"
            (change)="
            changeServiceEvent($event.target, geo.id)
            "
          />
          <label class="form-check-label" for="geo-{{ i }}">
            {{ geo.name  }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>