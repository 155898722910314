import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convert',
})

export class convertPipe implements PipeTransform {
  constructor() {}
  transform(milliseconds: any,format="") {
    if(milliseconds) {
      var hours = Math.floor(milliseconds / 3600000);
    var minutes = Math.floor((milliseconds % 3600000) / 60000);
    var seconds = Math.floor(milliseconds % 60);
    if(format == 'hh:mm') {
      return hours.toString().padStart(2, '0')+ ':'+ minutes.toString().padStart(2, '0');
    } else if (format == 'hh:mm:ss') {
      return hours.toString().padStart(2, '0')+ ':'+ minutes.toString().padStart(2, '0')+':'+seconds.toString().padStart(2, '0');
    } else {

      return hours + ' hr ' + minutes + ' min';
    }
    } else {
      if (format == 'hh:mm') {
        return '00:00';
      } else if (format == 'hh:mm:ss') {
        return '00:00:00';
      } else {
        return '0 hr 0 min';
      }      
    }
  }
}
