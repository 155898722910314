import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutesService } from 'src/app/services/routes.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css']
})
export class RouteComponent implements OnInit {

  lat: number = 20.5937;
  lng: number = 78.9629;
  zoom = 6;
  userId = localStorage.getItem("userId");
  routestatus="";
  pageOptionModal=10;
  pageOption=10;
  RouteTable=true;
  fullScreen=false;
  addRouteForm:FormGroup;
  vehiclename="";
  submitted = false;
  routeList=[];

  constructor(private route:RoutesService,private fb:FormBuilder,private customValidator: ValidationService) { 
    this.addRouteForm = this.fb.group(
      {
        sourcename: ['', Validators.required],
        destination: ['', Validators.required],
        address: ['', Validators.required],
        routename: ['', Validators.required],
        vehiclename: ['', Validators.required],
        checkhalt: [false],
        time: ['60', Validators.compose([Validators.required])],
        totaldistance : ['', Validators.required],
      })
  }

  ngOnInit(): void {
    this.getAllRoutes();
  }

  get RouteFormControl() {
    return this.addRouteForm.controls;
  }

  getFullWidth() {
    if (this.fullScreen) {
      document.getElementById("routeForm").style.display = "flex";
      document.getElementById("myMap").style.width = "66.6%";
    }
    else {
      document.getElementById("myMap").style.width = "100%";
      document.getElementById("myMap").style.paddingLeft = "0";
      document.getElementById("routeForm").style.display = "none";
    }
    this.fullScreen = !this.fullScreen;
  }

  onRouteSubmit() {
    console.log("ok");
  }

  onAddRouteBtn(){
    this.RouteTable = false;
    console.log("ok");
  }

  onClickBack(){
    this.RouteTable=true;
    this.addRouteForm.reset();
  }

  getAllRoutes(){
   this.route.getRoutes().subscribe(resp=>{
     console.log(resp);
     this.routeList=resp;
   })
  }

}
