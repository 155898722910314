// MODULES

import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';
import { BootstrapModule } from './modules/bootstrap/bootstrap.module';
import { AgmDrawingModule } from '@agm/drawing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { AgmDirectionModule } from 'agm-direction';
import { NgxPaginationModule } from 'ngx-pagination';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// COMPONENTS

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { MainComponent } from './shared/main/main.component';
import { GeofencesComponent } from './pages/geofences/geofences.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { PoiListComponent } from './pages/poi-list/poi-list.component';
import { DealersComponent } from './pages/dealers/dealers.component';
import { SensorComponent } from './pages/sensor/sensor.component';
import { AdminComponent } from './pages/admin/admin.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { ManagetripsComponent } from './pages/managetrips/managetrips.component';
import { RouteplanComponent } from './pages/routeplan/routeplan.component';
import { RouteComponent } from './pages/route/route.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DeviceModelComponent } from './pages/device-model/device-model.component';
import { DevicesMasterComponent } from './pages/devices-master/devices-master.component';
import { ModelMasterComponent } from './pages/model-master/model-master.component';
import { RTOMasterComponent } from './pages/rto-master/rto-master.component';
import { UserMasterComponent } from './pages/user-master/user-master.component';
import { AccountSettingsComponent } from './pages/Settings/account-settings/account-settings.component';
import { ToastrModule } from 'ngx-toastr';
// PIPE

import { convertPipe } from './pipe/convert.pipe';
import { PipesModule } from './pipes/pipes.module';

// SERVICES

import { InterceptService } from './services/auth/intercept.service';
import { AuthService } from './services/auth.service';
import { ValidationService } from './services/validation.service';
import { DashboardDataService } from './services/dashboard-data.service';
import { GeofenceService } from './services/geofence.service';
import { RoutesService } from './services/routes.service';
import { ReportService } from './services/report.service';
import { UsersettingService } from './services/usersetting.service';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { NotificationComponent } from './pages/Settings/notification/notification.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { ExportDataComponent } from './pages/export-data/export-data.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UserNotificationComponent } from './pages/Settings/user-notification/user-notification.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { SupportComponent } from './pages/support/support.component';
import { BulkUploadComponent } from './shared/bulk-upload/bulk-upload.component';
import { SearchDeviceComponent } from './shared/search-device/search-device.component';
import { CommonModalPopupComponent } from './shared/common-modal-popup/common-modal-popup.component';
import { EventsRemarksComponent } from './pages/events-remarks/events-remarks.component';
import { DeviceGroupsComponent } from './pages/device-groups/device-groups.component';
import { SensorsListComponent } from './pages/sensors-list/sensors-list.component';
import { DeviceServicesComponent } from './pages/device-services/device-services.component';


// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = {
//   url: 'wss://app.multitrack.in:8082/api/socket',
//   options: {
//     extraHeaders: {
//       Cookie: 'cHJhdmVlbkBnbWFpbC5jb206MTIzNDU2',
//     },
//   },
// };
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    MainComponent,
    SidenavComponent,
    BulkUploadComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    TrackingComponent,
    GeofencesComponent,
    ReportsComponent,
    PoiListComponent,
    DealersComponent,
    SensorComponent,
    AdminComponent,
    convertPipe,
    RouteComponent,
    ManagetripsComponent,
    RouteplanComponent,
    CustomersComponent,
    DeviceModelComponent,
    DevicesMasterComponent,
    ModelMasterComponent,
    RTOMasterComponent,
    UserMasterComponent,
    AccountSettingsComponent,
    LicensesComponent,
    DevicesComponent,
    NotificationComponent,
    ExportDataComponent,
    UserNotificationComponent,
    AnnouncementComponent,
    SupportComponent,
    SearchDeviceComponent,
    CommonModalPopupComponent,
    EventsRemarksComponent,
    DeviceGroupsComponent,
    SensorsListComponent,
    DeviceServicesComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    BootstrapModule,
    BrowserAnimationsModule,
    AgmDrawingModule,
    AgmDirectionModule,
    GooglePlaceModule,
    NgxPaginationModule,
    NgApexchartsModule,
    AgmJsMarkerClustererModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCy51wsYZFscnibD6aH3h38nlwjf8WQP_s',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    PipesModule,
    // SocketIoModule.forRoot(config),
  ],

  providers: [
    AuthService,
    ValidationService,
    DashboardDataService,
    GeofenceService,
    ReportService,
    DatePipe,
    DecimalPipe,
    RoutesService,
    UsersettingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
