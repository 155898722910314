<!-- FORGOT PASSWORD-->

<div class="container-fluid">
  <div class="row align-items-center section">
    <div class="content1">
      <div class="forgottext">{{'Forgot Password'|translate}}</div>

      <div class="card">
        <div class="card-body">

          <p class="enteremailtext">{{'Enter your Registered mobile Number. we will send you a confirmation code'|translate}}</p>

          <form class="otpform" [formGroup]="accountVerifyForm" (ngSubmit)="onSubmit(accountVerifyForm)">

            <div class="form__form-group formradio">
              <div class="form__form-group-field">
                <mat-radio-group formControlName="verifyType" class="alignbtns">
                  <mat-radio-button class="radiobtns" value="Email ID">{{'Email ID'|translate}}</mat-radio-button>
                  <mat-radio-button class="radiobtns" value="Mobile Number">{{'Mobile Number'|translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field">
                <input formControlName="email" type="email" placeholder="{{'Enter your Email ID/Mobile'|translate}}"
                  class="inputfields text-white">
              </div>
              <span class="text-danger"
                *ngIf="accountVerifyForm.controls['email'].hasError('required') && (accountVerifyForm.controls['email'].dirty || accountVerifyForm.controls['email'].touched)">
                *{{'Email ID/Mobile is required'|translate}}</span>
            </div>

            <div class="account__btns">
              <button class="btn otp" type="submit">{{'Send OTP'|translate}}</button>
            </div>

          </form>

        </div>
      </div>

      <div class="alreadyuser">{{'Already have an account'|translate}}? <span class="signin" routerLink="/login">Sign in</span> </div>
    </div>
  </div>
</div>

<!-- ENTER CODE -->

<div class="container-fluid">
  <div class="row align-items-center section">
    <div class="content1">
      <div class="forgottext">{{'Enter Code Below'|translate}}</div>

      <div class="card">
        <div class="card-body">

          <p class="enteremailtext m-0">{{'We Sent SMS To'|translate}} +91 9***** 98565</p>

          <p class="changenumber m-0" style="cursor: pointer;" routerLink="/forgotpassword">{{'Change Number'|translate}}</p>

          <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2">
            <input class="m-2 text-center form-control rounded text-white" type="text" id="first" maxlength="1" />
            <input class="m-2 text-center form-control rounded text-white" type="text" id="second" maxlength="1" />
            <input class="m-2 text-center form-control rounded text-white" type="text" id="third" maxlength="1" />
            <input class="m-2 text-center form-control rounded text-white" type="text" id="fourth" maxlength="1" />
            <input class="m-2 text-center form-control rounded text-white" type="text" id="fifth" maxlength="1" />
            <input class="m-2 text-center form-control rounded text-white" type="text" id="sixth" maxlength="1" />
          </div>

          <p class="changenumber m-0" style="color: #fff;">{{"Didn't receive the"|translate}} <span
              style="color:#F84A67;cursor: pointer;"> {{'code'|translate}}?</span></p>

          <div class="account__btns">
            <button class="btn otp" type="submit">{{'Continue'|translate}</button>
          </div>


        </div>
      </div>

      <div class="alreadyuser">{{'Already have an account'|translate}}? <span class="signin" routerLink="/login">Sign in</span> </div>
    </div>
  </div>
</div>

<!-- RESET YOUR PASSWORD-->

<div class="container-fluid">
  <div class="row align-items-center section">
    <div class="content1">
      <div class="forgottext" style="font-size:4.6vh;">{{'Reset Your Password'|translate}}</div>

      <div class="card" style="height: 47vh;">
        <div class="card-body">

          <form class="otpform" [formGroup]="resetForm" (ngSubmit)="onReset()" style="height: 42vh;">

            <div class="form__form-group">
              <div class="form__form-group-field">
                <input type="password" formControlName="password" class="inputfields text-white" placeholder="{{'Password'|translate}}">
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                *{{'Password is required'|translate}}
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.password.touched && registerFormControl.password.errors?.invalidPassword">
                *{{'Password should have minimum 8 characters at least 1 uppercase letter 1 lowercaseletter and 1 number'|translate}}
              </span>
            </div>

            <div class="form__form-group">
              <div class="form__form-group-field">
                <input type="password" formControlName="confirmPassword" class="inputfields text-white"
                  placeholder="{{'Confirm Password'|translate}}">
              </div>
              <span class="text-danger validation"
                *ngIf="(registerFormControl.confirmPassword.touched || submitted)&& registerFormControl.confirmPassword.errors?.required">
                *{{'Confirm Password'|translate}} {{'is required'|translate}}
              </span>
              <span class="text-danger validation"
                *ngIf="registerFormControl.confirmPassword.touched && registerFormControl.confirmPassword.errors?.passwordMismatch">
                *{{'Passwords does not match'|translate}}
              </span>
            </div>

            <div class="enteremailtext" style="font-size:2.2vh;">{{'Use minimum 8 or more characters with mix of letters'|translate}},
              {{'numbers'|translate}}, {{'and'|translate}} {{'symbols'|translate}}.</div>

            <div class="account__btns">
              <button class="btn otp" type="submit">{{'Submit'|translate}}</button>
            </div>

          </form>
        </div>
      </div>
      <div class="alreadyuser">{{'Already have an account'|translate}}? <span class="signin" routerLink="/login">{{'Sign in'|translate}}</span> </div>
    </div>
  </div>
</div>

<!-- NEW PASSWORD SET SUCCESSFULLY -->

<div class="container-fluid">
  <div class="row align-items-center section">
    <div class="content1" style="height: 75vh;">
      <img src="/assets/images/success.png" alt="success" class="successimg">
      <div class="successfully">{{'Your New Password Set successfully'|translate}}</div>
    </div>
  </div>
</div>
