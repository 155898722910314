import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { UsersettingService } from 'src/app/services/usersetting.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-device-model',
  templateUrl: './device-model.component.html',
  styleUrls: ['./device-model.component.css']
})
export class DeviceModelComponent implements OnInit {

  todate = "";
  fromdate = "";
  pageOption = 10;
  deviceModelList = [];
  deviceId = "";
  editForm = false;
  userId = localStorage.getItem("userId");
  page: number = 1;
  addDeviceModelForm: FormGroup;
  editDeviceModelForm: FormGroup;
  submitted = false;
  searchdealer = "";
  imooType="";

  constructor(private device: UsersettingService, private fb: FormBuilder, private customValidator: ValidationService) {
    this.addDeviceModelForm = this.fb.group(
      {
        devicename: ['', Validators.required],
        configcommand: ['', Validators.required],
        locationcommand: ['', Validators.required],
        immocommand: ['', Validators.required],
        resetcommand: ['', Validators.required],
        resumecommand: ['', Validators.required],
        timezone: ['', Validators.required],
        apn: ['', Validators.required],
        port: ['', Validators.required],
        smsIp: ['', Validators.required],
        smsApn: ['', Validators.required],
        smstimezone: ['', Validators.required],
        manufacture: ['', Validators.required],
        Immobilzertype: ['', Validators.required],
      });
    this.editDeviceModelForm = this.fb.group(
      {
        deviceName: ['', Validators.required],
        configCommand: ['', Validators.required],
        locationCommand: ['', Validators.required],
        immoCommand: ['', Validators.required],
        resetCommand: ['', Validators.required],
        resumeCommand: ['', Validators.required],
        Port: ['', Validators.required],
        smsIP: ['', Validators.required],
        smsAPN: ['', Validators.required],
        smsTimezone: ['', Validators.required],
        Manufacture: ['', Validators.required],
        ImmobilzerType: ['', Validators.required],
      });
  }

  ngOnInit(): void {
    this.getAllDeviceModel();
  }

  get AddDriverFormControl() {
    return this.addDeviceModelForm.controls;
  }

  getAllDeviceModel() {
    this.device.getDeviceModel().subscribe(resp => {
      console.log(resp);
      this.deviceModelList = resp;
    })
  }

  onDeviceModelSubmit() {
    let body = {
      "Manufacturer": this.addDeviceModelForm.value.manufacture,
      "config_cmd": this.addDeviceModelForm.value.configcommand,
      "device_apn": this.addDeviceModelForm.value.apn,
      "device_timezone": this.addDeviceModelForm.value.timezone,
      "device_type": this.addDeviceModelForm.value.devicename,
      "deviceport": this.addDeviceModelForm.value.port,
      "imob_cmd": this.addDeviceModelForm.value.immocommand,
      "imobliser_type": this.addDeviceModelForm.value.Immobilzertype,
      "loc_cmd": this.addDeviceModelForm.value.locationcommand,
      "reset_cmd": this.addDeviceModelForm.value.resetcommand,
      "resume_cmd": this.addDeviceModelForm.value.resumecommand,
      'sms_apn': this.addDeviceModelForm.value.smsApn,
      'sms_ip': this.addDeviceModelForm.value.smsIp,
      "sms_timezone": this.addDeviceModelForm.value.smstimezone,
    }
    this.device.addDeviceModel(body).subscribe(resp => {
      Swal.fire({
        icon: "success",
        title: "Your Form has been Submitted",
        showConfirmButton: false,
        timer: 1500,
      });
      this.addDeviceModelForm.reset();
      $('#staticBackdrop').modal("hide");
      this.getAllDeviceModel();
    })
  }

  onEditDeviceModelSubmit() {
    let body = {
      "Manufacturer": this.editDeviceModelForm.value.Manufacture,
      "config_cmd": this.editDeviceModelForm.value.configCommand,
      "device_type": this.editDeviceModelForm.value.deviceName,
      "deviceport": this.editDeviceModelForm.value.Port,
      "imob_cmd": this.editDeviceModelForm.value.immoCommand,
      "imobliser_type":this.editDeviceModelForm.value.ImmobilzerType,
      "loc_cmd": this.editDeviceModelForm.value.locationCommand,
      "reset_cmd": this.editDeviceModelForm.value.resetCommand,
      "resume_cmd":this.editDeviceModelForm.value.resumeCommand,
      'sms_apn': this.editDeviceModelForm.value.smsAPN,
      'sms_ip':this.editDeviceModelForm.value.smsIP,
      "sms_timezone":this.editDeviceModelForm.value.smsTimezone,
      "_id": this.deviceId
    }
    this.device.updateDeviceModel(body).subscribe(resp => {
      Swal.fire({
        icon: "success",
        title: "Your Form has been Updated",
        showConfirmButton: false,
        timer: 1500,
      });
      this.editDeviceModelForm.reset();
      $('#staticBackdrop2').modal("hide");
      this.getAllDeviceModel();
    })
  }

  onDeleteDevice(deviceId) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.device.deleteDeviceModel(deviceId).subscribe(resp => {
          console.log(resp);
          this.getAllDeviceModel();
        });
        Swal.fire(
          'Deleted!',
          'Device has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Device is safe :)',
          'error'
        )
      }
    })
  }

  onEditDevice(device) {
    if(device.imobliser_type=="1"){
      this.imooType = "1.0"
    }
    else if(device.imobliser_type=="0"){
      this.imooType = "0.0"
    }
    this.editDeviceModelForm.setValue({
      deviceName: device.device_type,
      configCommand: device.configuration_command,
      locationCommand:device.location_command,
      immoCommand: device.immoblizer_command,
      resetCommand:device.reset_command,
      resumeCommand: device.resume_command,
      Port:device.deviceport, 
      smsIP: device.sms_ip,
      smsAPN: device.sms_apn,
      smsTimezone: device.sms_timezone,
      Manufacture: device.Manufacturer,
      ImmobilzerType: this.imooType
    })
    this.deviceId = device._id;
  }

  onCloseModal() {
    this.addDeviceModelForm.reset();
  }

  onEditCloseModal(){
    this.editDeviceModelForm.reset();
  }

  onAddDealer() {
    this.addDeviceModelForm.reset();
  }

  values(event) {
    if (event.target.value) {
      this.deviceModelList = this.deviceModelList.filter(s => s.device_type.includes(event.target.value));
      console.log(this.deviceModelList)
    }
    else {
      this.getAllDeviceModel();
    }
  }

}
