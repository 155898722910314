<div class="profile-info">
  <div class="user-img">
    <img
      src="/assets/images/logo.png"
      alt="user"
      routerLink="/main/dashboard"
    />
  </div>
</div>

<div
  class="
    position-relative
    d-flex
    justify-content-center
    flex-column
    align-items-center
  "
>
  <a
    routerLink="/main/dashboard"
    class="default"
    style="display: block"
    [routerLinkActive]="['hide']"
  >
    <img src="/assets/icons/desktop.png" alt="desktop" class="pinkimg" />
  </a>
  <a
    routerLink="/main/dashboard"
    style="display: none"
    [routerLinkActive]="['show']"
  >
    <img src="/assets/icons/desktop_pink.png" class="pinkimg" alt="pink" />
    <label>{{'Dashboard'|translate}}</label>
  </a>

  <a
    routerLink="/main/tracking"
    class="default"
    style="display: block"
    [routerLinkActive]="['hide']"
  >
    <img src="/assets/icons/tracking.png" class="pinkimg" alt="track" />
  </a>
  <a
    routerLink="/main/tracking"
    style="display: none"
    [routerLinkActive]="['show']"
  >
    <img src="/assets/icons/tracking_pink.png" class="pinkimg" alt="track" />
    <label>{{'Tracking'|translate}} </label>
  </a>

  <a
    style="display: block"
    class="default"
    routerLink="/main/geofences"
    [routerLinkActive]="['hide']"
  >
    <img src="/assets/icons/disk.png" alt="geo" class="pinkimg" />
  </a>
  <a
    style="display: none"
    routerLink="/main/geofences"
    [routerLinkActive]="['show']"
  >
    <img src="/assets/icons/disk_pink.png" alt="geo" class="pinkimg" />
    <label>{{'Geofence'|translate}} </label>
  </a>

  <a
    style="display: block"
    class="default"
    routerLink="/main/reports"
    [routerLinkActive]="['hide']"
  >
    <img src="/assets/icons/report.png" alt="report" class="pinkimg" />
  </a>
  <a
    style="display: none"
    routerLink="/main/reports"
    [routerLinkActive]="['show']"
  >
    <img src="/assets/icons/reports_pink.png" alt="geo" class="pinkimg" />
    <label>{{'Reports'|translate}} </label>
  </a>
  <a
    style="display: block"
    class="default"
    routerLink="/main/user/customers"
    [routerLinkActive]="['hide']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/dealer.png" alt="customers" class="pinkimg" />
  </a>
  <a
    style="display: none"
    routerLink="/main/user/customers"
    [routerLinkActive]="['show']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/user_pink.png" alt="Users" class="pinkimg" />
    <label>{{'Customer'|translate}} </label>
  </a>
  <a
    style="display: block"
    class="default"
    routerLink="/main/user/dealers"
    [routerLinkActive]="['hide']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/dealer.png" alt="customers" class="pinkimg" />
  </a>
  <a
    style="display: none"
    routerLink="/main/user/dealers"
    [routerLinkActive]="['show']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/user_pink.png" alt="Users" class="pinkimg" />
    <label>{{'Dealer'|translate}} </label>
  </a>

  <a
    style="display: block"
    class="default"
    routerLink="/main/licenses"
    [routerLinkActive]="['hide']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/money.png" alt="licenses" class="pinkimg" />
  </a>
  <a
    style="display: none"
    routerLink="/main/licenses"
    [routerLinkActive]="['show']"
    *ngIf="userData.administrator || userData.userLimit !== 0"
  >
    <img src="/assets/icons/money.png" alt="Users" class="pinkimg" />
    <label>{{'Licenses'|translate}} </label>
  </a>

  <a
  style="display: block"
  class="default"
  routerLink="/main/devices"
  [routerLinkActive]="['hide']"
 
 
>
  <img src="/assets/icons/whitedevice.png" alt="devices" class="pinkimg" />
</a>
<a
  style="display: none"
  routerLink="/main/devices"
  [routerLinkActive]="['show']"
 
>
  <img src="/assets/icons/whitedevice.png" alt="Users" class="pinkimg" />
  <label>{{'Devices'|translate}} </label>
</a>

<a
*ngIf="userData.administrator || userData.userLimit !== 0"
  style="display: block"
  class="default"
  routerLink="/main/modelMaster"
  [routerLinkActive]="['hide']"
 
 
>
  <img src="/assets/icons/desktop.png" alt="modelMaster" class="pinkimg" />
</a>
<a
*ngIf="userData.administrator || userData.userLimit !== 0"
  style="display: none"
  routerLink="/main/modelMaster"
  [routerLinkActive]="['show']"
 
>
  <img src="/assets/icons/desktop_pink.png" alt="Device Model" class="pinkimg" />
  <label>{{'Device Model'|translate}} </label>
</a>




  <!-- <a style="display: block;" class="default" routerLink="/main/poilist" [routerLinkActive]="['hide']">
    <img src="/assets/icons/joystick.png" alt="poi" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/poilist" [routerLinkActive]="['show']">
    <img src="/assets/icons/joystick_pink.png" alt="poi" class="pinkimg">
    <label>POI List</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/routelist" [routerLinkActive]="['hide']">
    <img src="/assets/icons/route_sidenav.png" alt="timer" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/routelist" [routerLinkActive]="['show']">
    <img src="/assets/icons/route-sidenav1.png" alt="timer" class="pinkimg">
    <label>Route List</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/managetrips" [routerLinkActive]="['hide']">
    <img src="/assets/icons/route_sidenav.png" alt="timer" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/managetrips" [routerLinkActive]="['show']">
    <img src="/assets/icons/route-sidenav1.png" alt="timer" class="pinkimg">
    <label>Manage Trips</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/routeplan" [routerLinkActive]="['hide']">
    <img src="/assets/icons/route_sidenav.png" alt="timer" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/routeplan" [routerLinkActive]="['show']">
    <img src="/assets/icons/route-sidenav1.png" alt="timer" class="pinkimg">
    <label>Route Plan</label>
  </a>

  <ng-container *ngIf="dealer">
    <a style="display: block;" class="default" routerLink="/main/dealers" [routerLinkActive]="['hide']">
      <img src="/assets/icons/dealer.png" alt="dealer" class="pinkimg">
    </a>
    <a style="display: none;" routerLink="/main/dealers" [routerLinkActive]="['show']">
      <img src="/assets/icons/user_pink.png" alt="dealer" class="pinkimg">
      <label>Dealers</label>
    </a>
  </ng-container>

  <ng-container *ngIf="customer">
    <a style="display: block;" class="default" routerLink="/main/customers" [routerLinkActive]="['hide']">
      <span class="material-icons pinkimg" style="color: #fff !important;">
        people_alt
      </span>
    </a>
    <a style="display: none;" routerLink="/main/customers" [routerLinkActive]="['show']">
      <img src="/assets/icons/customers.png" alt="dealer" class="pinkimg">
      <label>Customers</label>
    </a>
  </ng-container>

  <a style="display: block;" class="default" routerLink="/main/sensor" [routerLinkActive]="['hide']">
    <img src="/assets/icons/wifi.png" alt="wifi" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/sensor" [routerLinkActive]="['show']">
    <img src="/assets/icons/wifi_pink.png" alt="wifi" class="pinkimg">
    <label>Sensor</label>
  </a> -->

  <div class="btn-group dropend">
    <!-- <button type="button" class="default" class="btn dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
      <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
      <!-- <label *ngIf="check">Admin</label> --
    </button> -->
    <!-- <button type="button" class="default" class="btn dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
      <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
      <label>Admin</label>
    </button> -->
    <!-- <div class="dropdown-menu flex-column dropmenu">
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/admin"><span>Driver Master</span> <img src="/assets/icons/menurighticon.png" alt="right"></a>
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/deviceModel"><span> Device Model </span><img src="/assets/icons/menurighticon.png" alt="right"></a>
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/devicesMaster"><span>Devices Master</span>  <img src="/assets/icons/menurighticon.png" alt="right"></a>
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/modelMaster"><span>Model Master</span>  <img src="/assets/icons/menurighticon.png" alt="right"></a>
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/rtomaster"><span>RTO Master </span> <img src="/assets/icons/menurighticon.png" alt="right"></a>
      <a class="dropdown-item submenu flex_row_center_spacebetween" routerLink="/main/usermaster"><span>User Master </span> <img src="/assets/icons/menurighticon.png" alt="right"></a>
    </div> -->
  </div>

  <!-- <a style="display: block;" class="default" routerLink="/main/admin" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a> -->
  <!-- <a style="display: none;" routerLink="/main/admin" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>Admin</label>
  </a> -->

  <!-- <a style="display: block;" class="default" routerLink="/main/deviceModel" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/deviceModel" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>Device Model</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/devicesMaster" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/devicesMaster" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>Devices Master</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/modelMaster" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/modelMaster" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>Model Master</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/rtomaster" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/rtomaster" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>RTO Master</label>
  </a>

  <a style="display: block;" class="default" routerLink="/main/usermaster" [routerLinkActive]="['hide']">
    <img src="/assets/icons/admin_control.png" alt="admin" class="pinkimg">
  </a>
  <a style="display: none;" routerLink="/main/usermaster" [routerLinkActive]="['show']">
    <img src="/assets/icons/admin_pink.png" alt="admin" class="pinkimg">
    <label>User Master</label>
  </a> -->
</div>
