import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDuration',
  pure: true,
})
export class DateDurationPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (value) {
      let nowDate = new Date();
      if (args) {
        nowDate = new Date(args);
      }
      const seconds = Math.floor((+nowDate - +new Date(value)) / 1000);
      const intervals: any = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i; // singular (1 day ago)
          } else {
            return counter + ' ' + i; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
