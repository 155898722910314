import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import * as moment from 'moment-timezone';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent implements OnInit {

  todate = "";
  fromdate = "";
  dealerId = "";
  addDevice = "";
  addCustomer = "";
  pageOption = 10;
  pageOptionModal = 10;
  p: number = 1;
  submitted = false;
  userId = localStorage.getItem("userId");
  dealersList = [];
  timezoneArray = [];
  availability: any;
  addDealerForm: FormGroup;
  editDealerForm: FormGroup;

  documentList = [
    {
      "docId": "Adhar",
      "docName": "Adhar Card"
    },
    {
      "docId": "voterCard",
      "docName": "Voter Id"
    },
    {
      "docId": "PAN",
      "docName": "Pan Card"
    },
    {
      "docId": "DL",
      "docName": "Driving License"
    },
    {
      "docId": "Nepali Citizenship",
      "docName": "Nepali Citizenship"
    }
    
  ];
  @Input()
  addMode = false;
  @Input()
  gridMode = false;

  constructor(private user: UsersettingService, 
    private fb: FormBuilder, 
    private customValidator: ValidationService,
    private commonService: CommonService
    ) {
    this.addDealerForm = this.fb.group(
      {
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        usernumber: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
        confirmpassword: ['', Validators.required],
        address: ['', Validators.required],
        timezone: ['', Validators.required],
        userid: ['', Validators.required],
        radiocheck: [false],
        bussinessType: [false],
        doctype: ['', Validators.required],
        docnumber: ['', Validators.required],
      },
      {
        validator: this.customValidator.MatchPassword('password', 'confirmpassword')
      }
    );

    this.editDealerForm = this.fb.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        userids: ['', Validators.required],
        expdate: ['', Validators.required],
        addresss: ['', Validators.required],
        emailid: ['', Validators.required],
        phonenumber: ['', Validators.required]
      }
    );
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "dealer") {
        this.gridMode = true;
        this.addMode = false;
      }
    })
  }

  ngOnInit(): void {
    this.getAllDealers();
    this.getUserAvailability();
    var timeZones = moment.tz.names();
    this.timezoneArray = [];
    for (var i in timeZones) {
      this.timezoneArray.push({ viewValue: "(GMT" + moment.tz(timeZones[i]).format('Z') + ")" + timeZones[i], value: timeZones[i] });
    }
  }

  get AddDealerFormControl() {
    return this.addDealerForm.controls;
  }

  getAllDealers() {
    this.user.getDealers().subscribe(resp => {
      this.dealersList = resp;
    })
  }

  getUserAvailability() {
    this.user.userCheckAvailability().subscribe(resp => {
      console.log(resp);
      this.availability = resp;
    })
  }

  onDealerSubmit() {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var expDate = new Date(year + 2, month, day);

    let body = {
      "address": this.addDealerForm.value.address,
      "bussinessType": this.addDealerForm.value.bussinessType ? '0' : '1',
      'custumer': false,
      "email": this.addDealerForm.value.email,
      "expdate": expDate,
      'first_name': this.addDealerForm.value.firstname,
      "imageDoc": [{
        "doctype": this.addDealerForm.value.doctype,
        "image": "",
        "phone": this.addDealerForm.value.docnumber
      }],
      "isDealer": true,
      "last_name": this.addDealerForm.value.lastname,
      "password": this.addDealerForm.value.password,
      "phone": this.addDealerForm.value.usernumber,
      "std_code": {
        "countryCode": "in",
        "dialcode": "91",
      },
      "supAdmin": this.userId,
      "sysadmin": true,
      "timezone": this.addDealerForm.value.timezone,
      "user_id": this.addDealerForm.value.userid
    }
    this.submitted = true;
    this.user.addDealer(body).subscribe((resp) => {
      console.log(resp);
      this.addDealerForm.reset();
      $('#staticBackdrop').modal("hide");
      this.getAllDealers();
    });

  }

  onClickEdit(dealer) {
    this.editDealerForm.setValue({
      first_name: dealer.first_name,
      last_name: dealer.last_name,
      userids: dealer.user_id,
      expdate: dealer.expire_date.split("T")[0],
      addresss: dealer.address,
      emailid: dealer.email,
      phonenumber: dealer.phone
    })
    this.dealerId = dealer._id;
  }

  onEditDealerSubmit() {
    let body = {
      "address": this.editDealerForm.value.addresss,
      "contactid": this.dealerId,
      "expire_date": this.editDealerForm.value.expdate,
      "first_name": this.editDealerForm.value.first_name,
      "last_name": this.editDealerForm.value.last_name,
      "user_id": this.editDealerForm.value.userids,
      "email": this.editDealerForm.value.emailid,
      "phone": this.editDealerForm.value.phonenumber,
    }
    this.user.editDealer(body).subscribe(resp => {
      console.log(resp);
      $('#staticBackdrop4').modal('hide');
      this.getAllDealers();
    })
  }

  onClickDealerPermission(device) {
    this.addDevice = device.device_add_permission;
    this.addCustomer = device.cust_add_permission;
  }

  onClickDelete(_id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this Dealer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          "deleteuser": true,
          "userId": _id
        }
        this.user.deleteDealer(body).subscribe(resp => {
          console.log(resp);
          this.getAllDealers();
        });
        Swal.fire(
          'Deleted!',
          'Dealer has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Dealer is safe :)',
          'error'
        )
      }
    })
  }

}
