

import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsersettingService } from 'src/app/services/usersetting.service';
import { ValidationService } from 'src/app/services/validation.service';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment-timezone';
import { ExternalLibraryService } from 'src/app/services/payment.service';
import pdfMake from 'pdfmake/build/pdfmake';
import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';
import { HttpParams } from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { convertPipe } from 'src/app/pipe/convert.pipe';
import { MeterTokm } from 'src/app/pipes/meter-km.pie';
declare var $: any;
declare let Razorpay: any;
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css'],
  providers: [DatePipe, MeterTokm, convertPipe]
})
export class DevicesComponent implements OnInit {
  @ViewChild('pdfTable') pdfTable: ElementRef;
  form:FormGroup;
  modelForm:FormGroup;
  resetODOForm:FormGroup;
  addDevice : any ={};
  pageOption = 10;
  pageOptionModal = 10;
  page: number = 1;
  submitted = false;
  userId = localStorage.getItem('userId');
  userData:any = {};
  isNormalUser = true;
  devicesList = [];
  devicesListTotalCount = 0;
  geofencesList = [];
  sensorsList = [];
  servicesList = [];
  addDevicesForm: any;
  editCustomerForm: FormGroup;
  timezoneArray = [];
  showModal = false;
  deviceList: any = [];
  errors: any = {};
  notificationsMasterList= [];
  attrList: any = [
    {
      id: 'speedLimit',
      name: 'Speed Limit',
      type: 'number',
    },
    {
      id: 'ignitionwire',
      name: 'ignition Wire',
      type: 'number',
    },
    {
      id: 'parkingMode',
      name: 'Parking Mode',
      type: 'number',
    },
    {
      id: 'timezone',
      name: 'Timezone',
      type: 'string',
    },
    {
      id: 'deviceInactivityStart',
      name: 'Device Inactivity Start',
      type: 'number',
    },
    {
      id: 'deviceInactivityPeriod',
      name: 'Device Inactivity Period',
      type: 'number',
    },
    {
      id: 'mileage',
      name: 'Mileage',
      type: 'string',
    },
    {
      id: 'relay',
      name: 'Relay',
      type: 'boolean',
    },
    {
      id: 'manufacturer',
      name: 'Manufacturer',
      type: 'string'
    },
    {
      id: 'model',
      name: 'Model',
      type: 'string'
    },
    {
      id: 'colors',
      name: 'Colors',
      type: 'string'
    },
    {
      id: 'chasis',
      name: 'Chasis No',
      type: 'string'
    },
    {
      id: 'engine',
      name: 'Engine No',
      type: 'string'
    }
  ];
  deviceCategoryList: any = [
    { id: 'default', name: 'Default' },
    { id: 'animal', name: 'Animal' },
    { id: 'bicycle', name: 'Bicycle' },
    { id: 'boat', name: 'Boat' },
    { id: 'bus', name: 'Bus' },
    { id: 'car', name: 'Car' },
    { id: 'crane', name: 'Crane' },
    { id: 'helicopter', name: 'Helicopter' },
    { id: 'motorcycle', name: 'Motorcycle' },
    { id: 'offroad', name: 'Offroad' },
    { id: 'person', name: 'Person' },
    { id: 'pickup', name: 'Pickup' },
    { id: 'plane', name: 'Plane' },
    { id: 'ship', name: 'Ship' },
    { id: 'tractor', name: 'Tractor' },
    { id: 'train', name: 'Train' },
    { id: 'tram', name: 'Tram' },
    { id: 'trolleybus', name: 'Trolleybus' },
    { id: 'truck', name: 'Truck' },
    { id: 'van', name: 'Van' },
    { id: 'scooter', name: 'Scooter' },
  ];
  addAttr: any = {
    attribute: '',
    type: '',
    value: null,
  };
  modalRef?: BsModalRef;
  ModelMasterList:any[] = [];
  @Input()
  addMode = false;
  @Input()
  gridMode = false;
  @Input()
  reportMode = false;
  geofenceSearch:any = '';
  sensorsSearch:any = '';
  selectedDeviceForGeofence: any = '';
  selectedDeviceForSensor: any = '';
  selectedEditDeviceForOdo: any = {};
  selectedDeviceForReport: any = {};
  @Input()
  calledFrom = '';
  sortcolumnName = 'name';
  sortdirection = '';
  isAscending = false;
  groupListData:any[] = [];
  searchKey = '';
  searchValue = '';
  reportData: any = {
    engineno: '',
    chassisno: '',
    ownername: '',
    owneraddress: '',
    ownermobileno: '',
    ownertype: '',
    owneridno: '',
    distributorname: '',
    distributoraddress: '',
    distributormobileno: ''
  };
  tableForPdfColumns:any[] = [];
  tableForPdfContent: any[][] = [];
  htmlString: string = '';
  siteDetails :any;
  selectedDeviceForServices: any = {};
  constructor(
    private modalService: BsModalService,
    private user: UsersettingService,
    private fb: FormBuilder,
    private customValidator: ValidationService,
    private commonService: CommonService,
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef,
    private masterModel : UsersettingService,
    private datepipe: DatePipe,
    private numberpipe: DecimalPipe,
    private translatepipe: TranslateService,
    private metertokm: MeterTokm,
    private convertpipe: convertPipe
  ) {
    this.siteDetails = this.commonService.getSiteDetails();
    this.commonService.pageLoadInModal.subscribe((result: string) => {
      if (result == "devices" && this.calledFrom == '' ) {
        this.gridMode = true;
        this.addMode = false;  
        this.reportMode = false;      
      } else if (result == "devices" && this.calledFrom == 'customer') {
        this.gridMode = false;
        this.addMode = true;
        this.reportMode = false;
        this.addDevicepopup();
      } else if (result == "devices" && this.calledFrom == 'tracking') {
        this.gridMode = false;
        this.addMode = true;
        this.reportMode = false;
        this.addDevicepopup();
      }
    });
    let userData = localStorage.getItem('userData');
   this.userData = userData ? JSON.parse(userData) : {};
   this.isNormalUser = !(this.userData.administrator || this.userData.userLimit !== 0);
  }
  
  ngOnInit(): void {
    this.getAllGroupData();
    this.form = new FormGroup({
      searchKey: new FormControl('-1'),
      searchValue: new FormControl(''),
    });
    this.resetODOForm = new FormGroup({
      hours: new FormControl(''),
      deviceId: new FormControl(''),
      totalDistance: new FormControl(''),
    });
    this.modelForm = new FormGroup({
      searchValue: new FormControl(''),
    });
    
    this.masterModel.getModelMaster().subscribe(resp => {
      console.log(resp);
      this.ModelMasterList = resp;
    })
    this.getAllGeofences();
    
  }

  submitODO() {
    //this.resetODOForm.value;
    this.commonService
          .putRequest('devices/' + this.resetODOForm.value.deviceId +'/accumulators', this.resetODOForm.value)
          .then((resp: any) => {
            this.getAllDevices();
            this.cancelODO();
            //$('#btncloseResetSubmit').click();
            Swal.fire({
              icon: 'success',
              title: 'Device Data has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          },(err)=>{
            //console.log(err);
            //  Swal.fire({
            //    icon: "error",
            //    title: "Something Went Wrong",
            //    showConfirmButton: false,
            //    timer: 1500,
            //  });
           });

           setTimeout(() => {
              this.getAllDevices();
              this.cancelODO();              
              Swal.fire({
                icon: 'success',
                title: 'Device Data has been Updated Successfully',
                showConfirmButton: false,
                timer: 1500,
              });
           }, 100);
          
  }
  newDevice() {

  }
  resetODODetails(device, modal) {    
    this.modalRef = this.modalService.show(modal);
    console.log(device);
    this.resetODOForm.controls.hours.setValue(device.totalDistance ?device.totalDistance:0 );
    this.resetODOForm.controls.deviceId.setValue(device.id);
    this.resetODOForm.controls.totalDistance.setValue( device.attributes && device.attributes.totalDistance ?device.attributes.totalDistance : 0);
  }
  editDevice(device) {
    this.gridMode = false;
    this.addMode = true;
    this.reportMode = false;
    console.log('device', device);
    let attributes = [];

    let attrLength = Object.keys(device.attributes);
    if (attrLength?.length) {
      attrLength.forEach((a) => {
        let dataSet = {
          attribute: '',
          type: '',
          value: null,
        };
        switch (a) {
          
          case 'speedLimit':
            if (true) {
              dataSet.attribute = 'speedLimit';
              dataSet.type = 'number';
              dataSet.value = device.attributes[a];
            }
            break;
            case 'ignitionwire':
              if (true) {
                dataSet.attribute = 'ignitionwire';
                dataSet.type = 'boolean';
                dataSet.value = device.attributes[a];
              }
              break;
              case 'parkingMode':
            if (true) {
              dataSet.attribute = 'parkingMode';
              dataSet.type = 'boolean';
              dataSet.value = device.attributes[a];
            }
            break;
          case 'decoder.timezone':
            dataSet.attribute = 'timezone';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityStart':
            dataSet.attribute = 'deviceInactivityStart';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'deviceInactivityPeriod':
            dataSet.attribute = 'deviceInactivityPeriod';
            dataSet.type = 'number';
            dataSet.value = device.attributes[a];
            break;
          case 'mileage':
            dataSet.attribute = 'mileage';
            dataSet.type = 'string';
            dataSet.value = device.attributes[a];
            break;
          default:
            dataSet.attribute = a;
            dataSet.type = '';
            dataSet.value = device.attributes[a];
            break;
        }
        console.log('dataSet', dataSet);
        if (dataSet.attribute) {
          attributes.push(dataSet);
        }
      });
    }
    
    this.addDevice = {
      id: device.id,
      name: device.name,
      identifier: device.uniqueId,
      groupId: device.groupId,
      phone: device.phone,
      model: device.model,
      otherModelValue:'',
      contact: device.contact,
      category: device.category,
      distanceForday:device.distanceForday,
      disabled: device.disabled,
      expirationTime:device.expirationTime ? moment(new Date(device.expirationTime)).format('yyyy-MM-DD') : '',
      attributes: attributes,
    };
   //
   let modelValue = this.ModelMasterList.find(mData=> mData.modelName == device.model);
    if(!modelValue) {
      this.addDevice.otherModelValue = device.model;
      this.addDevice.model = 'other';
    }
  }
 
  searchData(val: any) {
    this.page = 1;
    if (val == 'key') {
      this.form.patchValue({
        searchValue: ''
      });
    }
    this.deviceFilter();    
  }
  deviceFilter() {
    if (this.form.value.searchKey == "-1") {
      this.form.patchValue({
        searchValue: ''
      });
      this.getAllDevices();
    }
    else if (this.form.value.searchKey != "-1") {      
      this.getAllDevices('key='+this.form.value.searchKey+'&value='+this.form.value.searchValue);
    }
  }
  getAllDevices(url?:any) {    
    let urlwithparam = `devices/getUsersforDevices?limit=${this.pageOption == -1?99999:this.pageOption}&offset=${+(this.page - 1)}`;
    if (url) {
      urlwithparam += '&'+url;
    }
    this.commonService.getRequest(urlwithparam, []).then((resp: any) => {
      if (resp?.status) {
        if (resp.data.data.length > 0) {
          resp.data.data.forEach(ele => {
            let match = this.groupListData.findIndex(x => x.id == ele.device.groupId);
            if (match >= 0) {
              ele.device['group_name'] = this.groupListData[match].name;
            } else {
              ele.device['group_name'] = "";
            }
          })
          this.devicesList = resp.data.data;
          this.devicesListTotalCount = resp.data.count.devicescount;
        } else {
          this.devicesList = resp.data.data;
          this.devicesListTotalCount = resp.data.count.devicescount;
        }
        this.sorting("creation_date");
      }
    });
  }
  pageClickedEvent(event: any) {
    console.log(event);
    this.page = event;
    this.deviceFilter();
  }
  addDevicepopup() {
    this.gridMode = false;
    this.addMode = true;
    this.reportMode = false;
    this.addDevice = {
      id: null,
      name: '',
      expirationTime:moment().add(1, 'years').format('yyyy-MM-DD'),
      identifier: '',
      groupId: '',
      phone: '',
      model: '',
      contact: '',
      category: 'Default',
      disabled: false,
      attributes: [
          {
            "attribute": "speedLimit",
            "type": "",
            "value": 60
          },{
            "attribute": "mileage",
            "type": "",
            "value": 10
        },{
            "attribute": "ignitionwire",
            "type": "",
            "value": true
        },{
          "attribute": "parkingMode",
          "type": "",
          "value": false
      }],
    };
  }

  deviceSubmit() {
    let modelValue = this.addDevice && this.addDevice.model ? this.addDevice.model : '';
    if(this.addDevice && this.addDevice.model == 'other') {
      modelValue = this.addDevice.otherModelValue;
    } 
    this.errors = {};
    if (this.addDevice.name && this.addDevice.identifier) {
      let attributes = {};
      // decoder.timezone: "24"
      // deviceInactivityPeriod: 26
      // deviceInactivityStart: 25
      // speedLimit: 23

      if (this.addDevice?.attributes?.length) {
        this.addDevice.attributes.forEach((a) => {
          switch (a.attribute) {
            
            case 'speedLimit':
              attributes['speedLimit'] = a.value;
              break;
            case 'ignitionwire':
              attributes['ignitionwire'] = a.value;
              break;
            case 'parkingMode':
              attributes['parkingMode'] = a.value;
              break;
            case 'timezone':
              attributes['decoder.timezone'] = a.value;
              break;
            case 'deviceInactivityStart':
              attributes['deviceInactivityStart'] = a.value;
              break;
            case 'deviceInactivityPeriod':
              attributes['deviceInactivityPeriod'] = a.value;
              break;
            case 'relay':
              attributes['relay'] = a.value;
              break;
            case 'mileage':
              attributes['mileage'] = a.value;
              break;
            default:
              attributes[a.attribute] = a.value;
                break;
          }
        });
      }
      
      if (this.addDevice?.id) {
        this.commonService
          .putRequest('devices/' + this.addDevice.id, {
            id: this.addDevice.id,
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: modelValue,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            distanceForday:this.addDevice.distanceForday,
            attributes: attributes,
            expirationTime:new Date(this.addDevice.expirationTime)
          })
          .then((resp: any) => {
            this.gridMode = true;
            this.addMode = false;
            this.reportMode = false;
            this.getAllDevices();
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          },(err)=>{
            console.log(err);
             Swal.fire({
               icon: "error",
               title: err && err.data ? err.data : "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
      } else {
        this.commonService
          .postRequest('devices', {
            name: this.addDevice.name,
            uniqueId: this.addDevice.identifier,
            groupId: this.addDevice.groupId,
            phone: this.addDevice.phone,
            model: modelValue,
            contact: this.addDevice.contact,
            category: this.addDevice.category,
            disabled: this.addDevice.disabled,
            expirationTime:new Date(this.addDevice.expirationTime),
            attributes: attributes,
          })
          .then((resp: any) => {
            if (this.calledFrom == '' || this.calledFrom != 'customer') {
              this.gridMode = true;
              this.addMode = false;
              this.reportMode = false;
              this.getAllDevices();
            }           
            $('#btnclose').click();
            Swal.fire({
              icon: 'success',
              title: 'Your device has been Submitted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
            if (this.calledFrom == 'customer') {
              this.gridMode = false;
              this.addMode = false;
              this.reportMode = false;
              this.commonService.commonModel.next(false);
              setTimeout(() => {
                this.commonService.headerEvent.next({value:'customer', calledFrom: 'devices', response: resp});
              }, 100);                         
            }  
          },(err)=>{            
             Swal.fire({
               icon: "error",
               title: err && err.data ? err.data : "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
      }
    } else {
      if (!this.addDevice.name) {
        this.errors['Devicename'] = 'This field is required';
      }
      if (!this.addDevice.identifier) {
        this.errors['Deviceidentifier'] = 'This field is required';
      }
    }
  }

  deleteDevice(id) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this device!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          deleteuser: true,
          userId: id,
        };
        this.commonService
          .deleteRequest('devices/' + id)
          .then((resp) => {
            this.gridMode = true;
            this.addMode = false;
            this.reportMode = false;
            this.getAllDevices();
          })
          .catch((e) => {
            this.gridMode = true;
            this.addMode = false;
            this.reportMode = false;
            this.getAllDevices();
          });
        Swal.fire('Deleted!', 'Device has been deleted.', 'success');
      }
    });
  }
  onPopupClose(data) {
    this.gridMode = true;
    this.addMode = false;
    this.reportMode = false;
    this.getAllDevices();
  }
  addAttri(template: TemplateRef<any>) {
    this.addAttr = {
      attribute: '',
      type: '',
      value: null,
    };
    this.modalRef = this.modalService.show(template);
  }

  deleteAttr(key) {
    this.addDevice.attributes = this.addDevice.attributes.filter(
      (a, k) => k !== key
    );
  }
  changeAttrEvent(e) {
    // let findAttrName = this.attrList.find((a) => a.id === e.value);
    // if (findAttrName) {
    //   this.addAttr.type = findAttrName.type;
    // } else {
    //   this.addAttr.type = '';
    // }
  }
  checkboxEvent(e: any) {
    this.addDevice.disabled = e.checked;
  }

  deviceNotifications(device, modal) {
    this.modalRef = this.modalService.show(modal);
    this.notificationsMasterList = [];
    console.log('---------', device);
    this.commonService.getRequest('notifications', []).then((resp: any) => {
      if (resp?.status) {
        this.notificationsMasterList = resp.data;
        this.getDeviceNotifications(device.id);
      }
    });
  }
  getDeviceNotifications(deviceId) {
    this.commonService
      .getRequest('notifications', ['deviceId=' + deviceId])
      .then((resp: any) => {
        if (resp?.status) {
          let mappedId = resp.data.map((d: any) => {
            return d.id;
          });
          this.notificationsMasterList.forEach((d: any, k: number) => {
            this.notificationsMasterList[k]['mapDeviceId'] = deviceId;
            this.notificationsMasterList[k]['isSelectedDevice'] =
              mappedId.indexOf(d.id) > -1;
          });
          
        }
      });
  }

  changeEvent(e: any, notificationId,deviceId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: deviceId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: deviceId,
          notificationId: notificationId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  addAttrbute() {
    if (this.addAttr.attribute) {
      let find = this.addDevice.attributes.find(
        (a) => a.attribute === this.addAttr.attribute
      );
      if (!find) {
        this.addDevice.attributes.push(this.addAttr);
      }
      this.modalRef?.hide();
    }
  }
  dealerUsersList=[];
  displyDealerUserList(device, modal){
    this.modalRef = this.modalService.show(modal);
    this.dealerUsersList = device.userData  ? device.userData : [];
  }
  displayDeviceUsersList(data: any) {
    let str = '';
    data.forEach(ele => {
      str += ele.userName + ", ";
    });    
    return str.substr(0, str.length - 2);
  }
  onDeviceCancel() {
    this.gridMode = true;
    this.addMode = false;
    this.reportMode = false;
  }
  closeUserModal() {
    this.modalRef.hide();
  }
  closeNotificationModal() {
    this.selectedDeviceForGeofence = '';
    this.selectedDeviceForSensor = '';
    this.modalRef.hide();
  }
  closeHoursModal() {
    this.modalRef.hide();
  }
  assignGeofence(deviceId: any, modal) { 
    this.deselectAllGeofenceList();   
    this.selectedDeviceForGeofence = deviceId;
    this.getAllGeofences(deviceId);
    this.modalRef = this.modalService.show(modal);
    //this.geofencesList = device.userData  ? device.userData : [];
  }
  getAllGeofences(deviceId?: any) { 
    let url = '';
    url = 'geofences';
    if (deviceId) {
      url = url+'?deviceId='+deviceId;
    } 
    
    this.commonService.getRequest(url, []).then((resp: any) => {
      if (resp?.status) {
        if (deviceId) {
          resp.data.forEach((ele) => {
            let match = this.geofencesList.findIndex((x) => x.id == ele.id);
            if (match >= 0) {
              this.geofencesList[match]['isSelected'] = true; 
            }            
          });
        } else {          
          this.geofencesList = resp.data; 
          this.deselectAllGeofenceList();
        }      
      }
    });
  }
  deselectAllGeofenceList() {
    this.geofencesList.forEach((ele, index) => {
      this.geofencesList[index]['isSelected'] = false;            
    });
  }
  searchGeofenceData() {}
  changeGeofenceEvent(e: any, fenceId) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: this.selectedDeviceForGeofence,
          geofenceId: fenceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: this.selectedDeviceForGeofence,
          geofenceId: fenceId,
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  editOdoData(device, index) {
    this.cancelODO();   
    if (this.selectedEditDeviceForOdo && this.selectedEditDeviceForOdo?.device) { 
      this.setOdoEditFalse();
    }
    device['isEdit'] = true;
    this.selectedEditDeviceForOdo = device;
    this.resetODOForm.controls.hours.setValue(device.device.attributes && device.device.attributes.hours ?device.device.attributes.hours:0 );
    this.resetODOForm.controls.deviceId.setValue(device.device.id);
    this.resetODOForm.controls.totalDistance.setValue( device.device.attributes && device.device.attributes.totalDistance ?device.device.attributes.totalDistance : 0);
  }
  cancelODO() {
    //this.resetODOForm.reset();
    if (this.selectedEditDeviceForOdo && this.selectedEditDeviceForOdo?.device) { 
      this.setOdoEditFalse();
    }
  }
  setOdoEditFalse() {
    let match = this.devicesList.findIndex(x => x.device.uniqueId === this.selectedEditDeviceForOdo.device.uniqueId);
    if (match >= 0) {
      this.devicesList[match].isEdit = false;
    }
  }
  searchInsideUsers(val: any[], filterVal: any) {
    let match = false;
    if (val && val.length > 0) {
      val.forEach(element => {
        match = element.userName.toLowerCase().indexOf(filterVal.toLowerCase()) >= 0      
      });
    }
    return match;
  }
  setFilterPagination(filter: any) {
    
      let deviceMatchedArray = this.deviceList.filter(x => x.device.name.indexOf(filter) >= 0).length;
      let userMatchedArray = this.deviceList.filter(x => x.userData.filter(y => y.userName.indexOf(filter) >= 0)).length;
      if (userMatchedArray > 0) {
        return userMatchedArray;
      } else {
        return deviceMatchedArray;
      }
    
      //return this.deviceList.length;
  }

  sorting(col: any) {
    this.sortcolumnName = col;
    this.isAscending = !this.isAscending;
    this.sortdirection = this.isAscending? "asc":"desc";
    this.sortingFunction(this.sortcolumnName, this.sortdirection);
  }

  sortingFunction(column, direction) {
    if (column) {
      //this.deviceList.forEach(element => {
        this.devicesList.sort((a,b)=>{
          if(a.device[column] > b.device[column]){
            return (direction === 'desc') ? 1 : -1;
          }
          if(a.device[column] < b.device[column]){
            return (direction === 'desc') ? -1 : 1;
          }
          return 0;
        });        
      //})
      
    } else {
      return this.devicesList;
    }
  }
  getAllGroupData() {
    this.commonService.getRequest("groups", []).then((resp: any) => {
      if (resp?.status) { 
        this.groupListData = resp.data;
        this.getAllDevices();
      }
    })
  }

  assignServices(deviceId: any, modal) { 
    //this.deselectAllGeofenceList();   
    this.selectedDeviceForServices = deviceId;
    this.getAllServicesData(deviceId);
    this.modalRef = this.modalService.show(modal);
    //this.geofencesList = device.userData  ? device.userData : [];
  }
  getAllServicesData(deviceId: any) {
    this.commonService.getRequest("maintenance", []).then((resp: any) => {
      if (resp?.status) { 
        this.servicesList = resp.data; 
        this.servicesList.forEach((ele, index) => {
          this.servicesList[index]['isSelected'] = false;            
        });
        this.getAssignedServicesData(deviceId);
      }
    })
  }
  changeServiceEvent(e: any, Id) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          deviceId: this.selectedDeviceForServices, 
          maintenanceId: Id                  
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          deviceId: this.selectedDeviceForServices,
          maintenanceId: Id          
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  getAssignedServicesData(deviceId: any) {
    this.commonService.getRequest("maintenance?deviceId="+deviceId, []).then((resp: any) => {
      if (resp?.status) { 
        //this.servicesList = resp.data;
        //console.log(resp.data) ;
        resp.data.forEach((ele, index) => {
          let match = this.servicesList.findIndex(x => x.id == ele.id);
          if (match >= 0) {
            this.servicesList[match]['isSelected'] = true; 
          }          
        });   
      }
    })
  }
  assignSensor(deviceId: any, modal) { 
    //this.deselectAllGeofenceList();   
    this.selectedDeviceForSensor = deviceId;
    this.getAllSensorData(deviceId);
    this.modalRef = this.modalService.show(modal);
    //this.geofencesList = device.userData  ? device.userData : [];
  }
  getAllSensorData(deviceId: any) {
    this.commonService.getRequest("calibrations/usercalibrations/"+this.userData.id, []).then((resp: any) => {
      if (resp?.status) { 
        this.sensorsList = resp.data;    
      }
    })
  }
  changeSensorEvent(e: any, Id) {
    if (e.checked) {
      this.commonService
        .postRequest('permissions', {
          calibrationid: Id,
          deviceid: this.selectedDeviceForSensor          
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    } else {
      this.commonService
        .deleteRequest('permissions', {
          calibrationid: Id,
          deviceid: this.selectedDeviceForSensor          
        })
        .then((resp: any) => {
          if (resp?.status) {
          }
        });
    }
  }
  generateReport(val: any) {
    this.selectedDeviceForReport = val;
    this.reportMode = true;
    this.gridMode = false;
    this.addMode = false;
  }
  createReport() {
    let tableList = [];
    tableList = [
      {
        titleName: 'Device Name',
        type: '',
        filter: '',
        hirarchy: [],
        isHirarchy: false,
        colName: 'deviceName',
      },
      {
        titleName: 'Start Date',
        type: 'date',
        filter: '',
        format: 'dd/MM/yyyy hh:mm a',
        hirarchy: [],
        isHirarchy: false,
        colName: 'startTime',
      },
      {
        titleName: 'End Date',
        type: 'date',
        filter: '',
        format: 'dd/MM/yyyy hh:mm a',
        hirarchy: [],
        isHirarchy: false,
        colName: 'endTime',
      },
      {
        titleName: 'Distance',
        type: '',
        filter: 'meterTokm',
        format: '',
        hirarchy: ['distance'],
        isHirarchy: true,
        colName: 'distance',
      },
      {
        titleName: 'Odometer Start',
        type: '',
        filter: 'meterTokm',
        format: '',
        hirarchy: ['startOdometer'],
        isHirarchy: true,
        colName: 'startOdometer',
      },
      {
        titleName: 'Odometer End',
        type: '',
        filter: 'meterTokm',
        format: '',
        hirarchy: ['endOdometer'],
        isHirarchy: true,
        colName: 'endOdometer',
      },          
      {
        titleName: 'Average Speed',
        type: 'number',
        filter: 'userbased',
        format: '1.0-2',
        hirarchy: [],
        isHirarchy: false,
        colName: 'averageSpeed',
      }, {
        titleName: 'Maximum Speed',
        type: 'number',
        filter: 'userbased',
        format: '1.0-2',
        hirarchy: [],
        isHirarchy: false,
        colName: 'maxSpeed',
      },
      {
        titleName: 'Engine Hours',
        type: '',
        filter: 'convert',
        format: 'hh:mm',
        hirarchy: [],
        isHirarchy: false,
        colName: 'engineHours',
      }
    ];
    this.tableForPdfColumns = [];
      this.tableForPdfContent = [];
      tableList.forEach((t) => {
        //html += `<th>${t.titleName}</th>`;
        this.tableForPdfColumns.push(t.titleName);
      });
      //html += `</tr></thead><tbody>`;
      this.devicesList.forEach((r: any, i: number) => {
        //html += `<tr>`;
        this.tableForPdfContent[i] = [];
        tableList.forEach((t: any, j: number) => {
          if (t.type == 'date' && !t.isHirarchy) {
            //html += `<td>${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}`;
          } else if (t.type == '' && !t.isHirarchy && t.filter == 'convert') {
            //html += `<td>${r[t.colName] ? this.datepipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.convertpipe.transform(r[t.colName], t.format)  : '00:00'}`;
          } else if (t.type == 'number' && t.filter == '' && !t.isHirarchy) {
            //html += `<td>${r[t.colName] ? this.numberpipe.transform(r[t.colName], t.format)  : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? this.numberpipe.transform(r[t.colName], t.format)  : ''}`;
          } else if (t.type == 'number' && t.filter == 'userbased' && !t.isHirarchy) {
            //html += `<td>${r[t.colName] ? this.numberpipe.transform(this.onSpeedgenerateBasedOnuserPreference(r[t.colName]), t.format)  : '0'} ${this.translatepipe.instant(this.userData.attributes.speedUnit)}</td>`;
            //this.tableForPdfContent[i][j] = `${r[t.colName] ? this.numberpipe.transform(this.onSpeedgenerateBasedOnuserPreference(r[t.colName]), t.format)  : '0'} ${this.translatepipe.instant(this.userData.attributes.speedUnit)}`;
          } else if (t.type == '' && t.isHirarchy && t.filter == 'meterTokm') {
            if (t.hirarchy.length == 1) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]] ? this.metertokm.transform(r[t.hirarchy[0]])  : '0'}`;
            } else if (t.hirarchy.length == 2) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]][t.hirarchy[1]] ? this.metertokm.transform(r[t.hirarchy[0]][t.hirarchy[1]])  : '0'}`;
            }        
          } else if (t.type == '' && t.isHirarchy && t.filter == 'address') {
            if (t.hirarchy.length == 2) {
              //this.tableForPdfContent[i][j] = `${(r[t.hirarchy[0]]+'_'+r[t.hirarchy[1]]) ? this.addressBylatLong[r[t.hirarchy[0]]+'_'+r[t.hirarchy[1]]]  : 'NA'}`;
            }        
          } else if (t.type == '' && t.isHirarchy && t.filter == '') {
            if (t.hirarchy.length == 2) {
              this.tableForPdfContent[i][j] = `${r[t.hirarchy[0]][t.hirarchy[1]] ? r[t.hirarchy[0]][t.hirarchy[1]]  : ''}`;              
            }        
          }
          else {
            //html += `<td>${r[t.colName] ? r[t.colName] : ''}</td>`;
            this.tableForPdfContent[i][j] = `${r[t.colName] ? r[t.colName] : ''}`;
          }
        });
        //html += `</tr>`;
      });
      //html += `</tbody></table>`;

      this.htmlString = '';

      setTimeout(() => {
        const pdfTable = this.pdfTable.nativeElement;
        // this.convetHtmlToPDF(pdfTable.innerHTML);
        this.downloadAsPDF();
      }, 2000);
  }
  downloadAsPDF() {
    const doc = new jsPDF();
    let fontSizeTable = 10;
    // const startdate = new Date(`${this.fromdateP} ${this.fromDateHours}:${this.fromDateMinutes}`);
    // const enddate = new Date(`${this.todateP} ${this.toDateHours}:${this.toDateMinutes}`);
    let title1 = 'MultiTrack';
    let title2 = 'Installation Certificate';
    let deviceName = '';
    // if (this.Device_ID && this.reportsName != 'Summary') {
    //   deviceName = this.DeviceArray.filter(x => x.id == this.Device_ID)[0].name;
    // } 
    // if (this.myControl.value && this.myControl.value.length > 0 && this.reportsName == 'Summary') {
    //   this.myControl.value.forEach(element => {
    //     deviceName += element.name + ', ';
    //   });
    //   deviceName = deviceName.substring(0, deviceName.length - 2);
    // }
    const pdfTable = this.pdfTable.nativeElement;
    let html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize:true
    });

    //const documentDefinition = { content: html };
    let contentOfTable:any[] = [];
    this.tableForPdfContent.forEach(ele => {
      contentOfTable.push(ele);
    });
    const documentDefinition = 
    {
      content: [
        {
          columns: [
            {
              image: '',
              width: 150              
            },
            {
              width: '*',
              text: title2,
              style: 'title2',
              alignment: 'right'
            }
          ]          
        },
        {
          columns: [
            {
              style: 'tableExample',
              table: {
                body: [                  
                  [
                    {
                      text: `Asset Name: ${deviceName}`,
                      bold: true,
                    },
                    {
                      text: 'Group: NA',
                      bold: true
                    }
                  ],
                  [
                    {
                      text: [
                        {text: `Report Period: ` , style: 'fontBold'}                        
                      ],
                      colSpan: 2
                    }
                  ],
                  [
                    {
                      text: [
                        { text: `Report Run Time: `, style: 'fontBold'},
                      `${moment().format('DD/MMM/yyyy hh:mm:ss A')}`,
                      ],
                      colSpan: 2
                    }
                  ]
                ]
              },
              width: 400
            },
            {
              style: 'tableExample',
              table: {
                body: [                  
                  [
                    {
                      text: `Print By: ${this.userData.name}`,
                      bold: true,
                      style: 'printby'
                    }
                  ],
                  [
                    {
                      text: `Remarks: - NA`,
                      bold: true,
                      style: 'remarks'
                    }
                  ]
                ]
              },
              width: '*'
            }
          ]
        },
        {
          style: 'tablecss',
          table: {
            body: [
              [...this.tableForPdfColumns],
              ...contentOfTable
            ]
          },
          layout: {
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex == 0) ? '#CCCCCC' : null;
            }
          }
        }
      ],
      styles: {
        tablecss: {
          fontSize: fontSizeTable
        },
        tableStyle: {

        },
        testing: {
          bold: true,
          fontSize: 40
        },
        title1: {
          margin: [0, 0, 0, 10],
          fontSize: 10
        },
        title2: {

        },
        tableExample: {
          border: 0,
          margin: [0, 0, 5, 10],
          fontSize: 10
        },
        printby: {
          fontSize: 10,
        },
        remarks: {
          fontSize: 10,
        },
        fontBold: {
          bold: true
        }
      }
    };

    const devicePdfContent = {
      content: [
        {
          columns: [
            {
              image: this.siteDetails.b64,
              width: 100
            },
            {
              width: '*',
              text: title2,
              style: 'title2',
              alignment: 'right'
            }
          ]          
        },
        {text: `This is to certify that the Electronic VTS device is fitted as per the details given below. it is checked, sealed and is functioning in all manners, Device will not function if tampered or not having proper GSM/GPS signals`, style: 'titlepara'},
        {text: 'VEHICLE DETAILS:', fontSize: 12, bold: true, margin: [0, 20, 0, 8], color: '#4285f4'},
        {
          canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.4, color: 'gray' } ]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*'],            
            body: [              
              [{text: 'Vehicle No:', fontSize: 10, color: 'gray'}, {text: 'Engine No:', fontSize: 10, color: 'gray'}, {text: 'Chassis No:', fontSize: 10, color: 'gray'}],
              [{text: this.selectedDeviceForReport.name, fontSize: 12, bold: true}, {text: this.reportData.engineno, fontSize: 12, bold: true}, {text: this.reportData.chassisno, fontSize: 12, bold: true}]
            ]
          },
          layout: 'headerLineOnly'
        },
        {text: 'VEHICLE OWNER DETAILS:', fontSize: 12, bold: true, margin: [0, 20, 0, 8], color: '#4285f4'},
        {
          canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.4, color: 'gray' } ]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*'],            
            body: [              
              [{text: 'Owner Name:', fontSize: 10, color: 'gray'}, {text: 'Owner Address:', fontSize: 10, color: 'gray'}, {text: 'Mobile No:', fontSize: 10, color: 'gray'}],
              [{text: this.reportData.ownername, fontSize: 12, bold: true}, {text: this.reportData.owneraddress, fontSize: 12, bold: true}, {text: this.reportData.ownermobileno, fontSize: 12, bold: true}]
            ]
          },
          layout: 'headerLineOnly'
        },
        {text: 'DISTRIBUTOR DETAILS:', fontSize: 12, bold: true, margin: [0, 20, 0, 8], color: '#4285f4'},
        {
          canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.4, color: 'gray' } ]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*'],            
            body: [              
              [{text: 'Distributor Name:', fontSize: 10, color: 'gray'}, {text: 'Mobile No:', fontSize: 10, color: 'gray'}, {text: 'Distributor Address:', fontSize: 10, color: 'gray'}],
              [{text: this.reportData.distributorname, fontSize: 12, bold: true}, {text: this.reportData.distributormobileno, fontSize: 12, bold: true}, {text: this.reportData.distributoraddress, fontSize: 12, bold: true}]
            ]
          },
          layout: 'headerLineOnly'
        },
        {text: 'VTS DETAILS:', fontSize: 12, bold: true, margin: [0, 20, 0, 8], color: '#4285f4'},
        {
          canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.4, color: 'gray' } ]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],            
            body: [              
              [{text: 'IMEI No:', fontSize: 10, color: 'gray'}, {text: 'Sim No:', fontSize: 10, color: 'gray'}, {text: 'Device Model:', fontSize: 10, color: 'gray'}, {text: 'Valid Upto', fontSize: 10, color: 'gray'}],
              [{text: this.selectedDeviceForReport.uniqueId, fontSize: 12, bold: true}, {text: this.selectedDeviceForReport.phone, fontSize: 12, bold: true}, {text: this.selectedDeviceForReport.model, fontSize: 12, bold: true}, {text: moment(new Date(this.selectedDeviceForReport.expirationTime)).format('DD-MM-yyyy'), fontSize: 12, bold: true}]
            ]
          },
          layout: 'headerLineOnly'
        },
        {
          text: 'PRODUCT SATISFACTION REPORT', style: 'header', alignment: "center"
        },
        {
          text: 'This is to certify that, vehicle location tracking device has been installed properly and found to be working fine in all manners at the time of installation.', style: 'titlepara'
        },
        {
          text: 'UNDERTAKING', style: 'header', alignment: "center"
        },
        {
          text: [
            {text: 'I ', style: 'titlepara', bold: true},
            {text: `${this.reportData.ownername} `, style: 'titlepara', bold: true},
            {text: `confirm that the device has been installed as per my satisfactory in my vehicle `, style: 'titlepara'},
            {text: `${this.selectedDeviceForReport.name}`, style: 'titlepara', bold: true},
            {text: `. I promise not to tamper with the gps tracking device. Any problem/issue arising due to device shall solely and legally be my responsibility. `, style: 'titlepara'},
            {text: `${this.reportData.distributorname} `, bold: true, style: 'titlepara'},
            {text: `shall not be held liable legally or for any other reason.`, style: 'titlepara'}
          ]
        },{
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],            
            body: [              
              [{text: 'Place:', fontSize: 10, lineHeight: 1.5}, {text: this.reportData.distributoraddress, fontSize: 10}, {text: 'Time:', fontSize: 10}, {text: `${moment().format("hh:mm a")}`, fontSize: 10}],
              [{text: 'Date:', fontSize: 10, lineHeight: 1.5}, {text: `${moment().format("DD-MM-yyyy")}`, fontSize: 10}, {text: 'Mobile:', fontSize: 10}, {text: `${this.reportData.ownermobileno}`, fontSize: 10}],
              [{text: 'Name of customer:', fontSize: 10, lineHeight: 1.5}, {text: `${this.reportData.ownername}`, fontSize: 10}, {text: '', fontSize: 10}, {text: '', fontSize: 10}],
              [{text: 'Aadhar Card:', fontSize: 10, lineHeight: 1.5}, {text: this.reportData.owneridno, fontSize: 10}, {text: '', fontSize: 10}, {text: '', fontSize: 10}]
            ]
          },
          layout: 'headerLineOnly'
        }
      ],
      styles: {
        title1: {
          margin: [0, 0, 0, 20],
          fontSize: 10
        },
        title2: {
          margin: [0, 5, 0, 20],
          bold: true
        },
        header: {
          fontSize: 12,
          bold: true,
          margin: [0, 20, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        },
        titlepara: {
          fontSize: 10,
          lineHeight: 1.5
        }
      }
    };
    pdfMake.createPdf(devicePdfContent).open();
  }
}

