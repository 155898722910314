import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  baseUrl = url;
  socketBaseUrl = "https://socket.oneqlik.in/";
  userEmail = "";
  userId = localStorage.getItem("userId")
  todate:any;
  fromDate:any;
  todaysDate: any;
  yesterdayDate: any;

  constructor(private routes: HttpClient) {
    this.userEmail = JSON.parse(window.atob(window.localStorage.token.split('.')[1])).email;

    // TODAY & YESTERDAY DATE
    this.todaysDate = new Date().toISOString();
    const yesterday = new Date(this.todaysDate)
    yesterday.setDate(yesterday.getDate() - 1)
    this.yesterdayDate=yesterday.toISOString()
    console.log(this.todaysDate, this.yesterdayDate);
    // END

    // ISO DATE FORMAT 
    this.todate=new Date();
    const yesterdays = new Date(this.todate)
    yesterdays.setDate(yesterdays.getDate() - 1)
    this.fromDate=yesterdays;
    console.log(this.todate, this.fromDate);
    
  }

  getRoutes(){
    let _url = this.baseUrl + "trackRoute/user/" + this.userId;
    return this.routes.get<any>(_url)
  }

  getAllGeofence(){
    let _url = this.baseUrl + "geofencing/getallgeofence";
    let httpOptions = {
      params: {
        "uid": this.userId,
      }
    };
    return this.routes.get<any>(_url, httpOptions)
  }

  getRouteMap() {
    let _url = this.baseUrl + "trackRouteMap/user/getRouteMap";
    let httpOptions = {
      params: {
        "userid": this.userId,
      }
    };
    return this.routes.get<any>(_url, httpOptions)
  }

  addtrip(body){
    let _url = this.baseUrl + "trackRouteMap";
    return this.routes.post<any>(_url, body)
  }

  getPoiTable(body){
    let _url = this.baseUrl + "poi/poidatatable";
    return this.routes.post<any>(_url, body)  
  }

  getRoutePlan(body){
    let _url = this.baseUrl + "RoutePlan/get";
    return this.routes.post<any>(_url, body)
  }

  deletePoi(poiId){
    let _url = this.baseUrl + "poi/deletePoi";
    let httpOptions = {
      params: { '_id': poiId}
    };
    return this.routes.get<any>(_url, httpOptions)
  }

  updateUntrackedPOI(body){
    let _url = this.baseUrl + "poi/updatePOI";
    return this.routes.post<any>(_url, body)
  }

  addPoi(body){
    let _url = this.baseUrl + "poi/addpoi";
    return this.routes.post<any>(_url, body)
  }

}


