import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime',
  pure: true,
})
export class DateTimePipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (value) {
      value = value / (1000 * 60 * 60);
      return value.toFixed(2);
    } else {
      return 0;
    }
  }
}
