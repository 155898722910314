<!-- <p class="component my-1"><button (click)="openModel()">Open Model</button> {{'Dashboard'|translate}} <span class="trackcolor">/ {{'Users'|translate}}/{{currentUrl.name |translate}}</span></p> -->
<app-common-modal-popup [isCloseButton]="!assignDeviceMode" [isFooterNeeded]="assignDeviceMode">
<div class="" modelheadertext *ngIf="gridMode">{{currentUrl.name|translate}}</div>
<div class="" modelheadertext *ngIf="addMode">{{ addCustomerForm.id ? ("Edit"|translate) : ("Add"|translate) }} {{currentUrl.name |translate}}</div>
<div class="" modelheadertext *ngIf="assignDeviceMode">{{"Assign Device" |translate}}</div>
<div class="container-fluid" modeldata *ngIf="gridMode">
  <div class="row p-0">
    <div class="card p-0 headcard">
      <!-- HEADER WITH ADD POI BTNS -->
      <div class="bg-light d-flex align-items-center p-2">
        <div class="d-flex align-items-center justify-content-between w-100">
         
          <form [formGroup]="form">
          <div class="input-group searchvehicles bg-white">
            
            <input
            (keyup)="page =1"
            formControlName="searchValue"
            name="searchValue"
              type="text"
              class="form-control reportsbox"
              placeholder="{{'Search'|translate}}"
              
              autocomplete="off"
            />
          
            <div class="input-group-append">
              <button
                class="btn"
                type="button"
                style="padding: 1vh 1.4vh 1.1vh 1.4vh"
              >
                <img
                (click)="searchData()"
                  src="/assets/icons/searchicon.png"
                  alt="search"
                  style="width: 2.3vh"
                />
              </button>
            </div>
          </div>
        </form>
        <button
        class="d-flex align-items-center p-2 bg-white"        
        title="{{'Add'|translate}} {{currentUrl.name |translate}}"
        (click)="addUser()"
      >
        <span class="material-icons" style="font-size: 2.1vh">
          add
        </span>
        <!-- {{'Add'|translate}} {{currentUrl.name |translate}} -->
      </button>
        </div>         
      </div>
      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween">
          <select
            class="form-select selectimportant"
            [(ngModel)]="pageOption"
            name="pageOption"
          >
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 58vh;
          "
        >
        <div
            class="d-flex flex-row align-items-center"
            style="padding: 0.4vh 0 0 0"
            *ngIf="!currentUrl.isDealer"
          >
            <span class="addlabels">{{'Show All Customers'|translate}}</span>
            <mat-slide-toggle style="padding-left: 1vh"
            (change)="onToggleAllCustomer($event)"></mat-slide-toggle>
          </div>
          <div
            class="d-flex flex-row align-items-center"
            style="padding: 0.4vh 0 0 0"
          >
            <span class="addlabels">{{'Active'|translate}}</span>
            <mat-slide-toggle style="padding-left: 1vh"></mat-slide-toggle>
          </div>
          
          <span class="btn"
            ><img
              src="/assets/icons/report_refresh_gray.png"
              alt="refresh"
              style="width: 96%"
          /></span>
          <app-export-data [selectedPage]="currentUrl.isDealer ? 'dealer':'customer'" [dataList]="customerList"></app-export-data>
        </div>
      </div>
      <!-- CUSTOMER TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0">
          <thead class="bg-light text-dark">
            <tr>
              <th class="table_head_text" (click)="sorting('name')">{{'Name'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'name'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'name'"></i>
              </th>
              <th class="table_head_text">{{'Password'|translate}}</th>
              <th class="table_head_text" (click)="sorting('email')">{{'User ID'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'email'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'email'"></i>
              </th>
              <th class="table_head_text" (click)="sorting('phone')">{{'Mobile'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'phone'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'phone'"></i>
              </th>
              <th *ngIf="currentUrl.isDealer" (click)="sorting('deviceLimit')" class="table_head_text">{{'Device Limit'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'deviceLimit'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'deviceLimit'"></i>
              </th>
              <th *ngIf="currentUrl.isDealer" (click)="sorting('availableLicenses')" class="table_head_text">{{'Available Licenses'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'availableLicenses'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'availableLicenses'"></i>
              </th>
              <th *ngIf="currentUrl.isDealer" (click)="sorting('userLimit')" class="table_head_text">{{'User Limit'|translate}}
                <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'userLimit'"></i>
                <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'userLimit'"></i>
              </th>
              <th class="table_head_text">{{'Disabled'|translate}}</th>
              <th class="table_head_text">{{'Actions'|translate}}</th>
            </tr>
          </thead>

          <tbody *ngIf="customerList.length">
            <tr *ngFor="let customer of customerList  | searchPipe : form.value.searchValue |  paginate : {
              itemsPerPage: pageOption,
              currentPage: page,
              totalItems: (customerList  | searchPipe : form.value.searchValue).length
            };let i=index">
              <td class="table_data">
                {{ customer.name }}
              </td>
              <td class="table_data"> {{customer.password}}<a *ngIf="!customer.password"><span (click)="getPassaword(customer)" class="material-icons passicon" style="cursor: pointer;"> {{customer.password ? 'visibility':'visibility_off' }} </span></a></td>
              <td class="table_data">{{ customer.email }}</td>
              <td class="table_data">{{ customer.phone }}</td>
              <!-- <td class="table_data">
                {{ customer.administrator ? "Yes" : "No" }}
              </td> -->
              <!-- <td class="table_data">
                {{
                  !customer.administrator && customer.userLimit !== 0
                    ? "Yes"
                    : "No"
                }}
              </td>
              <td class="table_data">
                {{
                  !customer.administrator && customer.userLimit === 0
                    ? "Yes"
                    : "No"
                }}
              </td> -->
              <td *ngIf="currentUrl.isDealer">
                {{customer.deviceLimit}}
              </td>
              <td *ngIf="currentUrl.isDealer">
                {{customer.availableLicenses}}
              </td>
              <td *ngIf="currentUrl.isDealer">
                {{customer.userLimit}}
              </td>
              <td class="table_data">
                <mat-slide-toggle style="padding-left: 1vh" 
                [checked]="customer.disabled"
                (change)="onToggleDisable(customer, $event)"
                ></mat-slide-toggle>
                <!-- {{ customer.disabled ? "Yes" : "No" }} -->
              </td>
              <td class="table_data d-flex">
                <div *ngIf="superAdmin"
                class="btn btn-light btn-sm mx-1"
                (click)="loginToUser(customer)"
                
                >
                <i class="fa fa-sign-out" aria-hidden="true"></i>

            </div>
                <div
                  class="btn btn-primary btn-sm mx-1"
                  (click)="edtUser(customer)" 
                  matTooltip="Edit"                 
                >
                  <i class="fa fa-edit"></i>
                </div>
                <div
                  class="btn btn-danger btn-sm mx-1"
                  (click)="deleteUser(customer.id)"
                  matTooltip="Delete" 
                >
                  <i class="fa fa-trash"></i>
                </div>
                <div
                  class="btn btn-primary btn-sm mx-1"
                  (click)="assignDevice(customer, staticBackdrop6)"
                  matTooltip="Assign Device" 
                >
                  <i class="fa fa-car"></i>
                </div>
                <div
                class="btn btn-warning btn-sm mx-1"
                (click)="userNotifications(customer, notificationPopup)"
                matTooltip="Assign Notification" 
                >
                <i class="fa fa-bell"></i>
            </div>
            <div
            class="btn btn-primary btn-sm mx-1"
            (click)="assignUser(customer, userMappingPopup)"
            matTooltip="Assign Users" 
          >
            <i class="fa fa-user"></i>
          </div>
          <div            
            class="btn btn-primary btn-sm mx-1"
            (click)="addDevice(customer)"            
            matTooltip="Add New Device" 
          >
            <i class="fa fa-plus"></i>
          </div>
          <div            
            class="btn btn-primary btn-sm mx-1"
            (click)="assignGroup(customer, groupPopup)"            
            matTooltip="Assign Group" 
          >
          <i class="fa fa-object-group" aria-hidden="true"></i>
          </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!customerList.length">
            <tr>
              <td colspan="7">
                <div
                  class="d-flex justify-content-center align-items-center"
                  style="height: 48vh"
                >
                  <img
                    src="/assets/images/datanotfound.png"
                    alt="NO DATA AVAILABLE IN TABLE"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="customerList.length" class="flex_row_center_spacebetween"
        style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
        <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(customerList  | searchPipe : form.value.searchValue).length/pageOption | number: '1.0-0'}} {{'of'|translate}}
          {{(customerList  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid add-form" modeldata *ngIf="addMode">
  <div class="row">
    <div class="col-12">
      <accordion [isAnimated]="true">
        <accordion-group heading="Required" [isOpen]="true">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Name'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.name"
                  class="form-control inputfields"
                  id="name"
                  aria-describedby="name"
                />
                <span class="text-danger validation" *ngIf="errors?.name">
                  {{ errors.name }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'User ID'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.email"
                  class="form-control inputfields"
                  id="identifier"
                  aria-describedby="identifier"
                />
                <span class="text-danger validation" *ngIf="errors?.email">
                  {{ errors.email }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Mobile'|translate}}</label>
                <input
                  type="text"
                  [(ngModel)]="addCustomerForm.phone"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-phone"
                  aria-describedby="identifier"
                />
                <span class="text-danger validation" *ngIf="errors?.phone">
                  {{ errors.phone }}
                </span>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="name" class="pb-2">{{'Enter Password'|translate}}</label>
                <input
                  type="password"
                  [(ngModel)]="addCustomerForm.password"
                  class="form-control inputfields"
                  id="identifier"
                  autocomplete="new-password"
                  aria-describedby="identifier"
                />
                <span class="text-danger validation" *ngIf="errors?.password">
                  {{ errors.password }}
                </span>
              </div>
            </div>
          </div>
          
          
          
          
          
        </accordion-group>
        <accordion-group heading="Preferences">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group pb-2">
                <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.speedUnit" class="inputfields w-100" name="pageOption">
                  <option value="kn" class="select_options">kn</option>
                  <option value="kmh" class="select_options">km/h</option>
                  <option value="mph" class="select_options">mph</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.distanceUnit" class="inputfields w-100" name="pageOption">
                  <option value="km" class="select_options">km</option>
                  <option value="mi" class="select_options">mi</option>
                  <option value="nmi" class="select_options">nmi</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.altitudeUnit" class="inputfields w-100" name="pageOption">
                  <option value="m" class="select_options">m</option>
                  <option value="ft" class="select_options">ft</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
                <select [(ngModel)]="addCustomerForm.attributes.volumeUnit" class="inputfields w-100" name="pageOption">
                  <option value="ltr" class="select_options">Liter</option>
                  <option value="usGal" class="select_options">U.S. Gallon</option>
                  <option value="impGal" class="select_options">imp. Gallon</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="Time-Zone inline" class="pb-2">{{'Time Zone'|translate}}</label>
                <select  class="inputfields w-100" [(ngModel)]="addCustomerForm.attributes.timezone" name="pageOption">
                  <option value="{{data}}" class="select_options" *ngFor="let data of timeZoneList">{{data}}</option>
                </select>
              </div>
            </div>
           
          </div>
        </accordion-group>
        <accordion-group heading="Permissions" *ngIf="currentUrl.isDealer">
          <div class="row">
            <div class="col-12 col-md-6 d-none">
              <div class="form-group pb-2">
                <label for="expiration" class="pb-2">{{'Expiration'|translate}}</label>
                <input
                  type="date"
                  [(ngModel)]="addCustomerForm.expirationTime"
                  class="form-control inputfields w-100"
                  id="expiration"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="limit" class="pb-2">{{'Device Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.deviceLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="limit"
                  aria-describedby="model"
                />
              </div>
            </div>
            <div class="col-12 col-md-6 pb-2">
              <div class="form-group">
                <label for="userLimit" class="pb-2">{{'User Limit'|translate}}</label>
                <input
                  type="number"
                  [(ngModel)]="addCustomerForm.userLimit"
                  [min]="-1"
                  class="form-control inputfields"
                  id="userLimit"
                  aria-describedby="userLimit"
                />
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disabled"
                    (change)="checkboxEvent($event.target, 'disabled')"
                    id="disabled"
                  />
                  <label class="form-check-label" for="disabled">{{'Disabled'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.readonly"
                    (change)="checkboxEvent($event.target, 'readonly')"
                    id="readonly"
                  />
                  <label class="form-check-label" for="readonly">{{'Readonly'|translate}}</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.deviceReadonly"
                    (change)="checkboxEvent($event.target, 'deviceReadonly')"
                    id="deviceReadonly"
                  />
                  <label class="form-check-label" for="deviceReadonly"
                    >{{'Device Readonly'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.limitCommands"
                    (change)="checkboxEvent($event.target, 'limitCommands')"
                    id="limitCommands"
                  />
                  <label class="form-check-label" for="limitCommands"
                    >{{'Limit Commands'|translate}}</label
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="addCustomerForm.disableReports"
                    (change)="checkboxEvent($event.target, 'disableReports')"
                    id="disableReports"
                  />
                  <label class="form-check-label" for="disableReports"
                    >{{'Disable Reports'|translate}}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </accordion-group>
        <accordion-group heading="Attributes">
          <ng-container
            *ngFor="let attribute of addCustomerForm.attributesList; index as i"
          >
            <div class="row d-flex align-items-center">
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'notificationTokens'"
              >
                <label>{{'Notification Tokens'|translate}}</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'currency'"
              >
                <label>{{'Currency'|translate}}</label>
                
                <select
                  class="form-select "
                
                  [(ngModel)]="attribute.value"
                >
                  <option value="INR" class="select_options">INR</option>
                  <option value="USD" class="select_options">USD</option>
                 
        </select>
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'unitprice'"
              >
                <label>{{'Unit Price'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerEmailAddress'"
              >
                <label>{{'Manager Email Address'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div
                class="col-11 form-group mb-2"
                *ngIf="attribute.attribute === 'managerContactNumber'"
              >
                <label>{{'Manager Contact Number'|translate}}</label>
                <input
                  type="{{attribute.type}}"
                  class="form-control"
                  [(ngModel)]="attribute.value"
                />
              </div>
              <div class="col-1">
                <label>&nbsp;</label>
                <i
                  class="fa fa-trash text-danger"
                  (click)="deleteAttr(i)"
                ></i>
              </div>
            </div>
          </ng-container>

          <div
            class="btn btn-outline-dark w-100"
            (click)="addAttri(template)"
          >
            <i class="fa fa-plus"></i> {{'ADD'|translate}}
          </div>
        </accordion-group>
      </accordion>

      <div class="d-flex align-items pt-4 pb-2">
        <button
          mat-raised-button
          type="submit"
          class="cancelbtn"
          (click)="onCustomerSubmit()"
        >
        {{'SAVE' | translate}}
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancelbtn bg-secondary ms-3"
          (click)="onCustomerCancel()"
        >
        {{'CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid add-form" modeldata *ngIf="assignDeviceMode">
  <div class="row">
    <div class="col-12">
      <table class="table table-borderless m-0 caption-top">
        <caption>Selected Customer</caption>
        <thead class="bg-light text-dark">
          <tr>
            <th class="table_head_text">{{'Name'|translate}}              
            </th>
            <th class="table_head_text">{{'Password'|translate}}</th>
            <th class="table_head_text">{{'User ID'|translate}}              
            </th>
            <th class="table_head_text">{{'Mobile'|translate}}              
            </th>
            <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'Device Limit'|translate}}              
            </th>
            <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'Available Licenses'|translate}}              
            </th>
            <th *ngIf="currentUrl.isDealer" class="table_head_text">{{'User Limit'|translate}}              
            </th>
            <th class="table_head_text">{{'Disabled'|translate}}</th>
          </tr>
        </thead>

        <tbody *ngIf="selectedCustomerForAssignDevice.length">
          <tr *ngFor="let customer of selectedCustomerForAssignDevice;let i=index">
            <td class="table_data">
              {{ customer.name }}
            </td>
            <td class="table_data"> {{customer.password}}<a *ngIf="!customer.password"><span (click)="getPassaword(customer)" class="material-icons passicon" style="cursor: pointer;"> {{customer.password ? 'visibility':'visibility_off' }} </span></a></td>
            <td class="table_data">{{ customer.email }}</td>
            <td class="table_data">{{ customer.phone }}</td>
            <td *ngIf="currentUrl.isDealer">
              {{customer.deviceLimit}}
            </td>
            <td *ngIf="currentUrl.isDealer">
              {{customer.availableLicenses}}
            </td>
            <td *ngIf="currentUrl.isDealer">
              {{customer.userLimit}}
            </td>
            <td class="table_data">
              {{customer.disabled ? 'Disabled': 'Not Disabled'}}
              <!-- <mat-slide-toggle style="padding-left: 1vh" 
              [checked]="customer.disabled"
              
              ></mat-slide-toggle> -->
            </td>            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="modelForm" class="bg-light p-2">
        <div class="input-group searchvehicles">
        
          <input
          
          formControlName="searchValue"
          name="searchValue"
            type="text"
            class="form-control reportsbox"
            placeholder="{{'Search'|translate}}"
            
            autocomplete="off"
          />
        
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              style="padding: 1vh 1.4vh 1.1vh 1.4vh"
            >
              <img
              (click)="searchData()"
                src="/assets/icons/searchicon.png"
                alt="search"
                style="width: 2.3vh"
              />
            </button>
          </div>
        </div>
      </form>
      <div class="row fw-bold d-flex align-items-center mt-2 mb-2">
        <div class="col-2 text-center">
          {{'Vehicle No'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'IMEI'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Status'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Last Update'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Sim No'|translate}}
        </div>
        <div class="col-1 text-center">
          {{'Model'|translate}}
        </div>
        <div class="col-1 text-center">
          {{'Icon'|translate}}
        </div>
      </div>
      <div class="row min-height-scroll" style="max-height: 285px !important;">
        
        <div
          class="col-12 form-group"
          *ngFor="let device of deviceList | searchPipe : modelForm.value.searchValue; index as i"
        >
          <div class="row">
            <div class="col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [id]="'device-' + i"
                  [checked]="device.isSelectedDevice"
                  (change)="changeEventDevices($event.target, device.id, device.mapUserId)"
                />
                <label class="form-check-label" for="device-{{ i }}">
                  {{ device.name }}
                </label>
              </div>
            </div>
            <div class="col-2">
              {{ device.uniqueId }}
            </div>
            <div class="col-2">
              <img *ngIf="device.status == 'online';else offline"
                                src="/assets/icons/dotgreen.png"
                                alt="checkbox"
                                style="width: 1.5vh"
                              />
                              <ng-template #offline>
                              <img
                src="/assets/icons/stoped.png"
                alt="checkbox"
                style="width: 1.5vh"
              /></ng-template> {{ device.deviceStatus |titlecase}}
            </div>
            <div class="col-2">
              {{ device.lastUpdate |dateFormat }}
            </div>
            <div class="col-2">
              {{ device.phone }}
            </div>
            <div class="col-1">
              {{ device.model }}
            </div>
            <div class="col-1">
              {{ device.category }}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div class="row" footerdata style="position: sticky; bottom: 0;">
    <div class="col-12">
      <div class="d-flex align-items pb-2 justify-content-end">        
        <button
          mat-raised-button
          type="button"
          class="cancelbtn bg-secondary ms-3"
          (click)="onCustomerCancel()"
        >
        {{'CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
</app-common-modal-popup>
<!-- ADD CUSTOMERS MODAL -->

<!-- <div
  class="modal"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content" style="border: none">
      <div class="modal-header addtriphead">
        <div class="headtext">
          {{ addCustomerForm.id ? ("Edit"|translate) : ("Add"|translate) }} {{currentUrl.name |translate}}
        </div>
        <button
          type="button"
          class="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
        
        <accordion [isAnimated]="true">
          <accordion-group heading="Required" [isOpen]="true">
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Name'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.name"
                class="form-control inputfields"
                id="name"
                aria-describedby="name"
              />
              <span class="text-danger validation" *ngIf="errors?.name">
                {{ errors.name }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'User ID'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.email"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.email">
                {{ errors.email }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Mobile'|translate}}</label>
              <input
                type="text"
                [(ngModel)]="addCustomerForm.phone"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.phone">
                {{ errors.phone }}
              </span>
            </div>
            <div class="form-group pb-2">
              <label for="name" class="pb-2">{{'Enter Password'|translate}}</label>
              <input
                type="password"
                [(ngModel)]="addCustomerForm.password"
                class="form-control inputfields"
                id="identifier"
                aria-describedby="identifier"
              />
              <span class="text-danger validation" *ngIf="errors?.password">
                {{ errors.password }}
              </span>
            </div>
            
          </accordion-group>
          <accordion-group heading="Preferences">            
            <div class="form-group pb-2">
              <label for="speed-unit inline" class="pb-2">{{'Speed Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.speedUnit" class="inputfields w-100" name="pageOption">
                <option value="kn" class="select_options">kn</option>
                <option value="kmh" class="select_options">km/h</option>
                <option value="mph" class="select_options">mph</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Distance-Unit inline" class="pb-2">{{'Distance Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.distanceUnit" class="inputfields w-100" name="pageOption">
                <option value="km" class="select_options">km</option>
                <option value="mi" class="select_options">mi</option>
                <option value="nmi" class="select_options">nmi</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Altitude-Unit inline" class="pb-2">{{'Altitude Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.altitudeUnit" class="inputfields w-100" name="pageOption">
                <option value="m" class="select_options">m</option>
                <option value="ft" class="select_options">ft</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Volume-Unit inline" class="pb-2">{{'Volume Unit'|translate}}</label>
              <select [(ngModel)]="addCustomerForm.attributes.volumeUnit" class="inputfields w-100" name="pageOption">
                <option value="ltr" class="select_options">Liter</option>
                <option value="usGal" class="select_options">U.S. Gallon</option>
                <option value="impGal" class="select_options">imp. Gallon</option>
              </select>
            </div>
            <div class="form-group pb-2">
              <label for="Time-Zone inline" class="pb-2">{{'Time Zone'|translate}}</label>
              <select  class="inputfields w-100" [(ngModel)]="addCustomerForm.attributes.timezone" name="pageOption">
                <option value="{{data.tz}}" class="select_options" *ngFor="let data of TimezoneArray">{{data.tz}}</option>
              </select>
            </div>
          </accordion-group>
          <accordion-group heading="Permissions">
            <div class="form-group pb-2 d-none">
              <label for="expiration" class="pb-2">{{'Expiration'|translate}}</label>
              <input
                type="date"
                [(ngModel)]="addCustomerForm.expirationTime"
                class="form-control inputfields w-100"
                id="expiration"
              />
            </div>
            <div class="form-group pb-2">
              <label for="limit" class="pb-2">{{'Device Limit'|translate}}</label>
              <input
                type="number"
                [(ngModel)]="addCustomerForm.deviceLimit"
                [min]="-1"
                class="form-control inputfields"
                id="limit"
                aria-describedby="model"
              />
            </div>
            <div class="form-group pb-2">
              <label for="userLimit" class="pb-2">{{'User Limit'|translate}}</label>
              <input
                type="number"
                [(ngModel)]="addCustomerForm.userLimit"
                [min]="-1"
                class="form-control inputfields"
                id="userLimit"
                aria-describedby="userLimit"
              />
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.disabled"
                  (change)="checkboxEvent($event.target, 'disabled')"
                  id="disabled"
                />
                <label class="form-check-label" for="disabled">{{'Disabled'|translate}}</label>
              </div>
            </div>            
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.readonly"
                  (change)="checkboxEvent($event.target, 'readonly')"
                  id="readonly"
                />
                <label class="form-check-label" for="readonly">{{'Readonly'|translate}}</label>
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.deviceReadonly"
                  (change)="checkboxEvent($event.target, 'deviceReadonly')"
                  id="deviceReadonly"
                />
                <label class="form-check-label" for="deviceReadonly"
                  >{{'Device Readonly'|translate}}</label
                >
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.limitCommands"
                  (change)="checkboxEvent($event.target, 'limitCommands')"
                  id="limitCommands"
                />
                <label class="form-check-label" for="limitCommands"
                  >{{'Limit Commands'|translate}}</label
                >
              </div>
            </div>
            <div class="form-group pb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [checked]="addCustomerForm.disableReports"
                  (change)="checkboxEvent($event.target, 'disableReports')"
                  id="disableReports"
                />
                <label class="form-check-label" for="disableReports"
                  >{{'Disable Reports'|translate}}</label
                >
              </div>
            </div>
          </accordion-group>
          <accordion-group heading="Attributes">
            <ng-container
              *ngFor="let attribute of addCustomerForm.attributesList; index as i"
            >
              <div class="row">
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'notificationTokens'"
                >
                  <label>{{'Notification Tokens'|translate}}</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'currency'"
                >
                  <label>{{'Currency'|translate}}</label>
                  
                  <select
                    class="form-select "
                  
                    [(ngModel)]="attribute.value"
                  >
                    <option value="INR" class="select_options">INR</option>
                    <option value="USD" class="select_options">USD</option>
                   
          </select>
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'unitprice'"
                >
                  <label>{{'Unit Price'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'managerEmailAddress'"
                >
                  <label>{{'Manager Email Address'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div
                  class="col-11 form-group mb-2"
                  *ngIf="attribute.attribute === 'managerContactNumber'"
                >
                  <label>{{'Manager Contact Number'|translate}}</label>
                  <input
                    type="{{attribute.type}}"
                    class="form-control"
                    [(ngModel)]="attribute.value"
                  />
                </div>
                <div class="col-1">
                  <label>&nbsp;</label>
                  <i
                    class="fa fa-trash text-danger"
                    (click)="deleteAttr(i)"
                  ></i>
                </div>
              </div>
            </ng-container>

            <div
              class="btn btn-outline-dark w-100"
              (click)="addAttri(template)"
            >
              <i class="fa fa-plus"></i> {{'ADD'|translate}}
            </div>
          </accordion-group>
        </accordion>

        <div class="flex_row_center_spacebetween pt-4 pb-2">
          <button
            mat-raised-button
            type="submit"
            class="cancelbtn"
            (click)="onCustomerSubmit()"
          >
          {{'SAVE' | translate}}
          </button>
        </div>
        
      </div>
    </div>
  </div>
</div> -->

<!-- EDIT DEALER MODAL -->

<div
  class="modal fade"
  id="staticBackdrop4"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content content3">
      <div class="modal-header" style="border-bottom: 1px solid #324151">
        <div class="card-title titlehaed1 m-0">{{'Edit Customer'|translate}}</div>
        <button
          type="button"
          class="btnclose"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-icons"> close </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="editCustomerForm"
          (ngSubmit)="onEditCustomerSubmit()"
        >
          <div class="form-group pb-2">
            <label for="userids" class="addlabels3 pb-2">{{'User ID'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="userids"
              aria-describedby="userids"
            />
          </div>

          <div class="form-group pb-2">
            <label for="first_name" class="addlabels3 pb-2">{{'First Name'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="first_name"
              aria-describedby="first_name"
            />
          </div>

          <div class="form-group pb-2">
            <label for="last_name" class="addlabels3 pb-2">{{'Last Name'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="last_name"
              aria-describedby="last_name"
            />
          </div>

          <div class="form-group pb-2">
            <label for="emailid" class="addlabels3 pb-2">{{'Email ID'|translate}}</label>
            <input
              type="email"
              class="form-control inputfields"
              formControlName="emailid"
              aria-describedby="emailid"
            />
          </div>

          <div class="form-group pb-2">
            <label for="phonenumber" class="addlabels3 pb-2"
            >{{'Mobile Number'|translate}}</label>
            <input
              type="number"
              class="form-control inputfields"
              formControlName="phonenumber"
              aria-describedby="phonenumber"
            />
          </div>

          <div class="form-group pb-2">
            <label for="expdate" class="addlabels3 pb-2">{{'Expiration Date'|translate}}</label>
            <input
              type="date"
              class="form-control inputfields"
              formControlName="expdate"
              style="
                width: 100% !important;
                height: 5.6vh !important;
                border: 1px solid #324151 !important;
                flex-flow: row !important;
              "
              aria-describedby="expdate"
            />
          </div>

          <div class="form-group pb-3">
            <label for="addresss" class="addlabels3 pb-2">{{'Address'|translate}}</label>
            <input
              type="text"
              class="form-control inputfields"
              formControlName="addresss"
              aria-describedby="addresss"
            />
          </div>

          <div class="flex_row_center_spacebetween">
            <button
              mat-raised-button
              class="cancelbtn"
              style="width: 47%; padding: 0.8vh 0"
              data-bs-dismiss="modal"
            >{{'CANCEL'|translate}}</button>
            <button
              mat-raised-button
              class="cancelbtn"
              style="width: 47%; padding: 0.8vh 0"
            >
              PROCEED
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #staticBackdrop6>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Devices'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeDevicesModal()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
      
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row fw-bold d-flex align-items-center mt-2 mb-2">
      <div class="col-2 text-center">
        {{'Vehicle No'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'IMEI'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Status'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Last Update'|translate}}
      </div>
      <div class="col-2 text-center">
        {{'Sim No'|translate}}
      </div>
      <div class="col-1 text-center">
        {{'Model'|translate}}
      </div>
      <div class="col-1 text-center">
        {{'Icon'|translate}}
      </div>
    </div>
    <div class="row min-height-scroll">
      
      <div
        class="col-12 form-group"
        *ngFor="let device of deviceList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="row">
          <div class="col-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                [id]="'device-' + i"
                [checked]="device.isSelectedDevice"
                (change)="changeEventDevices($event.target, device.id, device.mapUserId)"
              />
              <label class="form-check-label" for="device-{{ i }}">
                {{ device.name }}
              </label>
            </div>
          </div>
          <div class="col-2">
            {{ device.uniqueId }}
          </div>
          <div class="col-2">
            <img *ngIf="device.status == 'online';else offline"
                              src="/assets/icons/dotgreen.png"
                              alt="checkbox"
                              style="width: 1.5vh"
                            />
                            <ng-template #offline>
                            <img
              src="/assets/icons/stoped.png"
              alt="checkbox"
              style="width: 1.5vh"
            /></ng-template> {{ device.deviceStatus |titlecase}}
          </div>
          <div class="col-2">
            {{ device.lastUpdate |dateFormat }}
          </div>
          <div class="col-2">
            {{ device.phone }}
          </div>
          <div class="col-1">
            {{ device.model }}
          </div>
          <div class="col-1">
            {{ device.category }}
          </div>
        </div>
        
      </div>
    </div>
  </div>
</ng-template>
    

<ng-template #userMappingPopup>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Users'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="closeUserModal()"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let device of usersList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'device-' + i"
            [checked]="device.isSelectedUser"
            (change)="changeEventUserMapping($event.target,device.mapDealerId, device.id )"
          />
          <label class="form-check-label" for="device-{{ i }}">
            {{ device.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label>{{'Attribute'|translate}}</label>
        <select
          class="form-control"
          [(ngModel)]="addAttr.attribute"
          (change)="changeAttrEvent($event.target)"
        >
          <option *ngFor="let attr of attrList" [value]="attr.id">
            {{ attr.name }}
          </option>
        </select>
      </div>
      <div class="col-12">
        <label>{{'Type'|translate}}</label>
        <input
          type="text"
          class="form-control"
          [(ngModel)]="addAttr.type"
          [readonly]="true"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-light btn-sm" (click)="addAttrbute()">{{'ADD'|translate}}</div>
    <div class="btn btn-light btn-sm" (click)="modalRef.hide()">{{'CANCEL'|translate}}</div>
  </div>
</ng-template>

<ng-template #notificationPopup>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Notifications'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="notificationModalClose()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let notification of notificationsMasterList | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'notification-' + i"
            [checked]="notification.isSelectedDevice"
            (change)="
              changeEvent($event.target, notification.id, notification.mapUserId)
            "
          />
          <label class="form-check-label" for="notification-{{ i }}">
            {{ notification.type  }} ({{notification.notificators}})
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #groupPopup>
  <div class="modal-header addtriphead">
    <div class="headtext">{{'Groups'|translate}}</div>
    <button
      type="button"
      class="btnclose"
      (click)="notificationModalClose()"
      aria-label="Close"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>
  <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
    
    <form [formGroup]="modelForm" class="bg-light p-2">
      <div class="input-group searchvehicles">
        
        <input
        
        formControlName="searchValue"
        name="searchValue"
          type="text"
          class="form-control reportsbox"
          placeholder="{{'Search'|translate}}"
          
          autocomplete="off"
        />
      
        <div class="input-group-append">
          <button
            class="btn"
            type="button"
            style="padding: 1vh 1.4vh 1.1vh 1.4vh"
          >
            <img
            (click)="searchData()"
              src="/assets/icons/searchicon.png"
              alt="search"
              style="width: 2.3vh"
            />
          </button>
        </div>
      </div>
    </form>
    <div class="row min-height-scroll">
      <div
        class="col-12 form-group"
        *ngFor="let notification of groupListData | searchPipe : modelForm.value.searchValue; index as i"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="'notification-' + i"
            [checked]="notification.isSelectedGroups"
            (change)="
              changeGroupEvent($event.target, notification.id, notification.userid)
            "
          />
          <label class="form-check-label" for="notification-{{ i }}">
            {{ notification.name  }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
