<!-- <p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Device Model</span></p> -->
<app-common-modal-popup>
    <div class="" modelheadertext *ngIf="gridMode">{{'Groups'|translate}}</div>
    <div class="" modelheadertext *ngIf="addMode">Add Group</div> 
    <div class="container-fluid " modeldata *ngIf="gridMode">
      <div class="row p-0">
        <div class="card p-0 headcard">
    
          <!-- HEADER WITH ADD POI BTNS -->
          <div class="bg-light d-flex align-items-center p-2">
            <div class="d-flex align-items-center justify-content-between w-100">
              
              <div [formGroup]="form" class="input-group searchvehicles" style="width: 20%;">
                <input (keyup)="page =1" formControlName="searchValue" name="searchValue"
                                        type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                                        autocomplete="off" />
                <div class="input-group-append bg-white">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
              <button class="d-flex align-items-center p-2 bg-white" title="{{' Add Group'|translate}}" (click)="onAddModelMaster()">
                <span class="material-icons" style="font-size: 2.1vh;">
                  add
                </span>
              </button>
            </div>           
          </div>
          <!-- DATE DOWNLOAD HEADER -->
          <div class="card-header flex_row_center_spacebetween">
            <div class="flex_row_center_spacebetween">
              <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
                <option value="10" class="select_options">10</option>
                <option value="20" class="select_options">20</option>
                <option value="30" class="select_options">30</option>
                <option value="40" class="select_options">40</option>
                <option value="50" class="select_options">50</option>
                <option value="60" class="select_options">60</option>
              </select>
            </div>
            <div style="display: flex;flex-direction: row;align-items: center;">
              <span class="btn"><img src="/assets/icons/report_refresh_gray.png" alt="refresh" style="width: 96%;"></span>
              <span class="btn"><img src="/assets/icons/report_download_gray.png" alt="download" style="width: 96%;"></span>
            </div>
          </div>
          <!-- MODEL MASTER TABLE -->
          <div class="card-body common_scroller fixed_height p-0">
            <table class="table table-borderless m-0">
    
              <thead class="bg-light">
                <tr>
                
                  <th class="table_head_text text-left" (click)="sorting('name')">{{'Group Name'|translate}}
                    <i class="bi bi-caret-down-fill" *ngIf="isAscending && sortcolumnName == 'name'"></i>
                    <i class="bi bi-caret-up-fill" *ngIf="!isAscending && sortcolumnName == 'name'"></i>
                  </th>
                  <th class="table_head_text text-left">{{'Contact'|translate}}
                  </th>
                  <th class="table_head_text text-left">Action</th>
                </tr>
              </thead>
    
              <tbody *ngIf="groupListData.length">
                <tr *ngFor="let model of groupListData  | searchPipe : form.value.searchValue |  paginate : {
                    itemsPerPage: 10,
                    currentPage: page,
                    totalItems: (groupListData  | searchPipe : form.value.searchValue).length
                  };let i=index">
                
                  <td class="table_data text-left">{{model.name}}</td>
                  <td class="table_data text-left">{{model.attributes?.contact}}</td>                 
                  <td class="table_data text-center" style="cursor: pointer;">
                    <div
                    class="btn btn-primary btn-sm mx-1"
                    (click)="onEditModelMaster(model)"                  
                  >
                    <i class="fa fa-edit"></i>
                  </div>
                  <div
                    class="btn btn-danger btn-sm mx-1"
                    (click)="onDeleteModelMaster(model.id)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <div
                  class="btn btn-primary btn-sm mx-1"
                  (click)="assignDevice(model, staticBackdrop6)"
                  matTooltip="Assign Device" 
                >
                  <i class="fa fa-car"></i>
                </div>
                </td>
                </tr>
              </tbody>
    
              <tbody *ngIf="!groupListData.length">
                <tr>
                  <td colspan="9">
                    <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                      <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                    </div>
                  </td>
                </tr>
              </tbody>
    
            </table>
          </div>
          <!-- PAGINATION -->
          <div *ngIf="groupListData.length" class="flex_row_center_spacebetween"
            style="padding:1.5vh 2vh;border-top: 1px solid #E3E7EC;">
            <div>{{'Showing'|translate}} {{page}} {{'to'|translate}} {{(groupListData  | searchPipe : form.value.searchValue).length/10 | number: '1.0-0'}} {{'of'|translate}}
              {{(groupListData  | searchPipe : form.value.searchValue).length}} {{'entries'|translate}}</div>
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
          </div>
    
        </div>
      </div>
    </div>
    <div class="container-fluid " modeldata *ngIf="addMode">
      <form [formGroup]="addGroupForm" (ngSubmit)="onModelMasterSubmit()">
  
        <div class="row">
          <div class="col-6">
            <div class="form-group pb-2">
              <label for="manufacturer" class="addlabels pb-2">Name</label>
              <input type="text" formControlName="name" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="name" aria-describedby="name">
              <span class="text-danger validation"
                *ngIf="(AddModelMasterControl.name.touched || submitted) && AddModelMasterControl.name.errors?.required">
                *Group Name is required.
              </span>
            </div>
          </div>
          <div class="col-6" formGroupName="attributes">
            <div class="form-group pb-2">
              <label for="contact" class="addlabels pb-2">{{'Contact'|translate}}</label>
              <input type="text" formControlName="contact" class="form-control inputfields"
                style="background: #FFF;box-shadow:none;color:#000;" id="contact" aria-describedby="contact">              
            </div>
          </div>
        </div>
  
        <div class="d-flex align-items-center pt-4 pb-2">
          <button mat-raised-button  type="submit" class="cancelbtn">SUBMIT</button>
          <button mat-raised-button type="button" class="cancelbtn bg-secondary ms-3"
            (click)="onCloseModal()">{{'CANCEL'|translate}}</button>
          
         </div>
  
      </form>
    </div>
  </app-common-modal-popup>
  <ng-template #staticBackdrop6>
    <div class="modal-header addtriphead">
      <div class="headtext">{{'Devices'|translate}}</div>
      <button
        type="button"
        class="btnclose"
        (click)="closeDevicesModal()"
        aria-label="Close"
      >
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="modal-body modal-bodys" style="padding: 1vh 4vh">
      
      <form [formGroup]="modelForm" class="bg-light p-2">
        <div class="input-group searchvehicles">
        
          <input
          
          formControlName="searchValue"
          name="searchValue"
            type="text"
            class="form-control reportsbox"
            placeholder="{{'Search'|translate}}"
            
            autocomplete="off"
          />
        
          <div class="input-group-append">
            <button
              class="btn"
              type="button"
              style="padding: 1vh 1.4vh 1.1vh 1.4vh"
            >
              <img
              (click)="searchData()"
                src="/assets/icons/searchicon.png"
                alt="search"
                style="width: 2.3vh"
              />
            </button>
          </div>
        </div>
      </form>
      <div class="row fw-bold d-flex align-items-center mt-2 mb-2">
        <div class="col-2 text-center">
          {{'Vehicle No'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'IMEI'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Status'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Last Update'|translate}}
        </div>
        <div class="col-2 text-center">
          {{'Sim No'|translate}}
        </div>
        <div class="col-1 text-center">
          {{'Model'|translate}}
        </div>
        <div class="col-1 text-center">
          {{'Icon'|translate}}
        </div>
      </div>
      <div class="row min-height-scroll">
        
        <div
          class="col-12 form-group"
          *ngFor="let device of deviceList | searchPipe : modelForm.value.searchValue; index as i"
        >
          <div class="row">
            <div class="col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [id]="'device-' + i"
                  [checked]="device.isSelectedDevice"
                  (change)="changeEventDevices($event.target, device)"
                />
                <label class="form-check-label" for="device-{{ i }}">
                  {{ device.name }}
                </label>
              </div>
            </div>
            <div class="col-2">
              {{ device.uniqueId }}
            </div>
            <div class="col-2">
              <img *ngIf="device.status == 'online';else offline"
                                src="/assets/icons/dotgreen.png"
                                alt="checkbox"
                                style="width: 1.5vh"
                              />
                              <ng-template #offline>
                              <img
                src="/assets/icons/stoped.png"
                alt="checkbox"
                style="width: 1.5vh"
              /></ng-template> {{ device.deviceStatus |titlecase}}
            </div>
            <div class="col-2">
              {{ device.lastUpdate |dateFormat }}
            </div>
            <div class="col-2">
              {{ device.phone }}
            </div>
            <div class="col-1">
              {{ device.model }}
            </div>
            <div class="col-1">
              {{ device.category }}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </ng-template>