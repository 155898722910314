<app-common-modal-popup [modelSize]="'modal-lg'">
    <div class="" modelheadertext>
      {{'Add Remarks'|translate}}
    </div>
    <div class="container-fluid" modeldata>
        <div class="row d-flex align-items-center mb-3">
            <div class="col-12 d-flex align-items-center ps-0">
                <span class="d-flex align-items-center justify-content-center p-2" [class.selected]="isEventsTab" (click)="tabChange('events')">Events</span>
                <span class="d-flex align-items-center justify-content-center p-2" [class.selected]="isVehiclesTab" (click)="tabChange('vehicle')">Vehicles Information</span>
                <span class="d-flex align-items-center justify-content-center p-2" [class.selected]="isCustomertab" (click)="tabChange('customer')">Customer Details</span>
            </div>            
        </div>
        <div class="row" *ngIf="isEventsTab">
            <div class="col-12 p-0">
                <form [formGroup]="eventsForm" class="bg-light p-2">
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="input-group searchvehicles">
                                <div class="form-floating w-100">
                                    <textarea class="form-control" formControlName="remarks" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Description</label>
                                </div>
                            </div>
                        </div>
                    </div>        
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Operator">
                                <label for="floatingInputValue">Operator</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" readonly class="form-control" placeholder="Object" value="{{eventsData.deviceName}}">
                                <label for="floatingInputValue">Object</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" readonly class="form-control" placeholder="Time" value="{{eventsData.eventTime| dateFormat}}">
                                <label for="floatingInputValue">Time</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" readonly class="form-control" placeholder="Notifications" value="{{eventsData.type}} ({{eventsData.attributes?.alarm}})">
                                <label for="floatingInputValue">Notifications</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <button type="button" class="btn btn-primary" (click)="saveEvents()">Save</button>
                            <button type="button" class="btn btn-light" (click)="cancelEvents()">Cancel</button>
                        </div>
                    </div>
                  </form>
            </div>
        </div>
        <div class="row" *ngIf="isVehiclesTab">
            <div class="col-12 p-0">
                <form class="bg-light p-2">
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Devices" [value]="deviceData?.name">
                                <label for="floatingInputValue">Devices</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Device Model" [value]="deviceData?.attributes?.model">
                                <label for="floatingInputValue">Device Model</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="SIM number" [value]="deviceData?.phone">
                                <label for="floatingInputValue">SIM number</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="MSISDN">
                                <label for="floatingInputValue">MSISDN</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Installation date">
                                <label for="floatingInputValue">Installation date</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Registration/Asset number">
                                <label for="floatingInputValue">Registration/Asset number</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Make">
                                <label for="floatingInputValue">Make</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Model" [value]="deviceData?.attributes?.model">
                                <label for="floatingInputValue">Model</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Color" [value]="deviceData?.attributes?.colors">
                                <label for="floatingInputValue">Color</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Engine" [value]="deviceData?.attributes?.engine">
                                <label for="floatingInputValue">Engine</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Chasis No" [value]="deviceData?.attributes?.chasis">
                                <label for="floatingInputValue">Chasis No</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Type" >
                                <label for="floatingInputValue">Type</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Owner Name">
                                <label for="floatingInputValue">Owner Name</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Corporate Name">
                                <label for="floatingInputValue">Corporate Name</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Bank Name">
                                <label for="floatingInputValue">Bank Name</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Insurance Name">
                                <label for="floatingInputValue">Insurance Name</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Province">
                                <label for="floatingInputValue">Province</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="City ">
                                <label for="floatingInputValue">City</label>
                            </form>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
        <div class="row" *ngIf="isCustomertab">
            <div class="col-12 p-0">
                <form class="bg-light p-2">
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Customer Name">
                                <label for="floatingInputValue">Customer Name</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Mobile No#1">
                                <label for="floatingInputValue">Mobile No#1</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Mobile No#2">
                                <label for="floatingInputValue">Mobile No#2</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Secondary User/Driver with mobile no.">
                                <label for="floatingInputValue">Secondary User/Driver with mobile no.</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Emergency contact 1 with mobile no">
                                <label for="floatingInputValue">Emergency contact 1 with mobile no</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Emergency contact 2 with mobile no">
                                <label for="floatingInputValue">Emergency contact 2 with mobile no</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="NIC NO">
                                <label for="floatingInputValue">NIC NO</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Date of Birth">
                                <label for="floatingInputValue">Date of Birth</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Mother Name">
                                <label for="floatingInputValue">Mother Name</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Address">
                                <label for="floatingInputValue">Address</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Instructions">
                                <label for="floatingInputValue">Instructions</label>
                            </form>
                        </div>
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Normal Password">
                                <label for="floatingInputValue">Normal Password</label>
                            </form>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <form class="form-floating">
                                <input type="text" class="form-control" placeholder="Emergency Password">
                                <label for="floatingInputValue">Emergency Password</label>
                            </form>
                        </div>
                        
                    </div>                    
                </form>
            </div>
        </div>
    </div>
  </app-common-modal-popup>