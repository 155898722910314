import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-events-remarks',
  templateUrl: './events-remarks.component.html',
  styleUrls: ['./events-remarks.component.css']
})
export class EventsRemarksComponent implements OnInit {
  eventsForm: FormGroup;
  isEventsTab = false;
  isVehiclesTab = false;
  isCustomertab = false;
  isEventsRemarksPopupOpen = false;
  eventsData:any = {};
  deviceData: any = {};
  constructor(public formBuilder: FormBuilder,
    public commonService: CommonService) { 
    this.eventsForm = this.formBuilder.group({
      remarks: [''],
      id: ['']
    });
    this.commonService.eventRemarksDataEvent.subscribe((resp: any) => {
      if (resp.events) {
        this.eventsData = resp.events;
        this.eventsForm.patchValue({remarks: this.eventsData.attributes?.remarks});
        this.getDeviceInforamtion();
      }
    })
  }
  getDeviceInforamtion() {
    this.commonService.getRequest('devices/'+this.eventsData.deviceId, []).then((resp: any) => {
      if (resp?.status) {
        this.deviceData = resp.data;
      }
    });
  }
  ngOnInit(): void {
    this.tabChange("events");
  }

  tabChange(val: any) {
    this.isEventsTab = false;
    this.isVehiclesTab = false;
    this.isCustomertab = false;
    if (val == 'events') {
      this.isEventsTab = true;
    }
    else if (val == 'vehicle') {
      this.isVehiclesTab = true;
    }
    else if (val == 'customer') {
      this.isCustomertab = true;
    }
  }

  saveEvents() {
    this.commonService
          .putRequest('events/update/' + this.eventsData.id, {
            remarks: this.eventsForm.value.remarks
          })
          .then((resp: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Your remarks has been Updated Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
            this.commonService.commonModel.next(false);
            this.commonService.updateNotificationtabInTracking.next({val: 'notification'});
          },(err)=>{
            console.log(err);
             Swal.fire({
               icon: "error",
               title: "Something Went Wrong",
               showConfirmButton: false,
               timer: 1500,
             });
           });
  }

  cancelEvents() {
    this.commonService.commonModel.next(false);
  }

}
