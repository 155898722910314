import { Injectable } from '@angular/core';
import { of, forkJoin, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { siteDetails } from 'src/assets/urlwiseconfig';
import * as jsonData from '../../assets/js/siteDetails.json';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private token = null;
  public socketConnection : any;
  public isFullscreenMap: boolean = false;
  public selectedCustObjectFromList: any = {};
  isAdminLoginForOtherUser = new Subject<boolean>();
  public commonModel = new Subject<boolean>();
  public commonModelReset = new Subject<any>();
  public pageLoadInModal = new Subject<any>();
  public headerEvent = new Subject<any>();
  public modalResponseReturn = new Subject<any>();
  public reportsParameterForHistory = new Subject<any>();
  public eventRemarksDataEvent = new Subject<any>();
  public updateNotificationtabInTracking = new Subject<any>();
  audio:any;
  router:Router;
  private BASE_URL = environment.apiUrl;
  addressBylatLong:any = {};
  private httpOptions = {};
  Language = new BehaviorSubject<string>('en');
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.httpOptions = {
      
    };
  }
  closeSocket() {
    if(this.socketConnection){
      this.socketConnection.close();
    }
  }
  getSiteDetails() {
    let siteDomain = window.location.host;
    let data: any = (jsonData as any).default;
    let filterSiteDetails =  data.find(site=>siteDomain.indexOf(site.name) >= 0);
    return filterSiteDetails && filterSiteDetails.details ? filterSiteDetails.details : { logo:'',text:'Not Found',name:'NA',"b64":''} 
  }
  getRequest(apiUrl: string, parameter: Array<string>,withoutContentType=false,withoutAuth=false) {
    return new Promise(async (resolve) => {
      const basicToken = localStorage.getItem('basicToken');
      if(withoutContentType){
        this.httpOptions = {
          
        };
      } else {
        this.httpOptions = {
          'Content-Type': 'application/json; charset=utf8',
        };
      }
      if (!withoutAuth && basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      this.httpOptions['Accept']='application/json';

      let extraParam = '';
      if (parameter?.length) {
        extraParam = '?' + parameter.join('&');
      }
      const response = await fetch(this.BASE_URL + apiUrl + extraParam, {
        method: 'GET',
        credentials: 'include',
        headers: this.httpOptions,
      });
      if (response.ok) {
        try {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        } catch (error) {
          
        resolve({
          status: false,
          data: '',
        });
        }
      } else {
        const responseJson = await response;
        resolve({
          status: false,
          data: responseJson,
        });
      }
    });
  }

  postRequest(apiUrl: string, body: any) {
    return new Promise(async (resolve,reject) => {
      this.httpOptions = {
        'Content-Type': 'application/json; charset=utf8',
      };
      const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }

      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
        headers: this.httpOptions
      });
      if (response.ok) {
        if(response.status == 204) {
          resolve({
            status: true,
            data: '',
          });
        } else {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        }
        
      } else {
        const responseJson = await response.text();
        reject({
          status: false,
          data: responseJson,
        });
      }
    });
  }

  putRequest(apiUrl: string, body: any) {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    return new Promise(async (resolve,reject) => {
      const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }

      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(body),
        headers: this.httpOptions,
      });
      if (response.ok) {
        const responseJson = await response.json();
        resolve({
          status: true,
          data: responseJson,
        });
      } else {
        const responseJson = await response.text();
        reject({
          status: false,
          data: responseJson,
        });
      }
    });
  }
  otherUserLogin(userId,adminId=0) {
    this.getRequest('session/'+ userId,null,true).then((resp: any) => {
      this.getRequest('server',null,true).then((resp: any) => {
        this.getRequest('session',null,true,true).then((resp: any) => {
          if (resp?.status) {
            this.setAdminOtherLogin(resp.data,adminId)
            
          }
        });
      });
    });
  }
  setAdminOtherLogin(responseData,adminId){
    
    localStorage.setItem('temp_adm_user_id',adminId);
    localStorage.setItem('Admin_User_Id',  localStorage.getItem('userData'));
    localStorage.setItem('Admin_token', localStorage.getItem('basicToken'));

    localStorage.setItem('userData', JSON.stringify(responseData));
    localStorage.setItem('basicToken', responseData.token);
    this.isAdminLoginForOtherUser.next(adminId ? true:false);
  }
  backToAdminSession(adminId){
    localStorage.removeItem('temp_adm_user_id');
  }
  deleteRequest(apiUrl: string, body: any = null) {
    this.httpOptions = {
      'Content-Type': 'application/json; charset=utf8',
    };
    return new Promise(async (resolve) => {
      const basicToken = localStorage.getItem('basicToken');
      if (basicToken) {
        this.httpOptions['Authorization'] = 'Basic ' + basicToken;
      }
      try {
        let dataSet = {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
        };

        if (body) {
          dataSet['body'] = JSON.stringify(body);
        }
        const response = await fetch(this.BASE_URL + apiUrl, {
          method: 'DELETE',
          credentials: 'include',
          headers: this.httpOptions,
          body : (body? JSON.stringify(body):'')
        });
        if (response.ok) {
          const responseJson = await response.json();
          resolve({
            status: true,
            data: responseJson,
          });
        } else {
          resolve({
            status: false,
            data: response,
          });
        }
      } catch (error) {
        resolve({
          status: false,
          data: null,
        });
      }
    });
  }
  postRequestSesstion(apiUrl,body) {
    let _url = this.BASE_URL + apiUrl;
    
  let options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    withCredentials: true
  };
      return this.http.post<any>(_url,body,options);
  }
  

  postFormRequest(apiUrl: string, body: any,isAuth = false) {
    return new Promise(async (resolve) => {
      const basicToken = localStorage.getItem('basicToken');
      
      let header = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf8',
      }
      if (basicToken && isAuth) {
        header['Authorization'] = 'Basic ' + basicToken;
      }
      const response = await fetch(this.BASE_URL + apiUrl, {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: header,
      });
      if (response.ok) {
        const responseJson = await response.json();
        resolve({
          status: true,
          data: responseJson,
        });
      } else {
        resolve({
          status: false,
          data: response,
        });
      }
    });
  }

  getUserDetails() {
    let userDetails = {};
    let user = localStorage.getItem('userData');
    if (user) {
      userDetails = JSON.parse(user);
    }
    return of(userDetails);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any) => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  getDeviceAddress(latitude,longitude,deviceId = '') {
    if(!(latitude && longitude&& this.addressBylatLong[latitude+'_'+longitude])){
      let params = [
        'latitude=' + longitude,
        'longitude=' + latitude,
        'maxDistance=1000'
      ];
      if (latitude && longitude) {
        this
        .getRequest('statistics/getAddress?' + params.join('&'), [])
        .then((resp: any) => {
          if(resp.data && resp.data.address){
            if(deviceId) {
              this.addressBylatLong[deviceId] = resp.data.address;
            }
            this.addressBylatLong[latitude+'_'+longitude] = resp.data.address;
          }
        }).finally(()=>{
         
        });
    }
    }
    

    
  }
  playAudio(notifSignal){
    if(notifSignal == 3){
      this.audio = new Audio();
      this.audio.src = 'assets/audio/notification.mp3';
      this.audio.play();
      Audio.prototype.stop = function()
      {
          this.pause();
          this.currentTime = 0.0;
      }
    }else{
      this.audio = new Audio();
      this.audio.src = 'assets/audio/notification.mp3';
      this.audio.play();
    }
  }
  // getWorldTimeZoneList() {
  //   return this.http.get("http://worldtimeapi.org/api/timezone", {headers: this.httpOptions});
  // }
}
