<p class="component my-1">{{'Dashboard'|translate}} <span class="trackcolor">/ Manage Trips</span></p>

<div class="container-fluid ">

  <div class="row p-0">
    <div class="card p-0 headcard">

      <!-- HEADER WITH ADD POI BTNS -->
      <div class="card-header1 flex_row_center_spacebetween">
        <div class="cardHead_text">Manage Trips</div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <div style="display: flex;flex-direction: row;">
              <button mat-raised-button class="addbtns px-3 py-1" style="margin-right: 1vh;" data-bs-toggle="modal"
                data-bs-target="#staticBackdrop">
                <span class="material-icons" style="font-size: 2.1vh;"> add</span> Add Trip
              </button>
              <div class="input-group searchvehicles">
                <input type="text" class="form-control reportsbox" placeholder="{{'Search'|translate}}"
                  aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button" style="padding: 1vh 1.4vh 1.1vh 1.4vh;">
                    <img src="/assets/icons/searchicon.png" alt="search" style="width: 2.3vh;">
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- DATE DOWNLOAD HEADER -->
      <div class="card-header flex_row_center_spacebetween">
        <div class="flex_row_center_spacebetween" style="width: 32vh;">
          <select class="form-select selectimportant" [(ngModel)]="pageOption" name="pageOption">
            <option value="10" class="select_options">10</option>
            <option value="20" class="select_options">20</option>
            <option value="30" class="select_options">30</option>
            <option value="40" class="select_options">40</option>
            <option value="50" class="select_options">50</option>
            <option value="60" class="select_options">60</option>
          </select>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;">
          <span class="btn"><img src="/assets/icons/report_refresh.png" alt="refresh" style="width: 96%;"></span>
          <span class="btn"><img src="/assets/icons/report_download.png" alt="download" style="width: 96%;"></span>
        </div>
      </div>

      <!-- POI LIST TABLE -->
      <div class="card-body common_scroller fixed_height p-0">
        <table class="table table-borderless m-0" style="border-bottom: 1px solid #E3E7EC;">

          <thead style="background-color:  #24282E;">
            <tr>
              <th class="table_head_text text-center">Route Name</th>
              <th class="table_head_text text-center">Vehicle Name</th>
              <th class="table_head_text text-center">LR Number</th>
              <th class="table_head_text text-center">Driver</th>
              <th class="table_head_text text-center">Start Time</th>
              <th class="table_head_text text-center">End Time</th>
              <th class="table_head_text text-center">Actual start time</th>
              <th class="table_head_text text-center">Actual end time</th>
              <th class="table_head_text text-center">Status</th>
              <th class="table_head_text text-center">Trip type</th>
              <th class="table_head_text text-center">Action</th>
            </tr>
          </thead>

          <tbody *ngIf="tripList.length">

            <tr *ngFor="let trip of tripList; let i = index">
              <td class="align-items-center table_data d-flex" style="padding-left: 3vh;">{{trip.Route_Name}}</td>
              <td class="table_data text-center">{{trip.Device_Name}}</td>
              <td class="table_data text-center">{{trip.lrNumber}}</td>
              <td class="table_data text-center">{{trip.drivername}}</td>
              <td class="table_data text-center">{{trip.Planned_Start_Time | date : 'medium' }}</td>
              <td class="table_data text-center">{{trip.Planned_End_Time | date : 'medium' }}</td>
              <td class="table_data text-center">{{trip.Actual_Start_Time | date : 'medium' }}</td>
              <td class="table_data text-center">{{trip.Actual_End_Time | date : 'medium' }}</td>
              <td class="table_data text-center">{{trip.Status}}</td>
              <td class="table_data text-center">{{trip.tripType===1?'One way':'round trip'}}</td>
              <td class="table_data text-center" style="cursor: pointer;">
                <div class="dropdown">
                  <button class="btn dropdown-toggle p-0 border-none" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="/assets/icons/hollow_options.png" alt="car">
                  </button>
                  <ul class="dropdown-menu option_drop py-0" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;" (click)="onEditTrip(trip._id)">
                        <img src="/assets/icons/editgeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">{{'Edit'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;" (click)="onDeleteTrip(trip._id)">
                        <img src="/assets/icons/deletegeo.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">{{'Delete'|translate}}</span>
                      </a></li>
                    <li><a class="dropdown-item pt-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/play.png" alt="edit" style="width: 3.3vh;">
                        <span class="editDeletebtns" style="padding-left: 1.5vh;">Trip History</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/addexpense.png" alt="edit" style="padding: 1vh;
                            background-color: #f84a67;
                            border-radius: 24px;">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">Add Expenses</span>
                      </a></li>
                    <li><a class="dropdown-item py-2" style="justify-content: flex-start;">
                        <img src="/assets/icons/expenselist.png" alt="edit">
                        <span class="editDeletebtns" style="padding-left: 2.5vh;">Expense List</span>
                      </a></li>
                  </ul>
                </div>
              </td>
            </tr>

          </tbody>

          <tbody *ngIf="!tripList.length">
            <tr>
              <td colspan="11">
                <div class="d-flex justify-content-center align-items-center" style="height: 48vh;">
                  <img src="/assets/images/datanotfound.png" alt="NO DATA AVAILABLE IN TABLE">
                </div>
              </td>
            </tr>
          </tbody>

        </table>
        <div *ngIf="tripList.length" class="flex_row_center_spacebetween" style="padding: 1vh 2vh;">
          <div>{{'Showing'|translate}} 1 to 9 of 9 entries</div>
          <div>1 2 3 4 6 7 8 9 10</div>
        </div>
      </div>

    </div>
  </div>

</div>

<!-- ADD TRIP MODAL -->

<div class="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content content1">
      <div class="modal-header addtriphead">
        <div class="headtext">Add Trip</div>
        <button type="button" class="btnclose" data-bs-dismiss="modal" aria-label="Close" click="onCloseModal()">
          <span class="material-icons">
            close
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addtripForm" (ngSubmit)="onTripSubmit()">

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="routename" class="addlabels pb-2">Route Name</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="routename" formControlName="routename">
                  <option value="" disabled>Select Route Name</option>
                  <option *ngFor="let route of routeNameList; let i = index" value="{{route.name}}">{{route.name}}</option>
                </select>
                <span class="text-danger validation"
                *ngIf="(AddTripFormControl.routename.touched || submitted) && AddTripFormControl.routename.errors?.required">
                *Routename is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="vehiclename" class="addlabels pb-2">Vehicle Name</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="vehiclename" formControlName="vehiclename">
                  <option value="" disabled>Select Vehicle Name</option>
                  <option *ngFor="let device of DeviceArray; let i = index" value="{{device.Device_Name}}">{{device.Device_Name}}</option>
                </select>
                <span class="text-danger validation"
                *ngIf="(AddTripFormControl.vehiclename.touched || submitted) && AddTripFormControl.vehiclename.errors?.required">
                *Vehiclename is required.
              </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="drivername" class="addlabels pb-2">Driver Name</label>
                <input type="text" formControlName="drivername" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="drivername"
                  aria-describedby="drivername">
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.drivername.touched || submitted) && AddTripFormControl.drivername.errors?.required">
                  *Drivername is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="loadingsite" class="addlabels pb-2">Loading Site</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="loadingsite" formControlName="loadingsite">
                  <option value="" disabled>Select Loading Site</option>
                  <option *ngFor="let geo of geofenceNameList; let i = index" value="{{geo._id}}">{{geo.geoname}}</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.loadingsite.touched || submitted) && AddTripFormControl.loadingsite.errors?.required">
                  *Loading Site is required.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="unloadingsite" class="addlabels pb-2">Unloading Site</label>
                <select class="form-select form_select1" style="background-color: #FFF;box-shadow:none;color:#000;height: 5.6vh;" id="unloadingsite" formControlName="unloadingsite">
                  <option value="" disabled>Select UnLoading Site</option>
                  <option *ngFor="let geo of geofenceNameList; let i = index" value="{{geo._id}}">{{geo.geoname}}</option>
                </select>
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.unloadingsite.touched || submitted) && AddTripFormControl.unloadingsite.errors?.required">
                  *Unloading Site is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="start" class="addlabels pb-2">Start Date/Time</label>
                <input type="date" id="start" name="start" formControlName="start" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;">
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.start.touched || submitted) && AddTripFormControl.start.errors?.required">
                  *Start Date/Time is required.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="end" class="addlabels pb-2">End Date/Time</label>
                <input type="date" id="end" name="end" formControlName="end" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;">
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.end.touched || submitted) && AddTripFormControl.end.errors?.required">
                  *End Date/Time is required.
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pb-2">
                <label for="lrnumber" class="addlabels pb-2">LR Number</label>
                <input type="text" formControlName="lrnumber" class="form-control inputfields" style="background: #FFF;box-shadow:none;color:#000;" id="lrnumber"
                  aria-describedby="lrnumber">
                <span class="text-danger validation"
                  *ngIf="(AddTripFormControl.lrnumber.touched || submitted) && AddTripFormControl.lrnumber.errors?.required">
                  *LR Number is required.
                </span>
              </div>
            </div>
          </div>

          <div class="row pt-3 pb-4">
            <mat-radio-group formControlName="radiocheck">
              <mat-radio-button class="addlabels" style="margin-right:2vh;" value="1">One Way Trip
              </mat-radio-button>
              <mat-radio-button class="addlabels" style="margin-right:2vh;" value="2">Two Way Trip
              </mat-radio-button>
              <mat-radio-button class="addlabels" value="3">Round Trip</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="flex_row_center_spacebetween pt-4 pb-2" style="border-top: 1px solid gray;">
            <button mat-raised-button class="cancelbtn" data-bs-dismiss="modal">{{'CANCEL'|translate}}</button>
            <button mat-raised-button type="submit" class="cancelbtn">{{'SAVE' | translate}}</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
